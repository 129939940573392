<template>
  <runai-app-loader v-if="!appLoaded" />
  <router-view class="app-main" v-if="preAppDataLoaded" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAppStore } from "./stores/app.store";
import { useAuthStore } from "./stores/auth.store";

// components
import { RunaiAppLoader } from "./components/common/runai-app-loader";

// models
import { ERROR_ROUTE_NAMES } from "@/router/error.routes/error.routes.names";

export default defineComponent({
  components: {
    RunaiAppLoader,
  },
  data() {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      preAppDataLoaded: false as boolean,
    };
  },
  async created() {
    this.appStore.setAppLoaded(false);

    try {
      this.preAppDataLoaded = false;
      await this.loadAppConfiguration();
      this.preAppDataLoaded = true;
    } catch (error: unknown) {
      console.error("Something went wrong during the app's initial loading. ", error);
      await this.$router.replace({ name: ERROR_ROUTE_NAMES.BAD_GATEWAY_ERROR });
    } finally {
      this.preAppDataLoaded = true;
    }
  },
  computed: {
    appLoaded(): boolean {
      return this.appStore.appLoaded;
    },
  },
  methods: {
    async loadAppConfiguration(): Promise<void> {
      this.authStore.loadTokenFromLocalStorage();
      await this.appStore.loadAppData();
      if (this.appStore.tenantName) {
        await this.authStore.loadAuthProviderConfig(this.appStore.config.authHost);
      }
    },
  },
});
</script>
<style scoped lang="scss"></style>
