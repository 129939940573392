import { defineStore } from "pinia";

// models
import type {
  AssetsDefaults,
  PolicyInfo,
  Training,
  TrainingCreationRequestV2,
  TrainingSpecificRunParams,
  TrainingV2,
  WorkloadCreationRequest,
} from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IUIWorkloadCreation } from "@/models/workload.model";
import type { Workload } from "@/swagger-models/workloads-service-client";

// services
import { trainingService } from "@/services/control-plane/training.service/training.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { permissionService } from "@/services/authorization/permission.service/permission.service";
import { deepCopy, retryCall } from "@/utils/common.util/common.util";
import type { ILoadedItemizeDefaults } from "@/models/policy.model";

export const useTrainingStore = defineStore("Training", {
  state: () => ({
    training: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    templateId: null as string | null,
    selectedTraining: null as TrainingV2 | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,

    // these details are treat as read only after setting (saving the origin model with policies set)
    loadedTrainingModelWithDefaultsAndPolicy: null as TrainingV2 | null,
  }),
  getters: {
    trainingName(): string {
      return this.training.name || "";
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedPolicyInfo(): PolicyInfo | null {
      return this.loadedTrainingModelWithDefaultsAndPolicy?.policyInfo || null;
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedItemizeDefaults(): ILoadedItemizeDefaults | null {
      const itemizeDefaults = {
        annotations: {
          instances: this.loadedTrainingModelWithDefaultsAndPolicy?.specificRunParams?.annotations || null,
        },
        labels: { instances: this.loadedTrainingModelWithDefaultsAndPolicy?.specificRunParams?.labels || null },
        environmentVariables: {
          instances: this.loadedTrainingModelWithDefaultsAndPolicy?.specificRunParams?.environmentVariables || null,
        },
        tolerations: {
          instances: this.loadedTrainingModelWithDefaultsAndPolicy?.specificRunParams?.tolerations || null,
          attributes:
            this.loadedTrainingModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.specificRunParams
              ?.tolerations || null,
        },
      };
      return deepCopy(itemizeDefaults);
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedPolicyInfoDistributedMaster(): PolicyInfo | null {
      return this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.policyInfo || null;
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedSpecificRunParamsWithDefaults(): TrainingSpecificRunParams | null {
      return deepCopy(this.loadedTrainingModelWithDefaultsAndPolicy?.specificRunParams || null);
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedItemizeDefaultsDistributedMaster(): ILoadedItemizeDefaults | null {
      const itemizeDefaults = {
        annotations: {
          instances:
            this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.specificRunParams?.annotations || null,
        },
        labels: {
          instances:
            this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.specificRunParams?.labels || null,
        },
        environmentVariables: {
          instances:
            this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.specificRunParams
              ?.environmentVariables || null,
        },
        tolerations: {
          instances:
            this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.specificRunParams?.tolerations || null,
          attributes:
            this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.policyInfo?.attributeDefaults
              ?.specificRunParams?.tolerations || null,
        },
      };
      return deepCopy(itemizeDefaults);
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedSpecificRunParamsWithDefaultsDistributedMaster(): TrainingSpecificRunParams | null {
      return deepCopy(this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.specificRunParams || null);
    },
    loadedAssetsAttributesDefaults(): AssetsDefaults | null {
      return this.loadedTrainingModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.assets || null;
    },
    loadedAssetsAttributesDefaultsMaster(): AssetsDefaults | null {
      return (
        this.loadedTrainingModelWithDefaultsAndPolicy?.distributed?.master?.policyInfo?.attributeDefaults?.assets || null
      );
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Trainings);
    },
    setLoadedTrainingModelWithDefaults(model: TrainingV2): void {
      this.loadedTrainingModelWithDefaultsAndPolicy = model;
    },
    setTraining(training: IUIWorkloadCreation): void {
      this.training = training;
    },
    setTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },
    resetCreateTrainingData(): void {
      this.templateId = null;
      this.loadedTrainingModelWithDefaultsAndPolicy = null;
      this.training = workloadUtil.getEmptyUIWorkloadCreation();
    },
    async createTrainingV2(training: TrainingCreationRequestV2): Promise<TrainingV2> {
      return trainingService.createTrainingV2(training);
    },
    async createTraining(training: WorkloadCreationRequest): Promise<Workload | null> {
      const createdTraining: Training = await trainingService.createFromAssets(training);
      return retryCall<Workload>(() => workloadService.getWorkloadById(createdTraining.meta.id)).catch(() => null);
    },
  },
});
