// services
import { pick } from "@/utils/common.util";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";
import { httpService } from "@/services/infra/https.service/http.service";

// models
import type {
  HttpResponse,
  WorkloadTemplate,
  WorkloadTemplateCreationRequest,
  WorkloadTemplateUpdateRequest,
} from "@/swagger-models/assets-service-client";
import type { IAssetsFilter } from "@/models/filter.model";
import type { IUIWorkloadTemplateCreationRequestModel } from "@/models/workload-template.model";

export const workloadTemplateService = {
  list,
  getById,
  remove,
  create,
  update,
  getEmptyWorkloadTemplateModel,
};

// api calls

async function list(filterBy: IAssetsFilter): Promise<Array<WorkloadTemplate>> {
  const filters: IAssetsFilter = pick(
    filterBy,
    "sortBy",
    "page",
    "rowsPerPage",
    "scope",
    "projectId",
    "clusterId",
    "isWorkspace",
    "isTraining",
    "isDistributed",
    "distributedFramework",
  );
  try {
    return assetsServiceApi.templateApi
      .listTemplates(
        undefined,
        filters.scope,
        Number(filters.projectId) || undefined,
        filters.departmentId || undefined,
        filterBy.clusterId || undefined,
        filters.distributedFramework || undefined,
        filters.isDistributed,
        filters.isTraining,
        filters.isWorkspace,
      )
      .then((res) => res.data.entries);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getById(id: string): Promise<WorkloadTemplate> {
  try {
    const response = await assetsServiceApi.templateApi.getTemplateById(id);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function create(workloadTemplate: WorkloadTemplateCreationRequest): Promise<WorkloadTemplate> {
  try {
    const response = await assetsServiceApi.templateApi.createTemplate(workloadTemplate);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function remove(workloadTemplate: WorkloadTemplate): Promise<HttpResponse> {
  try {
    const response = await assetsServiceApi.templateApi.deleteTemplateById(workloadTemplate.meta.id);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function update(templateId: string, template: WorkloadTemplateUpdateRequest): Promise<WorkloadTemplate> {
  try {
    const response = await assetsServiceApi.templateApi.updateTemplate(templateId, template);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

// helpers
function getEmptyWorkloadTemplateModel(): IUIWorkloadTemplateCreationRequestModel {
  return {
    meta: {
      name: "",
      description: null,
      scope: null,
      clusterId: null,
      projectId: null,
    },
    spec: {
      assets: {
        environment: "",
        compute: null,
        datasources: [],
      },
      specificEnv: {
        allowOverQuota: false,
        args: "",
        command: "",
        environmentVariables: [],
        connections: [],
        runAsGid: null,
        runAsUid: null,
        backoffLimit: 6,
      },
    },
  };
}
