<template>
  <div class="workload-node-pool-list-item">
    {{ nodePool }}

    <runai-tooltip
      icon="fa-regular fa-xmark"
      @click="$emit('remove')"
      dark-mode
      tooltip-text="Remove"
      class="remove-button"
      v-show="removeable"
    />
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";

export default defineComponent({
  name: "workload-node-pool-list-item",
  components: {
    RunaiTooltip,
  },
  emits: ["remove"],
  props: {
    nodePool: {
      type: String as PropType<string>,
      required: true,
    },
    removeable: {
      type: Boolean as PropType<boolean>,
      false: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.workload-node-pool-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .remove-button {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .remove-button {
      opacity: 1;
    }
  }
}
</style>
