import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { ISetting, SettingKeys, TSettingValue } from "@/models/setting.model";

import { K8S_API } from "@/common/api.constant";

export const settingService = {
  get,
  update,
  getByKey,
};

const ENDPOINT = `${K8S_API.v1}/setting`;

// api calls
async function get(): Promise<Map<string, ISetting>> {
  const setting = await controlPlaneService.get(ENDPOINT);
  return setting.reduce((acc: Map<string, ISetting>, item: ISetting) => {
    acc.set(item.key, item);
    return acc;
  }, new Map());
}

async function update(key: SettingKeys, value: TSettingValue): Promise<void> {
  await controlPlaneService.put(ENDPOINT, { key, value });
}

async function getByKey(key: SettingKeys): Promise<TSettingValue | undefined> {
  const setting = await controlPlaneService.get(`${ENDPOINT}/${key}`);
  return setting;
}
