<template>
  <span>{{ colLabel }}</span>
  <runai-filter-chip
    @click.stop
    :value="chipValue"
    :icon-name="config.iconName"
    @save="onFilterChanged"
    @close="selectedValue = filterByValue"
    size="sm"
    chip-value-text-weight="regular"
    menu-title="Show the last"
  >
    <template v-slot:input-component>
      <runai-radio-options v-model="selectedValue" :options="config.options" />
    </template>
  </runai-filter-chip>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ICustomHeaderRadioButtonsConfig, ITableColumn } from "@/models/table.model";
import RunaiFilterChip from "@/components/common/runai-filter-chip/runai-filter-chip.vue";
import RunaiRadioOptions from "@/components/common/runai-radio-options/runai-radio-options.vue";
import type { ISelectOption } from "@/models/global.model";
import type { IFilterBy } from "@/models/filter.model";
//cmps

//model
interface ISelectRadioButtonsTable extends ISelectOption {
  value: string;
}
export default defineComponent({
  name: "radio-buttons-table-header",
  components: { RunaiRadioOptions, RunaiFilterChip },
  emits: ["custom-header-event"],
  props: {
    col: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
    filterBy: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
  },
  data() {
    return {
      selectedValue: "" as ISelectRadioButtonsTable["value"],
      filterByValue: "" as string,
    };
  },
  created() {
    this.filterByValue = this.filterBy[this.config.selectedValueFilterByKey as keyof IFilterBy] as string;
    this.selectedValue =
      (this.config.options.find((option) => option.value === this.filterByValue)?.value as string | undefined) || "";
  },
  computed: {
    config(): ICustomHeaderRadioButtonsConfig {
      return this.col.customHeaderConfig as ICustomHeaderRadioButtonsConfig;
    },
    colLabel(): string {
      return this.col.label;
    },
    chipValue(): string {
      const value = this.config.options.find((option) => option.value === this.filterByValue)?.shortLabel || "";
      return `${this.config.chipPrefix} ${value}`;
    },
  },
  methods: {
    onFilterChanged(): void {
      this.filterByValue = this.selectedValue;
      this.$emit("custom-header-event", this.selectedValue);
    },
  },
});
</script>

<style scoped lang="scss"></style>
