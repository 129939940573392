export { addOrMoveItemToBeFirst };

function addOrMoveItemToBeFirst(items: string[], newItem: string): string[] {
  const index = items.findIndex((item) => item === newItem);

  if (index !== -1) {
    // Item exists, move it to the first position
    const [item] = items.splice(index, 1);
    items.unshift(item);
  } else {
    // Item does not exist, add it to the first position
    items.unshift(newItem);
  }

  return items;
}
