import { defineStore } from "pinia";

// models
import type {
  AssetsDefaults,
  PolicyInfo,
  WorkloadCreationRequest,
  Workspace,
  WorkspaceCreationRequestV2,
  WorkspaceSpecificRunParams,
  WorkspaceV2,
} from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IUIWorkloadCreation } from "@/models/workload.model";
import type { Workload } from "@/swagger-models/workloads-service-client";

// services
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { permissionService } from "@/services/authorization/permission.service/permission.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { deepCopy, retryCall } from "@/utils/common.util/common.util";
import type { ILoadedItemizeDefaults } from "@/models/policy.model";

export const useWorkspaceStore = defineStore("Workspace", {
  state: () => ({
    workspace: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    templateId: null as string | null,
    selectedWorkspace: null as WorkspaceV2 | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,

    // these details are treat as read only after setting (saving the origin model with policies set)
    loadedWorkspaceModelWithDefaultsAndPolicy: null as WorkspaceV2 | null,
  }),
  getters: {
    workspaceName(): string {
      return this.workspace.name || "";
    },
    loadedPolicyInfo(): PolicyInfo | null {
      return this.loadedWorkspaceModelWithDefaultsAndPolicy?.policyInfo || null;
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedItemizeDefaults(): ILoadedItemizeDefaults | null {
      const itemizeDefaults = {
        annotations: {
          instances: this.loadedWorkspaceModelWithDefaultsAndPolicy?.specificRunParams?.annotations || null,
        },
        labels: { instances: this.loadedWorkspaceModelWithDefaultsAndPolicy?.specificRunParams?.labels || null },
        environmentVariables: {
          instances: this.loadedWorkspaceModelWithDefaultsAndPolicy?.specificRunParams?.environmentVariables || null,
        },
        tolerations: {
          instances: this.loadedWorkspaceModelWithDefaultsAndPolicy?.specificRunParams?.tolerations || null,
          attributes:
            this.loadedWorkspaceModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.specificRunParams
              ?.tolerations || null,
        },
      };
      return deepCopy(itemizeDefaults);
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedSpecificRunParamsWithDefaults(): WorkspaceSpecificRunParams | null {
      return deepCopy(this.loadedWorkspaceModelWithDefaultsAndPolicy?.specificRunParams || null);
    },
    loadedAssetsAttributesDefaults(): AssetsDefaults | null {
      return this.loadedWorkspaceModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.assets || null;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Workspaces);
    },
    setLoadedWorkspaceModelWithDefaults(model: WorkspaceV2): void {
      this.loadedWorkspaceModelWithDefaultsAndPolicy = model;
    },
    setWorkspace(workspace: IUIWorkloadCreation): void {
      this.workspace = workspace;
    },
    setTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },
    resetCreateWorkspaceData(): void {
      this.templateId = null;
      this.loadedWorkspaceModelWithDefaultsAndPolicy = null;
      this.workspace = workloadUtil.getEmptyUIWorkloadCreation();
    },
    async createWorkspaceV2(workspace: WorkspaceCreationRequestV2): Promise<WorkspaceV2> {
      return workspaceService.createWorkspaceV2(workspace);
    },
    async createWorkspace(workspace: WorkloadCreationRequest): Promise<Workload | null> {
      const createdWorkspace: Workspace = await workspaceService.createFromAssets(workspace);
      return retryCall<Workload>(() => workloadService.getWorkloadById(createdWorkspace.meta.id)).catch(() => null);
    },
  },
});
