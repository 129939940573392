<template>
  <q-expansion-item
    :label="label"
    class="runai-sub-expansion-item"
    expand-icon-class="runai-expansion-icon"
    expand-separator
    expand-icon-toggle
    v-model="isOpen"
  >
    <template #header>
      <div class="runai-sub-expansion-header" @click="isOpen = !isOpen" :aid="aid">
        {{ label }}
      </div>
      <div class="information-bar-container">
        <runai-information-bar v-if="informationBar && !isOpen">
          <div class="information-bar-container-text">
            {{ informationBar }}
          </div>
        </runai-information-bar>
      </div>
    </template>
    <slot />
  </q-expansion-item>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { RunaiInformationBar } from "@/components/common/runai-information-bar";

export default defineComponent({
  components: {
    RunaiInformationBar,
  },
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    defaultOpened: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    informationBar: {
      type: String as PropType<string>,
      required: false,
    },
    aid: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      isOpen: !!this.defaultOpened,
    };
  },
});
</script>

<style lang="scss">
.runai-sub-expansion-item {
  width: 100%;

  hr {
    display: none;
  }
  .runai-expansion-icon {
    color: $primary;
    .q-icon {
      font-size: 14px;
    }
  }

  .q-item.q-hoverable {
    margin-top: 20px;
    padding: 10px 0;

    &:hover {
      .q-focus-helper {
        opacity: 0;
      }
    }
  }

  .runai-sub-expansion-header {
    font-weight: 500;
    color: $primary;
    display: flex;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
  }

  .information-bar-container {
    position: absolute;
    width: 580px;
    right: 5px;
    top: -4px;

    .information-bar-container-text {
      width: 430px;
    }
  }
}
</style>
