<template>
  <section class="clusters-dashboard-index">
    <runai-grafana-iframe class="iframe" :dashboard-name="dashboardName" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiGrafanaIframe } from "@/components/common/runai-grafana-iframe";
import { useAppStore } from "@/stores/app.store";
import { EDashboardName } from "@/models/dashboard.model";

export default defineComponent({
  name: "clusters-dashboard-index",
  components: {
    RunaiGrafanaIframe,
  },
  data() {
    return {
      dashboardName: EDashboardName.Clusters,
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
});
</script>

<style lang="scss">
.clusters-dashboard-index {
  height: 100%;
  display: flex;
  overflow: hidden;

  .iframe {
    flex: 1;
  }
}
</style>
