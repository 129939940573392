export enum EEnvTypes {
  Local = "local",
  OnPremise = "on-premise",
  Staging = "staging",
  Test = "test",
  CustomerTest = "customer-test",
  CustomerStaging = "customer-staging",
  CustomerCloud = "customer-cloud",
  Cloud = "cloud",
  Unknown = "unknown",
}
export type IConfig = {
  authClientID: string;
  tenantName: string;
  env: string;
  authHost: string;
  isMultiTenant: boolean;
};
