import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

export const fileService = {
  downloadFile,
};

export enum FileType {
  csv = 1,
}

function getFileName(requestedFilename: string | undefined, contentDispositionHeader?: string): string {
  if (requestedFilename) {
    return requestedFilename;
  }
  let fileName = "data.csv";
  if (contentDispositionHeader?.includes("attachment")) {
    const splitHeader = contentDispositionHeader.split("=");
    if (splitHeader?.length == 2) {
      fileName = splitHeader[1];
    }
  }
  return fileName;
}

async function downloadFile(
  path: string,
  fileType: FileType,
  filename?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any,
): Promise<void> {
  if (fileType == FileType.csv) {
    options = {
      headers: { Accept: "text/csv" },
      ...options,
    };
  }
  const response = await controlPlaneService.getFullData(path, query, { responseType: "blob", ...options });

  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link: HTMLAnchorElement = document.createElement("a");
  link.href = href;
  filename = getFileName(filename, response.headers["content-disposition"]);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
