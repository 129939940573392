<template>
  <q-slider
    class="q-mb-md"
    aid="quota-slider"
    label-always
    switch-marker-labels-side
    switch-label-side
    color="light-blue"
    :track-color="trackColor"
    inner-track-color="grey-4"
    thumb-size="25px"
    track-size="8px"
    :model-value="modelValue"
    :label-color="labelColor"
    label-text-color="black"
    :thumb-color="labelColor"
    :marker-labels="objMarkerLabel"
    :inner-min="isLimited ? innerMin : undefined"
    :inner-max="isLimited ? innerMax : undefined"
    :max="max"
    :min="min"
    dense
    @update:model-value="updateModelValue"
  >
    <template v-slot:marker-label-group="scope">
      <div
        v-for="marker in scope.markerList"
        :key="marker.index"
        :style="marker.style"
        :class="[marker.classes]"
        @click="model = marker.value"
      >
        <div v-if="marker.value !== max || !hideMax" class="marker-value">
          {{ marker.value }}
        </div>
        <template v-if="showInnerLabel(marker.value)">
          <div
            :style="getInnerLabelStyle(marker.value)"
            v-text="innerLabelText(marker.value)"
            class="inner-label-text"
          />
        </template>
      </div>
    </template>
  </q-slider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { SliderMarkerLabelObjectDefinition } from "quasar";
import type { PropType } from "vue/dist/vue";

export default defineComponent({
  name: "quota-slider",
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },
    max: {
      type: Number as PropType<number>,
      required: true,
    },
    min: {
      type: Number as PropType<number>,
      default: 0,
    },
    innerMax: {
      type: Number as PropType<number>,
    },
    innerMin: {
      type: Number as PropType<number>,
    },
    isWarningOn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isLimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    innerMinMarkerText: {
      type: String as PropType<string>,
      default: "",
    },
    innerMaxMarkerText: {
      type: String as PropType<string>,
      default: "",
    },
    hideMax: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    objMarkerLabel(): SliderMarkerLabelObjectDefinition {
      return {
        [this.min]: this.min.toString(),
        ...(this.innerMin ? { [this.innerMin]: this.innerMin.toString() } : {}),
        ...(this.innerMax ? { [this.innerMax]: this.innerMax.toString() } : {}),
        [this.max]: this.max.toString(),
      };
    },
    trackColor(): string {
      if (this.isLimited) return "red-2";
      if (this.isWarningOn) return "amber-14";
      return "light-blue";
    },
    labelColor(): string {
      if (this.isWarningOn) {
        if (this.isLimited) return "red-2";
        return "amber-14";
      }
      return "light-blue";
    },
  },
  methods: {
    updateModelValue(val: number): void {
      this.$emit("update:model-value", val);
    },
    showInnerLabel(val: number): boolean {
      return (val === this.innerMin && this.innerMin > 0) || val === this.max || val === this.innerMax;
    },
    innerLabelText(val: number): string {
      if (val === this.innerMin && val > 0) {
        return this.innerMinMarkerText;
      }
      if (val === this.innerMax && !this.hideMax) {
        return this.innerMaxMarkerText;
      }
      return "";
    },
    getInnerLabelStyle(val: number): Record<string, string> {
      const topPosition = val === this.innerMax ? "-36px" : "40px";
      return {
        position: "absolute",
        top: topPosition,
        width: "50px",
        fontSize: "11px",
        lineHeight: "11px",
      };
    },
  },
});
</script>

<style scoped lang="scss">
.marker-value {
  font-size: 11px;
  font-weight: 500;
}
</style>
