<template>
  <div class="row items-center node-pools-summary" aid="node-pools-summary">
    <div class="col-1">
      <div class="icon-wrapper">
        <runai-svg-icon :name="iconName" size="22"></runai-svg-icon>
      </div>
    </div>
    <div class="col-11">
      <div class="row border-bottom q-pb-sm items-center text-subtitle1 text-weight-medium">
        <div class="col" v-if="isProjectEntity"></div>
        <div class="col" aid="summary-header" :class="isCpuEnabled ? 'q-mr-md' : 'q-mr-xl'">{{ header }}</div>
        <div class="col" aid="summary-total-gpu">{{ totalGpus }}</div>
        <template v-if="isCpuEnabled">
          <div class="col" aid="summary-total-cpu">{{ totalCpus }}</div>
          <div class="col" aid="summary-total-memory">{{ totalMemory }}</div>
        </template>
        <div class="col" v-if="isProjectEntity"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
//cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

//models
import { EQuotaEntity, EResourceType } from "@/models/resource.model";
//services
import { resourceUtil } from "@/utils/resource.util";

//stores
import { useSettingStore } from "@/stores/setting.store";
import type { Resources } from "@/swagger-models/org-unit-service-client";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  inject: ["entity"],
  props: {
    resources: {
      type: Array as PropType<Array<Resources>>,
      required: true,
    },
    isOverQuotaPriorityEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    isProjectEntity(): boolean {
      return this.entity === EQuotaEntity.project;
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    totalGpus(): string | number {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.GPU);
    },
    totalCpus(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.CPU);
    },
    totalMemory(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.MEMORY);
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    header(): string {
      return this.isProjectEntity ? "Project total" : "Department total";
    },
    iconName(): string {
      return this.isProjectEntity ? "project-icon" : "departments-icon";
    },
  },
});
</script>

<style lang="scss" scoped>
.node-pools-summary {
  min-height: 55px;
}
.total-display {
  text-align: center;
  font-weight: 500;
}
.border-bottom {
  border-bottom: 1px solid $black-12;
}
.icon-wrapper {
  position: relative;
  background-color: $body-background-color;
  border-radius: 5px;
  width: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
</style>
