import {
  AssetKind,
  type HostPathSpec,
  type NFSAssetSpec,
  type ClaimInfo,
  type GitAssetSpec,
  type PVCAssetSpec,
  type S3AssetSpec,
  type ConfigMapAssetSpec,
  type SecretAssetSpec,
} from "@/swagger-models/assets-service-client";

import type { IUIAssetCreationMeta } from "./global.model";

export type TDataSourceKinds =
  | typeof AssetKind.HostPath
  | typeof AssetKind.Nfs
  | typeof AssetKind.Git
  | typeof AssetKind.S3
  | typeof AssetKind.Pvc
  | typeof AssetKind.ConfigMap
  | typeof AssetKind.SecretVolume;

export const NONE_STORAGE_CLASS = "runai-none";
export const NONE_STORAGE_CLASS_LABEL = "None";

export const DATA_SOURCE_TYPE: Record<TDataSourceKinds, string> = {
  [AssetKind.HostPath]: "Host path",
  [AssetKind.Nfs]: "NFS",
  [AssetKind.Git]: "Git",
  [AssetKind.S3]: "S3",
  [AssetKind.Pvc]: "PVC",
  [AssetKind.ConfigMap]: "ConfigMap",
  [AssetKind.SecretVolume]: "Secret",
};

export const DATA_SOURCE_KIND_TO_SPEC_KEY: Record<TDataSourceKinds, string> = {
  [AssetKind.HostPath]: AssetKind.HostPath,
  [AssetKind.Nfs]: AssetKind.Nfs,
  [AssetKind.Git]: AssetKind.Git,
  [AssetKind.S3]: AssetKind.S3,
  [AssetKind.Pvc]: AssetKind.Pvc,
  [AssetKind.ConfigMap]: "config_map",
  [AssetKind.SecretVolume]: "secret",
};

export const DATA_SOURCE_KIND_TO_CONTAINER_KEY: Record<TDataSourceKinds, string> = {
  [AssetKind.HostPath]: "mountPath",
  [AssetKind.Nfs]: "mountPath",
  [AssetKind.Git]: "path",
  [AssetKind.S3]: "path",
  [AssetKind.Pvc]: "path",
  [AssetKind.ConfigMap]: "mountPath",
  [AssetKind.SecretVolume]: "mountPath",
};

export const DATA_SOURCES_TYPES_TO_NAMES = {
  NFS: "NFS",
  PVC: "PVC",
  S3: "S3 Bucket",
  GIT: "Git",
  HOST_PATH: "Host path",
  CONFIG_MAP: "ConfigMap",
  SECRET_VOLUME: "Secret",
};

export interface IUIVolume {
  claimName: string;
  path: string;
  claimInfo: ClaimInfo;
  ephemeral: boolean;
}
export interface IUIHostPathCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: HostPathSpec;
}
export interface IUINFSCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: NFSAssetSpec;
}
export interface IUIS3CreationRequest {
  meta: IUIAssetCreationMeta;
  spec: S3AssetSpec;
}
export interface IUIGitCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: GitAssetSpec;
}
export interface IUIPVCCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: PVCAssetSpec;
}
export interface IUIConfigMapCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: ConfigMapAssetSpec;
}
export interface IUISecretCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: SecretAssetSpec;
}
export interface IDataSourceModalOptions {
  dataSourceIds: Array<string>;
  header: string;
}
