<template>
  <section class="distributed-selection q-mb-sm">
    <section class="row">
      <runai-radio-options
        :title="title"
        :model-value="distributedModel.isDistributed"
        @update:model-value="updateDistributed"
        :options="distributedOptions"
        :disable="disableSelection"
        :disable-tooltip="disableTooltip"
        tooltip-width="500px"
      />
    </section>
    <template v-if="distributedModel.isDistributed">
      <div class="q-mt-lg">Set the framework for the distributed workload</div>
      <runai-tooltip-wrapper
        :display-tooltip="disableSelection"
        tooltip-text="The framework is defined by the distributed workload and cannot be modified"
      >
        <div class="row">
          <runai-select
            class="col-3 q-mr-md"
            emit-value
            aid="dist-framework-select"
            :model-value="distFramework"
            @update:model-value="updateDistributedFramework"
            :options="distFrameworkOptions"
            :rules="[validFramework]"
            placeholder="Select..."
            unclearable
            :disable="disableSelection"
          />
        </div>
        <div v-if="showDocsLink" class="italic q-mt-md q-mb-lg">
          To learn how to enable all frameworks, see the
          <a
            href="https://docs.run.ai/latest/admin/runai-setup/cluster-setup/cluster-prerequisites/#distributed-training"
            target="_blank"
          >
            Distributed training prerequisites guide</a
          >
        </div>
      </runai-tooltip-wrapper>
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// stores
import { useClusterStore } from "@/stores/cluster.store";

// Components
import { RunaiRadioOptions } from "@/components/common/runai-radio-options";
import { RunaiSelect } from "@/components/common/runai-select";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";

// Models
import { DistributedFramework } from "@/swagger-models/assets-service-client";
import type { ISelectOption } from "@/models/global.model";
import type { IDistributedSelectionModel } from "./distributed-selection.model";

// Constants
import { errorMessages } from "@/common/error-message.constant";
import { MIN_DEPENDENCIES_VERSION } from "@/common/version.constant";

const clusterDependenciesKeys: Record<DistributedFramework, string> = {
  [DistributedFramework.PyTorch]: "pytorch",
  [DistributedFramework.Tf]: "tensorflow",
  [DistributedFramework.XgBoost]: "xgboost",
  [DistributedFramework.Mpi]: "mpi",
};

export enum EDistributedFormType {
  ENVIRONMENT = "environment",
  TRAINING = "training",
}

export default defineComponent({
  name: "distributed-selection",
  components: {
    RunaiRadioOptions,
    RunaiSelect,
    RunaiTooltipWrapper,
  },
  emits: ["update-distributed-model"],
  props: {
    distributedModel: {
      type: Object as PropType<IDistributedSelectionModel>,
      required: true,
    },
    disableSelection: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formType: {
      type: String as PropType<EDistributedFormType>,
      required: true,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      distFrameworkOptions: [
        { value: DistributedFramework.PyTorch, label: "PyTorch", icon: "pytorch", disable: false },
        { value: DistributedFramework.Tf, label: "TensorFlow", icon: "tensorboard", disable: false },
        { value: DistributedFramework.XgBoost, label: "XG Boost", icon: "xgboost", disable: false },
        { value: DistributedFramework.Mpi, label: "MPI", icon: "mpi", disable: false },
      ] as Array<ISelectOption>,
    };
  },
  created() {
    this.initOptionsAvailability();
  },
  computed: {
    showDocsLink(): boolean {
      return this.distFrameworkOptions.some((opt: ISelectOption) => opt.disable);
    },
    distFramework(): DistributedFramework | null {
      return this.distributedModel?.distFramework || null;
    },
    disableTooltip(): string {
      if (!this.disableSelection) return "";
      const selectionText = this.distributedModel.isDistributed ? "distributed" : "standard";
      return `This can't be modified when an environment is created via a ${selectionText} workload`;
    },
    title(): string {
      return this.formType === EDistributedFormType.ENVIRONMENT
        ? "Set whether the environment supports standard or distributed workloads"
        : "Set whether the workload should run as a standard or distributed workload";
    },
    distributedOptions(): ISelectOption[] {
      return [
        {
          value: false,
          label: "Standard",
          toolTip:
            this.formType === EDistributedFormType.ENVIRONMENT
              ? "Only standard workloads can use this environment. A standard workload consists of a single process."
              : "This workload will only be able to use environments that support standard workloads. A standard workload consists of a single process.",
        },
        {
          value: true,
          label: "Distributed",
          toolTip:
            this.formType === EDistributedFormType.ENVIRONMENT
              ? "Only distributed workloads can use this environment.  A distributed workload consists of multiple processes working together. These processes can run on different nodes."
              : "This workload will only be able to use environments that support distributed workloads. A distributed workload consists of multiple processes working together. These processes can run on different nodes.",
        },
      ];
    },
  },
  methods: {
    initOptionsAvailability(): void {
      if (!this.clusterStore.isClusterVersionSufficient(this.clusterId, MIN_DEPENDENCIES_VERSION)) {
        return;
      }

      const availableDependencies: Set<string> = new Set(
        this.clusterStore.availableDependenciesByClusterUuid(this.clusterId),
      );

      this.distFrameworkOptions.forEach((option: ISelectOption) => {
        const dependencyKey = clusterDependenciesKeys[option.value as DistributedFramework];
        option.disable = !availableDependencies.has(String(dependencyKey));
        if (option.disable) {
          option.disabledTooltip = "To select this framework, first install it on your cluster";
        }
      });
    },
    updateDistributed(isDistributed: boolean): void {
      const distFramework: DistributedFramework | null = isDistributed ? this.distributedModel.distFramework : null;
      this.$emit("update-distributed-model", { isDistributed, distFramework });
    },
    updateDistributedFramework(distFramework: DistributedFramework): void {
      if (!this.distributedModel.isDistributed) return;
      this.$emit("update-distributed-model", { ...this.distributedModel, distFramework });
    },
    validFramework(value: string | null): boolean | string {
      return !!value || errorMessages.SELECT_A_FRAMEWORK;
    },
  },
});
</script>
