<template>
  <section class="settings-index q-pt-md">
    <user-profile-section />
    <email-notifications-section
      v-if="notificationsSettingsCategories"
      :notification-categories="notificationsSettingsCategories"
      :smtp-enabled="isSmtpEnabledForTenant"
      @save="saveNotificationsSettings"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAppStore } from "@/stores/app.store";
import UserProfileSection from "@/components/user-settings/sections/user-profile-section/user-profile-section.vue";
import EmailNotificationsSection from "@/components/user-settings/sections/email-notifications-section/email-notifications-section.vue";
import type { CategorizedNotificationSettings, NotificationSettings, RouteConfig } from "@/models/user-setting.model";
import { notificationService } from "@/services/control-plane/notifications.service/notification.service";
import { userNotificationsUtil } from "@/utils/user-notifications.utils";
import { useAuthStore } from "@/stores/auth.store";
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  name: "user-settings-index",
  components: { EmailNotificationsSection, UserProfileSection },
  data() {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      isSmtpEnabledForTenant: false as boolean,
      notificationsSettingsCategories: null as CategorizedNotificationSettings | null,
      notificationsSettings: [
        {
          type: "workload running",
          enabled: true,
          category: "status",
        },
        {
          type: "Running with issues",
          enabled: false,
          category: "status",
        },
        {
          type: "workload completed",
          enabled: true,
          category: "status",
        },
        {
          type: "workload failed",
          enabled: true,
          category: "status",
        },
        {
          type: "workload Deleted",
          enabled: true,
          category: "status",
        },
        {
          type: "workload pending",
          enabled: false,
          category: "timeout",
        },
        {
          type: "workload stopped",
          enabled: true,
          category: "timeout",
        },
      ] as Array<NotificationSettings>,
    };
  },
  async created() {
    try {
      this.isSmtpEnabledForTenant = await notificationService.isSmtpEnabledForTenant(String(this.authStore.tenant.id));
      this.notificationsSettingsCategories = await this.getNotificationsSettings();
    } catch (error) {
      this.$q.notify(alertUtil.getError("Failed to load user notifications settings"));
      this.notificationsSettingsCategories = null;
    }
    this.appStore.setPageLoading(false);
  },
  computed: {},
  methods: {
    async getNotificationsSettings(): Promise<CategorizedNotificationSettings> {
      const notificationSettings: Array<NotificationSettings> =
        await notificationService.getUserNotificationCategories();
      const routeConfigs: Array<RouteConfig> = await notificationService.getUserNotificationCategoriesState(
        String(this.authStore.tenant.id),
        this.authStore.currentUser.username,
      );
      const filters = routeConfigs && routeConfigs[0]?.route_config?.filtering?.filters;
      return userNotificationsUtil.updateNotificationStatus(notificationSettings, filters ? filters : []);
    },
    async saveNotificationsSettings(routeConfig: RouteConfig) {
      try {
        await notificationService.updateUserNotificationCategoriesState(
          String(this.authStore.tenant.id),
          this.authStore.currentUser.username,
          routeConfig,
        );
        this.notificationsSettingsCategories = await this.getNotificationsSettings();
        this.$q.notify(alertUtil.getSuccess("Settings saved"));
      } catch (error) {
        this.$q.notify(alertUtil.getError("Failed to save user notifications settings"));
      }
    },
  },
});
</script>
