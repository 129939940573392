import { DATA_SOURCES_TYPES_TO_NAMES } from "@/models/data-source.model";

export enum DATA_SOURCE_ROUTE_NAMES {
  DATA_SOURCE_INDEX = "data-source-index",
  DATA_SOURCE_NEW = "data-source-new",
  DATA_SOURCE_ASSETS_EDIT = "data-source-assets-edit",
  DATA_SOURCE_ENVIRONMENT_NEW = "data-source-environment-new",
  DATA_SOURCE_HOST_PATH_NEW = "data-source-host-path-new",
  DATA_SOURCE_NFS_NEW = "data-source-nfs-new",
  DATA_SOURCE_S3_NEW = "datasource-s3-new",
  DATA_SOURCE_GIT_NEW = "data-source-git-new",
  DATA_SOURCE_PVC_NEW = "data-source-pvc-new",
  DATA_SOURCE_CONFIG_MAP_NEW = "data-source-config-map-new",
  DATA_SOURCE_SECRET_VOLUME_NEW = "data-source-secret-volume-new",
}

export const DATA_SOURCE_CREATION_ROUTE_MAP = {
  [DATA_SOURCES_TYPES_TO_NAMES.NFS]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_NFS_NEW,
  [DATA_SOURCES_TYPES_TO_NAMES.PVC]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_PVC_NEW,
  [DATA_SOURCES_TYPES_TO_NAMES.S3]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_S3_NEW,
  [DATA_SOURCES_TYPES_TO_NAMES.GIT]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_GIT_NEW,
  [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_HOST_PATH_NEW,
  [DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_CONFIG_MAP_NEW,
  [DATA_SOURCES_TYPES_TO_NAMES.SECRET_VOLUME]: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_SECRET_VOLUME_NEW,
};
