<template>
  <q-page>
    <dashboard-page-filter
      :filter-options="filterOptions"
      :async-filter-options-promise-map="asyncFilterOptionsPromiseMap"
      :initial-filters="filterBy"
      :filter-by-dates="filterByDates"
      @date-changed="onDateChanged"
      @filter-changed="onFilterChanged"
      :hide-filter="hideFilter"
    />
    <widget-layout
      :widgets-layout="widgetsLayout"
      :cluster-id="clusterId"
      :node-pool-name="nodePoolName"
      :filter-by-dates="filterByDates"
      :filter-by="filterBy"
    />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
//cmps
import { DashboardPageFilter } from "@/components/dashboard-v2/dashboard-page-filter/";
import { WidgetLayout } from "@/components/dashboard-v2/widgets/common/widget-layout/";
//model
import { CLUSTER_COLUMN_FILTER_NAME, type IFilterModel, type IFilterOption } from "@/models/filter.model";
import type { ISelectOption } from "@/models/global.model";
import { EWidgetComponent } from "@/models/chart.model";
import { PREDEFINED_DATES } from "@/models/date.model";

//store
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { useClusterStore } from "@/stores/cluster.store";

// utils
import { dateUtil, type IRangeDates } from "@/utils/date.util";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import type { INodePool } from "@/models/node-pool.model";
import { storageUtil } from "@/utils/storage.util";
//constants
import { MIN_TELEMETRY_NODE_POOL_FILTER_VERSION } from "@/common/version.constant";
import { OVERVIEW_DATE_FILTERS, OVERVIEW_FILTERS } from "@/common/storage.constant";

export default defineComponent({
  name: "overview-index",
  components: { WidgetLayout, DashboardPageFilter },
  data() {
    return {
      settingsStore: useSettingStore(),
      nodePoolStore: useNodePoolStore(),
      clusterStore: useClusterStore(),
      hideNodePoolsFilter: false as boolean,
      asyncFilterOptionsPromiseMap: {
        nodepool: this.getFilteredNodePools,
      } as unknown as Record<string, (searchQuery: string) => Promise<Array<ISelectOption>>>,
      nodepools: [] as INodePool[],
      filterByDates: {
        dateStart: dateUtil.dateSevenDaysAgo(),
        dateEnd: new Date() as Date,
        label: PREDEFINED_DATES.LAST_7_DAYS,
      } as IRangeDates,
      filterBy: [] as IFilterModel[],
    };
  },
  created() {
    this.loadFilters();
    useAppStore().setPageLoading(false);
  },
  computed: {
    widgetsLayout(): EWidgetComponent[][] {
      return [
        [
          EWidgetComponent.ReadyNodesWidget,
          EWidgetComponent.ReadyGpuWidget,
          EWidgetComponent.AllocatedGpuWidget,
          EWidgetComponent.IdleAllocatedGpuWidget,
          EWidgetComponent.RunningWorkloads,
          EWidgetComponent.PendingWorkloads,
        ],
        [EWidgetComponent.ResourceAllocationByWorkloadWidget, EWidgetComponent.WorkloadStatus],
        [EWidgetComponent.GpuAllocationRatioWidget, EWidgetComponent.NodePoolFreeResourcesWidget],
        [EWidgetComponent.ResourceUtilizationTimeRangeWidget],
        [EWidgetComponent.ResourceAllocationTimeRangeWidget],
      ];
    },
    filterOptions(): IFilterOption[] {
      return [
        {
          field: "nodepool",
          name: "Node pool",
          label: "Node pool",
        },
      ];
    },
    isDepartmentEnabled(): boolean {
      return this.settingsStore.isDepartmentEnabled;
    },
    nodePoolName(): string | undefined {
      return this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool")?.term;
    },
    clusterId(): string {
      return this.filterBy?.find((filter) => filter.name === CLUSTER_COLUMN_FILTER_NAME)?.term || this.defaultClusterId;
    },
    defaultClusterId(): string {
      return this.clusterStore.clusterList[0]?.uuid || "";
    },
    defaultClusterFilter(): IFilterModel {
      return {
        field: "",
        name: CLUSTER_COLUMN_FILTER_NAME,
        term: this.defaultClusterId,
      };
    },
    hideFilter(): boolean {
      return !this.clusterStore.isClusterVersionSufficient(this.clusterId, MIN_TELEMETRY_NODE_POOL_FILTER_VERSION);
    },
  },
  methods: {
    loadFilters(): void {
      this.filterBy = storageUtil.get<IFilterModel[] | null>(OVERVIEW_FILTERS) ?? [this.defaultClusterFilter];
      this.loadDateFilters();
    },
    loadDateFilters(): void {
      const dateFilters = storageUtil.get<{ startDate: Date; endDate: Date; label: string } | null>(
        OVERVIEW_DATE_FILTERS,
      );

      if (dateFilters !== null) {
        this.filterByDates.dateStart = new Date(dateFilters?.startDate);
        this.filterByDates.dateEnd = new Date(dateFilters?.endDate);
        this.filterByDates.label = dateFilters?.label;
      }
    },
    onFilterChanged(filterBy: Array<IFilterModel>): void {
      storageUtil.save(OVERVIEW_FILTERS, filterBy);
      this.filterBy = [...filterBy];
    },
    onDateChanged(data: { startDate: Date; endDate: Date; label: string }): void {
      storageUtil.save(OVERVIEW_DATE_FILTERS, data);

      this.filterByDates = {
        dateStart: data.startDate,
        dateEnd: data.endDate,
        label: data.label,
      };
    },
    async getFilteredNodePools(searchQuery: string): Promise<string[]> {
      if (this.nodepools.length === 0) {
        await this.fetchNodePools();
      }

      const filteredOptions = this.nodepools
        .map((nodePool: INodePool) => nodePool.name)
        .filter((name: string) => name.includes(searchQuery));

      return filteredOptions;
    },
    async fetchNodePools(): Promise<void> {
      try {
        this.nodepools = await nodePoolService.getNodePools(this.clusterId);
      } catch (error) {
        console.error("Error fetching node pools:", error);
        this.nodepools = [];
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
