<template>
  <section class="settings-index q-pt-md">
    <analytics-section :analytics-section-settings="analyticsSectionSettings" v-if="shouldShowAnalyticsSection" />
    <resources-section :resource-settings="resourceSettings" />
    <workloads-section :workload-section-settings="workloadSectionSettings" v-if="shouldShowWorkloadSection" />
    <security-section :security-settings="securitySectionSettings" />
    <notifications-section />
    <cluster-authentications-section />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import AnalyticsSection from "@/components/settings/sections/analytics/analytics-section.vue";
import SecuritySection from "@/components/settings/sections/security/security-section.vue";
import NotificationsSection from "@/components/settings/sections/notifications/notifications-section.vue";
import { WorkloadsSection } from "@/components/settings/sections/workloads";

// stores
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAuthStore } from "@/stores/auth.store";
import { useRegistryStore } from "@/stores/registry.store";

// models
import type {
  IAnalyticsSectionSettings,
  IResourceSectionSettings,
  ISecuritySettings,
  IWorkloadsSectionSettings,
} from "@/models/setting.model";
import { SettingKeys } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";

// utils
import { ResourcesSection } from "@/components/settings/sections/resources";
import ClusterAuthenticationsSection from "@/components/settings/sections/cluster-authentication/cluster-autentications-section.vue";

export const DEFAULT_TIMEOUT = 60;

export default defineComponent({
  name: "settings-index",
  components: {
    ClusterAuthenticationsSection,
    ResourcesSection,
    WorkloadsSection,
    NotificationsSection,
    SecuritySection,
    AnalyticsSection,
  },
  data() {
    return {
      settingStore: useSettingStore(),
      authStore: useAuthStore(),
      appStore: useAppStore(),
      registryStore: useRegistryStore(),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {
    analyticsSectionSettings(): IAnalyticsSectionSettings {
      return {
        cpu: this.getSettingValue(SettingKeys.EnableCpuDashboards),
        consumption: this.getSettingValue(SettingKeys.ConsumptionReport),
        advancedGpuMetrics: this.getSettingValue(SettingKeys.AdvancedGPUMetrics),
        optimizationEnabled: this.getSettingValue(SettingKeys.DashboardOptimization),
      };
    },
    resourceSettings(): IResourceSectionSettings {
      return {
        departments: this.getSettingValue(SettingKeys.DepartmentsUse),
        projectsOverDepartmentQuota: this.getSettingValue(SettingKeys.LimitQuotaOverSubscription),
        cpuOverQuota: this.getSettingValue(SettingKeys.CPUResourcesQuota),
        overQuotaPriority: this.getSettingValue(SettingKeys.ProjectManualOverQuota),
        gpuOptimization: this.getSettingValue(SettingKeys.GpuOverProvisioning),
      };
    },
    securitySectionSettings(): ISecuritySettings {
      return {
        timeout: Number(this.getSettingValue(SettingKeys.InactivityMinutesTillLogout)?.value || DEFAULT_TIMEOUT),
        logoutUrl: this.getSettingValue(SettingKeys.LogoutURI).value || null,
      };
    },
    workloadSectionSettings(): IWorkloadsSectionSettings {
      const workloadSettings: IWorkloadsSectionSettings = {
        policies: this.getSettingValue(SettingKeys.EnablePolicies),
        models: this.getSettingValue(SettingKeys.EnableModelCatalog),
        allowGPT2Model: this.getSettingValue(SettingKeys.AllowGPT2Model),
        tolerations: this.getSettingValue(SettingKeys.EnableWorkloadTolerations),
        jobGrid: this.getSettingValue(SettingKeys.JobsGridEnabled),
        jobSubmission: this.getSettingValue(SettingKeys.EnableJobSubmitForm),
        wabSweeps: this.getSettingValue(SettingKeys.EnableWandbSweeps),
        wabHost: this.getSettingValue(SettingKeys.WandbHost)?.value || "",
        mpiDistribution: this.getSettingValue(SettingKeys.EnableLegacyMpi),
        topology: this.getSettingValue(SettingKeys.EnableWorkloadTopology),
        dockerRegistry: this.getSettingValue(SettingKeys.RegistryIntegration),
        newCliInstaller: this.getSettingValue(SettingKeys.EnableNewCliInstaller),
        deletedWorkloadsView: this.getSettingValue(SettingKeys.DeletedWorkloadsView),
      };

      if (workloadSettings.jobSubmission) {
        workloadSettings.jobSubmission.stage = "Legacy";
      }

      if (workloadSettings.jobGrid) {
        workloadSettings.jobGrid.stage = "Legacy";
      }

      if (workloadSettings.policies) {
        workloadSettings.policies.stage = "Alpha";
      }

      if (workloadSettings.dockerRegistry?.value && this.registryStore?.registry) {
        workloadSettings.dockerRegistryData = {
          url: this.registryStore?.registry?.spec?.url,
          user: this.registryStore?.registry?.spec?.user,
          password: this.registryStore?.registry?.spec?.password,
        };
      }

      return workloadSettings;
    },
    shouldShowWorkloadSection(): boolean {
      return Object.values(this.workloadSectionSettings)
        .filter(
          (value: Setting) =>
            !value || (value.key != SettingKeys.EnableLegacyMpi && value.key != SettingKeys.EnableWandbSweeps),
        )
        .find((value: Setting) => {
          return value && value.key;
        });
    },
    shouldShowAnalyticsSection(): boolean {
      return Object.values(this.analyticsSectionSettings).find((value: Setting) => {
        return value && value.key;
      });
    },
  },
  methods: {
    getSettingValue(key: string): Setting {
      return this.settingStore.settings.get(key) as Setting;
    },
  },
});
</script>

<style lang="scss">
.settings-container {
  margin: auto;
  width: 800px;

  .runai-expansion-header-content-left {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
