<template>
  <q-select
    aid="autocomplete-select"
    class="runai-auto-complete-select"
    :model-value="modelValue"
    :options="options"
    :use-input="!modelValue?.length"
    :input-debounce="debounce"
    clearable
    clear-icon="fa-regular fa-xmark"
    @clear="$emit('clear')"
    @update:model-value="updateModelValue"
    @filter="filterFn"
    @input="filterFn"
    :rules="rules"
    no-error-icon
    hide-hint
    :stack-label="stackLabel"
    new-value-mode="add-unique"
    @new-value="handleNewValue"
    ref="autocompleteSelect"
    :loading="loading"
    :square="hasOutline"
    :outlined="hasOutline"
    :label="label"
  >
    <template v-slot:selected-item="scope">
      <span style="white-space: nowrap" class="ellipsis">{{ scope.opt }}</span>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey" aid="autocomplete-no-result">
          <span v-if="isError">No options - contact your admin</span>
          <span v-else>{{ searchQuery?.length < minimumCharacters ? searchHint : noResultLabel }}</span>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { QSelect, ValidationRule } from "quasar";
import type { PropType } from "vue";

//models
import type { ISelectOption, SelectUpdateFilterFunction } from "@/models/global.model";
export default defineComponent({
  emits: ["clear", "update:model-value"],
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    stackLabel: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    noResultLabel: {
      type: String as PropType<string>,
      required: true,
    },
    searchHint: {
      type: String as PropType<string>,
      required: true,
    },
    asyncOptionsPromise: {
      type: Function as PropType<(searchQuery: string) => Promise<ISelectOption[]>>,
      required: true,
    },
    rules: {
      type: Object as PropType<ValidationRule | ValidationRule[]>,
      required: false,
    },
    modelValue: {
      type: [String, null] as PropType<string | null>,
      required: false,
    },
    debounce: {
      type: Number as PropType<number>,
      required: false,
      default: 300,
    },
    minimumCharacters: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    isError: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    canAddNewValues: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    newValuesLabelPostfix: {
      type: String as PropType<string>,
      default: "(New)",
    },
    hasOutline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      selectedItem: null as ISelectOption | null,
      options: [] as ISelectOption[],
      searchQuery: "" as string,
      loading: false,
    };
  },
  methods: {
    updateModelValue($event: string | ISelectOption) {
      if (this.canAddNewValues && $event && typeof $event !== "string") {
        // in case of new value added by user and not the options list
        $event = $event.value as string;
      }
      this.$emit("update:model-value", $event);
    },
    handleNewValue($event: Event) {
      this.$emit("update:model-value", this.canAddNewValues ? $event : null);
      (this.$refs.autocompleteSelect as QSelect).hidePopup();
    },
    filterFn(searchQuery: string, update: SelectUpdateFilterFunction): void {
      this.searchQuery = searchQuery;
      if (update && typeof update === "function") {
        update(async () => {
          if (
            this.minimumCharacters != 0 &&
            (searchQuery === "" || searchQuery == null || searchQuery?.length < this.minimumCharacters)
          ) {
            this.options = [] as ISelectOption[];
          } else {
            this.loading = true;
            this.options = await this.asyncOptionsPromise(searchQuery);
            if (this.canAddNewValues) {
              const newSelection: ISelectOption = {
                label: `${searchQuery} ${this.newValuesLabelPostfix}`,
                value: searchQuery,
              };
              this.options.push(newSelection);
            }
            this.loading = false;
          }
        });
      }
    },
  },
});
</script>

<style lang="scss">
.runai-auto-complete-select {
  .q-icon {
    font-size: 16px;
  }
}
</style>
