<template>
  <q-input type="number" v-model.number="number" v-bind="$attrs" @keydown="onKeyDown" :min="0" stack-label />
  <slot name="error"></slot>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

enum AllowedKeys {
  Backspace = "Backspace",
  Delete = "Delete",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Tab = "Tab",
  Dot = ".",
}
// validate floating-point numbers with up to 5 digits before the decimal point and up to 2 digits after
const FLOATING_NUMBER_REGEX = /^\d{0,5}(\.\d{0,2})?$/;

// validate integers with up to 7 digits
const INTEGER_NUMBER_REGEX = /^\d{0,7}?$/;

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },
    isInteger: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      number: this.modelValue as number | string,
    };
  },
  computed: {
    decimalRegex() {
      if (this.isInteger) {
        return INTEGER_NUMBER_REGEX;
      }
      return FLOATING_NUMBER_REGEX;
    },
  },
  methods: {
    onKeyDown(event: KeyboardEvent) {
      if (event.key === AllowedKeys.Dot && this.isInteger) {
        event.preventDefault();
      }

      const input = event.target as HTMLInputElement;
      const value = input.value;
      const selectionStart = input.selectionStart || 0;
      const selectionEnd = input.selectionEnd || 0;

      // check if the key being pressed is a dot
      if (event.key === AllowedKeys.Dot) {
        // check if there is already a dot in the input
        if (value.indexOf(".") !== -1) {
          event.preventDefault();
        } else {
          // allow the dot to be typed if there is not already one in the input
          return;
        }
      }

      if (Object.values(AllowedKeys).includes(event.key as AllowedKeys)) {
        // allow navigation and deletion
        return;
      }

      if (!this.decimalRegex.test(value.slice(0, selectionStart) + event.key + value.slice(selectionEnd))) {
        event.preventDefault();
      }
    },
  },
  watch: {
    number() {
      if (this.number === "") {
        this.$emit("update:modelValue", null);
      } else {
        this.$emit("update:modelValue", this.number);
      }
    },
    modelValue(newVal) {
      this.number = newVal;
    },
  },
});
</script>
