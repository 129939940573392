import {
  type IAccessRuleDisplayConfig,
  type IAccessRuleManagementModalOptions,
  ResourceTypeGroupIdOrder,
} from "@/models/access-rule.model";
import { EAccessRuleModalPage } from "@/models/access-rule.model";
import type { IAccessRuleRecords } from "@/models/access-rule.model";
import type { IDepartmentTable } from "@/models/department.model";
import type { IProjectTable } from "@/models/project.model";
import { deepCopy } from "@/utils/common.util";
import { type AccessRule, type Permission, ResourceTypeGroupId } from "@/swagger-models/authorization-client";
import type { IApplication } from "@/models/applications.model";
import type { TRolesTypes } from "@/models/roles.model";
import type { IUser } from "@/models/user.model";

export const accessRuleUtil = {
  getAccessRuleModalDisplayConfig,
  getAccessRuleModalHeader,
  getAccessRuleModalSubHeader,
  isScopePage,
  getUserSubjectIds,
  sortAndGroupPermissionsByGroupId,
};

function getAccessRuleModalDisplayConfig(page: EAccessRuleModalPage): IAccessRuleDisplayConfig {
  switch (page) {
    case EAccessRuleModalPage.Project:
      return { subject: true, users: true, roles: true, scope: false };
    case EAccessRuleModalPage.Department:
      return { subject: true, users: true, roles: true, scope: false };
    case EAccessRuleModalPage.Application:
      return { subject: false, users: false, roles: true, scope: true };
    case EAccessRuleModalPage.User:
      return { subject: false, users: false, roles: true, scope: true };
    default:
      return { subject: false, users: false, roles: false, scope: false };
  }
}

function getAccessRuleModalHeader(options: IAccessRuleManagementModalOptions): string {
  switch (options.page) {
    case EAccessRuleModalPage.Project:
      return `Access Rules for ${options.scopeName}`;
    case EAccessRuleModalPage.Department:
      return `Access Rules for ${options.scopeName}`;
    case EAccessRuleModalPage.Application:
      return `Access Rules for ${options.subjectId}`;
    case EAccessRuleModalPage.User:
      return `Access Rules for ${options.subjectId}`;
    default:
      return "";
  }
}
function getAccessRuleModalSubHeader(page: EAccessRuleModalPage): string {
  switch (page) {
    case EAccessRuleModalPage.Project:
      return "Add rules to authorize access to this project";
    case EAccessRuleModalPage.Department:
      return "Add rules to authorize access to this department";
    case EAccessRuleModalPage.Application:
      return "Add rules and select roles and scopes to authorize this application’s access ";
    case EAccessRuleModalPage.User:
      return "Add rules and select roles and scopes to authorize this user’s access ";
    default:
      return "";
  }
}

function isScopePage(page: EAccessRuleModalPage): boolean {
  return [EAccessRuleModalPage.Project, EAccessRuleModalPage.Department].some((p: EAccessRuleModalPage) => p === page);
}

export function enrichApplicationsWithRoles(
  applications: IApplication[],
  applicationAccessRules: IAccessRuleRecords,
): IApplication[] {
  const appNameToRolesMap = _getSubjectIdToRolesMap(applicationAccessRules);
  if (appNameToRolesMap.size > 0) {
    _addRolesToApplications(applications, appNameToRolesMap);
  }
  return applications;
}

export function enrichScopeEntityWithAccessRules<T extends IDepartmentTable | IProjectTable>(
  items: T[],
  scopeAccessRules: IAccessRuleRecords,
): IDepartmentTable[] | IProjectTable[] {
  const itemsWithAccessRules = deepCopy(items) as IDepartmentTable[] | IProjectTable[];
  const accessRulesByScope = scopeAccessRules.accessRules.reduce(
    (assignmentsMap: Record<string, string[]>, item: AccessRule) => {
      assignmentsMap[item.scopeId] ??= [];
      assignmentsMap[item.scopeId].push(item.subjectType);
      return assignmentsMap;
    },
    {} as Record<string, string[]>,
  );
  for (const item of itemsWithAccessRules) {
    item.rolesNames = accessRulesByScope[String(item.id)] || [];
  }
  return itemsWithAccessRules;
}

function _getSubjectIdToRolesMap(subjectAccessRules: IAccessRuleRecords): Map<string, Array<string>> {
  const subjectIdToRolesMap = new Map<string, Array<string>>();
  subjectAccessRules.accessRules.forEach((accessRule) => {
    const subjectId = accessRule.subjectId;
    const roleName = accessRule.roleName;
    if (subjectIdToRolesMap.has(subjectId)) {
      const userRoles = subjectIdToRolesMap.get(subjectId);
      if (userRoles != undefined && !userRoles?.includes(roleName)) {
        userRoles?.push(roleName);
        subjectIdToRolesMap.set(subjectId, userRoles);
      }
    } else {
      subjectIdToRolesMap.set(subjectId, [roleName]);
    }
  });
  return subjectIdToRolesMap;
}

function _addRolesToApplications(apps: IApplication[], appNameToRolesMap: Map<string, Array<string>>): void {
  apps.forEach((app) => {
    if (app.name && appNameToRolesMap.has(app.name)) {
      app.roles = [];
      app.roles.push(...(appNameToRolesMap.get(app.name) as Array<TRolesTypes>));
    }
  });
}

function getUserSubjectIds(user: IUser): Array<string> {
  return user.groups ? [user.username].concat(user.groups || []) : [user.username];
}

/**
 * Sorts and groups permissions by their group ID.
 *
 * @param {Permission[]} permissions - An array of permissions to be sorted and grouped.
 *
 * @returns {Permission[]} - The sorted and grouped permissions.
 *
 * The function works in the following steps:
 * 1. It first sorts the permissions based on their display name.
 * 2. It then groups the sorted permissions by their group ID.
 * 3. Finally, it orders the groups according to the ResourceTypeGroupIdOrder and returns the result.
 */
function sortAndGroupPermissionsByGroupId(permissions: Permission[]): Permission[] {
  const sortedPermissions = [...permissions].sort((a, b) => a.displayName.localeCompare(b.displayName));

  const groupedPermissions: Record<string, Permission[]> = sortedPermissions.reduce((groups, permission) => {
    groups[permission.groupId] = [...(groups[permission.groupId] || []), permission];
    return groups;
  }, {} as Record<string, Permission[]>);

  return ResourceTypeGroupIdOrder.flatMap((key: ResourceTypeGroupId) => groupedPermissions[key] || []);
}
