export enum DASHBOARD_ROUTE_NAMES {
  OVERVIEW_INDEX = "overview-index",
  ANALYTICS_INDEX = "analytics-index",
  CONSUMPTION_INDEX = "consumption-index",
  CLUSTERS_DASHBOARD_INDEX = "clusters-dashboard-index",
  RESOURCES_INDEX = "resources-index",
  CPU_OVERVIEW = "cpu-overview",
  CPU_ANALYTICS = "cpu-analytics",
}
export const LANDING_PAGE_NAME = DASHBOARD_ROUTE_NAMES.OVERVIEW_INDEX;
