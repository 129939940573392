import type { Permission, Role } from "@/swagger-models/authorization-client";
import { pick } from "@/utils/common.util";
import type { IFilterBy } from "@/models/filter.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

export const rolesService = {
  list,
  isPermittedRole,
};
async function list(filterBy: IFilterBy = {}): Promise<Role[]> {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  return controlPlaneService.get(`api/v1/authorization/roles`, filters);
}

function isPermittedRole(role: Role, permissions: Array<Permission>): boolean {
  // Check that the permissions of the current user contain at least all the resource types of the role
  for (const permission of role.permissions) {
    const rolePermission = permissions.find((p) => p.resourceType === permission.resourceType);
    if (!rolePermission) {
      return false;
    }
    // Check that the role's permission object has at least all the actions that the first permission object has
    for (const action of permission.actions) {
      if (!rolePermission.actions.includes(action)) {
        return false;
      }
    }
  }
  // If all checks pass, the role is valid
  return true;
}

export default rolesService;
