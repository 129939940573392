<template>
  <section class="server-error row justify-center items-center">
    <div class="column">
      <span class="title">Our server got a cluster headache</span>
      <span class="title">and can't be reached right now</span>
      <span class="subtitle q-mt-xl">
        Try again in a few seconds or
        <a href="https://runai.my.salesforce-sites.com/casesupport/CreateCaseForm" target="_blank">contact support</a>
      </span>
    </div>
    <runai-svg-icon name="503" size="500px" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

export default defineComponent({
  name: "server-error",
  components: {
    RunaiSvgIcon,
  },
});
</script>

<style lang="scss" scoped>
.server-error {
  .title {
    font-size: 40px;
    color: $black-70;
  }

  .subtitle {
    font-size: 18px;
    color: $black-70;
  }

  .link {
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
