import {
  Configuration,
  type ConfigurationParameters,
  ExportApi,
  SupportApi,
} from "@/swagger-models/email-service-client";

class EmailServiceApi {
  configuration: Configuration;

  exportApi: ExportApi;
  supportApi: SupportApi;

  constructor() {
    this.configuration = new Configuration();

    this.exportApi = new ExportApi(this.configuration, this.baseUrl);
    this.supportApi = new SupportApi(this.configuration, this.baseUrl);
  }
  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const emailServiceApi = new EmailServiceApi();
