import type { INodePool } from "@/models/node-pool.model";
import { ECustomCell, type ITableColumn } from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import { convertToBytes, memoryFormat } from "@/utils/format.util";
import { nodePoolUtil } from "@/utils/node-pool.util/node-pool.util";
import { percentFormat, tableNumberFormat } from "@/utils/table-format.util";
import { PlacementStrategy, binPackLabel, spreadLabel } from "@/models/node-pool.model";
import { allWorkloadColumnsMap } from "@/table-models/workload.table-model";
import { CLUSTER_COLUMN_FILTER_NAME } from "@/models/filter.model";
import type { INodePoolsResourcesRow } from "@/models/project.model";
import { resourceUtil } from "@/utils/resource.util";
import { EResourceType } from "@/models/resource.model";
import { tableUtil } from "@/utils/table.util";

export enum ENodePoolColumnName {
  Name = "name",
  Status = "status",
  LabelKey = "label-key",
  LabelValue = "label-value",
  Nodes = "nodes",
  TotalGpus = "gpu",
  TotalGpuMemory = "gpu-memory",
  ProjectsGpus = "gpu-project-quota",
  AllocatedGpus = "allocated-gpu",
  UsedGpuMemory = "used-gpu-memory",
  Utilization = "utilization",
  MemoryUtilization = "memory-utilization",
  TotalCpus = "cpu",
  TotalCpuMemory = "cpu-memory",
  ProjectsCpuQuota = "cpu-project-quota",
  ProjectsMemoryQuota = "memory-project-quota",
  AllocatedCpus = "allocated-cpu",
  AllocatedCpuMemory = "allocated-cpu-memory",
  UsedCpuMemory = "used-cpu-memory",
  CpuComputeUtilization = "cpu-compute-utilization",
  CpuMemoryUtilization = "cpu-memory-utilization",
  GpuPlacementStrategy = "gpu-placement-strategy",
  CpuPlacementStrategy = "cpu-placement-strategy",
  UpdatedAt = "node-pool-updated-at",
  CreatedAt = "node-pool-created-at",
  Workloads = "workloads",
  OverProvisioning = "over-provisioning-ratio",
}

export const nodePoolColumns: Array<ITableColumn> = [
  {
    name: ENodePoolColumnName.Name,
    label: "Node pool",
    field: (row: INodePool) => row.name,
    sortable: true,
    align: "left",
    display: true,
    mandatory: true,
  },
  {
    name: ENodePoolColumnName.Status,
    label: "Status",
    field: "status",
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.NODE_POOL_STATUS_COL,
  },
  {
    name: ENodePoolColumnName.LabelKey,
    label: "Label key",
    field: "labelKey",
    sortable: true,
    align: "left",
    display: true,
    format: (key: string) => key || "-",
  },
  {
    name: ENodePoolColumnName.LabelValue,
    label: "Label value",
    field: "labelValue",
    sortable: true,
    align: "left",
    display: true,
    format: (val: string) => val || "-",
  },
  {
    name: ENodePoolColumnName.Nodes,
    label: "Node(s)",
    field: (row: INodePool) => {
      if (typeof row.nodes === "string") {
        return row.nodes.includes("[") ? JSON.parse(row.nodes) : row.nodes.split(",");
      }
      return row.nodes || [];
    },
    sortable: false,
    align: "left",
    display: true,
    format: (nodes: Array<string>): string[] | string => {
      if (!nodes?.length) return [];
      if (nodes[0] === "") return "-";
      return nodes.map((node: string) => node.trim());
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "nodes-clicked" },
  },
  {
    name: ENodePoolColumnName.TotalGpus,
    label: "GPU devices",
    field: "totalGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: ENodePoolColumnName.TotalGpuMemory,
    label: "GPU memory",
    field: "totalGpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: ENodePoolColumnName.ProjectsGpus,
    label: "Projects' GPU quota",
    field: "projectsGpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  {
    name: ENodePoolColumnName.AllocatedGpus,
    label: "Allocated GPUs",
    field: "allocatedGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: ENodePoolColumnName.UsedGpuMemory,
    label: "Used GPU memory",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: ENodePoolColumnName.Utilization,
    label: "GPU compute utilization",
    field: "utilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  {
    name: ENodePoolColumnName.MemoryUtilization,
    label: "GPU memory utilization",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalGpuMemory"),
  },
  {
    name: ENodePoolColumnName.TotalCpus,
    label: "CPUs (Cores)",
    field: "totalCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: ENodePoolColumnName.TotalCpuMemory,
    label: "CPU memory",
    field: "totalCpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: ENodePoolColumnName.ProjectsCpuQuota,
    label: "Projects' CPU quota (Cores)",
    field: "ProjectsCpuQuota",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  {
    name: ENodePoolColumnName.ProjectsMemoryQuota,
    label: "Projects' CPU memory quota",
    field: "projectsMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: ENodePoolColumnName.AllocatedCpus,
    label: "Allocated CPUs (Cores)",
    field: "allocatedCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: ENodePoolColumnName.AllocatedCpuMemory,
    label: "Allocated CPU memory",
    field: "allocatedMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: ENodePoolColumnName.UsedCpuMemory,
    label: "Used CPU memory",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: ENodePoolColumnName.CpuComputeUtilization,
    label: "CPU compute utilization",
    field: "usedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  {
    name: ENodePoolColumnName.CpuMemoryUtilization,
    label: "CPU memory utilization",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalCpuMemory"),
  },
  {
    name: ENodePoolColumnName.GpuPlacementStrategy,
    label: "GPU placement strategy",
    field: (row) => row.placementStrategy.gpu,
    sortable: true,
    align: "left",
    display: false,
    format: (placementStrategy) => (placementStrategy === PlacementStrategy.Spread ? spreadLabel : binPackLabel),
  },
  {
    name: ENodePoolColumnName.CpuPlacementStrategy,
    label: "CPU placement strategy",
    field: (row) => row.placementStrategy.cpu,
    sortable: true,
    align: "left",
    display: false,
    format: (placementStrategy) => (placementStrategy === PlacementStrategy.Spread ? spreadLabel : binPackLabel),
  },
  {
    name: ENodePoolColumnName.UpdatedAt,
    label: "Last update",
    field: "updatedAt",
    format: (updatedAt: string) => dateUtil.dateAndTimeFormat(new Date(updatedAt)),
    sortable: true,
    align: "left",
    display: false,
  },
  {
    name: ENodePoolColumnName.CreatedAt,
    label: "Creation time",
    field: "createdAt",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
    sortable: true,
    align: "left",
    display: false,
  },
  {
    name: ENodePoolColumnName.Workloads,
    label: "Workload(s)",
    field: () => "View",
    sortable: false,
    align: "left",
    display: false,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "workloads-clicked" },
  },
  {
    name: ENodePoolColumnName.OverProvisioning,
    label: "GPU resource optimization ratio",
    field: (row: INodePool) => row.overProvisioningRatio,
    format: (overProvisioningRatio: number): string | number =>
      nodePoolUtil.getNodePoolOprDisplayValue(overProvisioningRatio),
    sortable: true,
    align: "left",
    display: true,
  },
  {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (row: INodePool): string => row.clusterId,
    sortable: false,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
  },
];

export const nodePoolsDependentColumns = {
  overProvisioningRatio: new Set([ENodePoolColumnName.OverProvisioning]),
  nodes: new Set([ENodePoolColumnName.Nodes]),
  projectsQuota: new Set([
    ENodePoolColumnName.ProjectsCpuQuota,
    ENodePoolColumnName.ProjectsMemoryQuota,
    ENodePoolColumnName.ProjectsGpus,
  ]),
};

//workloads list modal
export const nodePoolWorkloadListModalColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPodsForModal, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: true },
  { ...allWorkloadColumnsMap.createdAt, display: true },
  { ...allWorkloadColumnsMap.allocatedGpu, display: true },
  { ...allWorkloadColumnsMap.allocatedGpuMemory, display: true },
  { ...allWorkloadColumnsMap.allocatedCpu, display: true },
  { ...allWorkloadColumnsMap.allocatedCpuMemory, display: true },
];

//org unit node pools modal
export const allOrgUnitNodePoolsModalColumnsMap: Record<string, ITableColumn> = {
  nodePoolName: {
    name: "node-pool-name",
    label: "Node pool",
    field: (row: INodePoolsResourcesRow) => row?.nodePool?.name || "-",
    sortable: true,
    align: "left",
  },
  gpuQuota: {
    name: "gpu",
    label: "GPU quota",
    field: (row: INodePoolsResourcesRow) => row.gpu.deserved,
    sortable: true,
    align: "left",
    format: (gpu: number) => resourceUtil.getResourceDisplayValue(gpu, EResourceType.GPU),
  },
  overQuotaWeight: {
    name: "over-quota-weight",
    label: "Over-quota weight",
    field: (row: INodePoolsResourcesRow) => row.overQuotaWeightLabel,
    sortable: true,
    align: "left",
  },
  cpuQuota: {
    name: "cpu-quota",
    label: "CPU (Cores)",
    field: (row: INodePoolsResourcesRow) => row.cpu?.deserved,
    sortable: true,
    align: "left",
    format: (cpu: number) => resourceUtil.getResourceDisplayValue(cpu, EResourceType.CPU),
  },
  cpuMemoryQuota: {
    name: "cpuMemory",
    label: "CPU memory",
    field: (row: INodePoolsResourcesRow) => row.memory?.deserved,
    sortable: true,
    align: "left",
    format: (memory: number) => resourceUtil.getResourceDisplayValue(memory, EResourceType.MEMORY),
  },
  allocatedGpus: {
    name: "allocatedGpus",
    label: "Allocated GPUs",
    field: (row: INodePoolsResourcesRow) => row.allocatedGpus,
    sortable: true,
    align: "left",
    format: (allocatedGpus: string | undefined) =>
      allocatedGpus ? resourceUtil.getResourceDisplayValue(parseFloat(allocatedGpus), EResourceType.GPU) : "-",
  },
  allocatedCpu: {
    name: "allocatedCpu",
    label: "Allocated CPU (Cores)",
    field: (row: INodePoolsResourcesRow) => row.allocatedCpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: string | undefined) =>
      allocatedCpu ? resourceUtil.getResourceDisplayValue(parseFloat(allocatedCpu), EResourceType.CPU) : "-",
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allOrgUnitNodePoolsModalColumnsMap.allocatedCpu, rowA, rowB, parseFloat),
  },
  allocatedMemory: {
    name: "allocatedMemory",
    label: "Allocated CPU memory",
    field: (row: INodePoolsResourcesRow) => row.allocatedMemory,
    sortable: true,
    align: "left",
    format: (allocatedMemory: number | undefined) =>
      allocatedMemory ? memoryFormat(convertToBytes(allocatedMemory, "MB")) : "-",
  },
  priority: {
    name: "priority",
    label: "Order of priority",
    field: (row: INodePoolsResourcesRow) => row.priority,
    sortable: true,
    align: "left",
  },
};

export const orgUnitNodePoolsModalTableColumns: Array<ITableColumn> = [
  { ...allOrgUnitNodePoolsModalColumnsMap.nodePoolName, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.gpuQuota, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.cpuQuota, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.overQuotaWeight, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.cpuMemoryQuota, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.allocatedGpus, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.allocatedCpu, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.allocatedMemory, display: true },
  { ...allOrgUnitNodePoolsModalColumnsMap.priority, display: true },
];

export const orgUnitNodePoolsModalDependentColumns = {
  cpu: new Set([
    allOrgUnitNodePoolsModalColumnsMap.cpuQuota.name,
    allOrgUnitNodePoolsModalColumnsMap.cpuMemoryQuota.name,
    allOrgUnitNodePoolsModalColumnsMap.allocatedCpu.name,
    allOrgUnitNodePoolsModalColumnsMap.allocatedMemory.name,
  ]),
  overQuotaWeight: new Set([allOrgUnitNodePoolsModalColumnsMap.overQuotaWeight.name]),
  priority: new Set([allOrgUnitNodePoolsModalColumnsMap.priority.name]),
};
