import {
  Configuration,
  type ConfigurationParameters,
  PolicyApi,
  SynchronizationApi,
} from "@/swagger-models/policy-service-client";

class PolicyServiceApi {
  configuration: Configuration;

  policyApi: PolicyApi;
  synchronizationApi: SynchronizationApi;

  constructor() {
    this.configuration = new Configuration();

    this.policyApi = new PolicyApi(this.configuration, this.baseUrl);
    this.synchronizationApi = new SynchronizationApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const policyServiceApi = new PolicyServiceApi();
