<template>
  <div class="column full-height">
    <div class="row full-height">
      <div class="col-4 main-content">
        <runai-svg-icon class="logo" name="runai"></runai-svg-icon>
        <div class="title">
          <div v-if="isLoading">Hang tight while we set up your account...</div>
          <div v-else>
            <div>Yikes,</div>
            <div>looks like we hit a bump while trying to create your account...</div>
          </div>
        </div>
        <div class="info-text">
          <div v-if="isLoading">This usually takes 1-2 minutes, and then you'll be able to sign in</div>
          <div v-else>
            <div>We already let our technical team know about this error. We'll email you with updates shortly.</div>
          </div>
        </div>
      </div>
      <div class="col-8 loading-section">
        <div v-if="isLoading">
          <runai-app-loader />
        </div>
        <div v-else>
          <runai-svg-icon class="failed-image" name="trial-failed"></runai-svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { trialService } from "@/services/control-plane/trial.service/trial.service";
import type { Trial } from "@/swagger-models/trial-service-client";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { useAppStore } from "@/stores/app.store";
import { RunaiAppLoader } from "@/components/common/runai-app-loader";
import { storageUtil } from "@/utils/storage.util";

const BASE64_URL_PARAM = "data";
const POLLING_TIME = 5000;
const TIME_OUT_TIME = 20000;

const TRIAL_ID = "trialId";
export default defineComponent({
  components: {
    RunaiSvgIcon,
    RunaiAppLoader,
  },
  data() {
    return {
      password: null as string | null,
      validation: null as string | null,
      trialId: null as string | null,
      pollIntervalId: null as number | null,
      trial: null as Trial | null,
      isLoading: true as boolean,
      appStore: useAppStore(),
    };
  },
  created() {
    this.getQueryParams();
    // must be after this.getQueryParams();
    if (storageUtil.get(TRIAL_ID) === this.trialId) {
      this.getTrialByIdAndRedirect(true);
    } else {
      this.appStore.setPageLoading(false);
      this.verifyTenant();
      this.getTenant();
    }
  },
  methods: {
    getQueryParams() {
      const base64UrlParams = new URLSearchParams(window.location.search);
      const data = base64UrlParams.get(BASE64_URL_PARAM);
      if (data) {
        const params = JSON.parse(atob(data));
        this.password = params.Password;
        this.validation = params.ValidationCode;
        this.trialId = params.TrialId;
      }
      if (this.isEmptyParams(data, this.password, this.validation, this.trialId)) {
        console.log("missing data");
        this.showError();
      }
    },
    async verifyTenant(): Promise<void> {
      if (this.isLoading && this.trialId && this.validation && this.password) {
        try {
          await trialService.verifyTenant(this.trialId, this.validation, this.password);
          storageUtil.save(TRIAL_ID, this.trialId);
          console.log("verification successful");
        } catch (err: unknown) {
          this.showError();
        }
      }
    },
    getTenant(): void {
      if (this.isLoading) {
        this.getTrialByIdAndRedirect(false);
        if (this.trial?.tenantDomain == undefined) {
          this.pollIntervalId = window.setInterval(() => this.getTrialByIdAndRedirect(false), POLLING_TIME);
          setTimeout(() => {
            this.pollIntervalId && clearInterval(this.pollIntervalId);
            this.showError();
          }, TIME_OUT_TIME);
        }
      }
    },
    async getTrialByIdAndRedirect(forceRedirect = false): Promise<void> {
      if ((this.trialId && this.validation) || forceRedirect) {
        const trial = await trialService.getTrialById(this.trialId, this.validation);
        if (trial.tenantDomain) {
          this.pollIntervalId && clearInterval(this.pollIntervalId);
          this.redirectToTenantDomain(trial.tenantDomain);
        }
        this.trial = trial;
      }
    },
    redirectToTenantDomain(tenantDomain: string): void {
      window.location.replace(tenantDomain);
    },
    hasTenantDomain(trial: Trial): boolean {
      return !!trial.tenantDomain;
    },
    isEmptyParams(
      data: string | null,
      password: string | null | undefined,
      validation: string | null | undefined,
      trialId: string | null | undefined,
    ): boolean {
      return !(data && password && validation && trialId);
    },
    showError(): void {
      this.isLoading = false;
    },
  },
});
</script>
<style lang="scss" scoped>
.main-content {
  background-color: #00263e;
  padding: 45px 0 0 45px;
}

.full-height {
  flex: 1;
}

.title {
  font-size: 36px;
  font-weight: 500;
  margin: 80px 10px 0 0;
  line-height: 50px;
  color: white;
}

.info-text {
  margin: 50px 10px 0 0;
  font-size: 20px;
  color: #ffffff80;
}
.loading-section {
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo {
  position: absolute;
  width: 60px;
  height: 40px;
}

.failed-image {
  width: 300px;
  height: 230px;
}
</style>
