<template>
  <q-chip clickable size="md" :removable="removable" :aid="chipAid" class="runai-filter-chip">
    <div class="row items-center no-wrap">
      <q-icon style="margin: unset" tag="div" v-if="iconName" :name="iconName" size="12px" />
      <span v-if="title" class="filter-chip-title"> {{ title }}:</span>
      <span :class="['q-px-xs ', `text-weight-${chipValueTextWeight}`]" class="filter-chip-value"> {{ value }}</span>
    </div>
    <q-menu
      class="page-filter-popup"
      transition-show="scale"
      transition-hide="scale"
      transition-duration="500"
      :model-value="display"
      @hide="$emit('close')"
    >
      <form @submit.prevent>
        <q-card-section class="row justify-between items-center q-py-sm">
          <div v-if="title || menuTitle" class="text-weight-medium">{{ title || menuTitle }}</div>
          <q-btn
            flat
            icon="fa-regular fa-xmark"
            class="q-pa-xs"
            round
            v-close-popup
            @click="$emit('close')"
            aid="close-btn"
          ></q-btn>
        </q-card-section>
        <q-card-section class="q-py-sm">
          <slot name="input-component"></slot>
        </q-card-section>
        <q-card-section class="row justify-end q-py-sm">
          <q-btn aid="apply-btn" type="submit" flat color="primary" label="Apply" v-close-popup @click="$emit('save')" />
        </q-card-section>
      </form>
    </q-menu>
  </q-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { stringUtil } from "@/utils/string.util";

export default defineComponent({
  emits: ["save", "close"],
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
    },
    menuTitle: {
      type: String as PropType<string>,
      required: false,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
    display: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    removable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    iconName: {
      type: String as PropType<string>,
      required: false,
    },
    chipValueTextWeight: {
      type: String as PropType<string>,
      default: "medium",
    },
  },
  computed: {
    chipAid(): string {
      return `${stringUtil.slug(this.title || this.menuTitle || "")}-filter-chip`;
    },
  },
});
</script>

<style lang="scss" scoped>
.page-filter-popup {
  width: 250px;
}
</style>
