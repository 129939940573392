import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { API } from "@/common/api.constant";
import type {
  Registry,
  RegistryCreationRequest,
  RegistryListResponse,
  Repositories,
  RepositoryTags,
} from "@/swagger-models/assets-service-client";

export const registryService = {
  getRegistries,
  getRepositories,
  getTags,
  createRegistry,
  deleteRegistry,
};

const ENDPOINT = `${API.asset}/registries`;

async function getRegistries(): Promise<RegistryListResponse> {
  return controlPlaneService.get(ENDPOINT);
}

async function getRepositories(registryId: string, query: string): Promise<Repositories> {
  if (!registryId) {
    return { repositories: [] };
  }
  return await controlPlaneService.get(`${ENDPOINT}/${registryId}/repositories?searchName=${query}`);
}

async function getTags(registryId: string, repository: string, query: string): Promise<RepositoryTags> {
  return await controlPlaneService.get(
    `${ENDPOINT}/${registryId}/repositories/tags?repository=${repository}&searchName=${query}`,
  );
}

async function createRegistry(registry: RegistryCreationRequest): Promise<Registry> {
  return controlPlaneService.post(ENDPOINT, registry);
}

async function deleteRegistry(id: string): Promise<void> {
  return controlPlaneService.delete(`api/v1/asset/registries/${id}`);
}
