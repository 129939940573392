<template>
  <q-btn :icon="icon" :size="size" flat round :ripple="ripple" :color="color">
    <q-tooltip
      class="q-pa-sm runai-tooltip"
      :class="{ 'dark-mode': darkMode }"
      :max-width="width"
      :anchor="anchorPosition"
      :self="selfPosition"
      @show="$emit('revealed')"
      @hide="$emit('hidden')"
    >
      <slot name="content">{{ tooltipText }} </slot>
    </q-tooltip>
  </q-btn>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
type TPosition = "bottom middle" | "top middle" | "center left" | "center right";
export default defineComponent({
  props: {
    tooltipText: {
      type: String as PropType<string>,
      required: true,
    },
    tooltipPosition: {
      type: String as PropType<string>,
      default: "top",
    },
    width: {
      type: String as PropType<string>,
      default: "250px",
      required: false,
    },
    icon: {
      type: String as PropType<string>,
      default: "far fa-circle-question",
      required: false,
    },
    size: {
      type: String as PropType<string>,
      default: "sm",
      required: false,
    },
    darkMode: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    ripple: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    color: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    selfPosition(): TPosition {
      switch (this.tooltipPosition) {
        case "top":
          return "bottom middle";
        case "bottom":
          return "top middle";
        case "right":
          return "center left";
        case "left":
          return "center right";
        default:
          return "bottom middle";
      }
    },
    anchorPosition(): TPosition {
      switch (this.tooltipPosition) {
        case "top":
          return "top middle";
        case "bottom":
          return "bottom middle";
        case "right":
          return "center right";
        case "left":
          return "center left";
        default:
          return "top middle";
      }
    },
  },
});
</script>

<style lang="scss">
.runai-tooltip {
  &.dark-mode {
    background-color: $black-70;
    color: $white;
  }
}
</style>
