<template>
  <component :is="tag">
    <slot></slot>
    <q-tooltip
      ref="wrapperToolTip"
      v-if="displayTooltip && tooltipText.length > 0"
      class="runai-tooltip-wrapper tooltip-content"
      :class="{ 'disable-break-line': disableBreakLine }"
      :anchor="anchor"
      :self="self"
      :max-width="maxWidth"
    >
      <slot name="tooltip-content">{{ tooltipText }}</slot>
    </q-tooltip>
  </component>
</template>

<script lang="ts">
import type { QTooltip } from "quasar";
import { defineComponent } from "vue";
import type { PropType } from "vue";

type TTooltipLocation =
  | "top left"
  | "top middle"
  | "top right"
  | "top start"
  | "top end"
  | "center left"
  | "center middle"
  | "center right"
  | "center start"
  | "center end"
  | "bottom left"
  | "bottom middle"
  | "bottom right"
  | "bottom start"
  | "bottom end";

export default defineComponent({
  props: {
    tag: {
      type: String as PropType<string>,
      default: "span",
    },
    tooltipText: {
      type: String as PropType<string>,
      default: "",
    },
    displayTooltip: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    disableBreakLine: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    useHtml: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    anchor: {
      type: String as PropType<TTooltipLocation>,
      default: "center right",
    },
    self: {
      type: String as PropType<TTooltipLocation>,
      default: "center left",
    },
    toggleTooltip: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    maxWidth: {
      type: String as PropType<string>,
      required: false,
    },
  },
  watch: {
    toggleTooltip: {
      handler() {
        this.$refs["wrapperToolTip"] && (this.$refs["wrapperToolTip"] as QTooltip).toggle();
      },
    },
  },
});
</script>

<style lang="scss">
.runai-tooltip-wrapper {
  &.tooltip-content {
    &.disable-break-line {
      white-space: nowrap;
    }
  }
}
</style>
