import { ResourceType, Action } from "@/swagger-models/authorization-client";
import AnalyticsIndex from "@/views/dashboard/analytics-index.vue";
import ConsumptionIndex from "@/views/dashboard/consumption-index.vue";
import ClustersDashboardIndex from "@/views/dashboard/clusters-dashboard-index.vue";
import ResourcesIndex from "@/views/dashboard/resources/resources-index.vue";
import { CpuAnalyticsIndex } from "@/views/dashboard/cpu-analytics";
import { CpuOverviewIndex } from "@/views/dashboard/cpu-overview";
import type { RunaiRouteRecord } from "vue-router";
import OverviewApp from "@/views/dashboard-v2/overview/overview-app.vue";
import { DASHBOARD_ROUTE_NAMES } from "@/router/dashboard.routes/dashboard.routes.names";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";

export const dashboardRoutes: RunaiRouteRecord = {
  path: "/dashboards",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/dashboard/dashboard-app.vue"),
  children: [
    {
      path: "now",
      name: DASHBOARD_ROUTE_NAMES.OVERVIEW_INDEX,
      component: OverviewApp,
      meta: {
        pageTitle: "Overview",
        resourceType: ResourceType.DashboardsOverview,
        minPermittedActions: [Action.Read],
        hideVersion: false,
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
    {
      path: "analytics",
      name: DASHBOARD_ROUTE_NAMES.ANALYTICS_INDEX,
      component: AnalyticsIndex,
      meta: {
        pageTitle: "Analytics",
        resourceType: ResourceType.DashboardsAnalytics,
        minPermittedActions: [Action.Read],
        hideVersion: true,
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
    {
      path: "consumption",
      name: DASHBOARD_ROUTE_NAMES.CONSUMPTION_INDEX,
      component: ConsumptionIndex,
      meta: {
        pageTitle: "Consumption",
        resourceType: ResourceType.DashboardsConsumption,
        minPermittedActions: [Action.Read],
        hideVersion: true,
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
    {
      path: "clusters",
      name: DASHBOARD_ROUTE_NAMES.CLUSTERS_DASHBOARD_INDEX,
      component: ClustersDashboardIndex,
      meta: {
        pageTitle: "Clusters dashboard",
        resourceType: ResourceType.DashboardsOverview,
        minPermittedActions: [Action.Read],
        hideVersion: true,
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
    {
      path: "resources",
      name: DASHBOARD_ROUTE_NAMES.RESOURCES_INDEX,
      component: ResourcesIndex,
      meta: {
        pageTitle: "Quota management",
        resourceType: ResourceType.DashboardsOverview,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
    {
      path: "cpu-analytics",
      name: DASHBOARD_ROUTE_NAMES.CPU_ANALYTICS,
      component: CpuAnalyticsIndex,
      meta: {
        pageTitle: "Analytics",
        resourceType: ResourceType.DashboardsAnalytics,
        minPermittedActions: [Action.Read],
        hideVersion: true,
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
    {
      path: "cpu-overview",
      name: DASHBOARD_ROUTE_NAMES.CPU_OVERVIEW,
      component: CpuOverviewIndex,
      meta: {
        pageTitle: "Overview",
        resourceType: ResourceType.DashboardsOverview,
        minPermittedActions: [Action.Read],
        hideVersion: true,
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ANALYTICS,
      },
    },
  ],
};
