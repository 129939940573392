import { type INode, NodeStatusMap } from "@/models/node.model";
import { NodeConditionMap } from "@/models/node.model";
import type { Node, NodeStatusConditionDetails } from "@/swagger-models/cluster-service-client";
import type { IStatusColOptions } from "@/models/table.model";
import { tableUtil } from "@/utils/table.util";

export const nodeUtil = {
  enrichNodesWithInfoFromCluster,
  getStatusColOptions,
};

function enrichNodesWithInfoFromCluster(nodes: Array<INode>, clusterServiceNodes: Node[]): Array<INode> {
  if (clusterServiceNodes.length === 0) {
    return nodes;
  }
  return clusterServiceNodes.map((csNode: Node) => {
    const node: INode = nodes.find((node: INode) => node.node === csNode.name) || _getEmptyNode();
    return {
      ...node,
      node: csNode.name,
      nodePool: csNode.nodePool,
      gpuType: csNode?.gpuInfo?.gpuType,
      totalGpus: csNode?.gpuInfo?.gpuCount?.toString(),
      status: csNode.status,
      conditions: csNode?.conditions,
      clusterId: csNode.clusterUuid,
    };
  });
}

function getStatusColOptions(node: Node): IStatusColOptions {
  const statusOptions: IStatusColOptions = NodeStatusMap[node.status];
  const tooltipText: string = getConditionsAsText(node);

  return tableUtil.getStatusColOptions(statusOptions, tooltipText);
}

function getConditionsAsText(node: Node): string {
  let conditions = "";
  node.conditions?.forEach((condition: NodeStatusConditionDetails) => {
    conditions += NodeConditionMap[condition.type]?.message + "<br>";
  });
  return conditions;
}

function _getEmptyNode(): INode {
  return {
    clusterId: "",
    id: "",
    node: "",
    nodeStatus: "",
    utilization: "",
    gpuType: "",
    totalGpus: "",
    allocatedGpus: "",
    usedGpus: "",
    totalCpus: "",
    allocatedCpus: "",
    usedCpus: "",
    totalGpuMemory: "",
    usedGpuMemory: "",
    totalCpuMemory: "",
    allocatedMemory: "",
    usedCpuMemory: "",
    swapCpuMemory: "",
    nodePool: "",
    gpuDevices: [],
    conditions: [],
  };
}
