import {
  ACTIVE_AXIS_LABEL_COLOR,
  chartColorPaletteArray,
  type IChartWidgetOptions,
  type ITooltipPoint,
  type TMeasurementsTimeAndValue,
  type IZoomEvent,
  CHART_COLOR_PALETTE,
  TOOLTIP_BASE_COLOR,
  EYAxisLabelFormat,
} from "@/models/chart.model";
import type { INodePoolChartSettings } from "@/models/node-pool.model";
import { chartSettings } from "@/models/node-pool.model";
import type { PrometheusMetricResponse } from "@/models/prometheus.model";
import { memoryFormat } from "@/utils/format.util";
import type {
  AxisSetExtremesEventObject,
  Chart,
  Options as HighchartsOptions,
  Options,
  XrangePointOptionsObject,
  PatternObject,
  GradientColorObject,
  XAxisOptions,
  YAxisOptions,
} from "highcharts";
import Highcharts from "highcharts";
import { dateUtil } from "@/utils/date.util";
import { differenceInHours, addSeconds } from "date-fns";
import type { MeasurementResponseValuesInner } from "@/swagger-models/cluster-service-client";
import { type MeasurementResponse } from "@/swagger-models/workloads-service-client";

const CHART_EVENTS = ["mousemove", "touchmove", "touchstart"];
const MOUSE_OUT_EVENT = "mouseout";
interface SeriesWithColor extends Highcharts.Series {
  color: string;
}

export const chartUtil = {
  getResourcesOptions,
  getMultiLineChartOptions,
  getSingleLineChartOptions,
  getAreaChartOptions,
  addMultiChartsCrosshairEvents,
  removeMultiChartsCrosshairEvent,
  getNodePoolChartSettings,
  getBasicTooltipOptions,
  getQuotaChartOptions,
  getQuotaChartSeries,
  getBasicWidgetChartOptions,
  formatTooltipValue,
  formatSharedTooltip,
  getTimeFrames,
  getTimeFrameForHoursAgo,
  arrangeMeasurementsByTime,
  getDatetimeOptions,
  assignColorsToKeys,
  addZoomOptions,
  updateYAxisLabelsDefaultsIfNeeded,
};

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

function getResourcesOptions(overQuotaValue: number, quotaValue: number, allocatedValue: number): Options {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "column",
      height: 105,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        visible: false,
      },
    ],
    yAxis: [
      {
        visible: false,
      },
    ],
    legend: {
      shadow: false,
      itemStyle: {
        fontWeight: "regular",
        fontSize: "10px",
      },
      width: "196px",
      itemDistance: 7,
      symbolWidth: 8,
      symbolHeight: 8,
      symbolPadding: 3,
    },
    tooltip: {
      shared: true,
      outside: true,
      hideDelay: 50,
      positioner: function (labelWidth) {
        const boundingClientRect = this.chart.container.getBoundingClientRect();
        return {
          x: boundingClientRect.x + labelWidth,
          y: boundingClientRect.y,
        };
      },
    },
    plotOptions: {
      column: {
        grouping: false,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "column",
        name: "Over quota",
        color: "#DF1995",
        data: [quotaValue + overQuotaValue],
        tooltip: {
          headerFormat: "",
          pointFormat: `<tspan style="color: {point.color}; fill: {point.color};">●</tspan> {series.name}: <tspan style="font-weight: bold;">${overQuotaValue} GPU</tspan><br />`,
        },
        pointWidth: 70,
      },
      {
        type: "column",
        name: "Quota",
        color: "#BED3FF",
        data: [quotaValue],
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<tspan style="color: {point.color}; fill: {point.color};">●</tspan> {series.name}: <tspan style="font-weight: bold;">{point.y} GPU</tspan><br />',
        },
        pointWidth: 70,
      },
      {
        type: "column",
        name: "Allocated",
        color: "#FC774A",
        data: [allocatedValue],
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<tspan style="color: {point.color}; fill: {point.color};">●</tspan> {series.name}: <tspan style="font-weight: bold;">{point.y} GPU</tspan><br />',
        },
        pointWidth: 50,
      },
    ],
  };
}

const AVERAGE_INDEX = 0;
function getMultiLineChartOptions(
  charts: Chart[],
  dataSeries: PrometheusMetricResponse,
  colors: string[],
  title: string,
  hasTheLegend: boolean,
  yAxisPostfix: string,
  showHours: boolean,
  isMemoryUnit = false,
): Options {
  Highcharts.setOptions({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      marginRight: 120,
      height: 280,
      type: "line",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
      },
      zoomType: "x",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
        fontWeight: "bold",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (): string {
          const label: string = this.axis.defaultLabelFormatter.call(this);
          if (isMemoryUnit) {
            return memoryFormat(this.value) + yAxisPostfix;
          }
          // Use thousands separator for four-digit numbers too
          if (/^\d{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value as number, 0, ".", ",");
          }
          return `${label}${yAxisPostfix === "%" ? "" : " "}${yAxisPostfix}`;
        },
      },
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function (): string {
          return _dateFormatter(+this.value, showHours);
        },
      },
      events: {
        afterSetExtremes: function (event: AxisSetExtremesEventObject) {
          _handleAxisSetExtremesEventObject(event, charts, this.chart);
        },
      },
      crosshair: true,
    },
    legend: {
      floating: true,
      verticalAlign: "top",
      align: "right",
      enabled: hasTheLegend,
      layout: "vertical",
      itemMarginTop: 1,
      itemMarginBottom: 7,
      symbolHeight: 20,
      itemStyle: {
        color: "#000000b3",
      },
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
        events: {
          legendItemClick: hasTheLegend
            ? function (): boolean {
                const isVisible = this.visible;
                charts.forEach((chart: Highcharts.Chart) => {
                  if (isVisible) {
                    chart.series[this.index].hide();
                  } else {
                    chart.series[this.index].show();
                  }
                });
                return false;
              }
            : undefined,
        },
        marker: {
          enabled: false,
        },
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },
    tooltip: {
      formatter: function (): string {
        const chartValuePoint: Highcharts.TooltipFormatterContextObject[] | undefined = this.points;
        if (!chartValuePoint) return "";
        const pointsLength = chartValuePoint?.length;
        const timestamp: number = chartValuePoint[0]?.key ? +chartValuePoint[0].key : 0;
        let tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' + Highcharts.dateFormat("%m/%d/%y %H:%M:%S", timestamp) + "</span><br/>"
          : "";
        chartValuePoint?.forEach((point: Highcharts.TooltipFormatterContextObject) => {
          const yValue = (point?.y as number).toFixed(2);

          // Check if it is more than 1000 to replace with K
          const thousandsFormat = Highcharts.numberFormat(+yValue / 1000, 0, ".", ",") + "K";
          const value = isMemoryUnit
            ? memoryFormat(point?.y as number)
            : `${yAxisPostfix === "%" ? yValue : thousandsFormat + " "}${yAxisPostfix}`;

          tooltipMarkup +=
            '<span style="color:' +
            (point.series as SeriesWithColor).color +
            '">\u25CF</span> ' +
            point.series.name +
            ": <b>" +
            value +
            " </b><br/>";
        });

        return tooltipMarkup;
      },
      valueDecimals: 2,
      shared: true,
      xDateFormat: "%m/%d/%y %H:%M:%S",
      backgroundColor: TOOLTIP_BASE_COLOR,
      borderColor: TOOLTIP_BASE_COLOR,
      shape: "square",
      hideDelay: 50,
      style: {
        color: "#F0F0F0",
      },
    },
    credits: {
      enabled: false,
    },
    series: dataSeries.map((item, index) => {
      return {
        name: `${index === AVERAGE_INDEX ? "Average" : index}`,
        data: item,
        color: colors[index],
        dashStyle: `${index === AVERAGE_INDEX ? "ShortDot" : "Solid"}`,
        marker: {
          symbol: "circle",
        },
      };
    }) as [],
  };
}

function getSingleLineChartOptions(
  charts: Chart[],
  dataSeries: PrometheusMetricResponse,
  color: string,
  title: string,
  yAxisPostfix: string,
  showHours: boolean,
  isMemoryUnit = false,
  decimalDigitsAmount = 2,
): Options {
  Highcharts.setOptions({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      height: 210,
      type: "line",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
      },
      zoomType: "x",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
        fontWeight: "500",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (): string {
          let label: string = this.axis.defaultLabelFormatter.call(this);
          // Use thousands separator for four-digit numbers too
          if (/^\d{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value as number, decimalDigitsAmount, ".", ",");
          }
          if (isMemoryUnit) {
            label = memoryFormat(this.value);
            return label;
          }
          label = Highcharts.numberFormat(this.value as number, decimalDigitsAmount, ".", ",");
          return `${label}${yAxisPostfix === "%" ? "" : " "}${yAxisPostfix}`;
        },
      },
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function (): string {
          return _dateFormatter(+this.value, showHours);
        },
      },
      crosshair: true,
      events: {
        afterSetExtremes: function (event): void {
          _handleAxisSetExtremesEventObject(event, charts, this.chart);
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },
    tooltip: {
      formatter: function (): string {
        const timeStamp: Date = new Date(this?.x ? +this?.x : 0);
        const timeStampWithFormat: string = Highcharts.dateFormat("%m/%d/%y %H:%M:%S", timeStamp.getTime());
        const label = isMemoryUnit
          ? memoryFormat(this?.y as number)
          : Highcharts.numberFormat(this?.y as number, decimalDigitsAmount, ".", ",") +
            `${yAxisPostfix === "%" ? "" : " "}${yAxisPostfix}`;
        return `${timeStampWithFormat}<br><b>${label}</b>`;
      },
      valueDecimals: decimalDigitsAmount,
      hideDelay: 50,
      shared: false,
      xDateFormat: "",
      backgroundColor: TOOLTIP_BASE_COLOR,
      borderColor: TOOLTIP_BASE_COLOR,
      shape: "square",
      style: {
        color: "#F0F0F0",
      },
    },
    credits: {
      enabled: false,
    },
    series: dataSeries.map((item) => {
      return {
        data: item,
        color: color,
      };
    }) as [],
  };
}

function getAreaChartOptions(
  charts: Chart[],
  dataSeries: PrometheusMetricResponse,
  colors: string[],
  names: string[],
  title: string,
  showHours: boolean,
  totalValue: number,
  totalColor: string,
  legendPadding = 0,
): Options {
  Highcharts.setOptions({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      marginRight: 200,
      height: 280,
      type: "area",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
      },
      zoomType: "x",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
        fontWeight: "500",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (): string {
          const label: string = this.axis.defaultLabelFormatter.call(this);
          return `${label}`;
        },
      },
      plotLines: [
        {
          color: totalColor,
          dashStyle: "ShortDot",
          value: totalValue + 0.2,
          width: 2,
        },
      ],
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function (): string {
          return _dateFormatter(+this.value, showHours);
        },
      },
      events: {
        afterSetExtremes: function (event: AxisSetExtremesEventObject) {
          _handleAxisSetExtremesEventObject(event, charts, this.chart);
        },
      },
      crosshair: true,
    },
    legend: {
      floating: true,
      verticalAlign: "top",
      align: "right",
      enabled: true,
      layout: "vertical",
      itemMarginTop: 1,
      itemMarginBottom: 10,
      padding: legendPadding,
      symbolHeight: 6,
      symbolRadius: 0,
      squareSymbol: false,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      area: {
        stacking: "normal",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
        },
      },
    },
    tooltip: {
      formatter: function (): string {
        const chartValuePoint: Highcharts.TooltipFormatterContextObject[] | undefined = this.points;
        if (!chartValuePoint) return "";
        const pointsLength = chartValuePoint?.length;
        const timestamp: number = chartValuePoint[0]?.key ? +chartValuePoint[0].key : 0;
        let tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' + Highcharts.dateFormat("%m/%d/%y %H:%M:%S", timestamp) + "</span><br/>"
          : "";
        tooltipMarkup += totalColor
          ? '<span style="color:' + totalColor + '">\u25CF</span> ' + "Total" + ": <b>" + totalValue + " </b><br/>"
          : "";
        chartValuePoint?.forEach((point: Highcharts.TooltipFormatterContextObject) => {
          tooltipMarkup +=
            '<span style="color:' +
            (point.series as SeriesWithColor).color +
            '">\u25CF</span> ' +
            point.series.name +
            ": <b>" +
            point?.y +
            " </b><br/>";
        });
        return tooltipMarkup;
      },
      valueDecimals: 0,
      shared: true,
      xDateFormat: "%m/%d/%y %H:%M:%S",
      backgroundColor: TOOLTIP_BASE_COLOR,
      borderColor: TOOLTIP_BASE_COLOR,
      shape: "square",
      hideDelay: 50,
      style: {
        color: "#F0F0F0",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Total",
        data: [],
        color: totalColor,
        dashStyle: "ShortDot",
        marker: {
          symbol: "circle",
        },
      },
    ].concat(
      dataSeries.map((item, index) => {
        return {
          fillOpacity: 0.2,
          lineWidth: 1,
          name: `${names[index]}`, //`${index === TOTAL_INDEX ? "Total" : index}`,
          data: item,
          color: colors[index],
          dashStyle: "Solid", //`${index === TOTAL_INDEX ? "ShortDot" : "Solid"}`,
          marker: {
            symbol: "circle",
          },
        };
      }) as [],
    ) as [],
  };
}

function _dateFormatter(timestamp: number, showHours: boolean): string {
  return Highcharts.dateFormat(showHours ? "%m/%d/%y %H:%M" : "%m/%d/%y", timestamp);
}
function _handleAxisSetExtremesEventObject(
  event: AxisSetExtremesEventObject,
  charts: Highcharts.Chart[],
  currentChart: Highcharts.Chart,
): void {
  const xMin: number = event.min;
  const xMax: number = event.max;
  charts.forEach((chart: Highcharts.Chart) => {
    if (chart != currentChart) {
      const ex: Highcharts.ExtremesObject = chart.xAxis[0].getExtremes();

      if (ex.min != xMin || ex.max != xMax) chart.xAxis[0].setExtremes(xMin, xMax, true, false);
    }
  });
}
function removeMultiChartsCrosshairEvent(charts: Highcharts.Chart[], containers: HTMLElement[]): void {
  CHART_EVENTS.forEach(function (eventType: string) {
    containers.forEach((container: HTMLElement) => {
      container.removeEventListener(eventType, _handChartsEvents);
    });
  });
  containers.forEach((container: HTMLElement) => {
    container.removeEventListener(MOUSE_OUT_EVENT, _handleChartMouseOut);
  });
}

function _handChartsEvents(event: MouseEvent | unknown, charts: Highcharts.Chart[] = []): void {
  charts.forEach((chart: Highcharts.Chart) => {
    if (!chart.pointer) return; // this is moslty can happen in dev mode when vite reload the page

    const e: Highcharts.PointerEventObject = chart.pointer.normalize(event as MouseEvent);
    const chartValuePoint: Highcharts.Point | undefined =
      chart.series[0]?.searchPoint(e, true) || chart.series[1]?.searchPoint(e, true);

    if (chartValuePoint && chartValuePoint.series) {
      try {
        const pointEvent: Highcharts.PointerEventObject = chartValuePoint.series.chart.pointer.normalize(
          event as MouseEvent,
        );
        chartValuePoint.onMouseOver();
        chartValuePoint.series.chart.xAxis[0].drawCrosshair(pointEvent, chartValuePoint);
      } catch (e) {
        // do nothing
      }
    }
  });
}

function _handleChartMouseOut(event: MouseEvent | undefined, charts: Highcharts.Chart[] = []): void {
  charts.forEach((chart: Highcharts.Chart) => {
    if (!chart.tooltip || !chart.xAxis || !chart.pointer) return; // this is moslty can happen in dev mode when vite reload the page
    chart.tooltip.hide();
    chart.xAxis[0].hideCrosshair();
    const e: Highcharts.PointerEventObject = chart.pointer.normalize(event as MouseEvent);
    const chartValuePoint: Highcharts.Point | undefined =
      chart.series[0]?.searchPoint(e, true) || chart.series[1]?.searchPoint(e, true);

    if (chartValuePoint) {
      chartValuePoint.onMouseOut && chartValuePoint.onMouseOut();
    }
  });
}
/**
 * In order to synchronize tooltips and crosshairs, override the
 * built-in events with handlers defined on the parent element.
 */
function addMultiChartsCrosshairEvents(charts: Highcharts.Chart[], containers: HTMLElement[]): void {
  /**
   * Override the reset function, we don't need to hide the tooltips and crosshair.
   */
  Highcharts.Pointer.prototype.reset = function (): undefined {
    return undefined;
  };

  containers.forEach((container: HTMLElement) => {
    container.addEventListener(MOUSE_OUT_EVENT, function (e: MouseEvent) {
      _handleChartMouseOut(e, charts);
    });
  });
  /**
   * In order to synchronize tooltips and crosshair, override the
   * built-in events with handlers defined on the parent element.
   */
  CHART_EVENTS.forEach(function (eventType: string) {
    containers.forEach((container: HTMLElement) => {
      container.addEventListener(eventType, function (event: Event) {
        _handChartsEvents(event, charts);
      });
    });
  });
}

function getNodePoolChartSettings(hasGpus: boolean): INodePoolChartSettings[] {
  return chartSettings.filter((setting) => (setting.isGpuChart && hasGpus) || !setting.isGpuChart);
}

function getQuotaChartOptions(): Highcharts.Options {
  return {
    chart: {
      type: "xrange",
      height: 72,
      width: 220,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    series: [],
  };
}

function getQuotaChartSeries(
  isOverQuotaExceeds: boolean,
  projectsDepartmentsMemoryRatio: number,
  data?: Array<XrangePointOptionsObject>,
): Highcharts.SeriesXrangeOptions[] {
  const seriesConfiguration: Highcharts.SeriesXrangeOptions[] = [
    {
      type: "xrange",
      name: "cpu-memory-quota",
      pointPadding: 0,
      groupPadding: 0,
      borderRadius: 1,
      borderWidth: 1,
      pointWidth: 25,
      borderColor: "#003F5C",
      enableMouseTracking: false,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return null;
        },
      },
      data: [
        {
          x: 0,
          x2: 1,
          y: 0,
          color: "#E0E0E0",
          partialFill: {
            fill: "#0BB4FF",
            amount: projectsDepartmentsMemoryRatio,
          },
        },
      ],
    },
  ];

  const overQuotaSeriesData = [
    {
      x: 1,
      x2: 1.009,
      y: 0,
      color: "#003F5C",
    },
    {
      x: 1.01,
      x2: 1.2,
      y: 0,
      color: "#FFAB00",
    },
  ];

  if (isOverQuotaExceeds) {
    seriesConfiguration[0].data = seriesConfiguration[0].data?.concat(overQuotaSeriesData);
  }

  if (data) {
    seriesConfiguration[0].data = data;
  }
  return seriesConfiguration;
}

function getBasicTooltipOptions(sharedTooltip?: boolean): Highcharts.TooltipOptions {
  return {
    shared: sharedTooltip || false,
    backgroundColor: TOOLTIP_BASE_COLOR,
    borderColor: TOOLTIP_BASE_COLOR,
    shape: "square",
    hideDelay: 50,
    style: {
      color: "#F0F0F0",
    },
  };
}

function getBasicWidgetChartOptions(chartOptions: IChartWidgetOptions): HighchartsOptions {
  const basicOptions: HighchartsOptions = {
    plotOptions: {
      column: {
        pointWidth: 30,
        borderWidth: 0,
      },
    },
    tooltip: getBasicTooltipOptions(chartOptions.sharedTooltip),
    legend: { enabled: true },
    title: { text: "" },
    chart: {
      type: chartOptions.type,
      height: chartOptions.height,
      style: {
        fontFamily: "Roboto",
      },
      events: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        drilldown: () => {},
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: chartOptions.yAxisTitle,
      },
    },
    xAxis: {
      type: "category",
    },
    colors: chartColorPaletteArray,
    series: [],
    drilldown: {
      series: [],
      breadcrumbs: {
        showFullPath: true,
        style: {
          fontWeight: "light",
        },
        buttonTheme: {
          style: {
            fontSize: "12px",
          },
        },
      },
      allowPointDrilldown: false,
      activeAxisLabelStyle: {
        color: ACTIVE_AXIS_LABEL_COLOR,
      },
      activeDataLabelStyle: {
        color: ACTIVE_AXIS_LABEL_COLOR,
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };
  if (chartOptions.drilldownFormatter) {
    basicOptions.drilldown = {
      breadcrumbs: {
        formatter: chartOptions.drilldownFormatter,
        showFullPath: true,
        buttonTheme: {
          style: {
            color: ACTIVE_AXIS_LABEL_COLOR,
          },
        },
      },
      allowPointDrilldown: false,
      activeAxisLabelStyle: {
        color: ACTIVE_AXIS_LABEL_COLOR,
      },
      activeDataLabelStyle: {
        color: ACTIVE_AXIS_LABEL_COLOR,
      },
    };
  }

  if (chartOptions.showSharedCrosshair) {
    basicOptions.xAxis = {
      ...basicOptions.xAxis,
      crosshair: {
        width: 2,
        color: "#0000008A",
      },
    };
  }

  if (chartOptions.yAxisFormatFunction) {
    basicOptions.yAxis = {
      ...basicOptions.yAxis,
      labels: {
        formatter: function (): string {
          if (chartOptions.yAxisFormatFunction) {
            return chartOptions.yAxisFormatFunction(this.value);
          }
          return "";
        },
      },
    };
  }

  return basicOptions;
}

function formatTooltipValue(
  name: string,
  color: string | GradientColorObject | PatternObject | undefined,
  value: number | string,
  symbol = "",
): string {
  const colorPoint = color
    ? `<span style="color: ${color}; padding-right: 5px;">\u25CF</span>`
    : `<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>`;

  return `${colorPoint ? colorPoint + " " : ""}${name}: <b>${value}${symbol}</b><br>`;
}

function formatSharedTooltip(points: Array<ITooltipPoint>, time?: string | number): string {
  let retTooltip = "";
  if (time) {
    retTooltip = `<div style="padding-left: 3px; padding-bottom: 5px;">${dateUtil.dateFormat(
      new Date(time),
      "dd/MM/yyyy HH:mm",
    )}</div>`;
  }
  retTooltip = points.reduce((tooltip: string, point: ITooltipPoint) => {
    if (point.y === undefined || point.y === null) {
      return tooltip;
    }

    return tooltip + chartUtil.formatTooltipValue(point.name, point.color, point.y, point.symbol);
  }, retTooltip);

  return retTooltip;
}

const DAYS_IN_HOURS: Record<string, number> = {
  ONE_DAY: _daysToHours(1),
  TWO_DAYS: _daysToHours(2),
  THREE_DAYS: _daysToHours(3),
  FOUR_DAYS: _daysToHours(4),
  NINE_DAYS: _daysToHours(9),
  THIRTEEN_DAYS: _daysToHours(13),
  TWENTY_DAYS: _daysToHours(20),
  THIRTY_DAYS: _daysToHours(30),
  THREE_MONTHS: _daysToHours(90),
  SIX_MONTHS: _daysToHours(6 * 30),
  ONE_YEAR: _daysToHours(365),
  TWO_YEARS: _daysToHours(2 * 365),
  FIVE_YEARS: _daysToHours(5 * 365),
};

function getTimeFrames(dateStart: Date, dateEnd: Date): string[] {
  const numberOfHours: number = differenceInHours(dateEnd, dateStart);
  let intervalInSeconds;
  if (numberOfHours <= 3) {
    intervalInSeconds = 15;
  } else if (numberOfHours <= 6) {
    intervalInSeconds = 30; // half an hour
  } else if (numberOfHours <= DAYS_IN_HOURS.TWO_DAYS) {
    intervalInSeconds = _minutesToSeconds(3);
  } else if (numberOfHours <= DAYS_IN_HOURS.FOUR_DAYS) {
    intervalInSeconds = _minutesToSeconds(10);
  } else if (numberOfHours <= DAYS_IN_HOURS.NINE_DAYS) {
    intervalInSeconds = _minutesToSeconds(15);
  } else if (numberOfHours <= DAYS_IN_HOURS.THIRTEEN_DAYS) {
    intervalInSeconds = _minutesToSeconds(20);
  } else if (numberOfHours <= DAYS_IN_HOURS.TWENTY_DAYS) {
    intervalInSeconds = _minutesToSeconds(30);
  } else if (numberOfHours <= DAYS_IN_HOURS.THIRTY_DAYS) {
    intervalInSeconds = _hoursToSeconds(1);
  } else if (numberOfHours <= DAYS_IN_HOURS.THREE_MONTHS) {
    intervalInSeconds = _hoursToSeconds(3);
  } else if (numberOfHours <= DAYS_IN_HOURS.SIX_MONTHS) {
    intervalInSeconds = _hoursToSeconds(6);
  } else if (numberOfHours <= DAYS_IN_HOURS.ONE_YEAR) {
    intervalInSeconds = _hoursToSeconds(12);
  } else if (numberOfHours <= DAYS_IN_HOURS.TWO_YEARS) {
    intervalInSeconds = _hoursToSeconds(24);
  } else if (numberOfHours <= DAYS_IN_HOURS.FIVE_YEARS) {
    intervalInSeconds = _daysToSeconds(3);
  } else {
    throw new Error(`Range time: ${dateStart} - ${dateEnd} is not supported`);
  }

  return getTimeFrameForHoursAgo(dateStart, dateEnd, intervalInSeconds);
}

function getTimeFrameForHoursAgo(dateStart: Date, dateEnd: Date, interval: number): string[] {
  const timeFrames: string[] = [];
  let startTime: Date = new Date(dateStart);

  while (startTime.getTime() <= dateEnd.getTime()) {
    const dateTimeString: string = startTime.toISOString();
    timeFrames.push(dateTimeString);
    startTime = addSeconds(startTime, interval);
  }
  return timeFrames;
}

function _minutesToSeconds(minutes: number): number {
  return minutes * 60;
}

function _hoursToSeconds(hours: number): number {
  const minutes = hours * 60;
  return _minutesToSeconds(minutes);
}

function _daysToHours(days: number): number {
  return days * 24;
}

function _daysToSeconds(days: number): number {
  const hours = _daysToHours(days);
  const minutes = hours * 60;
  return _minutesToSeconds(minutes);
}

function arrangeMeasurementsByTime(
  timeFrames: string[],
  measurements: Array<MeasurementResponseValuesInner>,
): Array<TMeasurementsTimeAndValue> {
  const chartData: Array<TMeasurementsTimeAndValue> = [];
  for (let i = 0; i < timeFrames.length - 1; i++) {
    const startTime = new Date(timeFrames[i]).getTime();
    const endTime = new Date(timeFrames[i + 1]).getTime();

    const pointOnCurrTime: Array<MeasurementResponseValuesInner> = measurements.filter(
      (currentPoint: MeasurementResponseValuesInner) => {
        if (!currentPoint.timestamp) return;
        const currentTimestamp = new Date(currentPoint.timestamp).getTime();
        return currentTimestamp >= startTime && currentTimestamp < endTime;
      },
    );

    chartData.push(..._generateChartDataPoint(timeFrames[i], pointOnCurrTime));
  }

  // last item
  const startTime = new Date(timeFrames[timeFrames.length - 1]).getTime();
  const pointOnCurrTime: Array<MeasurementResponseValuesInner> = measurements.filter(
    (currentPoint: MeasurementResponseValuesInner) => {
      if (!currentPoint.timestamp) return;
      const currentTimestamp = new Date(currentPoint.timestamp).getTime();
      return currentTimestamp === startTime;
    },
  );

  chartData.push(..._generateChartDataPoint(timeFrames[timeFrames.length - 1], pointOnCurrTime));
  return chartData;
}

function _generateChartDataPoint(
  timeFrame: string,
  pointOnCurrTime: MeasurementResponseValuesInner[],
): Array<TMeasurementsTimeAndValue> {
  const chartDataPoint: Array<TMeasurementsTimeAndValue> = [];
  if (pointOnCurrTime.length === 0) {
    chartDataPoint.push([new Date(timeFrame).getTime(), null]);
  } else {
    pointOnCurrTime.forEach((point: MeasurementResponseValuesInner) => {
      if (point.timestamp) {
        chartDataPoint.push([new Date(point.timestamp).getTime(), Number(point.value)]);
      }
    });
  }
  return chartDataPoint;
}

function getDatetimeOptions(xAxis: XAxisOptions): XAxisOptions {
  const retXAxis: XAxisOptions = {
    ...xAxis,
    type: "datetime",
    showLastLabel: true,
    labels: {
      formatter: function () {
        return dateUtil.dateFormat(new Date(this.value), "dd/MM/yyyy HH:mm");
      },
    },
  };

  return retXAxis;
}

function assignColorsToKeys<T>(keys: T[]): Map<T, string> {
  const colorsMap = new Map<T, string>();
  let colorIndex = 0;

  keys.forEach((key) => {
    const color: CHART_COLOR_PALETTE = chartColorPaletteArray[colorIndex % chartColorPaletteArray.length];
    colorsMap.set(key, color);
    colorIndex++;
  });

  return colorsMap;
}

function addZoomOptions(
  chartOptions: HighchartsOptions,
  chartId: string,
  onZoom: (event: IZoomEvent) => void,
  onZoomReset: (chartId: string) => void,
): HighchartsOptions {
  const options: HighchartsOptions = {
    ...chartOptions,
    chart: {
      ...chartOptions.chart,
      zoomType: "x",
      events: {
        ...chartOptions.chart?.events,
        click: () => onZoomReset(chartId),
      },
    },
    xAxis: {
      ...chartOptions.xAxis,
      events: {
        afterSetExtremes: (event: AxisSetExtremesEventObject) => {
          if (event.trigger === "zoom" && onZoom) {
            onZoom({ max: event.max, min: event.min, chartId: chartId });
          }
        },
      },
    },
  };

  return options;
}

/**
 * Updates the Y-axis configuration if all the metrics are zero or it is an empty array.
 * If the type is set to 'Memory', it updates the tick positions and label formatter to display
 * memory units (e.g., Bytes, G).
 *
 * @param {MeasurementResponse[]} measurements - An array of measurement responses that contain metric data.
 * @param {YAxisOptions} yAxis - The current Y-axis configuration object that may be updated (see highcharts for more info).
 * @param {{ steps?: number[]; type?: EYAxisLabelFormat }} options - Optional settings for customizing the Y-axis.
 * @param {number[]} [options.steps] - Optional array of tick positions for the Y-axis (e.g., [0, 20, 40, 60, 80]).
 * @param {EYAxisLabelFormat} [options.type] - The format type for the Y-axis labels, such as 'Memory'.
 *
 * @returns {YAxisOptions} - Returns the updated Y-axis configuration with custom tick positions and label formatter
 * if no valid measurements are found and the type is 'Memory'. Otherwise, returns the original Y-axis.
 */
function updateYAxisLabelsDefaultsIfNeeded(
  measurements: MeasurementResponse[],
  yAxis: YAxisOptions,
  options: {
    steps?: Array<number>;
    type?: EYAxisLabelFormat;
  },
): YAxisOptions {
  const yAxisUpdated: YAxisOptions = { ...yAxis };
  const filteredMeasurements = measurements.filter((measurement: MeasurementResponse) => {
    return measurement.values && measurement.values.length > 0;
  });

  if (filteredMeasurements.length > 0) return yAxisUpdated;

  if (options.steps) {
    yAxisUpdated.tickPositions = options.steps;
  }

  if (options.type === EYAxisLabelFormat.Memory) {
    if (!options.steps) {
      yAxisUpdated.tickPositions = [0, 20, 40, 60, 80];
    }

    yAxisUpdated.labels = {
      formatter: function () {
        return this.value === 0 ? `${this.value} Bytes` : `${this.value} G`;
      },
    };
  }

  return yAxisUpdated;
}
