import type { IFilterOption } from "@/models/filter.model";

export const filterOptions: Array<IFilterOption> = [
  {
    field: "department",
    name: "Department",
    label: "Department",
  },
  {
    field: "project",
    name: "Project",
    label: "Project",
  },
  {
    field: "nodepool",
    name: "Node pool",
    label: "Node pool",
  },
];

export enum EFilterOption {
  Department = "department",
  Project = "project",
  Nodepool = "nodepool",
}
