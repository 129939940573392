export const emailUtil = {
  renewLicenseTemplate,
  extendLicenseTemplate,
};

const SUPPORT_EMAIL = "support@run.ai";
const BREAK_LINE = "%0d%0a";

function renewLicenseTemplate() {
  return _baseTemplate(
    SUPPORT_EMAIL,
    `Renewing Run.ai license`,
    `Hello Run.ai,${BREAK_LINE}I'm interested in renewing my license. Please contact me so that we can move forward.${BREAK_LINE} Thank you.`,
  );
}

function extendLicenseTemplate() {
  return _baseTemplate(
    SUPPORT_EMAIL,
    `Extending Run:ai license`,
    `Hello Run.ai,${BREAK_LINE}I'm interested in extending my license. Please contact me so that we can move forward.${BREAK_LINE} Thank you.`,
  );
}

function _baseTemplate(to: string, subject: string, msg: string) {
  return `mailto:${to}?subject=${subject}&body=${msg}`;
}
