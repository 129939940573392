import ModelSpecIndex from "@/views/model-spec/model-spec-index.vue";
import { MODEL_SPEC_NAMES } from "./model-spec.routes.names";
import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";

export const modelSpecRoutes: RunaiRouteRecord = {
  path: "/models",
  component: () => import("@/views/model-spec/model-spec-app.vue"),
  children: [
    {
      path: "",
      name: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
      component: ModelSpecIndex,
      meta: {
        pageTitle: "Models",
        fullWidth: false,
        fullPage: false,
        requiresAuth: true,
        resourceType: ResourceType.Inferences,
        minPermittedActions: [Action.Read],
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
    },
  ],
};
