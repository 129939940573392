<template>
  <runai-expansion-item class="settings-container" label="Workloads" :subheader="sectionSubHeader">
    <template v-for="setting in workloadSettingsValues" :key="setting.key">
      <template v-if="setting.key === SettingKeys.EnableWandbSweeps && shouldShowSetting(setting.key)">
        <div class="row col-12 items-center">
          <toggle-setting class="q-mb-md" :setting="setting">
            <div class="q-mt-lg row col-12" v-if="isWabSweepsEnabled">
              <div class="q-mt-lg col-12">
                Set self-hosted server for the SWEEP integration <span class="text-italic">(optional)</span>
              </div>
              <div class="q-mt-lg col-12">
                <settings-url-field
                  :server-url="workloadSectionSettings.wabHost || ''"
                  @save="saveWabHost"
                  delete-tool-tip="Remove W&B server"
                  edit-tool-tip="Edit W&B server"
                  add-button-label="+ w&b server"
                  input-label="Server URL"
                  prefix-in-read-only-mode="W&B server: "
                  delete-sub-title="The W&B SWEEP integration will be configured to the W&B cloud."
                />
              </div>
            </div>
          </toggle-setting>
        </div>
        <q-separator class="q-mb-md hide-if-last-child" />
      </template>
      <template v-else-if="setting.key === SettingKeys.RegistryIntegration && shouldShowSetting(setting.key)">
        <docker-registry-fields
          :registry-data="workloadSectionSettings.dockerRegistryData"
          @deleted="deleteRegistry"
          @updated="saveRegistry"
          ref="dockerRegistryFields"
        />
        <q-separator class="q-mb-md hide-if-last-child" />
      </template>
      <template v-else-if="setting.key && !specialSettings.includes(setting.key) && shouldShowSetting(setting.key)">
        <toggle-setting class="q-mb-md" :setting="setting" />
        <q-separator class="q-mb-md hide-if-last-child" />
      </template>
    </template>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// components
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";

// stores
import { useSettingStore } from "@/stores/setting.store";

// models
import type { IRegistryData, IWorkloadsSectionSettings } from "@/models/setting.model";
import { SettingKeys, settingUpdateSuccessMessage } from "@/models/setting.model";
import SettingsUrlField from "@/components/settings/settings-url-field/settings-url-field.vue";
import { alertUtil } from "@/utils/alert.util";
import { settingsUtil } from "@/utils/settings.util";
import { useRegistryStore } from "@/stores/registry.store";
import { type RegistryCreationRequest, Scope } from "@/swagger-models/assets-service-client";
import { DockerRegistryFields } from "./docker-registry-fields";
import type { HttpErrorResponse } from "@/models/http-response.model";
import { BadRequestError, ConflictError } from "@/models/http-response.model";
import type { Setting } from "@/swagger-models/backend-client";

export default defineComponent({
  name: "workloads-section",
  components: {
    DockerRegistryFields,
    SettingsUrlField,
    ToggleSetting,
    RunaiExpansionItem,
  },
  props: {
    workloadSectionSettings: {
      type: Object as PropType<IWorkloadsSectionSettings>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      showPerformanceModal: false as boolean,
      registryStore: useRegistryStore(),
      specialSettings: [SettingKeys.EnableWandbSweeps, SettingKeys.RegistryIntegration] as Array<string>,
      SettingKeys,
    };
  },
  computed: {
    isJobSubmissionEnabled(): boolean {
      return Boolean(this.workloadSectionSettings.jobSubmission?.value);
    },
    isWabSweepsEnabled(): boolean {
      return Boolean(this.workloadSectionSettings.wabSweeps?.value);
    },
    sectionSubHeader(): string {
      const settings = { ...this.workloadSectionSettings };
      const isJobSubmissionEnabled = Boolean(settings.jobSubmission?.value);
      if (!isJobSubmissionEnabled) {
        settings.wabSweeps = {};
        settings.dockerRegistry = {};
        settings.mpiDistribution = {};
      }
      return settingsUtil.getToggleSectionCollapsedTitle(settings);
    },
    workloadSettings(): Array<Setting> {
      return Object.values(this.workloadSectionSettings).filter((setting) => !!setting);
    },
    workloadSettingsValues(): Setting[] {
      return Object.values(this.workloadSettings).filter(
        (iWorkloadSetting: Setting) => iWorkloadSetting || iWorkloadSetting === "",
      );
    },
  },
  methods: {
    shouldShowSetting(key: string): boolean {
      if (
        (
          [SettingKeys.EnableWandbSweeps, SettingKeys.RegistryIntegration, SettingKeys.EnableLegacyMpi] as Array<string>
        ).includes(key)
      ) {
        return this.isJobSubmissionEnabled;
      }
      if (key === SettingKeys.AllowGPT2Model) {
        return !!this.workloadSectionSettings.models?.value;
      }
      return true;
    },
    async saveWabHost(url: string): Promise<void> {
      await this.saveSettings(SettingKeys.WandbHost, url);
    },
    async saveSettings(key: SettingKeys, value: string | boolean | number): Promise<void> {
      try {
        await this.settingStore.updateSetting(key, value);
        this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
      } catch (error) {
        this.$q.notify(settingsUtil.getSettingErrorMessage());
      }
    },
    async deleteRegistry(): Promise<void> {
      await this.registryStore.deleteRegistry();
      await this.saveSettings(SettingKeys.RegistryIntegration, false);
    },
    async saveRegistry(registryData: IRegistryData): Promise<void> {
      const registry: RegistryCreationRequest = {
        meta: {
          name: "registry-name",
          scope: Scope.Tenant,
        },
        spec: {
          url: registryData.url,
          user: registryData.user,
          password: registryData.password,
          credentialKind: "password",
        },
      };
      try {
        await this.registryStore.createRegistry(registry);
        await this.saveSettings(SettingKeys.RegistryIntegration, true);
        (this.$refs.dockerRegistryFields as typeof DockerRegistryFields).changeToReadMode();
      } catch (err: unknown) {
        const statusCode = (err as HttpErrorResponse).statusCode;
        switch (statusCode) {
          case ConflictError.statusCode:
            this.$q.notify(alertUtil.getError("Registry can't be reached. Validate the registry URL and try again."));
            break;
          case BadRequestError.statusCode:
            this.$q.notify(
              alertUtil.getError("Registry couldn't be authenticated. Validate the credentials and try again."),
            );
            break;
          default:
            this.$q.notify(settingsUtil.getSettingErrorMessage());
            break;
        }
        console.error(err);
      }
    },
  },
});
</script>
