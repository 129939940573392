<template>
  <div class="row items-center">
    <div class="col-1">
      <div v-if="!isOnlyDefaultNodePool" class="node-pool-icon" aid="node-pool-icon">
        <runai-svg-icon name="node-pool" size="22"></runai-svg-icon>
        <div class="rotated-text">NODE POOLS</div>
      </div>
      <div v-else class="project-icon-wrapper">
        <runai-svg-icon :name="iconName" size="22"></runai-svg-icon>
      </div>
    </div>
    <div class="col-11">
      <node-pool-row
        v-for="resource in resources"
        :key="getNodePoolNameByResource(resource)"
        :resource="resource"
        :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
        :is-cpu-enabled="isCpuEnabled"
        :is-department-enabled="isDepartmentEnabled"
        :read-only="readOnly"
        :is-only-default-node-pool="isOnlyDefaultNodePool"
        :priorities="priorities"
        :priorities-options="prioritiesOptions"
        :is-node-pool-over-quota="isNodePoolOverQuota?.[getNodePoolNameByResource(resource)]"
        :projects-deserved-resources="projectsDeservedResources?.[getNodePoolNameByResource(resource)]"
        :department-deserved-resources="departmentDeservedResources?.[getNodePoolNameByResource(resource)]"
        :allocated-non-preemptible-resources="allocatedNonPreemptibleResources[getNodePoolNameByResource(resource)]"
        :total-cluster-resources="totalClusterResources"
        @update-node-pool-over-quota="updateNodePoolResourceOverQuota"
        @update-node-pool-resource="updateNodePoolResource"
        @update-node-pool-priority="setNodePoolPriority"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { inject, type PropType } from "vue";
import { defineComponent } from "vue";
//cmps
import { NodePoolRow } from "@/components/quota-management/node-pools-table/node-pool-row/";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import type {
  IClusterTotalResources,
  INodePoolIsOverQuotaRecord,
  NodePoolResourcesSumRecord,
} from "@/models/resource.model";
//models
import {
  CPU_VALUE_FACTOR,
  defaultQuotaOption,
  defaultQuotaPriorityOption,
  EMPTY_PRIORITY_VALUE,
  EQuotaEntity,
  EResourceType,
  type NodePoolAllocatedNonPreemptibleSumRecord,
} from "@/models/resource.model";
import type { ISelectOption } from "@/models/global.model";
//stores
import { useSettingStore } from "@/stores/setting.store";
//utils
import { resourceUtil } from "@/utils/resource.util";
import { deepCopy } from "@/utils/common.util";
import { NodeTelemetryType, type TelemetryResponseValuesInner } from "@/swagger-models/cluster-service-client";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import type {
  AggregatedResources,
  Project,
  ProjectCreationRequest,
  QuotaStatusNodePool,
  Resources,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";

export default defineComponent({
  components: {
    NodePoolRow,
    RunaiSvgIcon,
  },
  emits: ["update:resources", "update:node-pools-priorities", "update:node-pools-resource"],
  inject: ["entity"],
  props: {
    project: {
      type: Object as PropType<Project | ProjectCreationRequest>,
      required: false,
    },
    departmentId: {
      type: String as PropType<string>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      default: "",
    },
    resources: {
      type: Array as PropType<Array<Resources>>,
      required: true,
    },
    nodePoolsPriorities: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isOverQuotaPriorityEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<QuotaStatusNodePool>>,
      default: () => [],
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      selectedOverQuotaOption: defaultQuotaOption as ISelectOption,
      selectedOverQuotaPriorityOption: defaultQuotaPriorityOption as ISelectOption,
      selectedOverQuotaPriorityOptions: [] as Array<ISelectOption>,
      selectedOverQuotaOptions: [] as Array<ISelectOption>,
      prioritiesOptions: [] as Array<string | number>,
      priorities: [...this.nodePoolsPriorities] as Array<string>,
      totalClusterGpu: 0 as number,
      totalClusterCpuCores: 0 as number,
      totalClusterCpuMemoryBytes: 0 as number,
      initialProjectResources: [] as Resources[],
      projectsAggregatedResources: inject<AggregatedResources[]>("projectsAggregatedResources") || [],
      departmentResources: inject<Resources[]>("departmentResources") || [],
    };
  },
  async created() {
    if (this.isDepartmentEntity) {
      await this.loadClusterTotalResources();
    }

    this.getPrioritiesOptions();
    if (this.isOverQuotaPriorityEnabled) {
      this.initOverQuotaPriorityOptions();
    } else {
      this.initOverQuotaOptions();
    }
    this.initialProjectResources = deepCopy(this.resources);
  },
  computed: {
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isDepartmentEntity(): boolean {
      return this.entity === EQuotaEntity.department;
    },
    sortedDepartmentNodePools(): Resources[] {
      return resourceUtil.sortNodePools(this.departmentResources);
    },
    departmentDeservedResources(): NodePoolResourcesSumRecord | null {
      if (!this.departmentResources) return null;

      const nodePoolAggregatedResources: NodePoolResourcesSumRecord = {};
      this.departmentResources.forEach((resources) => {
        nodePoolAggregatedResources[this.getNodePoolNameByResource(resources)] = {
          gpu: resources.gpu.deserved,
          cpu: resources.cpu?.deserved || 0,
          memory: resources.memory?.deserved || 0,
        };
      });
      return nodePoolAggregatedResources;
    },
    projectsDeservedResources(): NodePoolResourcesSumRecord | null {
      if (this.projectsAggregatedResources.length === 0) return null;

      const nodePoolAggregatedResources: NodePoolResourcesSumRecord = {};
      this.projectsAggregatedResources?.forEach((projectsAggregatedResources) => {
        nodePoolAggregatedResources[projectsAggregatedResources.nodePool.name] = {
          gpu: projectsAggregatedResources.gpu?.deserved || 0,
          cpu: projectsAggregatedResources.cpu?.deserved || 0,
          memory: projectsAggregatedResources.memory?.deserved || 0,
        };
      });

      if (this.entity === EQuotaEntity.project) {
        this.resources.forEach((resources) => {
          const nodePoolName = this.getNodePoolNameByResource(resources);
          nodePoolAggregatedResources[nodePoolName].gpu += resources.gpu.deserved;
          nodePoolAggregatedResources[nodePoolName].cpu += resources.cpu?.deserved || 0;
          nodePoolAggregatedResources[nodePoolName].memory += resources?.memory?.deserved || 0;
        });

        this.initialProjectResources.forEach((resources) => {
          const nodePoolName = this.getNodePoolNameByResource(resources);
          nodePoolAggregatedResources[nodePoolName].gpu -= resources.gpu.deserved;
          nodePoolAggregatedResources[nodePoolName].cpu -= resources.cpu?.deserved || 0;
          nodePoolAggregatedResources[nodePoolName].memory -= resources?.memory?.deserved || 0;
        });
      }

      return nodePoolAggregatedResources;
    },
    isOnlyDefaultNodePool(): boolean {
      return this.resources.length === 1;
    },
    allocatedNonPreemptibleResources(): NodePoolAllocatedNonPreemptibleSumRecord {
      return resourceUtil.allocatedNonPreemptibleResources(this.quotaStatuses);
    },
    isNodePoolOverQuota(): INodePoolIsOverQuotaRecord {
      return this.sortedDepartmentNodePools.reduce(
        (nodePoolOverQuotaMap: INodePoolIsOverQuotaRecord, resources: Resources) => {
          const nodePoolName = this.getNodePoolNameByResource(resources);
          if (!this.projectsDeservedResources || !this.departmentDeservedResources) return nodePoolOverQuotaMap;
          nodePoolOverQuotaMap[nodePoolName] = resourceUtil.computeNodePoolResourceExceedsQuota(
            this.projectsDeservedResources?.[nodePoolName],
            this.departmentDeservedResources?.[nodePoolName],
          );
          return nodePoolOverQuotaMap;
        },
        {} as INodePoolIsOverQuotaRecord,
      );
    },
    iconName(): string {
      return this.entity === EQuotaEntity.project ? "project-icon" : "departments-icon";
    },
    totalClusterResources(): IClusterTotalResources {
      return {
        gpu: this.totalClusterGpu,
        cpuCores: this.totalClusterCpuCores,
        memoryBytes: this.totalClusterCpuMemoryBytes,
      };
    },
  },
  methods: {
    getPrioritiesOptions(): void {
      const priorities = Array.from({ length: this.resources.length }, (_, i) => i + 1) as [number | string];
      priorities.unshift(EMPTY_PRIORITY_VALUE);
      this.prioritiesOptions = priorities;
    },
    setNodePoolPriority({ value, nodePoolName }: { value: string | number; nodePoolName: string }): void {
      if (value === EMPTY_PRIORITY_VALUE) {
        this.priorities.splice(this.priorities.indexOf(nodePoolName), 1);
      } else {
        this.priorities[Number(value) - 1] = nodePoolName;
      }
      this.$emit("update:node-pools-priorities", this.priorities);
    },
    updateNodePoolResource({
      name,
      resourceType,
      resourceValue,
    }: {
      name: string;
      resourceType: EResourceType;
      resourceValue: number | null;
    }): void {
      const resources = deepCopy(this.resources);
      const nodePoolIndex = resources.findIndex(
        (resources: Resources) => this.getNodePoolNameByResource(resources) === name,
      );

      if (resourceType === EResourceType.CPU) {
        const updatedVal = resourceValue === null ? resourceValue : resourceValue * CPU_VALUE_FACTOR;
        resources[nodePoolIndex][resourceType].deserved = updatedVal;
      } else {
        resources[nodePoolIndex][resourceType].deserved = resourceValue;
      }

      this.$emit("update:resources", resources);
    },
    updateNodePoolResourceOverQuota({ name, overQuota }: { name: string; overQuota: number }): void {
      const resources = deepCopy(this.resources);
      const nodePoolIndex = resources.findIndex(
        (resources: Resources) => this.getNodePoolNameByResource(resources) === name,
      );
      if (nodePoolIndex === -1) return;

      resources[nodePoolIndex].gpu.overQuotaWeight = overQuota;
      if (resources?.[nodePoolIndex]?.cpu && resources?.[nodePoolIndex]?.memory) {
        resources[nodePoolIndex].cpu.overQuotaWeight = overQuota;
        resources[nodePoolIndex].memory.overQuotaWeight = overQuota;
      }
      this.$emit("update:resources", resources);
    },

    initOverQuotaPriorityOptions(): void {
      this.selectedOverQuotaPriorityOptions = this.resources.map((nodePool) =>
        this.getSelectedOverQuotaOption(nodePool.gpu.overQuotaWeight),
      );
    },
    getSelectedOverQuotaPriorityOption(overQuotaPriority: number | null | undefined): ISelectOption {
      if (overQuotaPriority === null || overQuotaPriority === undefined) {
        return defaultQuotaPriorityOption;
      }
      return {
        label: resourceUtil.getOverQuotaPriorityKeyByValue(overQuotaPriority),
        value: overQuotaPriority,
      };
    },
    initOverQuotaOptions(): void {
      this.selectedOverQuotaOptions = this.resources.map((nodePool) =>
        this.getSelectedOverQuotaPriorityOption(nodePool.gpu.overQuotaWeight),
      );
    },
    getSelectedOverQuotaOption(overQuota: number | null | undefined): ISelectOption {
      if (overQuota === null || overQuota === undefined) {
        return defaultQuotaOption;
      }
      return {
        label: resourceUtil.getOverQuotaKeyByValue(overQuota),
        value: overQuota,
      };
    },
    async loadClusterTotalResources(): Promise<void> {
      const response = await Promise.all([
        this.getNodeTelemetry(NodeTelemetryType.TotalGpus),
        this.getNodeTelemetry(NodeTelemetryType.TotalCpuCores),
        this.getNodeTelemetry(NodeTelemetryType.TotalCpuMemoryBytes),
      ]);
      this.totalClusterGpu = this.sumTelemetryValues(response[0]);
      this.totalClusterCpuCores = this.sumTelemetryValues(response[1]);
      this.totalClusterCpuMemoryBytes = this.sumTelemetryValues(response[2]);
    },
    async getNodeTelemetry(type: NodeTelemetryType): Promise<Array<TelemetryResponseValuesInner>> {
      try {
        return (await clusterService.getNodeTelemetry(type, this.clusterId)).values;
      } catch (e) {
        console.error(`Failed to get node telemetry ${type}`, e);
        return [];
      }
    },
    sumTelemetryValues(values: Array<TelemetryResponseValuesInner>): number {
      return values.reduce((acc, curr) => acc + parseFloat(curr.value), 0);
    },
    getNodePoolNameByResource(resources: Resources): string {
      return orgUnitUtil.getNodePoolNameByResource(resources);
    },
  },
});
</script>
<style scoped lang="scss">
.node-pool-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $body-background-color;
  border-radius: 5px;
  width: 46px;
  min-width: 46px;
  padding: 10px 0;
  font-size: 12px;
  margin-block: 4px;
  min-height: 130px;
  height: 100%;
  .rotated-text {
    white-space: nowrap;
    transform: rotate(-90deg);
    position: relative;
    top: 40px;
  }
}
.project-icon-wrapper {
  position: relative;
  background-color: $body-background-color;
  border-radius: 5px;
  width: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  min-height: 46px;
}
</style>
