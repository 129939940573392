<template>
  <runai-expansion-item
    class="email-settings-container"
    label="Email notifications"
    :subheader="notificationSummary"
    default-opened
  >
    <div>
      <div class="q-mb-md">Send me an email about my workloads when:</div>
      <div class="q-py-sm" v-for="(categoryNotifications, categoryName) in notificationCategories" :key="categoryName">
        <div class="row items-center q-mb-sm q-mt-lg">
          <span class="q-mr-md text-weight-bold">{{ getCategoryDisplayName(String(categoryName)) }}:</span>
          <runai-tooltip
            aid="tool-tip-system-notification"
            :tooltip-text="getCategoryTooltip(String(categoryName))"
            width="400px"
            tooltip-position="right"
          />
        </div>
        <div class="row items-center q-py-sm" :class="{ disabled: !smtpEnabled }">
          <q-checkbox
            v-if="isCategoryNeedAllCheckbox(String(categoryName))"
            :toggle-indeterminate="false"
            indeterminate-value="indeterminate"
            size="md"
            v-model="categoryStatus[categoryName]"
            dense
            label="All"
            :disable="!smtpEnabled"
            @update:model-value="toggleCategory(String(categoryName))"
          />
          <q-tooltip anchor="center middle" v-if="!smtpEnabled">
            To enable email notifications, contact your administrator about configuring your organization's email server
          </q-tooltip>
        </div>
        <div
          class="q-py-sm"
          v-for="notification in categoryNotifications"
          :key="notification.type"
          :class="{ disabled: !smtpEnabled }"
        >
          <q-tooltip anchor="center middle" v-if="!smtpEnabled">
            To enable email notifications, contact your administrator about configuring your organization's email server
          </q-tooltip>
          <q-checkbox
            :toggle-indeterminate="false"
            size="md"
            v-model="notificationStatus[notification.type]"
            dense
            :label="notification.type"
            @update:model-value="checkForIndeterminate(String(categoryName))"
            :disable="!smtpEnabled"
          />
        </div>
      </div>
      <div class="row footer">
        <div class="justify-start validation-message col-9 q-pt-sm">
          <span v-if="hasChanges">Unapplied changes</span>
        </div>
        <div class="justify-end no-wrap q-gutter-sm col-3">
          <q-tooltip anchor="center middle" v-if="!smtpEnabled">
            To enable email notifications, contact your administrator about configuring your organization's email server
          </q-tooltip>
          <q-btn label="cancel" flat color="primary" @click="cancelChanges" :disable="!smtpEnabled" class="q-pr-lg" />
          <q-btn label="save" flat color="primary" @click="saveChanges" :disable="!smtpEnabled" />
        </div>
      </div>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import type { CategorizedNotificationSettings, RouteConfig } from "@/models/user-setting.model";
import RunaiTooltip from "@/components/common/runai-tooltip/runai-tooltip.vue";
import { userNotificationsUtil } from "@/utils/user-notifications.utils";
import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  name: "email-notifications-section",
  components: {
    RunaiTooltip,
    RunaiExpansionItem,
  },
  emits: ["save"],
  props: {
    notificationCategories: {
      type: Object as PropType<CategorizedNotificationSettings>,
      required: true,
    },
    smtpEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      notificationStatus: {} as Record<string, boolean>,
      originalNotificationsStatus: {} as Record<string, boolean>,
      authStore: useAuthStore(),
      categoryStatus: {} as Record<string, boolean | string>,
    };
  },
  created() {
    this.initializeSettings();
  },
  computed: {
    notificationSummary(): string {
      let sumEnabledNotifications = 0;
      let allNotifications = 0;
      Object.values(this.notificationStatus).forEach((enabled: boolean) => {
        allNotifications += 1;
        sumEnabledNotifications += enabled ? 1 : 0;
      });
      return `${sumEnabledNotifications} / ${allNotifications}  notification types`;
    },
    hasChanges(): boolean {
      return JSON.stringify(this.notificationStatus) !== JSON.stringify(this.originalNotificationsStatus);
    },
  },
  methods: {
    cancelChanges() {
      this.initializeSettings();
    },
    saveChanges(): void {
      if (this.hasChanges) {
        const result: RouteConfig = userNotificationsUtil.convertCategoriesToRouteConfig(
          this.notificationCategories,
          this.notificationStatus,
          this.authStore.tenant.id,
          this.authStore.currentUser.username,
        );
        this.$emit("save", result);
        this.originalNotificationsStatus = { ...this.notificationStatus };
      }
    },
    initializeSettings(): void {
      Object.entries(this.notificationCategories).forEach(([categoryName, settings]) => {
        this.checkForIndeterminate(categoryName);
        settings.forEach((setting) => {
          this.notificationStatus[setting.type] = setting.enabled;
        });
        this.checkForIndeterminate(categoryName);
      });
      this.originalNotificationsStatus = { ...this.notificationStatus };
    },
    getCategoryTooltip(categoryName: string): string {
      if (categoryName === "Workload status change") {
        return "An email will be sent when a workload's status changes to the selected status(es).";
      } else if (categoryName === "Workload timeout") {
        return "An email will be sent when half the time remains before a workload is set to timeout, as defined in the project scheduling rules.";
      } else {
        return "";
      }
    },
    getCategoryDisplayName(categoryName: string): string {
      if (categoryName === "Workload status change") {
        return "The status changes to";
      } else if (categoryName === "Workload timeout") {
        return "One of these is about to stop due to timeout";
      } else {
        return categoryName;
      }
    },
    toggleCategory(categoryName: string) {
      const isEnabled = this.categoryStatus[categoryName];
      this.notificationCategories[categoryName].forEach((notification) => {
        this.notificationStatus[notification.type] = isEnabled as boolean;
      });
    },
    checkForIndeterminate(categoryName: string) {
      const isAllChecked = this.notificationCategories[categoryName].every(
        (notification) => this.notificationStatus[notification.type],
      );
      const isAllNotChecked = this.notificationCategories[categoryName].every(
        (notification) => !this.notificationStatus[notification.type],
      );
      this.categoryStatus[categoryName] = isAllChecked ? true : isAllNotChecked ? false : "indeterminate";
    },
    isCategoryNeedAllCheckbox(categoryName: string): boolean {
      return this.notificationCategories[categoryName].length >= 4;
    },
  },
});
</script>
<style lang="scss" scoped>
.email-settings-container {
  margin: auto;
  width: 800px;

  .footer {
    border-top: #dddddd 1px solid;
    margin: 10px -20px 0 -20px;
    padding-top: 10px;

    .validation-message {
      color: $negative;
      font-size: 12px;
      padding-left: 20px;
    }
  }
}
</style>
