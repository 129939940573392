<template>
  <span>{{ truncatedText }}</span>
  <q-tooltip :content="text" anchor="center right">
    {{ text }}
  </q-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    truncatedText() {
      if (this.text.length > this.maxLength) {
        return this.text.substring(0, this.maxLength) + "...";
      } else {
        return this.text;
      }
    },
  },
});
</script>
