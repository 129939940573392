import { ResourceType, Action } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";
import { APPLICATIONS_ROUTE_NAMES } from "@/router/applications.routes/applications.routes.names";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";
import { useAppStore } from "@/stores/app.store";

export const applicationsRoutes: RunaiRouteRecord = {
  path: "/applications",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/applications/applications-app.vue"),
  children: [
    {
      path: "",
      name: APPLICATIONS_ROUTE_NAMES.APPLICATIONS_INDEX,
      component: () => import("@/views/applications/applications-index.vue"),
      meta: {
        pageTitle: "Applications",
        resourceType: ResourceType.Apps,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ACCESS,
      },
      beforeEnter: (to, from, next) => {
        const appStore = useAppStore();
        if (appStore.isNewNavigationFeatureOn) {
          to.meta.fullWidth = false;
          to.meta.supportBack = false;
        } else {
          to.meta.fullWidth = true;
          to.meta.supportBack = true;
        }

        next();
      },
    },
  ],
};
