<template>
  <q-expansion-item
    popup
    :header-class="['runai-expansion-header', 'text-weight-medium']"
    expand-separator
    :model-value="isOpen"
    @update:model-value="onModelChanged"
    :disable="disable"
    :expand-icon-class="['runai-expansion-icon', (disableClosing || hideExpendIcon || !hasContent) && 'display-none']"
    class="runai-expansion-item"
    :label="label"
    @after-show="$emit('after-show', $event)"
    :aid="aidToggle"
  >
    <template #header>
      <div class="runai-expansion-header-content row">
        <div class="runai-expansion-header-content-left row items-center">
          <slot name="header">
            <div :aid="aidLabel">{{ label }}</div>
          </slot>
        </div>
        <div
          class="flex-1 runai-expansion-header-content-right justify-between text-weight-regular"
          v-if="!isOpen || showSubHeader"
        >
          <div class="flex-1" :aid="aidSubheader">
            <slot name="subheader">{{ subheader }}</slot>
          </div>
          <q-icon
            v-if="sectionInvalid"
            class="q-mr-md"
            name="fa-regular fa-circle-exclamation"
            color="red"
            size="18px"
          ></q-icon>
        </div>
      </div>
    </template>

    <template v-if="hasContent">
      <q-separator />
      <q-card class="runai-expansion-content">
        <q-card-section>
          <div class="loading-section" v-if="loading">
            <q-skeleton height="230px" square></q-skeleton>
          </div>
          <slot v-else />
        </q-card-section>
      </q-card>
    </template>
  </q-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  emits: ["after-show"],
  props: {
    label: {
      type: String as PropType<string>,
      required: false,
    },
    subheader: {
      type: String as PropType<string>,
      required: false,
    },
    defaultOpened: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hideExpendIcon: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    showSubHeader: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableClosing: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableOpening: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    sectionInvalid: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.defaultOpened || this.disableClosing,
    };
  },
  computed: {
    hasContent(): boolean {
      return !!this.$slots.default;
    },
    aidLabel(): string {
      return `${this.label}-expansion-label`.toLowerCase();
    },
    aidToggle(): string {
      return `${this.label}-expansion-toggle`.toLowerCase();
    },
    aidSubheader(): string {
      return `${this.label}-expansion-subheader`.toLowerCase();
    },
  },
  methods: {
    onModelChanged(isOpen: boolean): void {
      if (this.disableOpening) {
        this.isOpen = false;
      } else if (!this.disableClosing && this.hasContent) {
        this.isOpen = isOpen;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.runai-expansion-item {
  &.q-expansion-item--popup.q-expansion-item--collapsed {
    padding-left: 0;
    padding-right: 0;
  }

  .runai-expansion-header {
    .runai-expansion-header-content {
      width: 100%;

      .runai-expansion-header-content-left {
        width: 370px;
      }
      .runai-expansion-header-content-right {
        display: flex;
        align-items: center;
        color: $black-54;
      }
    }
  }
}
</style>

<style lang="scss">
.runai-expansion-item {
  > :first-child {
    box-shadow: 0 1px 4px 0 #00000040;
  }
  .runai-expansion-header {
    background-color: $white;

    .runai-expansion-icon .q-icon {
      @extend .text-body2;
    }
  }
  .loading-section {
    width: 100%;
  }
}
</style>
