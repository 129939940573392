export default {
  name: "fontawesome-v6-pro",
  type: {
    positive: "fa-regular fa-circle-check",
    negative: "fa-regular fa-circle-exclamation",
    info: "fa-regular fa-circle-exclamation",
    warning: "fa-regular fa-triangle-exclamation",
  },
  arrow: {
    up: "fal fa-arrow-up",
    right: "fal fa-arrow-right",
    down: "fal fa-arrow-down",
    left: "fal fa-arrow-left",
    dropdown: "fa-solid fa-caret-down",
  },
  chevron: {
    left: "fal fa-chevron-left",
    right: "fal fa-chevron-right",
  },
  colorPicker: {
    spectrum: "fal fa-eye-dropper",
    tune: "fal fa-sliders",
    palette: "fal fa-swatchbook",
  },
  pullToRefresh: {
    icon: "fal fa-sync-alt",
  },
  carousel: {
    left: "fal fa-chevron-left",
    right: "fal fa-chevron-right",
    up: "fal fa-chevron-up",
    down: "fal fa-chevron-down",
    navigationIcon: "fal fa-circle",
  },
  chip: {
    remove: "fas fa-circle-xmark",
    selected: "fal fa-check",
  },
  datetime: {
    arrowLeft: "fal fa-chevron-left",
    arrowRight: "fal fa-chevron-right",
    now: "fal fa-clock",
    today: "fal fa-calendar-check",
  },
  editor: {
    bold: "fal fa-bold",
    italic: "fal fa-italic",
    strikethrough: "fal fa-strikethrough",
    underline: "fal fa-underline",
    unorderedList: "fal fa-list-ul",
    orderedList: "fal fa-list-ol",
    subscript: "fal fa-subscript",
    superscript: "fal fa-superscript",
    hyperlink: "fal fa-link",
    toggleFullscreen: "fal fa-maximize",
    quote: "fal fa-quote-right",
    left: "fal fa-align-left",
    center: "fal fa-align-center",
    right: "fal fa-align-right",
    justify: "fal fa-align-justify",
    print: "fal fa-print",
    outdent: "fal fa-outdent",
    indent: "fal fa-indent",
    removeFormat: "fal fa-eraser",
    formatting: "fal fa-heading",
    fontSize: "fal fa-text-height",
    align: "fal fa-align-left",
    hr: "fal fa-square-minus",
    undo: "fal fa-rotate-left",
    redo: "fal fa-rotate-right",
    heading: "fal fa-heading",
    code: "fal fa-code",
    size: "fal fa-text-height",
    font: "fal fa-font",
    viewSource: "fal fa-code",
  },
  expansionItem: {
    icon: "far fa-chevron-down",
    denseIcon: "fal fa-caret-down",
  },
  fab: {
    icon: "fal fa-plus",
    activeIcon: "fal fa-xmark",
  },
  field: {
    clear: "fal fa-circle-xmark",
    error: "fal fa-circle-exclamation",
  },
  pagination: {
    first: "fal fa-backward-step",
    prev: "fal fa-chevron-left",
    next: "fal fa-chevron-right",
    last: "fal fa-forward-step",
  },
  rating: {
    icon: "fal fa-star",
  },
  stepper: {
    done: "fal fa-check",
    active: "fal fa-pencil",
    error: "fal fa-triangle-exclamation",
  },
  tabs: {
    left: "fal fa-chevron-left",
    right: "fal fa-chevron-right",
    up: "fal fa-chevron-up",
    down: "fal fa-chevron-down",
  },
  table: {
    arrowUp: "far fa-arrow-up",
    warning: "far fa-triangle-exclamation",
    firstPage: "fa-solid fa-angles-left",
    prevPage: "fa-solid fa-angle-left",
    nextPage: "fa-solid fa-angle-right",
    lastPage: "fa-solid fa-angles-right",
  },
  tree: {
    icon: "fal fa-play",
  },
  uploader: {
    done: "fal fa-check",
    clear: "fal fa-xmark",
    add: "fal fa-square-plus",
    upload: "fal fa-upload",
    removeQueue: "fal fa-bars-staggered",
    removeUploaded: "fal fa-clipboard-check",
  },
};
