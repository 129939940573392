import { defineStore } from "pinia";
import { useClusterStore } from "./cluster.store";

import { nodePoolColumns } from "@/table-models/node-pool.table-model";
import type { INodePool } from "@/models/node-pool.model";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_NODE_WORKLOAD_METRICS_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
import { usePermissionStore } from "@/stores/permissions.store";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

export const useNodePoolStore = defineStore("NodePool", {
  state: () => ({
    nodePools: [] as Array<INodePool>,
    nodePoolsCount: 0 as number,
    clusterStore: useClusterStore(),
    nodePoolAdded: null as null | INodePool,
    nodePoolsByClusterId: new Map<string, INodePool[]>(),
  }),
  getters: {
    lastCreatedNodePool(): INodePool | null {
      return this.nodePoolAdded;
    },
    nodePoolList(): Array<INodePool> {
      if (!this.nodePoolAdded) return this.nodePools;
      return this.nodePools.filter((nodePool: INodePool) => nodePool.id !== this.nodePoolAdded?.id);
    },
    isOnlyDefaultNodePool(): boolean {
      return this.nodePoolsCount === 1;
    },
    hasOverProvisioning(): boolean {
      return this.nodePools.some((nodePool: INodePool) => nodePool.overProvisioningRatio != 1);
    },
  },
  actions: {
    async loadNodePools(clusterId: string): Promise<INodePool[]> {
      const clusterVersion = this.clusterStore.getClusterVersion(clusterId);
      const updatedNodePools = await nodePoolService.getNodePools(clusterId);
      const nodePools = updatedNodePools.map((updatedNodePool: INodePool) => {
        // we want to keep the metric data and only update data from backend.
        const nodePool: INodePool | undefined = this.nodePools.find(
          (nodePool: INodePool) => nodePool.name === updatedNodePool?.name,
        );
        const nodes: string = updatedNodePool?.nodes ? JSON.parse(updatedNodePool.nodes) : nodePool?.nodes;
        return { ...nodePool, ...updatedNodePool, nodes };
      });

      const nodePoolsWithMetric = await this.loadNodePoolsWithMetrics(clusterId, clusterVersion || "", nodePools);
      this.nodePools = updatedNodePools;
      return nodePoolsWithMetric;
    },
    async loadNodePoolsWithMetrics(
      clusterUuid: string,
      clusterVersion: string,
      nodePools: INodePool[],
    ): Promise<INodePool[]> {
      const useNewMetrics =
        isNewerVersion(clusterVersion, MIN_NODE_WORKLOAD_METRICS_VERSION) || clusterVersion.includes(TEST_ENV_VERSION);
      const displayedColumns = nodePoolColumns.filter((column) => column.display);
      const hasReadNodesPermission = usePermissionStore().hasPermission(ResourceType.Nodes, Action.Read);
      const hasReadProjectsPermission = usePermissionStore().hasPermission(ResourceType.Project, Action.Read);

      return await nodePoolService.list(
        nodePools,
        clusterVersion,
        clusterUuid,
        displayedColumns,
        useNewMetrics,
        hasReadNodesPermission,
        hasReadProjectsPermission,
      );
    },
    async isOnlyDefaultNodePoolByClusterId(clusterId: string): Promise<boolean> {
      const existingNodePools = this.nodePoolsByClusterId.get(clusterId);

      if (existingNodePools) {
        return existingNodePools.length <= 1;
      }

      const fetchedNodePools = await nodePoolService.getNodePools(clusterId);

      this.nodePoolsByClusterId.set(clusterId, fetchedNodePools);

      return fetchedNodePools.length <= 1;
    },
    removeNodePoolsByClusterId(clusterId: string): void {
      this.nodePoolsByClusterId.delete(clusterId);
    },
  },
});
