<template>
  <slot :readonly="isReadonly"></slot>
  <q-tooltip v-if="isReadonly" anchor="center middle" self="center middle">You can only view this screen</q-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { usePermissionStore } from "@/stores/permissions.store";
import { ResourceType } from "@/swagger-models/authorization-client";

export default defineComponent({
  name: "setting-readonly-input",
  computed: {
    isReadonly() {
      return usePermissionStore().isReadOnly(ResourceType.Settings);
    },
  },
});
</script>
