<template>
  <section class="serving-endpoint-section">
    <runai-sub-expansion-item v-if="showExpansion" label="Serving endpoint access" aid="serving-endpoint-access-btn">
      <serving-endpoint-access
        :access="access"
        :title="title"
        :title-tooltip="titleTooltip"
        :protocol="protocol"
        :port="port"
        @access-changed="onAccessChanged"
      />
    </runai-sub-expansion-item>
    <serving-endpoint-access
      v-else
      :access="access"
      :title="title"
      :title-tooltip="titleTooltip"
      :protocol="protocol"
      :port="port"
      @access-changed="onAccessChanged"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiSubExpansionItem } from "@/components/common/runai-sub-expansion-item";
import ServingEndpointAccess from "./serving-endpoint-access.vue";

// models
import type { IConnectionAccess } from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";

export default defineComponent({
  components: {
    RunaiSubExpansionItem,
    ServingEndpointAccess,
  },
  emits: ["access-changed"],
  props: {
    access: {
      type: Object as PropType<IConnectionAccess>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    titleTooltip: {
      type: String as PropType<string>,
      required: false,
    },
    showExpansion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    protocol: {
      type: [String, undefined] as PropType<string | undefined>,
      required: false,
    },
    port: {
      type: [String, undefined] as PropType<string | undefined>,
      required: false,
    },
  },
  methods: {
    onAccessChanged(access: IConnectionAccess): void {
      this.$emit("access-changed", access);
    },
  },
});
</script>
<style lang="scss" scoped></style>
