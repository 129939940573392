import axios, { type AxiosRequestConfig } from "axios";
import { useAuthStore } from "@/stores/auth.store";
import type { IReadinessStatus } from "@/models/researcher.model";

export const whoamiService = {
  isCurrentClusterConnected,
};

interface IHeaders {
  Accept: string;
  Authorization: string;
  AuthInfo?: string;
}

async function isCurrentClusterConnected(currentClusterDomain: string): Promise<IReadinessStatus> {
  const authStore = useAuthStore();

  try {
    const headers = getHeaders(authStore.accessToken);
    const requestOptions: AxiosRequestConfig = getRequestOptions(currentClusterDomain, headers);
    await axios(requestOptions);
    return { connected: true };
    // eslint-disable-next-line
  } catch (error: any) {
    if (error?.response?.status) {
      return { connected: false, statusCode: error.response.status };
    } else {
      return { connected: false };
    }
  }
}

function getRequestOptions(clusterDomain: string, headers: IHeaders): AxiosRequestConfig {
  // Check if the domain ends with "/"
  const domain = clusterDomain.endsWith("/") ? clusterDomain.slice(0, -1) : clusterDomain;
  return {
    url: `${domain}/researcher/api/v1/whoami`,
    method: "GET",
    headers,
  };
}

function getHeaders(accessToken: string) {
  return {
    Accept: "application/json, text/plain, */*",
    Authorization: `Bearer ${accessToken}`,
  };
}
