export const uniqueListenerRunaiId = "http-runai";
export const uniqueNotificationRunaiId = "runai-email";
export enum MatcherType {
  Equal = "==",
  Any = "=~",
}

export enum MatcherKey {
  Category = "category",
  Type = "type",
}

export enum FilteringPolicy {
  Any = "any",
  Drop = "drop",
}

export interface CategorizedNotificationSettings {
  [category: string]: NotificationSettings[];
}

export interface NotificationSettings {
  type: string;
  enabled: boolean;
  category: string;
  description?: string;
}

export interface Matcher {
  match_type: string;
  key: string;
  value: string;
}

export interface Filter {
  type: string;
  matchers: Matcher[];
}

export interface RouteConfig {
  route_config: {
    listener_id: string;
    filtering: {
      policy: string;
      filters: Array<Filter>;
    };
  };
  route_id: string;
  tenant_id: string;
}
