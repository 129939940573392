// services
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { workloadServiceApi } from "@/services/infra/client-apis/workloads-service-api/workloads-service-api";
import { httpService } from "@/services/infra/https.service/http.service";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { policyService } from "@/services/control-plane/policy.service/policy.service";

// models
import type { IWorkloadCreate, IWorkloadResponse } from "@/models/workload.model";
import type {
  WorkloadCreationRequest,
  Inference,
  InferenceCreationRequestV2,
  InferenceV2,
  HuggingFaceModelInfoRequest,
  HuggingFaceModelInfo,
} from "@/swagger-models/assets-service-client";
import type { InferenceWorkloadMetricType, MetricsResponse } from "@/swagger-models/workloads-service-client";
import type { AxiosResponse } from "axios";
import type { InferencePolicyV2 } from "@/swagger-models/policy-service-client";
import { ScopeType } from "@/swagger-models/authorization-client";

// constants
import { K8S_API } from "@/common/api.constant";

function inferenceEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/inferences`;
}

export const inferenceService = {
  create,
  getById,
  remove,
  getMetrics,
  createInferenceV2,
  getHuggingFaceModelInfo,
  getPolicy,
  getInferenceMergedWithPolicy,
};

async function createInferenceV2(inference: InferenceCreationRequestV2): Promise<InferenceV2> {
  try {
    const response: AxiosResponse = await assetsServiceApi.inferenceApi.createInferenceV2(inference);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function create(inference: WorkloadCreationRequest): Promise<Inference> {
  let createdInference: Inference;
  try {
    const response: AxiosResponse = await assetsServiceApi.inferenceApi.createInference(inference);
    createdInference = response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }

  try {
    const workloadCreate: IWorkloadCreate = createdInference.workload as IWorkloadCreate;
    await _createWorkload(workloadCreate);
    await _submitInferenceCreationStatus(inference.clusterId, createdInference.meta.id, true);
    return createdInference;
  } catch (e: unknown) {
    await workloadService.handleFailedWorkloadClusterCreation(createdInference.meta.id, e);
    await _submitInferenceCreationStatus(inference.clusterId, createdInference.meta.id, false);
    console.error(e);
    throw new Error("Failed to create workload");
  }
}

async function getById(inferenceId: string): Promise<InferenceV2> {
  try {
    const response = await assetsServiceApi.inferenceApi.getInferenceByIdV2(inferenceId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function remove(inferenceName: string, namespace: string): Promise<any> {
  return await researcherService.deleteWorkload(inferenceName, namespace);
}

async function getHuggingFaceModelInfo(
  huggingFaceModelInfoRequest: HuggingFaceModelInfoRequest,
): Promise<HuggingFaceModelInfo> {
  try {
    const response: AxiosResponse = await assetsServiceApi.inferenceApi.getHuggingFaceModelInfo(
      huggingFaceModelInfoRequest,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getPolicy(projectId: number): Promise<InferencePolicyV2> {
  return policyService.getInferencePolicyByScope({ scope: ScopeType.Project, projectId: String(projectId) });
}

async function getInferenceMergedWithPolicy(projectId?: string, workloadId?: string): Promise<InferenceV2> {
  try {
    const response = await assetsServiceApi.inferenceApi.getInferenceMerged(projectId, workloadId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function _submitInferenceCreationStatus(clusterUid: string, workspaceId: string, status: boolean): Promise<void> {
  await controlPlaneService.put(`${inferenceEndpoint(clusterUid)}/${workspaceId}/submission`, { success: status });
}

// new metrics apis
async function getMetrics(
  workloadId: string,
  start: string,
  end: string,
  metricType: Array<InferenceWorkloadMetricType>,
  numberOfSamples?: number,
): Promise<MetricsResponse> {
  try {
    const res: AxiosResponse<MetricsResponse> = await workloadServiceApi.inferencesApi.getInferenceWorkloadMetrics(
      workloadId,
      metricType,
      start,
      end,
      numberOfSamples,
    );
    return res.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function _createWorkload(workloadCreate: IWorkloadCreate): Promise<IWorkloadResponse> {
  return workloadService.createWorkload("InferenceWorkload", workloadCreate.metadata, {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  });
}
