import { defineStore } from "pinia";

// models
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import type { IConfig } from "@/models/config.model";
import { ESidebarState } from "@/models/global.model";

// stores
import { usePermissionStore } from "./permissions.store";
import { useAuthStore } from "./auth.store";
import { useSettingStore } from "./setting.store";

// services
import { urlService } from "@/services/url.service/url.service";
import { appService } from "@/services/control-plane/app.service/app.service";
import { configService } from "@/services/control-plane/config.service/config.service";
import { unleashService } from "@/services/infra/unleash.service/unleash.service";

// utils
import { storageUtil } from "@/utils/storage.util";

// constant
import { IS_OVERVIEW_V2_MODE_ON, IS_NEW_NAV_ON, NEW_NAV_INTRO_MODAL_SEEN } from "@/common/storage.constant";

// routes
import { LANDING_PAGE_NAME } from "@/router/dashboard.routes/dashboard.routes.names";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes/cluster.routes.names";

import { SettingKeys } from "@/models/setting.model";

export const useAppStore = defineStore("App", {
  state: () => ({
    appVersion: "" as string,
    config: {} as IConfig,
    backendBaseUrl: urlService.getBackendBaseUrl(),
    pageLoading: false as boolean,
    fallback: false as boolean,
    parentAppOrigin: "" as string,
    lastBackPath: "" as string,
    appLoaded: false as boolean,
    sidebarState: ESidebarState.Mini as ESidebarState,
    isOverviewV2ModeOn: false as boolean,
    isAppHeaderDisabled: false as boolean,
    isAppWideFeatureIntroModalOpen: false as boolean,
    isNewNavOn: false as boolean,
    newNavIntroModalSeen: false as boolean,
  }),
  getters: {
    version(): string {
      return this.appVersion;
    },
    supportLink(): string {
      const authStore = useAuthStore();
      return `https://runai.secure.force.com/casesupport/CreateCaseForm?email=${authStore.currentUser.username}`;
    },
    isMultiTenant(): boolean {
      return this.config.isMultiTenant;
    },
    tenantDomain(): string {
      return this.config.tenantName?.concat(".", window.location.hostname).replace("app.run.ai", "run.ai");
    },
    isProductionEnv(): boolean {
      return !["localhost", "test.run.ai"].some((h) => window.location.hostname.startsWith(h));
    },
    isPageLoading(): boolean {
      return this.pageLoading;
    },
    isFallback(): boolean {
      return this.fallback;
    },
    tenantName(): string {
      return this.config.tenantName;
    },
    isSwitchBetweenNavigationEnabled(): boolean {
      const settingStore = useSettingStore();
      if (settingStore.isFeatureEnabled(SettingKeys.NewNavigation)) return false;

      return unleashService.shouldShowNewLayout();
    },
    isNewNavigationFeatureOn(): boolean {
      return this.isNewNavOn;
    },
  },
  actions: {
    async loadAppData(): Promise<void> {
      try {
        const res = await Promise.all([configService.get(this.backendBaseUrl), appService.getAppVersion()]);
        this.config = res[0];
        this.appVersion = res[1];
      } catch (error: unknown) {
        console.error("Failed to load init data: ", error);
        console.log("re-trying to load init data...");
        const res = await Promise.all([configService.get(this.backendBaseUrl), appService.getAppVersion()]);
        this.config = res[0];
        this.appVersion = res[1];
      }
    },
    setPageLoading(pageLoading: boolean): void {
      this.pageLoading = pageLoading;
    },
    setFallback(fallbackStatus: boolean): void {
      this.fallback = fallbackStatus;
    },
    setLastBackPath(path: string): void {
      this.lastBackPath = path;
    },

    setAppLoaded(appLoaded: boolean): void {
      this.appLoaded = appLoaded;
    },
    setSidebarState(sidebarState: ESidebarState): void {
      this.sidebarState = sidebarState;
    },
    setOverviewV2ModeOn(): void {
      this.isOverviewV2ModeOn = true;
      storageUtil.save(IS_OVERVIEW_V2_MODE_ON, true);
    },
    setOverviewV2ModeOff(): void {
      this.isOverviewV2ModeOn = false;
      storageUtil.save(IS_OVERVIEW_V2_MODE_ON, false);
    },
    setIsAppHeaderDisabled(val: boolean): void {
      this.isAppHeaderDisabled = val;
    },
    setNewNavOn(val: boolean): void {
      this.isNewNavOn = val;
      storageUtil.save<boolean>(IS_NEW_NAV_ON, this.isNewNavOn);
    },
    setDisplayNewNavIntroModalAsSeen(): void {
      this.newNavIntroModalSeen = true;
      storageUtil.save<boolean>(NEW_NAV_INTRO_MODAL_SEEN, true);
    },
    getUserLandingPage(routeName?: string): string | undefined {
      const permissionStore = usePermissionStore();
      if (routeName && routeName !== LANDING_PAGE_NAME) return routeName;

      if (permissionStore.hasOverviewV2ModePermission()) {
        return LANDING_PAGE_NAME;
      } else if (permissionStore.hasPermission(ResourceType.Workloads, Action.Read)) {
        return WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX;
      } else {
        return CLUSTER_ROUTE_NAMES.CLUSTER_INDEX;
      }
    },
  },
});
