import type { IStatusColOptions } from "@/models/table.model";
import { EClusterStatusTooltip } from "@/models/cluster.model";
import { STORAGE_KEYS } from "@/models/storage.model";
import { parseCamelCaseAndCapitalize } from "@/utils/string.util/string.util";
import type { ClusterDependenciesStatus, ClusterDisplayedStatus } from "@/swagger-models/cluster-service-client";
import {
  ClusterDisplayedStatusStateEnum,
  ClusterReportedStatusPlatformTypeEnum,
  type DisplayedCluster,
} from "@/swagger-models/cluster-service-client";
import type { ClusterReportedStatusOperandsValue } from "@/swagger-models/backend-client";
import { MIN_DEPENDENCIES_VERSION } from "@/common/version.constant";
import { isNewerVersion } from "@/utils/version.util";

export const clusterUtil = {
  getStatusColOptions,
  addSessionCreatedCluster,
  removeSessionCreatedCluster,
  getNonFunctioningServices,
  getMissingPrerequisites,
  getDistribution,
  isKnativeInstalledInCluster,
};

function isKnativeInstalledInCluster(cluster: DisplayedCluster): boolean {
  // if the cluster version is lower than the minimum dependencies version, return true
  // and let the cluster validation handle the knative availability
  if (!isNewerVersion(cluster.version || "", MIN_DEPENDENCIES_VERSION)) return true;

  return cluster.status?.dependencies?.optional?.knative.available || false;
}

function getStatusColOptions(status?: ClusterDisplayedStatus): IStatusColOptions {
  if (!status) {
    return {
      filterKey: "status",
      status: "-",
      displayAnimation: false,
      colStyle: { "min-width": "150px" },
    };
  }
  return {
    status: _getStatus(status.state as ClusterDisplayedStatusStateEnum),
    tooltipText: _getTooltipText(status),
    color: _statusColor(status.state as ClusterDisplayedStatusStateEnum),
    displayAnimation: false,
    showIconToolTip: true,
    filterKey: "status",
    colStyle: { "min-width": "150px" },
  };
}

function _getStatus(status: ClusterDisplayedStatusStateEnum): string {
  if (!Object.keys(ClusterDisplayedStatusStateEnum).includes(status)) return "-";
  return parseCamelCaseAndCapitalize(status).trim();
}

function getClusterTooltipSupportLinkOnly(): string {
  const loggedInUserEmail: string | null = localStorage.getItem(STORAGE_KEYS.LOGGED_IN_USER_EMAIL);
  return `Contact <a href="https://runai.secure.force.com/casesupport/CreateCaseForm?email=${
    loggedInUserEmail ? loggedInUserEmail : "unknown"
  }" style="text-decoration: underline;" target="_blank">Run:ai support</a>`;
}
function getClusterTooltipSupportLinks(): string {
  return (
    "<br>" +
    "<div style='padding-left: 20px'>" +
    '<ul style="list-style: disc;">' +
    `<li style="list-style: disc;">See the <a href="https://docs.run.ai/latest/admin/troubleshooting/cluster-health-check" style="text-decoration: underline;" target="_blank">Troubleshooting guide</a></li>` +
    `<li style="list-style: disc;">${getClusterTooltipSupportLinkOnly()}</li>` +
    "</ul>" +
    "</div>"
  );
}

function _getTooltipText(status: ClusterDisplayedStatus): string {
  switch (status.state) {
    case ClusterDisplayedStatusStateEnum.WaitingToConnect:
      return EClusterStatusTooltip.WaitingToConnect + getClusterTooltipSupportLinks();
    case ClusterDisplayedStatusStateEnum.Connected:
      return EClusterStatusTooltip.Connected;
    case ClusterDisplayedStatusStateEnum.Disconnected:
      return EClusterStatusTooltip.Disconnected + getClusterTooltipSupportLinks();
    case ClusterDisplayedStatusStateEnum.ServiceIssues:
      return EClusterStatusTooltip.ServiceIssues + getClusterTooltipSupportLinks();
    case ClusterDisplayedStatusStateEnum.MissingPrerequisites:
      return getMissingPrerequisitesText(status);
    case ClusterDisplayedStatusStateEnum.Unknown:
      return EClusterStatusTooltip.Unknown + getClusterTooltipSupportLinkOnly();
    default:
      return "-";
  }
}

function _statusColor(status: ClusterDisplayedStatusStateEnum): string {
  switch (status) {
    case ClusterDisplayedStatusStateEnum.Connected:
      return "success";
    case ClusterDisplayedStatusStateEnum.Unknown:
    case ClusterDisplayedStatusStateEnum.WaitingToConnect:
      return "normal";
    default:
      return "warning";
  }
}

const storageKey = "sessionCreatedClustersIds";

function _setSessionCreatedClusters(newClustersUuidList: string[]): void {
  sessionStorage.setItem(storageKey, JSON.stringify(newClustersUuidList));
}

function getSessionCreatedClusters(): string[] {
  const storageInfo: string | null = sessionStorage.getItem(storageKey);
  if (!storageInfo) return [];
  return JSON.parse(storageInfo);
}

function addSessionCreatedCluster(newClusterCreatedUuid: string): void {
  const createdClustersUuids: string[] = getSessionCreatedClusters();
  const newList: string[] = [...createdClustersUuids, newClusterCreatedUuid];
  _setSessionCreatedClusters(newList);
}

function removeSessionCreatedCluster(clusterUuid: string): void {
  const createdClustersUuids: string[] = getSessionCreatedClusters();
  const updatedList: string[] = createdClustersUuids.filter((uuid) => uuid !== clusterUuid);
  _setSessionCreatedClusters(updatedList);
}

function getNonFunctioningServices(operands: { [key: string]: ClusterReportedStatusOperandsValue }): string[] {
  return (
    Object.entries(operands)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, operand]) => operand.unreadyThresholdCrossed)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([key, _]) => key)
  );
}

function getMissingPrerequisites(dependencies: ClusterDependenciesStatus): string[] {
  return (
    Object.entries(dependencies.required)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, dependency]) => !dependency.available)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([key, _]) => key)
  );
}

function getMissingPrerequisitesText(status: ClusterDisplayedStatus): string {
  const missingPrerequisites = clusterUtil.getMissingPrerequisites(status.dependencies as ClusterDependenciesStatus);
  if (missingPrerequisites.length) {
    return EClusterStatusTooltip.MissingPrerequisites.replace(
      "{{missing_prerequisit}}",
      missingPrerequisites.join(", "),
    );
  } else {
    return EClusterStatusTooltip.MissingPrerequisites.replace("{{missing_prerequisit}}", "none");
  }
}

function getDistribution(distribution: ClusterReportedStatusPlatformTypeEnum): string {
  switch (distribution) {
    case ClusterReportedStatusPlatformTypeEnum.Aks:
    case ClusterReportedStatusPlatformTypeEnum.Eks:
    case ClusterReportedStatusPlatformTypeEnum.Gke:
      return distribution.toUpperCase();
    case ClusterReportedStatusPlatformTypeEnum.Vanilla:
      return "Vanilla";
    case ClusterReportedStatusPlatformTypeEnum.Openshift:
      return "OpenShift";
    default:
      return "-";
  }
}
