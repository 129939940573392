<template>
  <div class="runai-duration-inputs row items-center q-gutter-md">
    <q-input
      aid="days-input"
      :min="0"
      stack-label
      outlined
      style="width: 120px; background-color: white"
      type="number"
      label="days"
      v-model="days"
      @update:model-value="updateTotalTime"
      :disable="disable"
    />
    <q-input
      aid="hours-input"
      :min="0"
      stack-label
      outlined
      style="width: 120px; background-color: white"
      type="number"
      :max="23"
      label="hours"
      v-model="hours"
      @update:model-value="updateTotalTime"
      :disable="disable"
    />
    <q-input
      aid="minutes-input"
      :min="0"
      stack-label
      outlined
      style="width: 120px; background-color: white"
      type="number"
      :max="59"
      label="minutes"
      v-model="minutes"
      @update:model-value="updateTotalTime"
      :disable="disable"
    />
    <q-input
      v-if="!hideSeconds"
      aid="seconds-input"
      :min="0"
      stack-label
      outlined
      style="width: 120px; background-color: white"
      type="number"
      :max="59"
      label="seconds"
      v-model="seconds"
      @update:model-value="updateTotalTime"
      :disable="disable"
    />
  </div>
  <div class="q-mt-xs">
    <q-field class="hidden-field" :rules="rules" :model-value="modelValue"></q-field>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
import type { ValidationRule } from "quasar";

const DAY_IN_SECONDS = 24 * 60 * 60;
const HOUR_IN_SECONDS = 60 * 60;
const MINUTE_IN_SECONDS = 60;
export default defineComponent({
  name: "runai-duration-input",
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: true,
    },
    hideSeconds: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rules: {
      type: Object as PropType<ValidationRule[]>,
      required: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      days: null as string | number | null,
      hours: null as string | number | null,
      minutes: null as string | number | null,
      seconds: null as string | number | null,
    };
  },
  created() {
    this.initModelValue();
  },
  methods: {
    initModelValue(): void {
      if (this.modelValue === null || this.modelValue === undefined) return;
      // Initializing the inputs values
      let remainingSeconds = Math.floor(this.modelValue);
      this.days = Math.floor(remainingSeconds / DAY_IN_SECONDS) || null;
      remainingSeconds %= DAY_IN_SECONDS;
      this.hours = Math.floor(remainingSeconds / HOUR_IN_SECONDS) || null;
      remainingSeconds %= HOUR_IN_SECONDS;
      this.minutes = Math.floor(remainingSeconds / MINUTE_IN_SECONDS) || null;
      if (!this.hideSeconds) {
        this.seconds = remainingSeconds % MINUTE_IN_SECONDS || null;
        if (this.modelValue === 0) this.seconds = 0;
      }
    },
    updateTotalTime(): void {
      if ([this.days, this.hours, this.minutes, this.seconds].every((value) => value === null || value === "")) {
        this.$emit("update:model-value", null);
        return;
      }

      let totalTime = 0;
      totalTime += Number(this.seconds) || 0;
      totalTime += Number(this.minutes) * MINUTE_IN_SECONDS || 0;
      totalTime += Number(this.hours) * HOUR_IN_SECONDS || 0;
      totalTime += Number(this.days) * DAY_IN_SECONDS || 0;
      this.$emit("update:model-value", totalTime);
    },
  },
  watch: {
    modelValue() {
      this.initModelValue();
    },
  },
});
</script>
