import { EQuotaEntity } from "@/models/resource.model";
import { computed, type ComputedRef, type Ref, ref } from "vue";
import { useSettingStore } from "@/stores/setting.store";

export interface useQuotaInputProperties {
  isProjectEntity: Ref<boolean>;
  isOpen: Ref<boolean>;
  isLimitQuotaOverSubscriptionEnabled: ComputedRef<boolean>;
  isDepartmentEnabled: ComputedRef<boolean>;
  documentationLink: ComputedRef<string>;
  documentationLinkText: ComputedRef<string>;
  innerMinMarkerText: ComputedRef<string>;
  buttonClass: ComputedRef<string>;
  textAlertClass: ComputedRef<string>;
  tooltipAlertClass: ComputedRef<string>;
}

export function useQuotaInput(entity: EQuotaEntity, isAlertOn: Ref<boolean>): useQuotaInputProperties {
  const settingStore = useSettingStore();

  const isProjectEntity: Ref<boolean> = ref(entity === EQuotaEntity.project);
  const isOpen: Ref<boolean> = ref(false);

  const isLimitQuotaOverSubscriptionEnabled: ComputedRef<boolean> = computed(
    () => settingStore.isLimitQuotaOverSubscriptionEnabled,
  );

  const isDepartmentEnabled: ComputedRef<boolean> = computed(() => settingStore.isDepartmentEnabled);

  const documentationLink: ComputedRef<string> = computed(() =>
    isProjectEntity.value
      ? "https://docs.run.ai/latest/admin/admin-ui-setup/project-setup/?h=project+quotas#project-quotas"
      : "https://docs.run.ai/latest/admin/admin-ui-setup/department-setup/",
  );

  const documentationLinkText: ComputedRef<string> = computed(() =>
    isProjectEntity.value ? "Project quotas guide" : "Departments guide",
  );

  const innerMinMarkerText: ComputedRef<string> = computed(() =>
    isProjectEntity.value ? "In use by non-preemptive workloads" : "Assigned to subordinate projects",
  );

  const buttonClass: ComputedRef<string> = computed(() => {
    if (isOpen.value) return "primary-border";
    if (!isAlertOn.value) return "";
    return isLimitQuotaOverSubscriptionEnabled.value ? "negative-border" : "amber-14-border";
  });

  const textAlertClass: ComputedRef<string> = computed(() =>
    isLimitQuotaOverSubscriptionEnabled.value ? "text-negative" : "text-amber-14",
  );

  const tooltipAlertClass: ComputedRef<string> = computed(() =>
    isLimitQuotaOverSubscriptionEnabled.value ? "bg-red-2" : "bg-orange-1",
  );

  return {
    isProjectEntity,
    isOpen,
    isLimitQuotaOverSubscriptionEnabled,
    isDepartmentEnabled,
    documentationLink,
    documentationLinkText,
    innerMinMarkerText,
    buttonClass,
    textAlertClass,
    tooltipAlertClass,
  };
}
