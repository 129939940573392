import type { DepartmentsMetricData, MetricsData } from "@/models/metrics.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { dateUtil } from "@/utils/date.util";

const DEFAULT_NUMBER_OF_SAMPLES = 30;

export const resourcesService = {
  getClusterMetrics,
  getDepartmentsMetrics,
  getProjectsMetrics,
};

async function getMetrics(
  endPointResourcePath: string,
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  resourceId?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<DepartmentsMetricData | MetricsData> {
  const { startDate, endDate } = dateUtil.getEpochDates(start, end);
  let endPoint = `v1/k8s/clusters/${clusterUUID}`;

  if (endPointResourcePath) {
    endPoint += `/${endPointResourcePath}`;
  }
  if (resourceId) {
    endPoint += `/${resourceId}`;
  }
  endPoint += `/metrics?start=${startDate}&end=${endDate}&numberOfSamples=${numberOfSamples}`;

  if (nodePoolFilter) {
    endPoint += `&nodepoolName=${nodePoolFilter}`;
  }

  return await controlPlaneService.get(endPoint);
}

async function getClusterMetrics(
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<MetricsData> {
  return (await getMetrics("", clusterUUID, start, end, nodePoolFilter, undefined, numberOfSamples)) as MetricsData;
}

async function getDepartmentsMetrics(
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  departmentId?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<DepartmentsMetricData | MetricsData> {
  return await getMetrics("departments", clusterUUID, start, end, nodePoolFilter, departmentId, numberOfSamples);
}

async function getProjectsMetrics(
  clusterUUID: string,
  start: string,
  end: string,
  nodePoolFilter?: string,
  projectId?: string,
  numberOfSamples = DEFAULT_NUMBER_OF_SAMPLES,
): Promise<MetricsData> {
  return (await getMetrics(
    "projects",
    clusterUUID,
    start,
    end,
    nodePoolFilter,
    projectId,
    numberOfSamples,
  )) as MetricsData;
}
