import { UnleashClient } from "@unleash/proxy-client-vue";
import { unleashTestConfig, unleashStagingConfig, unleashProdConfig, type IUnleashConfig } from "./unleash.config";
import { getEnvType, EnvType } from "@/utils/infra.util/infra.util";

class UnleashConfigService {
  private client: UnleashClient | undefined;

  async init(tenantId: string): Promise<void> {
    const unleashClient = this.getClient();
    if (unleashClient) {
      await this.getClient()?.updateContext({ properties: { tenantId: tenantId } });
    }
  }
  getClient(): UnleashClient | undefined {
    if (!this.client) {
      const config = this.getEnvConfig(window.location.hostname);
      if (!config) {
        return;
      }
      try {
        this.client = new UnleashClient(config);
      } catch {
        return;
      }
    }
    return this.client;
  }

  isFeatureEnabled(featureName: string): boolean {
    return this.getClient()?.isEnabled(featureName) ?? true;
  }

  getEnvConfig(hostName: string): IUnleashConfig | undefined {
    const env = getEnvType(hostName);
    if (env === EnvType.DEV) {
      return;
    }
    if (env === EnvType.TEST) {
      return unleashTestConfig;
    } else if (env === EnvType.STAGING) {
      return unleashStagingConfig;
    } else if (env === EnvType.PROD) {
      return unleashProdConfig;
    }
  }
}

export const unleashConfigService = new UnleashConfigService();
