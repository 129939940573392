<template>
  <runai-autocomplete-select
    v-bind="$attrs"
    :no-result-label="getNotfoundMessage"
    :label="getSubjectLabel"
    :search-hint="getSearchHint"
    :model-value="subjectName"
    @update:model-value="updateSelectedSubject"
    :async-options-promise="getSubjectOptionsByType"
    :minimum-characters="2"
    @clear="clearSubjectId"
    :rules="rules"
    :is-error="isGetSubjectOptionsError"
    class="user-auto-complete"
    can-add-new-values
    :new-values-label-postfix="newLabelPrefix"
    :stack-label="false"
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
//cmps
import RunaiAutocompleteSelect from "@/components/common/runai-autocomplete-select/runai-autocomplete-select.vue";
//model
import type { ISelectOption } from "@/models/global.model";
import { SubjectType } from "@/swagger-models/authorization-client";
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { useAuthStore } from "@/stores/auth.store";
import { errorMessages } from "@/common/error-message.constant";
import type { ValidationRule } from "quasar";

export default defineComponent({
  name: "subject-name-select",
  components: { RunaiAutocompleteSelect },
  emits: ["update:subject-name"],
  props: {
    subjectName: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    subjectTypeOption: {
      type: Object as PropType<ISelectOption>,
      required: true,
    },
    rules: {
      type: Object as PropType<ValidationRule | ValidationRule[]>,
      required: false,
    },
  },
  data() {
    return {
      isGetSubjectOptionsError: false,
      authStore: useAuthStore(),
    };
  },
  computed: {
    newLabelPrefix(): string {
      return `(+${this.subjectTypeOption.label.toLowerCase()})`;
    },
    getNotfoundMessage(): string {
      return `No ${this.subjectTypeOption?.value} match your filter`;
    },
    getSubjectLabel(): string {
      switch (this.subjectTypeOption?.value) {
        case SubjectType.User:
          return "User email";
        case SubjectType.App:
          return "Application name";
        case SubjectType.Group:
          return "Group name";
        default:
          return "";
      }
    },
    getSearchHint(): string {
      return "Search or type a name";
    },
    isSso(): boolean {
      return this.authStore.isSSO;
    },
  },
  methods: {
    async getSubjectOptionsByType(searchQuery = ""): Promise<string[]> {
      return accessRuleService.getSubjectIds(this.isSso, this.subjectTypeOption?.value as SubjectType, searchQuery);
    },
    clearSubjectId(): void {
      this.updateSelectedSubject(null);
    },
    requiredSubjectId(val: string): boolean | string {
      return !!val || errorMessages.SUBJECT_ID_NOT_EMPTY;
    },
    updateSelectedSubject(value: string | null): void {
      this.$emit("update:subject-name", value);
    },
  },
});
</script>

<style scoped lang="scss"></style>
