import type {
  CategorizedNotificationSettings,
  Filter,
  NotificationSettings,
  RouteConfig,
} from "@/models/user-setting.model";
import { FilteringPolicy, MatcherKey, MatcherType, uniqueListenerRunaiId } from "@/models/user-setting.model";

export const userNotificationsUtil = {
  updateNotificationStatus,
  convertCategoriesToRouteConfig,
};

function updateNotificationStatus(
  notificationsSettings: Array<NotificationSettings>,
  filters: Array<Filter>,
): CategorizedNotificationSettings {
  const mergedArray: Array<NotificationSettings> = notificationsSettings.map((notification) => {
    const categoryMatcher = findFilterByCategory(filters, notification);
    if (categoryMatcher) {
      const typeMatcher = findMatcherByType(categoryMatcher, notification);
      if (typeMatcher) {
        return { ...notification, enabled: true };
      }
    }
    return { ...notification, enabled: false };
  });
  return getByCategory(mergedArray);
}

function findMatcherByType(categoryMatcher: Filter, notification: NotificationSettings) {
  return categoryMatcher.matchers.find(
    (matcher) => matcher.key === "type" && new RegExp(matcher.value).test(notification.type),
  );
}

function findFilterByCategory(filters: Array<Filter>, notification: NotificationSettings): Filter | undefined {
  return filters.find((filter) =>
    filter.matchers.some((matcher) => matcher.key === "category" && matcher.value === notification.category),
  );
}

function getByCategory(notificationSettings: NotificationSettings[]): CategorizedNotificationSettings {
  const categorizedSettings: { [key: string]: NotificationSettings[] } = {};

  notificationSettings.forEach((setting) => {
    if (!categorizedSettings[setting.category]) {
      categorizedSettings[setting.category] = [];
    }
    categorizedSettings[setting.category].push(setting);
  });

  return categorizedSettings;
}

function getRouteConfigWrapper(tenantId: string, userId: string): RouteConfig {
  return {
    route_config: {
      listener_id: uniqueListenerRunaiId,
      filtering: {
        policy: FilteringPolicy.Any,
        filters: [],
      },
    },
    route_id: tenantId + "_" + userId,
    tenant_id: tenantId,
  };
}

function convertCategoriesToRouteConfig(
  categories: CategorizedNotificationSettings,
  notificationsStatus: Record<string, boolean>,
  tenantId: number,
  routeId: string,
): RouteConfig {
  const filters: Record<string, string> = {};
  for (const [category, items] of Object.entries(categories)) {
    const enabledTypes = items
      .filter((item) => notificationsStatus[item.type])
      .map((item) => item.type)
      .join("|");

    if (enabledTypes) {
      filters[category] = enabledTypes;
    }
  }

  const routeConfig = getRouteConfigWrapper(String(tenantId), routeId);
  Object.keys(filters).forEach((category) => {
    routeConfig.route_config.filtering.filters.push({
      type: "matcher",
      matchers: [
        {
          match_type: MatcherType.Equal,
          key: MatcherKey.Category,
          value: category,
        },
        {
          match_type: MatcherType.Any,
          key: MatcherKey.Type,
          value: filters[category],
        },
      ],
    });
  });
  return routeConfig;
}
