import { unleashConfigService } from "./unleash-config.service";

export const unleashService = {
  shouldShowContainerPathOverride,
  isWorkloadDetailsTabEnabled,
  shouldShowNewLayout,
  showOrgUnitParity,
  showOrgUnitOverTimeData,
  showPolicyForInference,
  isMigProfilesDisabled,
  isUpdateAssetEnabled,
  showNodePoolsV2,
  showNimInference,
  showServingEndpointAccessInference,
};

function shouldShowContainerPathOverride(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19400-show-workload-container-path");
}

function isWorkloadDetailsTabEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled("release-20600-show-workload-details-tab");
}

function shouldShowNewLayout(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19405-show-new-ui-navigation");
}

function showOrgUnitParity(): boolean {
  return unleashConfigService.isFeatureEnabled("release-18736-show-org-unity-parity");
}

function showOrgUnitOverTimeData(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19487-show-org-unit-over-time-data");
}

function showPolicyForInference(): boolean {
  return unleashConfigService.isFeatureEnabled("release-23112-show-policy-for-inference");
}

function isMigProfilesDisabled(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19264-show-mig-profile-deprecation");
}

function isUpdateAssetEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled("release-23268-show-asset-editing");
}

function showNodePoolsV2(): boolean {
  return unleashConfigService.isFeatureEnabled("release-11773-show-node-pools-v2");
}

function showNimInference(): boolean {
  return unleashConfigService.isFeatureEnabled("release-RUN-21924-show-nim-for-inference");
}

function showServingEndpointAccessInference(): boolean {
  return unleashConfigService.isFeatureEnabled("release-RUN-22019-show-serving-endpoint-inference");
}
