import { ResourceType, Action } from "@/swagger-models/authorization-client";

import {
  type RunaiRouteRecord,
  type RouteLocationNormalized,
  type NavigationGuardNext,
  START_LOCATION,
} from "vue-router";
import { INFERENCE_ROUTE_NAMES } from "./inference.routes.names";

import { useSettingStore } from "@/stores/setting.store";
import { SettingKeys } from "@/models/setting.model";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";

export const inferenceRoutes: RunaiRouteRecord = {
  path: "/inferences/new",
  component: () => import("@/views/inference/inference-app.vue"),
  children: [
    {
      path: "",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW,
      component: () => import("@/views/inference/creation/inference-new.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        resourceType: ResourceType.Inferences,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
    },
    {
      path: "assets",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
      component: () => import("@/views/inference/creation/inference-assets-based.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        resourceType: ResourceType.Inferences,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
      beforeEnter: (to, from, next) => {
        // If the user tries to access the assets page directly, redirect to the new training page
        if (from === START_LOCATION) next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
        else next();
      },
    },
    {
      path: "hugging-face",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_HUGGING_FACE_EDIT,
      component: () => import("@/views/inference/creation/inference-hugging-face-based.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        resourceType: ResourceType.Inferences,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
      beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        if (from === START_LOCATION) {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
          return;
        }
        const settingStore = useSettingStore();
        if (settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog)) {
          next();
        } else {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
        }
      },
    },
    {
      path: "model",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_MODEL_EDIT,
      component: () => import("@/views/inference/creation/inference-model-based.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        resourceType: ResourceType.Inferences,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
      beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        // If the user tries to access the assets page directly, redirect to the new inference page
        if (from === START_LOCATION) {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
          return;
        }
        const settingStore = useSettingStore();
        if (settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog)) {
          next();
        } else {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
        }
      },
    },
    {
      path: "nim",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_NIM_EDIT,
      component: () => import("@/views/inference/creation/inference-nim-based.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        resourceType: ResourceType.Inferences,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
      beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        if (from === START_LOCATION) {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
          return;
        }
        const settingStore = useSettingStore();
        if (settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog)) {
          next();
        } else {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
        }
      },
    },
  ],
};
