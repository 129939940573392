<template>
  <section>
    <node-pools-table-header
      :is-cpu-enabled="isCpuEnabled"
      :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
      :is-only-default-node-pool="isOnlyDefaultNodePool"
    />
    <node-pools-table-body
      :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
      :is-cpu-enabled="isCpuEnabled"
      :department-id="departmentId"
      :cluster-id="clusterId"
      :read-only="readOnly"
      :resources="resources"
      :node-pools-priorities="nodePoolsPriorities"
      :quota-statuses="quotaStatuses"
      @update:resources="$emit('update:resources', $event)"
      @update:node-pools-priorities="$emit('update:node-pools-priorities', $event)"
      @update:node-pools-resource="updateNodePoolResources"
    />
    <node-pools-table-summary
      v-if="!isOnlyDefaultNodePool"
      :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
      :resources="resources"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps

import { NodePoolsTableHeader } from "@/components/quota-management/node-pools-table/node-pools-table-header/";
import { NodePoolsTableBody } from "@/components/quota-management/node-pools-table/node-pools-table-body";
import { NodePoolsTableSummary } from "@/components/quota-management/node-pools-table/node-pools-table-summary";
// stores
import { useSettingStore } from "@/stores/setting.store";
// models
import { EResourceLabel } from "@/models/resource.model";
//utils
import { deepCopy } from "@/utils/common.util";
import type {
  Project,
  ProjectCreationRequest,
  QuotaStatusNodePool,
  Resources,
} from "@/swagger-models/org-unit-service-client";
export default defineComponent({
  components: { NodePoolsTableHeader, NodePoolsTableBody, NodePoolsTableSummary },
  emits: ["update:resources", "update:node-pools-priorities"],
  RESOURCE_LABEL: EResourceLabel,
  props: {
    project: {
      type: Object as PropType<Project | ProjectCreationRequest>,
      required: false,
    },
    departmentId: {
      type: String as PropType<string>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      default: "",
    },
    resources: {
      type: Array as PropType<Array<Resources>>,
      required: true,
    },
    nodePoolsPriorities: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<QuotaStatusNodePool>>,
      default: () => [],
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    isOnlyDefaultNodePool(): boolean {
      return this.resources.length === 1;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
  },
  methods: {
    updateNodePoolResources(nodePoolResources: Resources): void {
      const items = deepCopy(this.resources);
      items[0] = nodePoolResources;
      this.$emit("update:resources", items);
    },
  },
});
</script>
