// This file is a hack to fix the bug in vite.
// The bug:
// When you import the WORKSPACE_ROUTE_NAMES from the workspace.route.ts file into
// component, during the work you do some changes to the component (or to his parents),
// HMR (used by vite) show an exception about "initialization" and "syntax errors or importing non-existent modules".
// So I moved the names out of the route file, which fix this error.
export const WORKSPACE_ROUTE_NAMES = {
  WORKSPACE_NEW: "workspace-new",
  WORKSPACE_ASSETS_EDIT: "workspace-assets-edit",
  WORKSPACE_ENVIRONMENT_NEW: "workspace-environment-new",
  WORKSPACE_COMPUTE_RESOURCE_NEW: "workspace-compute-resource-new",
  WORKSPACE_HOST_PATH_NEW: "workspace-host-path-new",
  WORKSPACE_NFS_NEW: "workspace-nfs-new",
  WORKSPACE_S3_NEW: "workspace-s3-new",
  WORKSPACE_GIT_NEW: "workspace-git-new",
  WORKSPACE_PVC_NEW: "workspace-pvc-new",
  WORKSPACE_CONFIG_MAP_NEW: "workspace-config-map-new",
  WORKSPACE_SECRET_VOLUME_NEW: "workspace-secret-volume-new",
};
