export const APP_WIDE_FEATURE_INTRO_MODAL_SHOWN = "app-wide-feature-intro-has-been-shown";
export const IS_OVERVIEW_V2_MODE_ON = "is-overview-v2-mode-on";
export const ACCESS_TOKEN = "access-token";
export const ID_TOKEN = "id-token";
export const REFRESH_TOKEN = "refresh-token";
export const OVERVIEW_FILTERS = "overview-filters";
export const QUOTA_MANAGEMENT_FILTERS = "quota-management-filters";
export const OVERVIEW_DATE_FILTERS = "overview-date-filters";
export const IS_NEW_NAV_ON = "is-new-nav-on";
export const NEW_NAV_INTRO_MODAL_SEEN = "new-nav-intro-modal-seen";
