<template>
  <div :class="getClass()">
    <div class="col justify-center">
      <template v-if="showError">
        <section class="table-error-section text-center">
          <i class="fa-sharp fa-solid fa-triangle-exclamation fa-xl text-body-color"></i>
          For some reason this page can't be displayed right now. Try refreshing the page or contact your admin.
        </section>
      </template>

      <template v-else>
        <template v-if="!isFiltered">
          <div class="row justify-center q-my-md" v-if="iconName">
            <runai-svg-icon :name="iconName" :size="iconSize" />
          </div>
          <div class="row justify-center empty-state-message">{{ message }}</div>
          <div class="row justify-center empty-state-message">
            <slot name="secondary-text"></slot>
          </div>
          <div class="row justify-center q-mt-md" v-if="showCreateBtn">
            <slot name="primary-btn">
              <q-btn
                v-permission="{ resourceType, action: Action.Create }"
                color="primary"
                flat
                class="btn-icon-sm"
                icon="fa-solid fa-plus"
                aid="empty-state-add-entity-btn"
                :label="'New ' + entityName"
                @click="createNew"
              ></q-btn>
            </slot>
          </div>
        </template>
        <template v-else>
          <div class="row justify-center empty-state-message">{{ noFilterResultsText }}</div>
          <section v-if="showFilterIconAndButton">
            <div class="row justify-center q-my-md">
              <q-icon v-if="lightIcon" class="fa-thin fa-filter" style="font-size: 76px" />
              <runai-svg-icon v-else :name="tableNoResultsIcon" size="76" />
            </div>
            <div class="row justify-center" v-if="!lightIcon">
              <q-btn
                color="primary"
                flat
                class="btn-icon-sm"
                aid="empty-state-clear-filters-btn"
                label="Clear filters"
                @click="clearFilters"
              ></q-btn>
            </div>
          </section>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { CLUSTER_COLUMN_FILTER_NAME, type IFilterBy } from "@/models/filter.model";
import { Action, type ResourceType } from "@/swagger-models/authorization-client";
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  emits: ["clear-filters", "create-new"],
  props: {
    entityName: {
      type: String as PropType<string>,
      required: true,
    },
    iconName: {
      type: String as PropType<string>,
      required: false,
    },
    lightIcon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    iconSize: {
      type: String as PropType<string>,
      default: "83",
    },
    showCreateBtn: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    filterBy: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
    showError: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    showFilterIconAndButton: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    customMessage: {
      type: String as PropType<string>,
      required: false,
    },
    ignoreClusterFilter: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      message: "" as string,
    };
  },
  created() {
    this.message = this.customMessage ? this.customMessage : `You don't have any ${this.entityPlural} yet.`;
  },
  computed: {
    Action(): typeof Action {
      return Action;
    },
    resourceType(): ResourceType {
      return this.$route.meta.resourceType as ResourceType;
    },
    isFiltered(): boolean {
      const columnsFiltersLength: number = this.filterBy.columnFilters?.length || 0;
      if (columnsFiltersLength === 1) {
        const isClusterFilterExist = this.filterBy.columnFilters?.some(
          (filter) => filter.name === CLUSTER_COLUMN_FILTER_NAME,
        );
        if (isClusterFilterExist && this.ignoreClusterFilter) {
          return !!(this.filterBy.searchTerm || this.filterBy.advancedFilters?.length);
        }
      }
      return !!(
        this.filterBy.columnFilters?.length ||
        this.filterBy.searchTerm ||
        this.filterBy.advancedFilters?.length
      );
    },
    noFilterResultsText(): string {
      return `No ${this.entityPlural.toLocaleLowerCase()} match your filters`;
    },
    entityPlural(): string {
      const lastCharacter: string = this.entityName.charAt(this.entityName.length - 1);
      switch (lastCharacter) {
        case "y":
          return this.entityName.slice(0, this.entityName.length - 1) + "ies";
        default:
          return this.entityName + "s";
      }
    },
    tableNoResultsIcon(): string {
      return this.appStore.isNewNavigationFeatureOn ? "table-no-results-new" : "table-no-results";
    },
  },
  methods: {
    clearFilters(): void {
      this.$emit("clear-filters");
    },
    createNew(): void {
      this.$emit("create-new");
    },
    getClass(): string {
      return `empty-state-container full-width row ${this.showError ? "q-py-sm" : "q-py-xl"}`;
    },
  },
});
</script>

<style lang="scss">
.empty-state-container {
  .table-error-section {
    i {
      margin-inline-end: 5px;
    }
  }

  .empty-state-message {
    color: $black-70;
    font-size: 14px;
  }
}
</style>
