<template>
  <div class="idp-setup-fields col-12">
    <div>
      <div>Add your identity provider metadata</div>
      <div class="row col-12">
        <setting-readonly-input>
          <template v-slot:default="{ readonly }">
            <q-input
              class="col-12 q-mb-lg"
              :model-value="ocpSettingsFields.idpBaseUrl"
              placeholder="Enter URL"
              debounce="300"
              :rules="[isValidUrl]"
              no-error-icon
              @update:model-value="updateIdpBaseUrl"
              stack-label
              label="Base URL"
              ref="ocpUrlInput"
              autofocus
              :hint="urlHint"
              :disable="readonly"
            />
          </template>
        </setting-readonly-input>
        <div class="row col-12">
          <setting-readonly-input>
            <template v-slot:default="{ readonly }">
              <q-input
                class="col-5"
                :model-value="ocpSettingsFields.clientId"
                debounce="300"
                :rules="[isNotEmptyClientID]"
                no-error-icon
                @update:model-value="updateClientId"
                stack-label
                label="Client ID"
                ref="ocpClientIdInput"
                :disable="readonly"
              />
            </template>
          </setting-readonly-input>
          <div class="col-1" />
          <setting-readonly-input>
            <template v-slot:default="{ readonly }">
              <q-input
                class="col-6 items-end"
                :model-value="ocpSettingsFields.clientSecret"
                debounce="300"
                :rules="[isNotEmptySecret]"
                no-error-icon
                @update:model-value="updateClientSecret"
                stack-label
                label="Client secret"
                ref="ocpClientSecretInput"
                :disable="readonly"
              />
            </template>
          </setting-readonly-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { isNotEmpty, isValidUrl } from "@/common/form.validators";
import type { QInput } from "quasar";
import type { OcpCreationData } from "@/swagger-models/identity-manager-client";
import { urlHintMessage } from "@/models/setting.model";
import SettingReadonlyInput from "@/components/settings/setting-readonly-input/setting-readonly-input.vue";

export default defineComponent({
  name: "ocp-fields",
  components: { SettingReadonlyInput },
  emits: ["updated"],
  props: {
    ocpSettings: {
      type: Object as PropType<OcpCreationData>,
    },
    isEditMode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      ocpSettingsFields: {} as OcpCreationData,
    };
  },
  created() {
    if (this.ocpSettings) {
      this.ocpSettingsFields = {
        idpBaseUrl: this.ocpSettings.idpBaseUrl,
        clientId: this.ocpSettings.clientId,
        clientSecret: this.ocpSettings.clientSecret,
      };
    }
  },
  computed: {
    urlHint(): string {
      return this.isEditMode ? urlHintMessage : "";
    },
  },
  methods: {
    async validate(): Promise<boolean> {
      return (
        (await (this.$refs.ocpUrlInput as QInput).validate()) &&
        (await (this.$refs.ocpClientIdInput as QInput).validate()) &&
        (await (this.$refs.ocpClientSecretInput as QInput).validate())
      );
    },
    isNotEmptyClientID(value: string): boolean | string {
      return isNotEmpty(value) || "Enter ID";
    },
    isNotEmptySecret(value: string): boolean | string {
      return isNotEmpty(value) || "Enter a secret";
    },
    isValidUrl(url: string): boolean | string {
      return (this.isEditMode && url === "") || isValidUrl(url) || "Enter a valid URL";
    },
    updateClientId(clientId: string): void {
      this.ocpSettingsFields.clientId = clientId;
      this.onUpdated();
    },
    updateClientSecret(clientSecret: string): void {
      this.ocpSettingsFields.clientSecret = clientSecret;
      this.onUpdated();
    },
    updateIdpBaseUrl(idpBaseUrl: string): void {
      this.ocpSettingsFields.idpBaseUrl = idpBaseUrl;
      this.onUpdated();
    },
    onUpdated(): void {
      this.$emit("updated", this.ocpSettingsFields, this.isIdpFieldsValid());
    },
    isIdpFieldsValid(): boolean {
      const idpBaseUrl: string = this.ocpSettingsFields.idpBaseUrl;
      return (
        (this.isEditMode && !idpBaseUrl) ||
        (isValidUrl(idpBaseUrl) &&
          this.ocpSettingsFields.clientId != undefined &&
          isNotEmpty(this.ocpSettingsFields.clientId) &&
          isNotEmpty(this.ocpSettingsFields.clientSecret))
      );
    },
  },
});
</script>
