<template>
  <div class="runai-stepper-form q-pa-md row no-wrap justify-center">
    <div class="steps-map q-mt-sm">
      <q-stepper
        v-model="currentStep"
        active-icon="fa-solid fa-pen"
        vertical
        ref="stepper"
        color="primary"
        animated
        flat
        style="background-color: transparent"
      >
        <q-step
          v-for="(step, idx) in steps"
          :key="step.name"
          :name="step.name"
          :done="currentStep > idx + 1"
          :title="step.title"
          :icon="step.icon"
        />
      </q-stepper>
    </div>
    <div class="main-content cluster-creation" v-if="currentStep === 1">
      <div class="justify-center">
        <runai-form-wrapper class="cluster-form" :form-state="clusterFormModel" ref="elClusterForm">
          <cluster-creation-step
            v-if="clusterCreationStepLoading"
            :is-cluster-edit-mode="isClusterEditMode"
            :is-multi-tenant="isMultiTenant"
            :cluster-form-model="clusterFormModel"
            :versions="sortedVersions"
            :is-higher-cluster-version="isHigherClusterVersion"
            @update-form-model="onUpdateCreationFormModel"
          ></cluster-creation-step>
        </runai-form-wrapper>
      </div>
      <div class="stepper-controls row justify-between">
        <q-field class="col-4 form-hint no-padding" :model-value="displayFormHint" :rules="[isFormIncomplete]"></q-field>
        <q-stepper-navigation class="q-pt-none">
          <q-btn
            aid="continue-btn"
            @click="goNext"
            :loading="loading"
            color="primary"
            label="Continue"
            class="q-mt-md"
          />
        </q-stepper-navigation>
      </div>
    </div>
    <div class="main-content cluster-installation" v-else>
      <div class="row justify-center">
        <cluster-installation-step
          v-if="createdCluster?.uuid && operatorVersionsMap"
          :cluster-form-model="clusterFormModel"
          :is-multi-tenant="isMultiTenant"
          :created-cluster="createdCluster"
          :versions-map="operatorVersionsMap"
          :is-staging-env="isStagingEnv"
          :is-higher-cluster-version="isHigherClusterVersion"
          :is-load-versions-failed="isLoadVersionsFailed"
        ></cluster-installation-step>
      </div>
      <div class="stepper-controls row justify-end">
        <q-stepper-navigation>
          <q-btn flat @click="goBack" aid="back-btn" color="primary" label="Back" class="q-mr-md" />
          <q-btn aid="leave-btn" @click="leave" color="primary" label="Done" />
        </q-stepper-navigation>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  clusterNewInstallerThreshold,
  EClusterLocations,
  ICluster,
  IClusterInstalltionModel,
} from "@/models/cluster.model";
// stores
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import type { QStepper } from "quasar";
// cmps
import { ClusterCreationStep } from "@/components/cluster/cluster-creation-step";
import { ClusterInstallationStep } from "@/components/cluster/cluster-installation-step";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
// services
import { isNewerVersion } from "@/utils/version.util";
import { alertUtil } from "@/utils/alert.util";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { configService } from "@/services/control-plane/config.service/config.service";
import { clusterUtil } from "@/utils/cluster.util";
// models
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes/cluster.routes.names";
import { HttpErrorResponse } from "@/models/http-response.model";
import { defineComponent } from "vue";
import { getVersionWithoutPatch } from "@/utils/version.util/version.util";

export default defineComponent({
  components: { ClusterCreationStep, ClusterInstallationStep, RunaiFormWrapper },
  data() {
    return {
      loading: false,
      clusterCreationStepLoading: false,
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      currentStep: 1,
      steps: [
        { title: "Setup", name: 1, icon: "fa-solid fa-clipboard-list" },
        { title: "Installation instructions", name: 2, icon: "fa-solid fa-download" },
      ],
      timeOutId: null as number | null,
      displayFormHint: false,
      createdCluster: null as null | ICluster,
      isClusterEditMode: false as boolean,
      clusterFormModel: {
        name: "",
        version: "",
        prerequisites: {
          location: "",
          clusterUrl: "",
          k8sDistribution: "",
        },
      } as IClusterInstalltionModel,
      operatorVersionsMap: null as Record<string, string> | null,
      isLoadVersionsFailed: false,
    };
  },
  async created() {
    const clusterUuid = this.$route.params.id;
    await this.setOperatorVersionMap();
    if (clusterUuid) {
      this.setClusterDataEditMode(clusterUuid as string);
    } else {
      this.setClusterDataCreateMode();
    }
    this.appStore.setPageLoading(false);
    this.clusterCreationStepLoading = true;
  },
  computed: {
    versions(): string[] {
      if (!this.operatorVersionsMap) return [];
      return Object.keys(this.operatorVersionsMap);
    },
    sortedVersions(): string[] {
      return [...this.versions].sort((versionA, versionB) => (isNewerVersion(versionA, versionB) ? -1 : 1));
    },
    isMultiTenant(): boolean {
      return this.appStore.isMultiTenant;
    },
    isStagingEnv(): boolean {
      return configService.isStagingOrTestOrLocalEnv();
    },
    isHigherClusterVersion(): boolean {
      if (!this.clusterFormModel.version) return false;
      return isNewerVersion(this.clusterFormModel.version, clusterNewInstallerThreshold);
    },
  },
  methods: {
    onUpdateCreationFormModel(newModel: IClusterInstalltionModel): void {
      const isUrlChanged = this.clusterFormModel.prerequisites.clusterUrl != newModel.prerequisites.clusterUrl;
      this.clusterFormModel = newModel;
      if (isUrlChanged) return;
      const selectedCluster: ICluster | null = this.clusterStore.getClusterById(this.createdCluster?.uuid as string);
      if (selectedCluster?.domain && this.clusterFormModel.prerequisites.location === EClusterLocations.REMOTE) {
        this.clusterFormModel.prerequisites.clusterUrl = selectedCluster?.domain;
      } else if (this.clusterFormModel.prerequisites.location !== EClusterLocations.REMOTE) {
        this.clusterFormModel.prerequisites.clusterUrl = "";
      }
    },
    setClusterDataEditMode(clusterId: string): void {
      const selectedCluster: ICluster | null = this.clusterStore.getClusterById(clusterId);
      if (!selectedCluster) return;
      this.createdCluster = selectedCluster;
      this.clusterFormModel.name = selectedCluster.name;
      this.clusterFormModel.version = getVersionWithoutPatch(selectedCluster.version) || this.sortedVersions[0];
      this.clusterFormModel.prerequisites.clusterUrl = selectedCluster.domain;
      this.clusterFormModel.prerequisites.location =
        selectedCluster.domain === window.location.hostname ? EClusterLocations.CONTROL_PLANE : EClusterLocations.REMOTE;
      this.isClusterEditMode = true;
    },
    setClusterDataCreateMode(): void {
      this.clusterFormModel.version = this.sortedVersions[0];
      this.clusterFormModel.prerequisites.location = this.isMultiTenant
        ? EClusterLocations.REMOTE
        : EClusterLocations.CONTROL_PLANE;
    },
    async setOperatorVersionMap(): Promise<void> {
      try {
        let fifteenSecondsTimeOutId: number;
        const fifteenSecondsTimeoutPromise = new Promise((resolve, reject) => {
          fifteenSecondsTimeOutId = window.setTimeout(() => {
            reject(new Error("Request timed out"));
          }, 15000);
        });

        const operatorMapPromise = clusterService.getOperatorVersions().then((versionsMap) => {
          window.clearTimeout(fifteenSecondsTimeOutId);
          this.operatorVersionsMap = versionsMap;
        });

        await Promise.race([operatorMapPromise, fifteenSecondsTimeoutPromise]).catch((error) => {
          throw error;
        });
      } catch (error: unknown) {
        // This is a quick fix for air-gapped installation
        this.operatorVersionsMap = { "2.15": "" } as Record<string, string>;
        this.isLoadVersionsFailed = true;
        console.error(error);
        this.$q.notify(alertUtil.getError("Failed to load cluster versions - setting default"));
      }
    },
    async validate(): Promise<boolean> {
      return await (this.$refs["elClusterForm"] as HTMLFormElement).validate();
    },
    async validateAndNotify(): Promise<boolean> {
      this.displayFormHint = false;
      const success = await this.validate();
      if (!success) {
        this.showHint();
      }
      return success;
    },
    async createCluster(): Promise<void> {
      this.displayFormHint = false;
      const isValid: boolean = await this.validateAndNotify();
      if (!isValid) return;
      try {
        this.loading = true;
        if (this.createdCluster?.uuid) await this.deleteLastCreatedCluster();
        this.createdCluster = await this.clusterStore.create(this.clusterFormModel.name);
        this.createdCluster?.uuid && clusterUtil.addSessionCreatedCluster(this.createdCluster.uuid);
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.$q.notify(alertUtil.getError("Failed to create cluster"));
        }
      } finally {
        this.loading = false;
        (this.$refs.stepper as QStepper).next();
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    async deleteLastCreatedCluster(): Promise<void> {
      if (!this.createdCluster?.uuid) return;
      try {
        this.loading = true;
        this.createdCluster?.uuid && clusterUtil.removeSessionCreatedCluster(this.createdCluster.uuid);
        await this.clusterStore.deleteCluster(this.createdCluster.uuid);
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.$q.notify(alertUtil.getError("Failed to delete cluster"));
        }
      } finally {
        this.loading = false;
      }
    },
    async goNext(): Promise<void> {
      if (this.isClusterEditMode) {
        const isValid: boolean = await this.validateAndNotify();
        if (!isValid) return;
        (this.$refs.stepper as QStepper).next();
        return;
      }
      await this.createCluster();
    },
    goBack(): void {
      (this.$refs.stepper as QStepper).previous();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    leave(): void {
      this.$router.push({
        name: CLUSTER_ROUTE_NAMES.CLUSTER_INDEX,
        query: { createdEntityId: this.createdCluster?.uuid },
      });
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = window.setTimeout(() => (this.displayFormHint = false), 15000);
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
  },
});
</script>
<style lang="scss">
.runai-stepper-form {
  position: relative;
  .steps-map {
    top: 80px;
    left: 0;
    position: fixed;
  }
  a:hover {
    text-decoration: underline;
  }
  .stepper-controls {
    .q-stepper__nav {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>
