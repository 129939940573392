import { assetsServiceApi } from "./assets-service-api/assets-service-api";
import { authorizationApi } from "./authorization-api/authorization-api";
import { backendApi } from "./backend-api/backend-api";
import { clusterServiceApi } from "./cluster-service-api/cluster-service-api";
import { emailServiceApi } from "./email-service-api/email-service-api";
import { identityManagerApi } from "./identity-manager-api/identity-manager-api";
import { metricsServiceApi } from "./metrics-service-api/metrics-service-api";
import { policyServiceApi } from "./policy-service-api/policy-service-api";
import { tenantManagerApi } from "./tenants-manager-api/tenants-manager-api";
import { trailServiceApi } from "./trial-service-api/trail-service-api";
import { workloadServiceApi } from "./workloads-service-api/workloads-service-api";
import { k8sObjectTrackerApi } from "./k8s-object-tracker-api/k8s-object-tracker-api";
import { cliApi } from "./cli-exposer-api/cli-exposer-api";
import { orgUnitServiceApi } from "@/services/infra/client-apis/org-unit-service-api/org-unit-service-api";

export function initClientApis(accessToken: string): void {
  assetsServiceApi.setAccessToken = accessToken;
  authorizationApi.setAccessToken = accessToken;
  backendApi.setAccessToken = accessToken;
  clusterServiceApi.setAccessToken = accessToken;
  emailServiceApi.setAccessToken = accessToken;
  identityManagerApi.setAccessToken = accessToken;
  metricsServiceApi.setAccessToken = accessToken;
  policyServiceApi.setAccessToken = accessToken;
  tenantManagerApi.setAccessToken = accessToken;
  trailServiceApi.setAccessToken = accessToken;
  workloadServiceApi.setAccessToken = accessToken;
  k8sObjectTrackerApi.setAccessToken = accessToken;
  cliApi.setAccessToken = accessToken;
  orgUnitServiceApi.setAccessToken = accessToken;
}
