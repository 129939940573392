// import { Configuration } from "@/swagger-models/presets-loader-client"; TODO: what is that???
import {
  ClustersApi,
  DepartmentsApi,
  InternalApi,
  OrganizationalApi,
  ProjectsApi,
  WandbApi,
  Configuration,
  type ConfigurationParameters,
} from "@/swagger-models/backend-client";

class BackendApi {
  configuration: Configuration;

  clustersApi: ClustersApi;
  wandbApi: WandbApi;
  projectsApi: ProjectsApi;
  organizationalApi: OrganizationalApi;
  internalApi: InternalApi;
  departmentsApi: DepartmentsApi;

  constructor() {
    this.configuration = new Configuration();

    this.clustersApi = new ClustersApi(this.configuration, this.baseUrl);
    this.wandbApi = new WandbApi(this.configuration, this.baseUrl);
    this.projectsApi = new ProjectsApi(this.configuration, this.baseUrl);
    this.organizationalApi = new OrganizationalApi(this.configuration, this.baseUrl);
    this.internalApi = new InternalApi(this.configuration, this.baseUrl);
    this.departmentsApi = new DepartmentsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const backendApi = new BackendApi();
