import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { IProject } from "@/models/project.model";
import type { IFilterBy } from "@/models/filter.model";
import { K8S_API } from "@/common/api.constant";
import { pick } from "@/utils/common.util";
/**
 * @deprecated This service is deprecated and will be removed in future versions. use org unit service instead
 */
export const projectService = {
  list,
};

const getEndpoint = (clusterUuid: string): string => `${K8S_API.v1}/clusters/${clusterUuid}/projects`;

/**
 * @deprecated This function is deprecated and will be removed in future versions. use org unit service instead
 */
async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Array<IProject>> {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  return controlPlaneService.get(getEndpoint(clusterUuid), {
    ...filters,
    excludePermissions: true,
  });
}
