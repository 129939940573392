import type { QTreeNode } from "quasar";
import type { ScopeType } from "@/swagger-models/authorization-client";

export enum EOrgTreeNodeIcons {
  Tenant = "fa-regular fa-sitemap",
  Cluster = "fa-regular fa-circle-nodes",
  Department = "fa-regular fa-warehouse-full",
  Project = "fa-regular fa-suitcase",
}
export interface IOrgTreeNode extends QTreeNode {
  id: string;
  path: string;
  type: ScopeType;
  children: IOrgTreeNode[];
}

export interface IOrgTreeNodeId {
  path?: string;
  id: string;
  type: ScopeType;
}
