<template>
  <highcharts :options="scatterChartOptions" ref="chart" key="scatter-chart" />
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { Chart as Highcharts } from "highcharts-vue";
//highcharts
import type { Options as HighchartsOptions, Point, SeriesScatterOptions } from "highcharts";
//util
import { chartUtil } from "@/utils/chart.util";
//model
import { chartColorPaletteArray, TOOLTIP_BASE_COLOR, TOOLTIP_TEXT_COLOR } from "@/models/chart.model";
import { ENodeTelemetryGroupBy } from "@/models/cluster.model";
//service
import type {
  TelemetryResponseValuesInner,
  TelemetryResponseValuesInnerGroupsInner,
} from "@/swagger-models/cluster-service-client";

interface INodePoolSummary {
  totalFreeGpus: number;
  totalNodes: number;
}
interface IScatterSeriesPointCoordinates {
  x: number;
  y: number;
}
interface IScatterSeriesOptions extends SeriesScatterOptions {
  name: string;
  data: IScatterSeriesPointCoordinates[];
  meta: INodePoolSummary;
}
interface InodePoolSummaryRecord {
  [key: string]: INodePoolSummary;
}

const SMALL_MARKER_RADIUS = 2;
const MEDIUM_MARKER_RADIUS = 7;
const LARGE_MARKER_RADIUS = 10;
export default defineComponent({
  name: "node-pool-free-gpu-chart",
  components: { Highcharts },
  emits: ["scatter-point-click"],
  props: {
    nodePoolFreeGpusTelemetry: {
      type: Array as PropType<TelemetryResponseValuesInner[]>,
      default: () => [],
    },
  },
  data() {
    return {
      scatterChartOptions: {} as HighchartsOptions,
      nodePoolNames: [] as string[],
      chartData: [] as IScatterSeriesOptions[],
    };
  },
  created() {
    this.nodePoolNames = this.getNodePoolNames(this.nodePoolFreeGpusTelemetry);
    this.loadScatterChartData();
    this.loadScatterChartOptions();
  },
  methods: {
    getMarkerRadius(): number {
      const nodesAmount = this.nodePoolFreeGpusTelemetry.length;
      if (nodesAmount < 50) {
        return LARGE_MARKER_RADIUS;
      }
      if (nodesAmount < 100) {
        return MEDIUM_MARKER_RADIUS;
      }
      return SMALL_MARKER_RADIUS;
    },
    loadScatterChartOptions(): void {
      const onPointClick = (nodePoolName: string, nodePoolColor: string): void => {
        this.$emit("scatter-point-click", nodePoolName, nodePoolColor);
      };

      const markerRadius = this.getMarkerRadius();
      const basicOptions: HighchartsOptions = chartUtil.getBasicWidgetChartOptions({
        yAxisTitle: "Free GPU devices",
        type: "scatter",
        height: 215,
        sharedTooltip: true,
      });
      basicOptions.yAxis = { ...basicOptions.yAxis, tickInterval: 8, max: 16 };
      this.scatterChartOptions = {
        ...basicOptions,
        plotOptions: {
          scatter: {
            opacity: 0.8,
            jitter: {
              x: 0.5,
              y: 0,
            },
            marker: {
              radius: markerRadius,
              symbol: "marker",
              lineColor: "white",
              lineWidth: 1,
            },
            point: {
              events: {
                click: function (this: Point) {
                  //@ts-ignore
                  onPointClick(this.series.name, this.series.color);
                },
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: TOOLTIP_BASE_COLOR,
          style: {
            color: TOOLTIP_TEXT_COLOR,
            fontSize: "12px",
          },
          borderColor: TOOLTIP_BASE_COLOR,
          shape: "square",
          headerFormat: "",
          pointFormatter: function () {
            const totalNodes = (this.series.options as IScatterSeriesOptions).meta.totalNodes || 0;
            const nodePoolName = this.series.name;
            const freeGpus = this.y || 0;
            const nodePoolTooltip = chartUtil.formatTooltipValue("Node pool", this.color, nodePoolName, "");
            const totalNodesTooltip = chartUtil.formatTooltipValue("Nodes", "", totalNodes, "");
            const freeGpusTooltip = chartUtil.formatTooltipValue("Free GPUs devices", "", freeGpus, "");

            return `${nodePoolTooltip}${totalNodesTooltip}${freeGpusTooltip}`;
          },
        },
        xAxis: {
          visible: false,
        },
        series: this.chartData as IScatterSeriesOptions[],
        colors: chartColorPaletteArray,
      };
    },
    loadScatterChartData(): void {
      const chartData: IScatterSeriesOptions[] = [];
      const nodePoolSummary: InodePoolSummaryRecord = {};

      this.nodePoolFreeGpusTelemetry.forEach((telemetryValue: TelemetryResponseValuesInner) => {
        const nodePoolName = this.extractNodePoolNameFromTelemetry(telemetryValue);
        const freeGpus = parseFloat(telemetryValue.value);

        if (nodePoolName) {
          this.updateNodePoolSummary(nodePoolName, freeGpus, nodePoolSummary);
          this.updateChartData(chartData, nodePoolName, freeGpus, nodePoolSummary);
        }
      });
      this.chartData = chartData;
    },
    extractNodePoolNameFromTelemetry(telemetryValue: TelemetryResponseValuesInner): string | undefined {
      return telemetryValue.groups?.find(
        (group: TelemetryResponseValuesInnerGroupsInner) => group.key === ENodeTelemetryGroupBy.Nodepool,
      )?.value;
    },
    updateNodePoolSummary(nodePoolName: string, freeGpus: number, nodePoolSummary: InodePoolSummaryRecord): void {
      nodePoolSummary[nodePoolName] = {
        totalFreeGpus: (nodePoolSummary[nodePoolName]?.totalFreeGpus || 0) + freeGpus,
        totalNodes: (nodePoolSummary[nodePoolName]?.totalNodes || 0) + 1,
      };
    },
    getNodePoolNames(data: TelemetryResponseValuesInner[]): string[] {
      const nodePoolSet = new Set<string>();
      data.forEach((item: TelemetryResponseValuesInner) => {
        const nodepool = (item.groups || []).find(
          (group: TelemetryResponseValuesInnerGroupsInner) => group.key === ENodeTelemetryGroupBy.Nodepool,
        )?.value;
        if (nodepool) {
          nodePoolSet.add(nodepool);
        }
      });
      return Array.from(nodePoolSet);
    },
    updateChartData(
      chartData: IScatterSeriesOptions[],
      nodePoolName: string,
      freeGpus: number,
      nodePoolSummary: InodePoolSummaryRecord,
    ): void {
      const nodePoolIndex: number = chartData.findIndex((series: IScatterSeriesOptions) => series.name === nodePoolName);
      const bubbleIndex = this.nodePoolNames.findIndex((name) => name === nodePoolName);

      const newDataPoint: IScatterSeriesPointCoordinates = {
        y: freeGpus,
        x: bubbleIndex,
      };

      const meta: INodePoolSummary = {
        totalFreeGpus: nodePoolSummary[nodePoolName].totalFreeGpus,
        totalNodes: nodePoolSummary[nodePoolName].totalNodes,
      };

      if (nodePoolIndex === -1) {
        chartData.push({
          name: nodePoolName,
          data: [newDataPoint],
          meta,
          type: "scatter",
        });
      } else {
        chartData[nodePoolIndex].data.push(newDataPoint);
        chartData[nodePoolIndex].meta = meta;
      }
    },
  },
});
</script>
