import { ResourceType, Action } from "@/swagger-models/authorization-client";

import SettingsIndex from "@/views/settings/settings-index.vue";
import type { RunaiRouteRecord } from "vue-router";
import { SETTINGS_ROUTE_NAMES } from "@/router/settings.routes/settings.routes.names";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";
import { useAppStore } from "@/stores/app.store";

export const settingsRoutes: RunaiRouteRecord = {
  path: "/general-settings",
  component: () => import("@/views/settings/settings-app.vue"),
  children: [
    {
      path: "",
      name: SETTINGS_ROUTE_NAMES.SETTINGS_INDEX,
      component: SettingsIndex,
      meta: {
        pageTitle: "General settings",
        resourceType: ResourceType.Settings,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.ADMIN,
      },
      beforeEnter: (to, from, next) => {
        const appStore = useAppStore();
        if (appStore.isNewNavigationFeatureOn) {
          to.meta.fullWidth = false;
          to.meta.supportBack = false;
        } else {
          to.meta.fullWidth = true;
          to.meta.supportBack = true;
        }

        next();
      },
    },
  ],
};
