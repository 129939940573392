// models
import { MappersKeys, type IIdpMapperItem, type ISettingEmailServer } from "@/models/setting.model";
import type {
  IdpCreationRequest,
  Mappers,
  OcpCreationData,
  OidcCreationData,
  SamlCreationData,
} from "@/swagger-models/identity-manager-client";
import { IdpTypeEnum } from "@/swagger-models/identity-manager-client";
import type { QNotifyCreateOptions } from "quasar/dist/types";

// utils
import { alertUtil } from "@/utils/alert.util";

// store
import { useAuthStore } from "@/stores/auth.store";
import type { Setting } from "@/swagger-models/backend-client";
import type { SmtpConfig } from "@/models/notifications.model";
import { EAuthType, ENotificationType } from "@/models/notifications.model";
export const settingsUtil = {
  getIdpMappersItems,
  isIdpMappersEqual,
  getMappersFromIdpMapperItems,
  createIdpCreationRequest,
  getSettingErrorMessage,
  getToggleSectionCollapsedTitle,
  emailSettingsToSmtpConfig,
  smtpConfigToEmailSettings,
};

function getIdpMappersItems(mappers: Mappers): Record<string, IIdpMapperItem> {
  return {
    uid: {
      key: MappersKeys.Uid,
      label: "Linux user ID",
      value: mappers?.uid || "",
    },
    gid: {
      key: MappersKeys.Gid,
      label: "Linux group ID",
      value: mappers?.gid || "",
    },
    groups: {
      key: MappersKeys.Groups,
      label: "User role groups",
      value: mappers?.groups || "",
    },
    supplementaryGroups: {
      key: MappersKeys.SupplementaryGroups,
      label: "SUPPLEMENTARYGROUPS",
      value: mappers?.supplementaryGroups || "",
    },
    email: {
      key: MappersKeys.Email,
      label: "Email",
      value: mappers?.email || "",
    },
  };
}

function isIdpMappersEqual(mappers: Mappers, items: Record<string, IIdpMapperItem>): boolean {
  return (
    mappers.uid === items[MappersKeys.Uid]?.value &&
    mappers.gid === items[MappersKeys.Gid]?.value &&
    mappers.groups === items[MappersKeys.Groups]?.value &&
    mappers.supplementaryGroups === items[MappersKeys.SupplementaryGroups]?.value &&
    mappers.email === items[MappersKeys.Email]?.value
  );
}

function getMappersFromIdpMapperItems(items: Record<string, IIdpMapperItem>): Mappers {
  return {
    uid: items[MappersKeys.Uid].value,
    gid: items[MappersKeys.Gid].value,
    groups: items[MappersKeys.Groups].value,
    supplementaryGroups: items[MappersKeys.SupplementaryGroups].value,
    email: items[MappersKeys.Email].value,
  };
}

function createIdpCreationRequest(
  alias: string,
  idpNewFields: OidcCreationData | SamlCreationData | OcpCreationData,
  mappers: Mappers,
): IdpCreationRequest | null {
  switch (alias) {
    case IdpTypeEnum.Oidc:
      return {
        type: alias,
        oidcData: idpNewFields as OidcCreationData,
        mappers: mappers,
      };

    case IdpTypeEnum.Saml:
      return {
        type: alias,
        samlData: idpNewFields as SamlCreationData,
        mappers: mappers,
      };

    case IdpTypeEnum.OpenshiftV4:
      return {
        type: alias,
        ocpData: idpNewFields as OcpCreationData,
        mappers: mappers,
      };

    default:
      return null;
  }
}

function getSettingErrorMessage(): QNotifyCreateOptions {
  const email = useAuthStore().currentUser.username;
  const message = `Settings couldn't be saved. Try again. If the issue persists, <a href="https://runai.my.salesforce-sites.com/casesupport/CreateCaseForm?email=${email}" target="_blank" style="color: white; text-decoration: underline">contact Run:ai support.</a>`;
  return alertUtil.getError(message, { html: true });
}

function getToggleSectionCollapsedTitle(settings: object): string {
  let enabled = 0;
  let disabled = 0;
  Object.values(settings)
    .filter((value) => typeof value?.value === "boolean")
    .forEach((value: Setting) => {
      if (!value) return;
      if (value.value) {
        enabled++;
      } else {
        disabled++;
      }
    });
  return `Enabled (${enabled}) / Disabled (${disabled})`;
}

function emailSettingsToSmtpConfig(emailSettings: ISettingEmailServer): SmtpConfig {
  return {
    type: ENotificationType.Email,
    config: {
      user: emailSettings.username,
      password: emailSettings.password,
      smtp_tls_enabled: true,
      smtp_host: emailSettings.smtpHost,
      smtp_port: emailSettings.smtpPort,
      auth_type: EAuthType.AuthPlain,
      from_display_name: emailSettings.fromName,
      from: emailSettings.fromEmail,
      direct_notifications: true,
      subject: "{{ .title }}",
    },
  };
}

function smtpConfigToEmailSettings(smtpConfig: SmtpConfig): ISettingEmailServer {
  return {
    smtpHost: smtpConfig.config?.smtp_host,
    smtpPort: smtpConfig.config?.smtp_port,
    username: smtpConfig.config?.user,
    password: smtpConfig.config?.password,
    fromEmail: smtpConfig.config?.from,
    fromName: smtpConfig.config?.from_display_name,
    authType: smtpConfig.config?.auth_type,
  };
}
