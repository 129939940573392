import type {
  ModelAsset,
  ModelInferenceCreationRequest,
  ModelInference,
  ModelInferenceCreationRequestV2,
  ModelInferenceV2,
} from "@/swagger-models/assets-service-client";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";
import { httpService } from "@/services/infra/https.service/http.service";

import { workloadService } from "@/services/cluster/workload.service/workload.service";
import type { IWorkloadCreate, IWorkloadResponse } from "@/models/workload.model";

import type { AxiosResponse } from "axios";

export const modelAssetService = {
  list,
  getById,
  createModelAssetInference,
  createModelAssetInferenceV2,
};

async function list(): Promise<Array<ModelAsset>> {
  try {
    const response: AxiosResponse = await assetsServiceApi.modelApi.listModelAssets();
    return response.data.entries;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getById(modelId: string): Promise<ModelAsset> {
  try {
    const response: AxiosResponse = await assetsServiceApi.modelApi.getModelAssetById(modelId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createModelAssetInferenceV2(
  modelInferenceCreation: ModelInferenceCreationRequestV2,
): Promise<ModelInferenceV2> {
  try {
    const response: AxiosResponse = await assetsServiceApi.modelInferenceApi.createModelInferenceV2(
      modelInferenceCreation,
    );

    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createModelAssetInference(
  modelInferenceCreation: ModelInferenceCreationRequest,
): Promise<ModelInference> {
  try {
    const response: AxiosResponse = await assetsServiceApi.modelInferenceApi.createModelInference(
      modelInferenceCreation,
    );

    const modelInference: ModelInference = response.data;
    await _createWorkload(modelInference.workload as IWorkloadCreate);
    return modelInference;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function _createWorkload(workloadCreate: IWorkloadCreate): Promise<IWorkloadResponse> {
  return workloadService.createWorkload("InferenceWorkload", workloadCreate.metadata, {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  });
}
