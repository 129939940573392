<template>
  <section class="row items-center copy-command-box q-pa-md">
    <div class="col-11" :class="{ 'code-with-colors': useColors }">
      <pre v-if="codeWithHtml" :style="codeBlockStyle" v-html="codeWithHtml" />
      <pre v-else :style="codeBlockStyle" v-text="code" />
    </div>
    <div class="col flex justify-end">
      <runai-copy-to-clipboard-btn :tooltip-anchor="tooltipAnchor" :text-to-copy="code" />
    </div>
  </section>
</template>

<script lang="ts">
import { RunaiCopyToClipboardBtn } from "@/components/common/runai-copy-to-clipboard-btn";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "runai-copyable-code-block",
  components: { RunaiCopyToClipboardBtn },
  props: {
    code: {
      type: String,
      required: true,
    },
    codeWithHtml: {
      type: String,
      required: false,
    },
    maxHeight: {
      type: Number,
      required: false,
    },
    useColors: {
      type: Boolean,
      default: false,
    },
    tooltipAnchor: {
      type: String as PropType<
        | "top left"
        | "top middle"
        | "top right"
        | "top start"
        | "top end"
        | "center left"
        | "center middle"
        | "center right"
        | "center start"
        | "center end"
        | "bottom left"
        | "bottom middle"
        | "bottom right"
        | "bottom start"
        | "bottom end"
      >,
      required: false,
    },
  },
  computed: {
    codeBlockStyle(): object {
      return {
        "max-height": this.maxHeight ? this.maxHeight + "px" : "unset",
        "overflow-y": "auto",
      };
    },
  },
});
</script>

<style scoped lang="scss">
.copy-command-box {
  background: $body-background-color;
}
pre {
  white-space: pre-line;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}
</style>
<style lang="scss">
.code-with-colors {
  .yaml-item {
    color: rgba(0, 0, 0, 0.54);
  }

  .yaml-colon {
    color: rgba(0, 0, 0, 0.54);
  }

  .yaml-key {
    color: rgb(63, 110, 198);
  }

  .yaml-value {
    color: rgb(28, 125, 77);
  }
}
</style>
