<template>
  <runai-expansion-item
    class="runai-name-section"
    :label="sectionLabel"
    default-opened
    :section-invalid="sectionInvalid"
  >
    <template #subheader>
      <span>{{ summary }}</span>
    </template>
    <section class="row">
      <div class="col-8">
        <runai-name-validation
          ref="nameValidationCmp"
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
          :rules="rules"
          @invalid="nameAlreadyExist = $event"
          debounce="300"
          :aid="`${assetKind}-name-input`"
          :auto-focus="autoFocus"
        />
      </div>
      <q-input
        class="q-mt-lg col-12"
        v-if="supportDescription"
        :model-value="description"
        @update:model-value="updateDescription"
        :maxlength="250"
        counter
        placeholder="Description"
        autogrow
      />
    </section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiNameValidation } from "@/components/common/runai-name-validation";
import { isAssetNameUnique, isEmpty, isNotEmpty, isValidRunaiEntityNameFormat } from "@/common/form.validators";
import { Scope, type AssetKind } from "@/swagger-models/assets-service-client";
import type { ValidationRule } from "quasar";
import { errorMessages } from "@/common/error-message.constant";
import type { IScopeModel } from "@/models/global.model";
import type { IAssetNameValidationOptions } from "@/models/asset.model";

export default defineComponent({
  name: "runai-name-section",
  components: {
    RunaiExpansionItem,
    RunaiNameValidation,
  },
  emits: ["update:modelValue", "is-section-invalid", "update-description"],
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    scopeModel: {
      type: Object as PropType<IScopeModel>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: true,
    },
    assetKind: {
      type: String as PropType<AssetKind>,
      required: true,
    },
    supportDescription: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    description: {
      type: [String, null] as PropType<string | null>,
    },
    // If the asset is being edited, we shouldn't check if the name is the same as the original name
    originalName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      nameAlreadyExist: false as boolean,
      isNameUnique: {} as ValidationRule,
      autoFocus: false as boolean,
    };
  },
  created() {
    this.autoFocus = !!this.$route.query.fromCopyId;
  },
  computed: {
    sectionLabel(): string {
      let label = `${this.label} name`;
      if (this.supportDescription) label += ` & description`;
      return label;
    },
    rules(): ValidationRule[] {
      if (!this.scopeModel.scope || this.originalName === this.modelValue) return [this.isEmpty, this.isValidFormat];
      return [this.isEmpty, this.isValidFormat, this.isNameUnique];
    },
    sectionInvalid(): boolean {
      return isEmpty(this.modelValue) || !isValidRunaiEntityNameFormat(this.modelValue) || this.nameAlreadyExist;
    },
    summary(): string {
      return this.modelValue || "None";
    },
    assetNameOptions(): IAssetNameValidationOptions {
      if (!this.scopeModel.scope || this.scopeModel.scope === Scope.Tenant)
        return { scopeType: Scope.Tenant } as IAssetNameValidationOptions;
      return {
        scopeType: this.scopeModel.scope,
        scopeId: this.scopeId,
      };
    },
    scopeId(): string {
      switch (this.scopeModel.scope) {
        case Scope.Department:
          return String(this.scopeModel.departmentId);
        case Scope.Project:
          return String(this.scopeModel.projectId);
        case Scope.Cluster:
          return String(this.scopeModel.clusterId);
        default:
          return "";
      }
    },
  },
  methods: {
    isEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.NAME_NOT_EMPTY;
    },
    isValidFormat(val: string): boolean | string {
      return isValidRunaiEntityNameFormat(val) || errorMessages.VALID_FORMAT;
    },
    updateAssetNameValidation(): void {
      this.isNameUnique = isAssetNameUnique(this.assetKind, this.assetNameOptions);
    },
    updateDescription(val: string | number | null): void {
      this.$emit("update-description", val?.toString() || null);
    },
  },
  watch: {
    sectionInvalid: {
      handler(newVal: boolean): void {
        this.$emit("is-section-invalid", newVal);
      },
      immediate: true,
    },
    assetNameOptions: {
      handler(): void {
        this.updateAssetNameValidation();
      },
      immediate: true,
    },
  },
});
</script>
