import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { API } from "@/common/api.constant";
import type { EnableSmtpConfig, SmtpConfig, VerificationObject } from "@/models/notifications.model";
import type { NotificationSettings, RouteConfig } from "@/models/user-setting.model";
import { uniqueListenerRunaiId, uniqueNotificationRunaiId } from "@/models/user-setting.model";

export const notificationService = {
  getSmtpConfig,
  updateSmtpConfig,
  enableEmailNotificationsForTenant,
  isSmtpEnabledForTenant,
  verifySmtpConfig,
  deleteSmtpConfig,
  disableEmailNotificationsForTenant,
  getUserNotificationCategories,
  getUserNotificationCategoriesState,
  updateUserNotificationCategoriesState,
};

const notificationsEndpoint = `${API.v1}/notifications/config`;
const enableSmtpEndpoint = (tenantId: string): string =>
  `${notificationsEndpoint}/${tenantId}/route?route_id=${tenantId}`;
const verifySmtpEndpoint = `${notificationsEndpoint}/sinks/${uniqueNotificationRunaiId}/validate`;
const getSmtpConfigurationEndpoint = `${notificationsEndpoint}/sinks`;
const createSmtpConfigurationEndpoint = `${getSmtpConfigurationEndpoint}/${uniqueNotificationRunaiId}`;
const userNotificationCategoried = `${notificationsEndpoint}/notifications`;
const userNotificationCategoriedState = (tenantId: string, userId: string): string =>
  `${notificationsEndpoint}/${tenantId}/user/${userId}/route`;
const isEnableSmtpEndpoint = (tenantId: string): string => `${notificationsEndpoint}/notifications/${tenantId}/enabled`;

async function getSmtpConfig(): Promise<SmtpConfig[]> {
  return await controlPlaneService.get(getSmtpConfigurationEndpoint);
}

async function updateSmtpConfig(smtpConfig: SmtpConfig): Promise<void> {
  return await controlPlaneService.post(createSmtpConfigurationEndpoint, smtpConfig);
}

async function deleteSmtpConfig(): Promise<void> {
  return await controlPlaneService.delete(createSmtpConfigurationEndpoint);
}

async function verifySmtpConfig(smtpConfig: SmtpConfig): Promise<VerificationObject> {
  return await controlPlaneService.post(verifySmtpEndpoint, smtpConfig);
}

async function isSmtpEnabledForTenant(tenantId: string): Promise<boolean> {
  const isEnabled: { enabled: boolean } = await controlPlaneService.get(isEnableSmtpEndpoint(tenantId));
  return isEnabled.enabled;
}

async function enableEmailNotificationsForTenant(tenantId: string): Promise<void> {
  return await controlPlaneService.post(enableSmtpEndpoint(tenantId), {
    route_config: {
      listener_id: uniqueListenerRunaiId,
      notify_ids: [uniqueNotificationRunaiId],
      filtering: {
        policy: "drop",
      },
    },
    route_id: tenantId,
    tenant_id: tenantId,
  } as EnableSmtpConfig);
}

async function disableEmailNotificationsForTenant(tenantId: string): Promise<void> {
  return await controlPlaneService.delete(enableSmtpEndpoint(tenantId));
}

async function getUserNotificationCategories(): Promise<Array<NotificationSettings>> {
  return await controlPlaneService.get(userNotificationCategoried);
}

async function getUserNotificationCategoriesState(tenantId: string, userId: string): Promise<Array<RouteConfig>> {
  return await controlPlaneService.get(userNotificationCategoriedState(tenantId, userId));
}

async function updateUserNotificationCategoriesState(
  tenantId: string,
  userId: string,
  routeConfig: RouteConfig,
): Promise<void> {
  return await controlPlaneService.post(userNotificationCategoriedState(tenantId, userId), routeConfig);
}
