<template>
  <section class="training-new column items-center q-pt-md">
    <runai-form-wrapper :form-state="training">
      <q-form ref="trainingForm">
        <section class="create-training-form">
          <runai-expansion-wrapper>
            <cluster-selection-section
              entity-name="training"
              :cluster-id="selectedClusterId || ''"
              @update:cluster-id="updateClusterId"
              cluster-connectivity-required
            />
            <transition name="fade">
              <project-section
                v-if="selectedClusterId && isClusterSectionValid"
                aid="project-section"
                :loading="loadingProject"
                :selected-project-id="selectedProjectId"
                :projects="projectsList"
                :cluster-id="selectedClusterId"
                @project-changed="onSelectedProject"
              />
            </transition>

            <transition-group name="fade">
              <template v-if="selectedProjectId && selectedProjectId !== -1">
                <multi-node-section
                  v-if="isClusterVersionSupportDistributedTraining"
                  :is-selectable="true"
                  :multi-node-section-model="selectedDistributedDetails"
                  @update-multi-node-section-model="updateMultiNodeSection"
                  :cluster-id="selectedClusterId"
                />
                <template-section
                  aid="template-section"
                  :templates="templates"
                  :loading="loadingTemplate"
                  @template-changed="onSelectedTemplate"
                  :template-id="selectedTemplateId"
                />
                <workload-name-section
                  entity-type="training"
                  aid="training-name-section"
                  v-model:name="trainingName"
                  :disable-closing="true"
                  :project-id="selectedProjectId"
                  :cluster-id="selectedClusterId"
                />
              </template>
            </transition-group>
          </runai-expansion-wrapper>
          <section class="row items-center q-mt-md">
            <q-field
              class="col-4 form-hint no-padding"
              :model-value="displayFormHint"
              :rules="[isFormIncomplete]"
            ></q-field>
            <div class="buttons q-ml-auto">
              <q-btn flat class="q-mr-sm" color="primary" label="Cancel" aid="cancel-btn" @click="onCancel" />
              <q-btn
                label="continue"
                aid="create-training-continue-btn"
                @click="continueToEdit"
                :loading="submitting"
                color="primary"
              ></q-btn>
            </div>
          </section>
        </section>
      </q-form>
    </runai-form-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// store
import { useTrainingStore } from "@/stores/training.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkloadTemplateStore } from "@/stores/workload-template.store";
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { urlService } from "@/services/url.service/url.service";
import { trainingService } from "@/services/control-plane/training.service/training.service";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";

// cmps
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { ProjectSection } from "@/components/section/project-section";
import { TemplateSection } from "@/components/section/template-section";
import { MultiNodeSection } from "@/components/section/multi-node-section";
import { ClusterSelectionSection } from "@/components/cluster/cluster-selection/cluster-selection-section";

// models
import { type WorkloadTemplate, type PVCAsset, UidGidSource } from "@/swagger-models/assets-service-client";
import type { IUIDistributed, IUIWorkloadCreation } from "@/models/workload.model";
import type { IAssetsFilter } from "@/models/filter.model";
import type { Project, ProjectMetadata } from "@/swagger-models/org-unit-service-client";

// constant
import { errorMessages } from "@/common/error-message.constant";
// common
import { required } from "@/common/form.validators";

// route
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { dataSourceUtil } from "@/utils/data-source.util";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { deepCopy } from "@/utils/common.util";
import { MIN_CLUSTER_VERSION_FOR_COMPLETIONS_AND_PARALLELISM } from "@/common/version.constant";

export default defineComponent({
  components: {
    RunaiExpansionWrapper,
    RunaiFormWrapper,
    ProjectSection,
    WorkloadNameSection,
    TemplateSection,
    MultiNodeSection,
    ClusterSelectionSection,
  },
  data() {
    return {
      trainingStore: useTrainingStore(),
      workloadTemplateStore: useWorkloadTemplateStore(),
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      environmentStore: useEnvironmentStore(),
      authStore: useAuthStore(),
      loadingProject: false as boolean,
      loadingTemplate: false as boolean,
      trainingForm: null as HTMLFormElement | null,
      loadedProjects: [] as Array<ProjectMetadata>,
      training: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,

      submitting: false as boolean,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      isClusterSectionValid: false as boolean,

      selectedProjectId: null as number | null,
      selectedTemplateId: null as string | null,
      selectedClusterId: "" as string,
      selectedDistributedDetails: null as IUIDistributed | null,
      trainingName: "" as string,
    };
  },
  async created() {
    this.appStore.setPageLoading(false);
  },
  async mounted() {
    this.trainingForm = this.$refs["trainingForm"] as HTMLFormElement;
    const createdEntityId: string | undefined = this.$route.query.createdEntityId?.toString();
    const savedProjectId = this.trainingStore.training.projectId || -1;

    const selectedDefaultClusterId = this.getDefaultClusterId();
    if (selectedDefaultClusterId) {
      await this.updateClusterId(selectedDefaultClusterId);
    }

    const selectedDefaultProjectId = this.getDefaultProjectId(savedProjectId, createdEntityId);
    if (selectedDefaultProjectId) {
      await this.onSelectedProject(selectedDefaultProjectId);
    }
  },
  computed: {
    projectsList(): Array<ProjectMetadata> {
      return this.loadedProjects;
    },
    templates(): Array<WorkloadTemplate> {
      return this.workloadTemplateStore.workloadTemplateList;
    },
    isClusterVersionSupportDistributedTraining(): boolean {
      return this.clusterStore.isVersionSupportDistributedTraining(this.selectedClusterId);
    },
    templatesFilter(): IAssetsFilter {
      const templatesFilter: IAssetsFilter = {
        projectId: this.selectedProjectId,
        isTraining: true,
      };
      if (this.selectedDistributedDetails) {
        templatesFilter.isDistributed = true;
        if (this.selectedDistributedDetails?.distFramework)
          templatesFilter.distributedFramework = this.selectedDistributedDetails.distFramework;
      }
      return templatesFilter;
    },
  },
  methods: {
    getDefaultClusterId(): string {
      let selectedDefaultClusterId = "";
      if (this.clusterStore.clusterList.length === 1) {
        selectedDefaultClusterId = this.clusterStore.clusterList[0].uuid;
      } else if (this.$route.query.clusterId) {
        selectedDefaultClusterId = this.$route.query.clusterId.toString();
      } else if (this.trainingStore.training.clusterId) {
        selectedDefaultClusterId = this.trainingStore.training.clusterId;
      }

      return selectedDefaultClusterId;
    },
    getDefaultProjectId(savedProjectId: number, createdEntityId?: string): number | null {
      let selectedDefaultProjectId = null;
      if (createdEntityId && this.projectsList.length > 1) {
        selectedDefaultProjectId = Number(createdEntityId);
      } else if (savedProjectId !== -1) {
        selectedDefaultProjectId = savedProjectId;
      }

      return selectedDefaultProjectId;
    },
    async loadProjects(): Promise<void> {
      try {
        this.loadingProject = true;
        this.selectedTemplateId = null;

        this.loadedProjects = await orgUnitService.getProjectsMetadata(this.selectedClusterId);

        if (this.projectsList.length === 1) {
          await this.onSelectedProject(+this.projectsList[0].id);
        } else if (this.trainingStore.training.projectId !== -1) {
          await this.onSelectedProject(this.trainingStore.training.projectId);
        }
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load projects"));
        console.error(error);
        this.appStore.setFallback(true);
      } finally {
        this.loadingProject = false;
      }
    },
    async loadTemplates(): Promise<void> {
      this.loadingTemplate = true;
      await this.workloadTemplateStore.loadWorkloadTemplates(this.templatesFilter);
      if (this.templates.length === 0) {
        this.selectedTemplateId = "-1";
      } else if (
        this.trainingStore.templateId &&
        (this.trainingStore.templateId === "-1" ||
          this.templates.find((template) => template.meta.id === this.trainingStore.templateId))
      ) {
        this.selectedTemplateId = this.trainingStore.templateId;
      }

      this.loadingTemplate = false;
    },
    async onSelectedProject(projectId: number | null): Promise<void> {
      try {
        this.selectedProjectId = projectId || -1;
        this.selectedTemplateId = null;
        if (!projectId) return;
        await this.loadTemplates();
      } catch (error: unknown) {
        console.error(error);
      }
    },
    async loadTrainingModel(projectId: string, templateId?: string | null, isDistributed?: boolean): Promise<void> {
      const tempId = templateId === "-1" ? undefined : templateId;
      const trainingModel = await trainingService.getTrainingMergedWithPolicy(
        projectId,
        undefined,
        tempId || undefined,
        isDistributed,
      );
      this.trainingStore.setLoadedTrainingModelWithDefaults(deepCopy(trainingModel));
      this.training = workloadUtil.convertWorkloadToWorkloadUI({
        ...trainingModel,
        meta: {
          ...trainingModel.meta,
          name: this.trainingName,
          clusterId: this.selectedClusterId,
          projectId: this.selectedProjectId?.toString() || "",
        },
      });

      this.setFormDefaultsIfNeeded();
      this.trainingStore.setTraining(this.training);
    },
    onSelectedTemplate(templateId: string | null): void {
      this.selectedTemplateId = templateId;
      this.trainingStore.setTemplate(templateId);
    },
    updateMultiNodeSection(distributed: IUIDistributed): void {
      this.selectedDistributedDetails = distributed || null;
      this.selectedTemplateId = null;
      this.loadTemplates();
    },
    redirectToPrevRoute(): void {
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    async continueToEdit(): Promise<void> {
      this.displayFormHint = false;
      this.submitting = true;
      const success = await this.validate();
      if (!success) {
        this.submitting = false;
        this.showHint();
        return;
      }

      if (!this.selectedProjectId) {
        console.error("ProjectId is missing");
        this.$q.notify(alertUtil.getError("Failed to create training"));
        this.submitting = false;
        return;
      }

      await this.loadTrainingModel(
        this.selectedProjectId.toString(),
        this.selectedTemplateId,
        !!this.selectedDistributedDetails,
      );

      await this.setSpecificUserGidUidIfNeeded();

      const template: WorkloadTemplate | undefined = this.templates.find(
        (template) => template.meta.id === this.selectedTemplateId,
      );
      if (template?.spec.assets.workloadVolumes?.length) {
        const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(template.spec.assets.workloadVolumes);
        this.training.assets.uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
      }

      this.trainingStore.setTraining(this.training);

      const routeName = this.training.distributed
        ? TRAINING_ROUTE_NAMES.DISTRIBUTED_ASSETS_EDIT
        : TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT;

      await this.$router.push({ name: routeName });
    },
    validate(): Promise<boolean> {
      return (this.trainingForm as HTMLFormElement).validate();
    },
    isRequiredTemplate(val: string): boolean | string {
      return required(val) || errorMessages.SELECT_TEMPLATE;
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
    findProject(projectId: string): ProjectMetadata | undefined {
      return this.loadedProjects.find((project: ProjectMetadata) => project.id === projectId);
    },
    async loadProjectById(projectId: string): Promise<Project | void> {
      try {
        const loadedProject = await orgUnitService.getProject(projectId);
        return loadedProject;
      } catch (error: unknown) {
        console.error(error);
        this.appStore.setFallback(true);
      }
    },
    async setSpecificUserGidUidIfNeeded(): Promise<void> {
      if (this.training.assets.environment) {
        const relevantEnvironment = await this.environmentStore.loadById(this.training.assets.environment);
        if (relevantEnvironment?.spec.uidGidSource === UidGidSource.FromIdpToken) {
          this.training.specificEnv.runAsUid = this.authStore.getUID || null;
          this.training.specificEnv.runAsGid = this.authStore.getGID || null;
          this.training.specificEnv.supplementalGroups = this.authStore.getSupplementaryGroups;
        }
      }
    },
    async updateClusterId(clusterId: string): Promise<void> {
      this.isClusterSectionValid = false;
      this.displayFormHint = false;
      this.selectedClusterId = clusterId;
      this.selectedProjectId = -1;
      urlService.updateQueryParams({ clusterId, createdEntityId: null });

      this.$nextTick(async () => {
        this.isClusterSectionValid = await this.validate();
        if (this.isClusterSectionValid) {
          await this.loadProjects();
        }
      });
    },
    // Setting form defaults for specific fields as defined by product
    setFormDefaultsIfNeeded(): void {
      const supportRunsAndParallelism = this.clusterStore.isClusterVersionSufficient(
        this.training.clusterId,
        MIN_CLUSTER_VERSION_FOR_COMPLETIONS_AND_PARALLELISM,
      );

      if (supportRunsAndParallelism && !this.training.specificEnv?.completions && !this.training.distributed) {
        this.training.specificEnv.completions = 1;
        this.training.specificEnv.parallelism = null;
      }

      if (this.training.distributed) {
        this.training.distributed.distFramework = this.selectedDistributedDetails?.distFramework;
        this.training.distributed.numWorkers ||= 1;
        this.training.distributed.noMaster = this.selectedDistributedDetails?.noMaster;
      }
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
