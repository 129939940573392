// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { storageUtil } from "@/utils/storage.util";
import { dateUtil } from "@/utils/date.util";

// models
import type {
  WorkloadCreationRequest,
  Workspace,
  WorkspaceCreationRequestV2,
  WorkspaceV2,
} from "@/swagger-models/assets-service-client";
import type { WorkspacePolicyV2 } from "@/swagger-models/policy-service-client";
import type { IWorkloadCreate, IWorkloadResponse } from "@/models/workload.model";
import type { ILocalStatus } from "@/models/global.model";

// constants
import { K8S_API } from "@/common/api.constant";
import { API } from "@/common/api.constant";
import { policyService } from "../policy.service/policy.service";
import { ScopeType } from "@/swagger-models/authorization-client";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";
import { httpService } from "@/services/infra/https.service/http.service";
import { workloadServiceApi } from "@/services/infra/client-apis/workloads-service-api/workloads-service-api";
import type { HttpResponse } from "@/swagger-models/workloads-service-client";

const WORKSPACE_STATUS = "workspaceStatus";

export const workspaceService = {
  createFromAssets,
  removeV2,
  resumeV2,
  stopV2,
  getById,
  stop,
  gpuUtilizationMetric,
  gpuMemoryUsageMetric,
  cpuMemoryUsageMetric,
  cpuUsageMetric,
  getPolicy,
  createWorkspaceV2,
  getWorkspaceMergedWithPolicy,
};

async function createWorkspaceV2(wsCreationRequest: WorkspaceCreationRequestV2): Promise<WorkspaceV2> {
  try {
    const response = await assetsServiceApi.workspaceApi.createWorkspaceV2(wsCreationRequest);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

const endpoint = `${API.v1}/workspace`;
function workspacesEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/workspaces`;
}

async function removeV2(workspaceId: string): Promise<void> {
  try {
    const response = await workloadServiceApi.workspacesApi.deleteWorkspace(workspaceId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
async function resumeV2(workspaceId: string): Promise<HttpResponse> {
  try {
    const response = await workloadServiceApi.workspacesApi.resumeWorkspace(workspaceId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
async function stopV2(workspaceId: string): Promise<HttpResponse> {
  try {
    const response = await workloadServiceApi.workspacesApi.suspendWorkspace(workspaceId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getById(workspaceId: string): Promise<WorkspaceV2> {
  try {
    const response = await assetsServiceApi.workspaceApi.getWorkspaceByIdV2(workspaceId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getWorkspaceMergedWithPolicy(
  projectId?: string,
  workloadId?: string,
  templateId?: string,
): Promise<WorkspaceV2> {
  try {
    const response = await assetsServiceApi.workspaceApi.getWorkspaceMerged(projectId, workloadId, templateId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createFromAssets(workspace: WorkloadCreationRequest): Promise<Workspace> {
  const createdWorkspace: Workspace = await controlPlaneService.post(endpoint, workspace);
  try {
    const workloadCreate: IWorkloadCreate = createdWorkspace.workload as IWorkloadCreate;
    await createWorkload(workloadCreate);
  } catch (e) {
    // submit failure status
    await workloadService.handleFailedWorkloadClusterCreation(createdWorkspace.meta.id, e);
    await _submitWorkspaceCreationStatus(workspace.clusterId, createdWorkspace.meta.id, false);
    throw e;
  }
  // submit success status
  await _submitWorkspaceCreationStatus(workspace.clusterId, createdWorkspace.meta.id, true);
  return createdWorkspace;
}

async function getPolicy(projectId: number): Promise<WorkspacePolicyV2> {
  return policyService.getWorkspacePolicyByScope({ scope: ScopeType.Project, projectId: Number(projectId) });
}

async function _submitWorkspaceCreationStatus(clusterUid: string, workspaceId: string, status: boolean): Promise<void> {
  await controlPlaneService.put(`${workspacesEndpoint(clusterUid)}/${workspaceId}/submission`, { success: status });
}

async function createWorkload(workloadCreate: IWorkloadCreate): Promise<IWorkloadResponse> {
  return workloadService.createWorkload("InteractiveWorkload", workloadCreate.metadata, {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  });
}

async function gpuUtilizationMetric(podUUID: string, start: number, end: number, step = 100) {
  const query = `avg((runai_gpu_utilization_per_workload{job_uuid="${podUUID}"})) or (avg(runai_utilization_full_gpu_jobs{pod_group_uuid="${podUUID}", node_name!=""}) or avg(runai_utilization_shared_gpu_jobs{pod_group_uuid="${podUUID}"}))`;
  return prometheusService.rangeQuery(query, start, end, step);
}

async function cpuUsageMetric(podUUID: string, start: number, end: number, step = 100) {
  const query = `runai_job_cpu_usage{pod_group_uuid="${podUUID}"}`;
  return prometheusService.rangeQuery(query, start, end, step);
}

async function gpuMemoryUsageMetric(podUUID: string, start: number, end: number, step = 100) {
  const query = `sum(runai_gpu_memory_used_mebibytes_per_workload{job_uuid="${podUUID}"} * 1024 * 1024) or sum(runai_pod_group_used_gpu_memory{pod_group_uuid="${podUUID}"} * 1024 * 1024)`;
  return prometheusService.rangeQuery(query, start, end, step);
}

async function cpuMemoryUsageMetric(podUUID: string, start: number, end: number, step = 5) {
  const query = `runai_job_memory_used_bytes{pod_group_uuid="${podUUID}"}`;
  return prometheusService.rangeQuery(query, start, end, step);
}

function _cleanupStatus() {
  const currentStatus: Record<string, ILocalStatus> = _getWorkspaceStatus();
  if (!currentStatus || Object.keys(currentStatus).length === 0) return;
  Object.keys(currentStatus).forEach((key: string) => {
    if (_shouldCleanLocalStatus(currentStatus[key])) {
      delete currentStatus[key];
    }
  });
  _saveWorkspaceStatus(currentStatus);
}

// this should be called only once in a while,
// because it's a heavy operation and not needed to be called on every status update
_cleanupStatus();

function _shouldCleanLocalStatus(workspaceToCheck: ILocalStatus): boolean {
  return dateUtil.moreThanHourFromNow(workspaceToCheck.creationTimestamp);
}

function _getWorkspaceStatus(): Record<string, ILocalStatus> {
  return storageUtil.get<Record<string, ILocalStatus>>(WORKSPACE_STATUS);
}

function _saveWorkspaceStatus(workspaceStatus: Record<string, ILocalStatus>): void {
  storageUtil.save<Record<string, ILocalStatus>>(WORKSPACE_STATUS, workspaceStatus);
}
