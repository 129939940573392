<template>
  <component :is="component" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
//cmps
import { OverviewIndex as OverviewIndexV2 } from "@/views/dashboard-v2/overview/index";
import OverviewIndex from "@/views/dashboard/overview-index.vue";
import { storageUtil } from "@/utils/storage.util";
import { IS_OVERVIEW_V2_MODE_ON } from "@/common/storage.constant";
import { usePermissionStore } from "@/stores/permissions.store";
import { useAppStore } from "@/stores/app.store";
//util

export default defineComponent({
  name: "overview-app",
  data() {
    return {
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
    };
  },
  created() {
    this.initIsOverviewV2ModeOn();
  },
  computed: {
    isOverviewV2ModeOn(): boolean {
      return this.appStore.isOverviewV2ModeOn;
    },
    component(): typeof OverviewIndex | typeof OverviewIndexV2 {
      return this.isOverviewV2ModeOn ? OverviewIndexV2 : OverviewIndex;
    },
    hasOverviewV2ModePermission(): boolean {
      return this.permissionStore.hasOverviewV2ModePermission();
    },
  },
  methods: {
    initIsOverviewV2ModeOn(): void {
      if (this.hasOverviewV2ModePermission) {
        const storedValue = storageUtil.get(IS_OVERVIEW_V2_MODE_ON);

        // Set the default value to true if it's the first time
        this.appStore.isOverviewV2ModeOn = storedValue === null ? true : !!storedValue;
      } else {
        this.appStore.isOverviewV2ModeOn = false;
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
