import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

export const appService = {
  getAppVersion,
};

async function getAppVersion(): Promise<string> {
  let appVersion = "";
  try {
    const { version } = await controlPlaneService.get("_version");
    appVersion = version;
  } catch (error: unknown) {
    console.error("could not load app version");
  }
  return appVersion;
}
