<template>
  <section class="runai-code-block">
    <code v-html="codeHtml" ref="code"></code>
    <q-btn class="copy-btn" flat round icon="fa-regular fa-copy" size="sm" color="grey-8" @click="copy" />
  </section>
</template>

<script lang="ts">
import { alertUtil } from "@/utils/alert.util";
import { copyToClipboard } from "quasar";
import { type PropType, defineComponent } from "vue";

export default defineComponent({
  name: "runai-code-block",
  props: {
    codeHtml: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    async copy(): Promise<void> {
      const text: string = (this.$refs["code"] as HTMLElement).innerText;
      await copyToClipboard(text);
      this.$q.notify(alertUtil.getInfo("Copied to clipboard"));
    },
  },
});
</script>

<style lang="scss">
.runai-code-block {
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-block: 10px;
  position: relative;
  background-color: #f3f3f3;
  padding: 12px 18px;
  .copy-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .yaml-code {
    line-height: 1.4;
    font-size: 15px;
    font-family: "Roboto Mono", SFMono-Regular, Consolas, Menlo, monospace;
    white-space: pre;
    margin: 0;
    overflow: scroll;
  }

  .yaml-item {
    color: rgba(0, 0, 0, 0.54);
  }

  .yaml-colon {
    color: rgba(0, 0, 0, 0.54);
  }

  .yaml-key {
    color: rgb(63, 110, 198);
  }

  .yaml-value {
    color: rgb(28, 125, 77);
  }
}
</style>
