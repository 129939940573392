/* tslint:disable */
/* eslint-disable */
/**
 * ClusterService
 * An API for managing RunAi cluster objects (nodes).
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * The ancestors of an org unit.
 * @export
 * @interface Ancestors
 */
export interface Ancestors {
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'tenant_id': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Ancestors
     */
    'cluster_uuid'?: string | null;
    /**
     * The id of the department.
     * @type {string}
     * @memberof Ancestors
     */
    'department_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'project_id'?: string | null;
}
/**
 * Cluster configuration status
 * @export
 * @interface ClusterConfigStatus
 */
export interface ClusterConfigStatus {
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof ClusterConfigStatus
     */
    'actualConfig'?: ClusterUserConfigData;
    /**
     * The state of the configuration. The possible values are: Updating - The control plane got a request to update the cluster configuration and did not receive a status update yet from the cluster. Applying - The configuration has been stored on the cluster, but is not yet applied. Applied - The configuration has been successfully applied in the cluster. Failed - The cluster has failed to apply the configuration. 
     * @type {string}
     * @memberof ClusterConfigStatus
     */
    'state'?: ClusterConfigStatusStateEnum;
    /**
     * A message describing the state of the configuration.
     * @type {string}
     * @memberof ClusterConfigStatus
     */
    'stateMessage'?: string;
}

export const ClusterConfigStatusStateEnum = {
    Updating: 'updating',
    Applying: 'applying',
    Applied: 'applied',
    Failed: 'failed'
} as const;

export type ClusterConfigStatusStateEnum = typeof ClusterConfigStatusStateEnum[keyof typeof ClusterConfigStatusStateEnum];

/**
 * The cluster\'s configuration for connecting to the control plane.
 * @export
 * @interface ClusterControlPlaneConfig
 */
export interface ClusterControlPlaneConfig {
    /**
     * 
     * @type {ClusterControlPlaneConfigAgent}
     * @memberof ClusterControlPlaneConfig
     */
    'agent'?: ClusterControlPlaneConfigAgent;
    /**
     * 
     * @type {ClusterControlPlaneConfigWorkloads}
     * @memberof ClusterControlPlaneConfig
     */
    'workloads'?: ClusterControlPlaneConfigWorkloads;
    /**
     * 
     * @type {ClusterControlPlaneConfigMetricsRemoteWrite}
     * @memberof ClusterControlPlaneConfig
     */
    'metricsRemoteWrite'?: ClusterControlPlaneConfigMetricsRemoteWrite;
    /**
     * Additional remote write configurations.
     * @type {Array<ClusterControlPlaneConfigMetricsRemoteWrite>}
     * @memberof ClusterControlPlaneConfig
     */
    'metricsRemoteWrites'?: Array<ClusterControlPlaneConfigMetricsRemoteWrite>;
    /**
     * 
     * @type {ClusterControlPlaneConfigResearcherService}
     * @memberof ClusterControlPlaneConfig
     */
    'researcherService'?: ClusterControlPlaneConfigResearcherService;
    /**
     * The cluster\'s name.
     * @type {string}
     * @memberof ClusterControlPlaneConfig
     */
    'clusterName'?: string;
    /**
     * the tenant\'s identifier
     * @type {number}
     * @memberof ClusterControlPlaneConfig
     */
    'tenantId'?: number;
    /**
     * the name of the release
     * @type {string}
     * @memberof ClusterControlPlaneConfig
     */
    'releaseName'?: string;
    /**
     * test/staging/production/self-hosted
     * @type {string}
     * @memberof ClusterControlPlaneConfig
     */
    'environment'?: string;
    /**
     * The cluster\'s docker config json.
     * @type {string}
     * @memberof ClusterControlPlaneConfig
     */
    'dockerConfigJson'?: string;
}
/**
 * The cluster\'s agent configuration for connecting to the control plane.
 * @export
 * @interface ClusterControlPlaneConfigAgent
 */
export interface ClusterControlPlaneConfigAgent {
    /**
     * 
     * @type {ClusterControlPlaneConfigAgentClient}
     * @memberof ClusterControlPlaneConfigAgent
     */
    'client'?: ClusterControlPlaneConfigAgentClient;
}
/**
 * Client configuration for connecting to the control plane.
 * @export
 * @interface ClusterControlPlaneConfigAgentClient
 */
export interface ClusterControlPlaneConfigAgentClient {
    /**
     * Client id.
     * @type {string}
     * @memberof ClusterControlPlaneConfigAgentClient
     */
    'id'?: string;
    /**
     * Client name.
     * @type {string}
     * @memberof ClusterControlPlaneConfigAgentClient
     */
    'name'?: string;
    /**
     * Client secret.
     * @type {string}
     * @memberof ClusterControlPlaneConfigAgentClient
     */
    'secret'?: string;
}
/**
 * The cluster\'s metrics remote write configuration.
 * @export
 * @interface ClusterControlPlaneConfigMetricsRemoteWrite
 */
export interface ClusterControlPlaneConfigMetricsRemoteWrite {
    /**
     * Remote write label.
     * @type {string}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWrite
     */
    'label'?: string;
    /**
     * Remote write url.
     * @type {string}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWrite
     */
    'url'?: string;
    /**
     * Remote write org ID. Deprecated, use headers instead.
     * @type {string}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWrite
     * @deprecated
     */
    'orgID'?: string;
    /**
     * Remote write headers.
     * @type {{ [key: string]: string; }}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWrite
     */
    'headers'?: { [key: string]: string; };
    /**
     * 
     * @type {ClusterControlPlaneConfigMetricsRemoteWriteCredentials}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWrite
     */
    'credentials'?: ClusterControlPlaneConfigMetricsRemoteWriteCredentials;
}
/**
 * Remote write credentials.
 * @export
 * @interface ClusterControlPlaneConfigMetricsRemoteWriteCredentials
 */
export interface ClusterControlPlaneConfigMetricsRemoteWriteCredentials {
    /**
     * Remote write username.
     * @type {string}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWriteCredentials
     */
    'username'?: string;
    /**
     * Remote write password.
     * @type {string}
     * @memberof ClusterControlPlaneConfigMetricsRemoteWriteCredentials
     */
    'password'?: string;
}
/**
 * The cluster\'s researcher service configuration related to the control plane.
 * @export
 * @interface ClusterControlPlaneConfigResearcherService
 */
export interface ClusterControlPlaneConfigResearcherService {
    /**
     * URL to the endpoint that returns JWT validation keys
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherService
     */
    'jwksURL'?: string;
    /**
     * Logout link. DEPRECARED from 2.9
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherService
     */
    'logoutLink'?: string;
    /**
     * 
     * @type {ClusterControlPlaneConfigResearcherServiceOauthProxy}
     * @memberof ClusterControlPlaneConfigResearcherService
     */
    'oauthProxy'?: ClusterControlPlaneConfigResearcherServiceOauthProxy;
}
/**
 * OAuth Proxy configuration. DEPRECARED from 2.18
 * @export
 * @interface ClusterControlPlaneConfigResearcherServiceOauthProxy
 */
export interface ClusterControlPlaneConfigResearcherServiceOauthProxy {
    /**
     * OAuth proxy oidc issuer DN.
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherServiceOauthProxy
     */
    'oidcIssuerDN'?: string;
    /**
     * OAuth proxy oidc issuer url.
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherServiceOauthProxy
     */
    'oidcIssuerUrl'?: string;
    /**
     * OAuth proxy client id.
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherServiceOauthProxy
     */
    'clientId'?: string;
    /**
     * OAuth proxy client secret.
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherServiceOauthProxy
     */
    'clientSecret'?: string;
    /**
     * OAuth proxy provider type.
     * @type {string}
     * @memberof ClusterControlPlaneConfigResearcherServiceOauthProxy
     */
    'providerType'?: string;
}
/**
 * The cluster\'s agent configuration for connecting to researcher\'s workspaces (notebook, visual studio, etc).
 * @export
 * @interface ClusterControlPlaneConfigWorkloads
 */
export interface ClusterControlPlaneConfigWorkloads {
    /**
     * 
     * @type {ClusterControlPlaneConfigWorkloadsClient}
     * @memberof ClusterControlPlaneConfigWorkloads
     */
    'client'?: ClusterControlPlaneConfigWorkloadsClient;
}
/**
 * Client configuration for connecting to researcher\'s workspaces.
 * @export
 * @interface ClusterControlPlaneConfigWorkloadsClient
 */
export interface ClusterControlPlaneConfigWorkloadsClient {
    /**
     * Client id.
     * @type {string}
     * @memberof ClusterControlPlaneConfigWorkloadsClient
     */
    'id'?: string;
    /**
     * Client name.
     * @type {string}
     * @memberof ClusterControlPlaneConfigWorkloadsClient
     */
    'name'?: string;
    /**
     * Client secret.
     * @type {string}
     * @memberof ClusterControlPlaneConfigWorkloadsClient
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface ClusterCreationRequest
 */
export interface ClusterCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'version'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClusterCreationRequest
     */
    'tenantId'?: number;
}
/**
 * 
 * @export
 * @interface ClusterDependenciesStatus
 */
export interface ClusterDependenciesStatus {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterDependencyStatus
 */
export interface ClusterDependencyStatus {
    /**
     * whether the dependency is available
     * @type {boolean}
     * @memberof ClusterDependencyStatus
     */
    'available': boolean;
    /**
     * the reason for the dependency status
     * @type {string}
     * @memberof ClusterDependencyStatus
     */
    'reason'?: string;
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependencyStatus
     */
    'components'?: { [key: string]: ClusterDependencyStatus; };
}
/**
 * The cluster\'s displayed status.
 * @export
 * @interface ClusterDisplayedStatus
 */
export interface ClusterDisplayedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterDisplayedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterDisplayedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterReportedStatusPlatform}
     * @memberof ClusterDisplayedStatus
     */
    'platform'?: ClusterReportedStatusPlatform | null;
    /**
     * 
     * @type {ClusterReportedConfig}
     * @memberof ClusterDisplayedStatus
     */
    'config'?: ClusterReportedConfig | null;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterDisplayedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterDisplayedStatus
     */
    'state'?: ClusterDisplayedStatusStateEnum;
}

export const ClusterDisplayedStatusStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterDisplayedStatusStateEnum = typeof ClusterDisplayedStatusStateEnum[keyof typeof ClusterDisplayedStatusStateEnum];

/**
 * 
 * @export
 * @interface ClusterDisplayedStatusAllOf
 */
export interface ClusterDisplayedStatusAllOf {
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterDisplayedStatusAllOf
     */
    'state'?: ClusterDisplayedStatusAllOfStateEnum;
}

export const ClusterDisplayedStatusAllOfStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterDisplayedStatusAllOfStateEnum = typeof ClusterDisplayedStatusAllOfStateEnum[keyof typeof ClusterDisplayedStatusAllOfStateEnum];

/**
 * Internal cluster configuration
 * @export
 * @interface ClusterFullConfig
 */
export interface ClusterFullConfig {
    /**
     * 
     * @type {string}
     * @memberof ClusterFullConfig
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterFullConfig
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterFullConfig
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterFullConfig
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof ClusterFullConfig
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterFullConfig
     */
    'clusterId'?: string;
    /**
     * 
     * @type {ClusterFullConfigData}
     * @memberof ClusterFullConfig
     */
    'config'?: ClusterFullConfigData;
}
/**
 * 
 * @export
 * @interface ClusterFullConfigAllOf
 */
export interface ClusterFullConfigAllOf {
    /**
     * 
     * @type {string}
     * @memberof ClusterFullConfigAllOf
     */
    'clusterId'?: string;
    /**
     * 
     * @type {ClusterFullConfigData}
     * @memberof ClusterFullConfigAllOf
     */
    'config'?: ClusterFullConfigData;
}
/**
 * Internal cluster configuration
 * @export
 * @interface ClusterFullConfigData
 */
export interface ClusterFullConfigData {
    /**
     * 
     * @type {ClusterSystemConfigData}
     * @memberof ClusterFullConfigData
     */
    'systemConfig'?: ClusterSystemConfigData;
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof ClusterFullConfigData
     */
    'userConfig'?: ClusterUserConfigData;
}
/**
 * 
 * @export
 * @interface ClusterFullConfigDataAllOf
 */
export interface ClusterFullConfigDataAllOf {
    /**
     * 
     * @type {ClusterSystemConfigData}
     * @memberof ClusterFullConfigDataAllOf
     */
    'systemConfig'?: ClusterSystemConfigData;
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof ClusterFullConfigDataAllOf
     */
    'userConfig'?: ClusterUserConfigData;
}
/**
 * 
 * @export
 * @interface ClusterHistory
 */
export interface ClusterHistory {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ClusterHistory
     */
    'cluster_id': string;
    /**
     * the cluster version
     * @type {string}
     * @memberof ClusterHistory
     */
    'version': string;
    /**
     * the cluster version update timestamp in unix format
     * @type {number}
     * @memberof ClusterHistory
     */
    'update_timestamp': number;
}
/**
 * 
 * @export
 * @interface ClusterInfoSyncRequest
 */
export interface ClusterInfoSyncRequest {
    /**
     * the cluster domain
     * @type {string}
     * @memberof ClusterInfoSyncRequest
     */
    'domain': string;
    /**
     * the cluster version
     * @type {string}
     * @memberof ClusterInfoSyncRequest
     */
    'version': string;
    /**
     * 
     * @type {ClusterInfoSyncRequestDependencies}
     * @memberof ClusterInfoSyncRequest
     * @deprecated
     */
    'dependencies'?: ClusterInfoSyncRequestDependencies | null;
    /**
     * 
     * @type {ClusterInfoSyncRequestStatus}
     * @memberof ClusterInfoSyncRequest
     */
    'status'?: ClusterInfoSyncRequestStatus | null;
}
/**
 * Deprecated. Use status.dependencies instead.
 * @export
 * @interface ClusterInfoSyncRequestDependencies
 */
export interface ClusterInfoSyncRequestDependencies {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterInfoSyncRequestDependencies
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterInfoSyncRequestDependencies
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterInfoSyncRequestStatus
 */
export interface ClusterInfoSyncRequestStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterReportedStatusPlatform}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'platform'?: ClusterReportedStatusPlatform | null;
    /**
     * 
     * @type {ClusterReportedConfig}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'config'?: ClusterReportedConfig | null;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * 
 * @export
 * @interface ClusterInstallationInfoResponse
 */
export interface ClusterInstallationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'installationStr': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'repositoryName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'chartRepoURL': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @interface ClusterInstallationResponse
 */
export interface ClusterInstallationResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ClusterLivenessSyncRequest
 */
export interface ClusterLivenessSyncRequest {
    /**
     * 
     * @type {number}
     * @memberof ClusterLivenessSyncRequest
     */
    'timeTag': number;
}
/**
 * 
 * @export
 * @interface ClusterNotification
 */
export interface ClusterNotification {
    /**
     * 
     * @type {NotificationOperation}
     * @memberof ClusterNotification
     */
    'operation': NotificationOperation;
    /**
     * 
     * @type {string}
     * @memberof ClusterNotification
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterNotification
     */
    'name'?: string;
}
/**
 * Holds cluster configuration values.
 * @export
 * @interface ClusterReportedConfig
 */
export interface ClusterReportedConfig {
    /**
     * indicates if the cluster workload ownership protection is enabled
     * @type {boolean}
     * @memberof ClusterReportedConfig
     */
    'workloadOwnershipProtection'?: boolean | null;
    /**
     * indicates if the cluster subdomain is enabled
     * @type {boolean}
     * @memberof ClusterReportedConfig
     */
    'subdomainEnabled'?: boolean | null;
}
/**
 * the cluster status reported by the cluster
 * @export
 * @interface ClusterReportedStatus
 */
export interface ClusterReportedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterReportedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterReportedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterReportedStatusPlatform}
     * @memberof ClusterReportedStatus
     */
    'platform'?: ClusterReportedStatusPlatform | null;
    /**
     * 
     * @type {ClusterReportedConfig}
     * @memberof ClusterReportedStatus
     */
    'config'?: ClusterReportedConfig | null;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterReportedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * Condition contains details for one aspect of the current state of this API Resource
 * @export
 * @interface ClusterReportedStatusConditionsInner
 */
export interface ClusterReportedStatusConditionsInner {
    /**
     * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'lastTransitionTime': string;
    /**
     * message is a human readable message indicating details about the transition. This may be an empty string.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'message': string;
    /**
     * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
     * @type {number}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'observedGeneration'?: number;
    /**
     * reason contains a programmatic identifier indicating the reason for the condition\'s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'reason': string;
    /**
     * status of the condition, one of True, False, Unknown.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'status': ClusterReportedStatusConditionsInnerStatusEnum;
    /**
     * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'type': string;
}

export const ClusterReportedStatusConditionsInnerStatusEnum = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;

export type ClusterReportedStatusConditionsInnerStatusEnum = typeof ClusterReportedStatusConditionsInnerStatusEnum[keyof typeof ClusterReportedStatusConditionsInnerStatusEnum];

/**
 * Status specifies the status of an operand. And operand manages resources, some of which are not immediately available and need their status monitored, such as Deployments, Daemonsets, Ingressess etc. Some resources are immediately available and should not be monitored once accepted by the cluster, such as Services, ConfigMaps, Secrets etc. If all resources of an operand are ready, a Status with Ready set to \'true\' and a nil \'Reasons\' slice should be returned, otherwise, a Status with \'Reasons\' slice containig the reason why the Operand is not ready (Deployment pods are not ready for example.)
 * @export
 * @interface ClusterReportedStatusOperandsValue
 */
export interface ClusterReportedStatusOperandsValue {
    /**
     * LastTransitionTime specifies the last time the operand readiness changed
     * @type {string}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'lastTransitionTime'?: string;
    /**
     * Ready specifies if the operand is ready or not
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'ready': boolean;
    /**
     * Reasons specifies the reasons why the operand is not ready
     * @type {Array<string>}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'reasons'?: Array<string>;
    /**
     * UnreadyThresholdCrossed specifies if the operand has been unready for longer than the threshold
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'unreadyThresholdCrossed'?: boolean;
}
/**
 * 
 * @export
 * @interface ClusterReportedStatusPlatform
 */
export interface ClusterReportedStatusPlatform {
    /**
     * the cluster platform type
     * @type {string}
     * @memberof ClusterReportedStatusPlatform
     */
    'type'?: ClusterReportedStatusPlatformTypeEnum;
    /**
     * the cluster kubernetes version
     * @type {string}
     * @memberof ClusterReportedStatusPlatform
     */
    'kubeVersion'?: string | null;
}

export const ClusterReportedStatusPlatformTypeEnum = {
    Vanilla: 'vanilla',
    Openshift: 'openshift',
    Rke: 'rke',
    Gke: 'gke',
    Aks: 'aks',
    Eks: 'eks'
} as const;

export type ClusterReportedStatusPlatformTypeEnum = typeof ClusterReportedStatusPlatformTypeEnum[keyof typeof ClusterReportedStatusPlatformTypeEnum];

/**
 * Cluster configuration data - can be updated by the system only
 * @export
 * @interface ClusterSystemConfigData
 */
export interface ClusterSystemConfigData {
    /**
     * Whether the cluster is deactivated.
     * @type {boolean}
     * @memberof ClusterSystemConfigData
     */
    'deactivated': boolean;
}
/**
 * 
 * @export
 * @interface ClusterUpdateRequest
 */
export interface ClusterUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ClusterUrlRequest
 */
export interface ClusterUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterUrlRequest
     */
    'targetHost': string;
}
/**
 * 
 * @export
 * @interface ClusterUrlResponse
 */
export interface ClusterUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterUrlResponse
     */
    'url': string;
}
/**
 * Cluster configuration
 * @export
 * @interface ClusterUserConfig
 */
export interface ClusterUserConfig {
    /**
     * 
     * @type {string}
     * @memberof ClusterUserConfig
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterUserConfig
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterUserConfig
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterUserConfig
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof ClusterUserConfig
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterUserConfig
     */
    'clusterId'?: string;
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof ClusterUserConfig
     */
    'config'?: ClusterUserConfigData;
}
/**
 * 
 * @export
 * @interface ClusterUserConfigAllOf
 */
export interface ClusterUserConfigAllOf {
    /**
     * 
     * @type {string}
     * @memberof ClusterUserConfigAllOf
     */
    'clusterId'?: string;
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof ClusterUserConfigAllOf
     */
    'config'?: ClusterUserConfigData;
}
/**
 * Cluster configuration data - can be updated by the user
 * @export
 * @interface ClusterUserConfigData
 */
export interface ClusterUserConfigData {
}
/**
 * Cluster configuration data for partial update - can be updated by the user
 * @export
 * @interface ClusterUserConfigDataPartialUpdate
 */
export interface ClusterUserConfigDataPartialUpdate {
}
/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
     * @type {string}
     * @memberof Condition
     */
    'lastTransitionTime': string;
    /**
     * message is a human readable message indicating details about the transition. This may be an empty string.
     * @type {string}
     * @memberof Condition
     */
    'message': string;
    /**
     * reason contains a programmatic identifier indicating the reason for the condition\'s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
     * @type {string}
     * @memberof Condition
     */
    'reason': string;
    /**
     * status of the condition, one of True, False, Unknown.
     * @type {string}
     * @memberof Condition
     */
    'status': ConditionStatusEnum;
    /**
     * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important.
     * @type {string}
     * @memberof Condition
     */
    'type': string;
}

export const ConditionStatusEnum = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;

export type ConditionStatusEnum = typeof ConditionStatusEnum[keyof typeof ConditionStatusEnum];

/**
 * 
 * @export
 * @interface CountNodepools200Response
 */
export interface CountNodepools200Response {
    /**
     * 
     * @type {number}
     * @memberof CountNodepools200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DisplayedCluster
 */
export interface DisplayedCluster {
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'uuid': string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof DisplayedCluster
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'domain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'version'?: string | null;
    /**
     * 
     * @type {ClusterDisplayedStatus}
     * @memberof DisplayedCluster
     */
    'status'?: ClusterDisplayedStatus | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'lastLiveness'?: string | null;
    /**
     * the timestamp value of when the cluster deletion request was received
     * @type {string}
     * @memberof DisplayedCluster
     */
    'deleteRequestedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface GetClusterName200Response
 */
export interface GetClusterName200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClusterName200Response
     */
    'name': string;
}
/**
 * GET Cluster Internal Configuration Response
 * @export
 * @interface GetInternalClusterConfigResponse
 */
export interface GetInternalClusterConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof GetInternalClusterConfigResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetInternalClusterConfigResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInternalClusterConfigResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInternalClusterConfigResponse
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof GetInternalClusterConfigResponse
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetInternalClusterConfigResponse
     */
    'clusterId'?: string;
    /**
     * 
     * @type {ClusterFullConfigData}
     * @memberof GetInternalClusterConfigResponse
     */
    'config': ClusterFullConfigData;
}
/**
 * 
 * @export
 * @interface GetNodepools200Response
 */
export interface GetNodepools200Response {
    /**
     * 
     * @type {Array<Nodepool>}
     * @memberof GetNodepools200Response
     */
    'nodepools'?: Array<Nodepool>;
}
/**
 * GET/PUT/PATCH Cluster Configuration Response
 * @export
 * @interface GetPutPatchClusterConfigResponse
 */
export interface GetPutPatchClusterConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'clusterId'?: string;
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'config': ClusterUserConfigData;
    /**
     * 
     * @type {ClusterConfigStatus}
     * @memberof GetPutPatchClusterConfigResponse
     */
    'status'?: ClusterConfigStatus;
}
/**
 * 
 * @export
 * @interface GetPutPatchClusterConfigResponseAllOf
 */
export interface GetPutPatchClusterConfigResponseAllOf {
    /**
     * 
     * @type {ClusterConfigStatus}
     * @memberof GetPutPatchClusterConfigResponseAllOf
     */
    'status'?: ClusterConfigStatus;
}
/**
 * 
 * @export
 * @interface GpuInfo
 */
export interface GpuInfo {
    /**
     * 
     * @type {string}
     * @memberof GpuInfo
     */
    'gpuType': string;
    /**
     * 
     * @type {number}
     * @memberof GpuInfo
     */
    'gpuCount': number;
}
/**
 * 
 * @export
 * @interface HttpResponse
 */
export interface HttpResponse {
    /**
     * 
     * @type {number}
     * @memberof HttpResponse
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof HttpResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface MeasurementResponse
 */
export interface MeasurementResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof MeasurementResponse
     */
    'type': string;
    /**
     * labels of the metric measurement
     * @type {{ [key: string]: string; }}
     * @memberof MeasurementResponse
     */
    'labels'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<MeasurementResponseValuesInner>}
     * @memberof MeasurementResponse
     */
    'values': Array<MeasurementResponseValuesInner> | null;
}
/**
 * 
 * @export
 * @interface MeasurementResponseValuesInner
 */
export interface MeasurementResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'timestamp': string | null;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'deletedAt'?: string | null;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof Metadata
     */
    'tenantId'?: number;
}
/**
 * 
 * @export
 * @interface MetricsResponse
 */
export interface MetricsResponse {
    /**
     * 
     * @type {Array<MeasurementResponse>}
     * @memberof MetricsResponse
     */
    'measurements': Array<MeasurementResponse>;
}
/**
 * specifies what data to request
 * @export
 * @enum {string}
 */

export const MetricsType = {
    TotalGpuNodes: 'TOTAL_GPU_NODES',
    GpuUtilization: 'GPU_UTILIZATION',
    GpuUtilizationDistribution: 'GPU_UTILIZATION_DISTRIBUTION',
    GpuMemoryUtilization: 'GPU_MEMORY_UTILIZATION',
    CpuUtilization: 'CPU_UTILIZATION',
    CpuMemoryUtilization: 'CPU_MEMORY_UTILIZATION',
    TotalGpu: 'TOTAL_GPU',
    GpuQuota: 'GPU_QUOTA',
    AllocatedGpu: 'ALLOCATED_GPU',
    UnallocatedGpu: 'UNALLOCATED_GPU',
    AvgWorkloadWaitTime: 'AVG_WORKLOAD_WAIT_TIME'
} as const;

export type MetricsType = typeof MetricsType[keyof typeof MetricsType];


/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * The calculated status of the node.
     * @type {string}
     * @memberof Node
     */
    'status': NodeStatusEnum;
    /**
     * 
     * @type {Array<NodeStatusConditionDetails>}
     * @memberof Node
     */
    'conditions'?: Array<NodeStatusConditionDetails>;
    /**
     * 
     * @type {Array<NodeTaint>}
     * @memberof Node
     */
    'taints'?: Array<NodeTaint>;
    /**
     * The node\'s NodePool.
     * @type {string}
     * @memberof Node
     */
    'nodePool': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'createdAt': string;
    /**
     * 
     * @type {GpuInfo}
     * @memberof Node
     */
    'gpuInfo'?: GpuInfo | null;
    /**
     * The name of the node
     * @type {string}
     * @memberof Node
     */
    'name': string;
    /**
     * The unique identifier of the node.
     * @type {string}
     * @memberof Node
     */
    'id'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Node
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'updatedAt': string;
}

export const NodeStatusEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type NodeStatusEnum = typeof NodeStatusEnum[keyof typeof NodeStatusEnum];

/**
 * 
 * @export
 * @interface NodeAdditionalFields
 */
export interface NodeAdditionalFields {
    /**
     * The name of the node
     * @type {string}
     * @memberof NodeAdditionalFields
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NodeAdditionalReadFields
 */
export interface NodeAdditionalReadFields {
    /**
     * The unique identifier of the node.
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'id'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NodeForSync
 */
export interface NodeForSync {
    /**
     * The name of the node
     * @type {string}
     * @memberof NodeForSync
     */
    'name': string;
    /**
     * 
     * @type {NodeInfo}
     * @memberof NodeForSync
     */
    'nodeInfo': NodeInfo;
}
/**
 * 
 * @export
 * @interface NodeForSyncAllOf
 */
export interface NodeForSyncAllOf {
    /**
     * 
     * @type {NodeInfo}
     * @memberof NodeForSyncAllOf
     */
    'nodeInfo': NodeInfo;
}
/**
 * 
 * @export
 * @interface NodeInfo
 */
export interface NodeInfo {
    /**
     * The calculated status of the node.
     * @type {string}
     * @memberof NodeInfo
     */
    'status': NodeInfoStatusEnum;
    /**
     * 
     * @type {Array<NodeStatusConditionDetails>}
     * @memberof NodeInfo
     */
    'conditions'?: Array<NodeStatusConditionDetails>;
    /**
     * 
     * @type {Array<NodeTaint>}
     * @memberof NodeInfo
     */
    'taints'?: Array<NodeTaint>;
    /**
     * The node\'s NodePool.
     * @type {string}
     * @memberof NodeInfo
     */
    'nodePool': string;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {GpuInfo}
     * @memberof NodeInfo
     */
    'gpuInfo'?: GpuInfo | null;
}

export const NodeInfoStatusEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type NodeInfoStatusEnum = typeof NodeInfoStatusEnum[keyof typeof NodeInfoStatusEnum];

/**
 * Specify which metric data to request.
 * @export
 * @enum {string}
 */

export const NodeMetricType = {
    GpuUtilizationPerGpu: 'GPU_UTILIZATION_PER_GPU',
    GpuUtilization: 'GPU_UTILIZATION',
    GpuMemoryUtilizationPerGpu: 'GPU_MEMORY_UTILIZATION_PER_GPU',
    GpuMemoryUtilization: 'GPU_MEMORY_UTILIZATION',
    GpuMemoryUsageBytesPerGpu: 'GPU_MEMORY_USAGE_BYTES_PER_GPU',
    GpuMemoryUsageBytes: 'GPU_MEMORY_USAGE_BYTES',
    CpuUsageCores: 'CPU_USAGE_CORES',
    CpuUtilization: 'CPU_UTILIZATION',
    CpuMemoryUsageBytes: 'CPU_MEMORY_USAGE_BYTES',
    CpuMemoryUtilization: 'CPU_MEMORY_UTILIZATION',
    GpuOomkillBurstCountPerGpu: 'GPU_OOMKILL_BURST_COUNT_PER_GPU',
    GpuOomkillIdleCountPerGpu: 'GPU_OOMKILL_IDLE_COUNT_PER_GPU',
    GpuOomkillSwapOutOfRamCountPerGpu: 'GPU_OOMKILL_SWAP_OUT_OF_RAM_COUNT_PER_GPU'
} as const;

export type NodeMetricType = typeof NodeMetricType[keyof typeof NodeMetricType];


/**
 * 
 * @export
 * @interface NodeStatusConditionDetails
 */
export interface NodeStatusConditionDetails {
    /**
     * Type of node condition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'type': string;
    /**
     * (brief) reason for the condition\'s last transition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'reason': string;
    /**
     * Human readable message indicating details about last transition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface NodeTaint
 */
export interface NodeTaint {
    /**
     * The taint key to be applied to a node.
     * @type {string}
     * @memberof NodeTaint
     */
    'key': string;
    /**
     * The taint value corresponding to the taint key.
     * @type {string}
     * @memberof NodeTaint
     */
    'value'?: string;
    /**
     * The effect of the taint on pods that do not tolerate the taint.
     * @type {string}
     * @memberof NodeTaint
     */
    'effect': NodeTaintEffectEnum;
}

export const NodeTaintEffectEnum = {
    NoSchedule: 'NoSchedule',
    PreferNoSchedule: 'PreferNoSchedule',
    NoExecute: 'NoExecute'
} as const;

export type NodeTaintEffectEnum = typeof NodeTaintEffectEnum[keyof typeof NodeTaintEffectEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const NodeTelemetryType = {
    ReadyGpuNodes: 'READY_GPU_NODES',
    ReadyGpus: 'READY_GPUS',
    TotalGpuNodes: 'TOTAL_GPU_NODES',
    TotalGpus: 'TOTAL_GPUS',
    IdleAllocatedGpus: 'IDLE_ALLOCATED_GPUS',
    FreeGpus: 'FREE_GPUS',
    AllocatedGpus: 'ALLOCATED_GPUS',
    TotalCpuCores: 'TOTAL_CPU_CORES',
    UsedCpuCores: 'USED_CPU_CORES',
    AllocatedCpuCores: 'ALLOCATED_CPU_CORES',
    TotalGpuMemoryBytes: 'TOTAL_GPU_MEMORY_BYTES',
    UsedGpuMemoryBytes: 'USED_GPU_MEMORY_BYTES',
    TotalCpuMemoryBytes: 'TOTAL_CPU_MEMORY_BYTES',
    UsedCpuMemoryBytes: 'USED_CPU_MEMORY_BYTES',
    AllocatedCpuMemoryBytes: 'ALLOCATED_CPU_MEMORY_BYTES'
} as const;

export type NodeTelemetryType = typeof NodeTelemetryType[keyof typeof NodeTelemetryType];


/**
 * 
 * @export
 * @interface Nodepool
 */
export interface Nodepool {
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'name': string;
    /**
     * Label key for associated nodes to the Node Pool (with value as in labelValue)
     * @type {string}
     * @memberof Nodepool
     */
    'labelKey': string;
    /**
     * Label value for associated nodes to the Node Pool (with key as in labelKey)
     * @type {string}
     * @memberof Nodepool
     */
    'labelValue': string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'clusterId': string;
    /**
     * 
     * @type {number}
     * @memberof Nodepool
     */
    'overProvisioningRatio'?: number | null;
    /**
     * 
     * @type {NodepoolCreateFieldsPlacementStrategy}
     * @memberof Nodepool
     */
    'placementStrategy'?: NodepoolCreateFieldsPlacementStrategy;
    /**
     * 
     * @type {NodepoolPhase}
     * @memberof Nodepool
     */
    'phase'?: NodepoolPhase;
    /**
     * Message for status of Node Pool
     * @type {string}
     * @memberof Nodepool
     */
    'phaseMessage'?: string;
    /**
     * 
     * @type {NodepoolSyncFieldsStatus}
     * @memberof Nodepool
     */
    'status'?: NodepoolSyncFieldsStatus;
    /**
     * The NodePool id
     * @type {string}
     * @memberof Nodepool
     */
    'id': string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof Nodepool
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'updatedBy': string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'deletedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Nodepool
     */
    'deletedAt'?: string | null;
    /**
     * Is the Node Pool is the cluster default
     * @type {boolean}
     * @memberof Nodepool
     */
    'isDefault': boolean;
}
/**
 * 
 * @export
 * @interface NodepoolCalculatedFields
 */
export interface NodepoolCalculatedFields {
    /**
     * The NodePool id
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'id': string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof NodepoolCalculatedFields
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'updatedBy': string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'deletedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCalculatedFields
     */
    'deletedAt'?: string | null;
    /**
     * Is the Node Pool is the cluster default
     * @type {boolean}
     * @memberof NodepoolCalculatedFields
     */
    'isDefault': boolean;
}
/**
 * 
 * @export
 * @interface NodepoolCreateFields
 */
export interface NodepoolCreateFields {
    /**
     * 
     * @type {string}
     * @memberof NodepoolCreateFields
     */
    'name': string;
    /**
     * Label key for associated nodes to the Node Pool (with value as in labelValue)
     * @type {string}
     * @memberof NodepoolCreateFields
     */
    'labelKey': string;
    /**
     * Label value for associated nodes to the Node Pool (with key as in labelKey)
     * @type {string}
     * @memberof NodepoolCreateFields
     */
    'labelValue': string;
    /**
     * 
     * @type {string}
     * @memberof NodepoolCreateFields
     */
    'clusterId': string;
    /**
     * 
     * @type {number}
     * @memberof NodepoolCreateFields
     */
    'overProvisioningRatio'?: number | null;
    /**
     * 
     * @type {NodepoolCreateFieldsPlacementStrategy}
     * @memberof NodepoolCreateFields
     */
    'placementStrategy'?: NodepoolCreateFieldsPlacementStrategy;
}
/**
 * scheduling strategy per resource
 * @export
 * @interface NodepoolCreateFieldsPlacementStrategy
 */
export interface NodepoolCreateFieldsPlacementStrategy {
    /**
     * 
     * @type {PlacementStrategy}
     * @memberof NodepoolCreateFieldsPlacementStrategy
     */
    'cpu'?: PlacementStrategy | null;
    /**
     * 
     * @type {PlacementStrategy}
     * @memberof NodepoolCreateFieldsPlacementStrategy
     */
    'gpu'?: PlacementStrategy | null;
}
/**
 * Phase of Node Pool
 * @export
 * @enum {string}
 */

export const NodepoolPhase = {
    Creating: 'Creating',
    Updating: 'Updating',
    Deleting: 'Deleting',
    Empty: 'Empty',
    Unschedulable: 'Unschedulable',
    Ready: 'Ready',
    Deleted: 'Deleted'
} as const;

export type NodepoolPhase = typeof NodepoolPhase[keyof typeof NodepoolPhase];


/**
 * 
 * @export
 * @enum {string}
 */

export const NodepoolSortFilterFields = {
    Name: 'name',
    Phase: 'phase',
    ClusterId: 'clusterId',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type NodepoolSortFilterFields = typeof NodepoolSortFilterFields[keyof typeof NodepoolSortFilterFields];


/**
 * 
 * @export
 * @interface NodepoolSyncFields
 */
export interface NodepoolSyncFields {
    /**
     * 
     * @type {string}
     * @memberof NodepoolSyncFields
     */
    'name': string;
    /**
     * 
     * @type {NodepoolPhase}
     * @memberof NodepoolSyncFields
     */
    'phase'?: NodepoolPhase;
    /**
     * Message for status of Node Pool
     * @type {string}
     * @memberof NodepoolSyncFields
     */
    'phaseMessage'?: string;
    /**
     * 
     * @type {NodepoolSyncFieldsStatus}
     * @memberof NodepoolSyncFields
     */
    'status'?: NodepoolSyncFieldsStatus;
}
/**
 * 
 * @export
 * @interface NodepoolSyncFieldsStatus
 */
export interface NodepoolSyncFieldsStatus {
    /**
     * Label key for associated nodes to the Node Pool (with value as in labelValue)
     * @type {string}
     * @memberof NodepoolSyncFieldsStatus
     */
    'labelKey'?: string | null;
    /**
     * Label value for associated nodes to the Node Pool (with key as in labelKey)
     * @type {string}
     * @memberof NodepoolSyncFieldsStatus
     */
    'labelValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodepoolSyncFieldsStatus
     */
    'overProvisioningRatio'?: number | null;
    /**
     * 
     * @type {NodepoolCreateFieldsPlacementStrategy}
     * @memberof NodepoolSyncFieldsStatus
     */
    'placementStrategy'?: NodepoolCreateFieldsPlacementStrategy;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodepoolSyncFieldsStatus
     */
    'nodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NodepoolSyncFieldsStatusAllOf
 */
export interface NodepoolSyncFieldsStatusAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof NodepoolSyncFieldsStatusAllOf
     */
    'nodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NodepoolUpdateFields
 */
export interface NodepoolUpdateFields {
    /**
     * Label key for associated nodes to the Node Pool (with value as in labelValue)
     * @type {string}
     * @memberof NodepoolUpdateFields
     */
    'labelKey'?: string | null;
    /**
     * Label value for associated nodes to the Node Pool (with key as in labelKey)
     * @type {string}
     * @memberof NodepoolUpdateFields
     */
    'labelValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodepoolUpdateFields
     */
    'overProvisioningRatio'?: number | null;
    /**
     * 
     * @type {NodepoolCreateFieldsPlacementStrategy}
     * @memberof NodepoolUpdateFields
     */
    'placementStrategy'?: NodepoolCreateFieldsPlacementStrategy;
}
/**
 * 
 * @export
 * @interface Nodes
 */
export interface Nodes {
    /**
     * 
     * @type {Array<Node>}
     * @memberof Nodes
     */
    'nodes': Array<Node>;
}
/**
 * 
 * @export
 * @interface NodesSyncRequest
 */
export interface NodesSyncRequest {
    /**
     * 
     * @type {Array<NodeForSync>}
     * @memberof NodesSyncRequest
     */
    'nodesForSync': Array<NodeForSync>;
    /**
     * The first node to sync. Will delete all nodes that don\'t exist in the request, after this node. If empty, all nodes before the first one sent will be deleted.
     * @type {string}
     * @memberof NodesSyncRequest
     */
    'from'?: string;
    /**
     * The last node to sync. Will delete all nodes that don\'t exist in the request, before this node. If empty, all nodes after the last one sent will be deleted.
     * @type {string}
     * @memberof NodesSyncRequest
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationOperation = {
    Create: 'Create',
    Delete: 'Delete'
} as const;

export type NotificationOperation = typeof NotificationOperation[keyof typeof NotificationOperation];


/**
 * PATCH Cluster Configuration Request. NOTE - unset operations are not supported.
 * @export
 * @interface PatchClusterConfigRequest
 */
export interface PatchClusterConfigRequest {
    /**
     * 
     * @type {ClusterUserConfigDataPartialUpdate}
     * @memberof PatchClusterConfigRequest
     */
    'config'?: ClusterUserConfigDataPartialUpdate;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlacementStrategy = {
    Spread: 'spread',
    Binpack: 'binpack'
} as const;

export type PlacementStrategy = typeof PlacementStrategy[keyof typeof PlacementStrategy];


/**
 * PUT Cluster Configuration Request
 * @export
 * @interface PutClusterConfigRequest
 */
export interface PutClusterConfigRequest {
    /**
     * 
     * @type {ClusterUserConfigData}
     * @memberof PutClusterConfigRequest
     */
    'config'?: ClusterUserConfigData;
}
/**
 * 
 * @export
 * @interface PutClusterConfigStatusRequest
 */
export interface PutClusterConfigStatusRequest {
    /**
     * 
     * @type {PutClusterConfigStatusRequestStatus}
     * @memberof PutClusterConfigStatusRequest
     */
    'status': PutClusterConfigStatusRequestStatus;
}
/**
 * 
 * @export
 * @interface PutClusterConfigStatusRequestStatus
 */
export interface PutClusterConfigStatusRequestStatus {
    /**
     * 
     * @type {ClusterFullConfigData}
     * @memberof PutClusterConfigStatusRequestStatus
     */
    'actualConfig'?: ClusterFullConfigData;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof PutClusterConfigStatusRequestStatus
     */
    'conditions': Array<Condition>;
}
/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface SyncNodepoolsStatus207Response
 */
export interface SyncNodepoolsStatus207Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncNodepoolsStatus207Response
     */
    'successfullySyncedNodepools': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncNodepoolsStatus207Response
     */
    'failedNodepools': Array<string>;
}
/**
 * 
 * @export
 * @interface SyncNodepoolsStatusRequest
 */
export interface SyncNodepoolsStatusRequest {
    /**
     * 
     * @type {Array<NodepoolSyncFields>}
     * @memberof SyncNodepoolsStatusRequest
     */
    'nodepools': Array<NodepoolSyncFields>;
    /**
     * 
     * @type {boolean}
     * @memberof SyncNodepoolsStatusRequest
     */
    'deleteMissing'?: boolean;
}
/**
 * 
 * @export
 * @interface TelemetryResponse
 */
export interface TelemetryResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof TelemetryResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponse
     */
    'timestamp': string;
    /**
     * 
     * @type {Array<TelemetryResponseValuesInner>}
     * @memberof TelemetryResponse
     */
    'values': Array<TelemetryResponseValuesInner>;
}
/**
 * 
 * @export
 * @interface TelemetryResponseValuesInner
 */
export interface TelemetryResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInner
     */
    'value': string;
    /**
     * columns the data is grouped by
     * @type {Array<TelemetryResponseValuesInnerGroupsInner>}
     * @memberof TelemetryResponseValuesInner
     */
    'groups'?: Array<TelemetryResponseValuesInnerGroupsInner>;
}
/**
 * 
 * @export
 * @interface TelemetryResponseValuesInnerGroupsInner
 */
export interface TelemetryResponseValuesInnerGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'name'?: string;
}

/**
 * ClusterConfigApi - axios parameter creator
 * @export
 */
export const ClusterConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterConfig: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterConfig', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/config`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the internal configuration of a cluster. This API is intended to be used by RunAI components on the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalClusterConfig: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getInternalClusterConfig', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/internal-config`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PatchClusterConfigRequest} patchClusterConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClusterConfig: async (clusterUuid: string, patchClusterConfigRequest: PatchClusterConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('patchClusterConfig', 'clusterUuid', clusterUuid)
            // verify required parameter 'patchClusterConfigRequest' is not null or undefined
            assertParamExists('patchClusterConfig', 'patchClusterConfigRequest', patchClusterConfigRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/config`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchClusterConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PutClusterConfigRequest} putClusterConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putClusterConfig: async (clusterUuid: string, putClusterConfigRequest: PutClusterConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('putClusterConfig', 'clusterUuid', clusterUuid)
            // verify required parameter 'putClusterConfigRequest' is not null or undefined
            assertParamExists('putClusterConfig', 'putClusterConfigRequest', putClusterConfigRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/config`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putClusterConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put the status of the cluster configuration. This endpoint will be used by the cluster to sync its remote configuration status.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PutClusterConfigStatusRequest} putClusterConfigStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putClusterConfigStatus: async (clusterUuid: string, putClusterConfigStatusRequest: PutClusterConfigStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('putClusterConfigStatus', 'clusterUuid', clusterUuid)
            // verify required parameter 'putClusterConfigStatusRequest' is not null or undefined
            assertParamExists('putClusterConfigStatus', 'putClusterConfigStatusRequest', putClusterConfigStatusRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/config/status`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putClusterConfigStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterConfigApi - functional programming interface
 * @export
 */
export const ClusterConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClusterConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterConfig(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPutPatchClusterConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterConfig(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the internal configuration of a cluster. This API is intended to be used by RunAI components on the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalClusterConfig(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInternalClusterConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalClusterConfig(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PatchClusterConfigRequest} patchClusterConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchClusterConfig(clusterUuid: string, patchClusterConfigRequest: PatchClusterConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPutPatchClusterConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchClusterConfig(clusterUuid, patchClusterConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PutClusterConfigRequest} putClusterConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putClusterConfig(clusterUuid: string, putClusterConfigRequest: PutClusterConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPutPatchClusterConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putClusterConfig(clusterUuid, putClusterConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put the status of the cluster configuration. This endpoint will be used by the cluster to sync its remote configuration status.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PutClusterConfigStatusRequest} putClusterConfigStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putClusterConfigStatus(clusterUuid: string, putClusterConfigStatusRequest: PutClusterConfigStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putClusterConfigStatus(clusterUuid, putClusterConfigStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClusterConfigApi - factory interface
 * @export
 */
export const ClusterConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClusterConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterConfig(clusterUuid: string, options?: any): AxiosPromise<GetPutPatchClusterConfigResponse> {
            return localVarFp.getClusterConfig(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the internal configuration of a cluster. This API is intended to be used by RunAI components on the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalClusterConfig(clusterUuid: string, options?: any): AxiosPromise<GetInternalClusterConfigResponse> {
            return localVarFp.getInternalClusterConfig(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PatchClusterConfigRequest} patchClusterConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClusterConfig(clusterUuid: string, patchClusterConfigRequest: PatchClusterConfigRequest, options?: any): AxiosPromise<GetPutPatchClusterConfigResponse> {
            return localVarFp.patchClusterConfig(clusterUuid, patchClusterConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put the cluster configuration.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PutClusterConfigRequest} putClusterConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putClusterConfig(clusterUuid: string, putClusterConfigRequest: PutClusterConfigRequest, options?: any): AxiosPromise<GetPutPatchClusterConfigResponse> {
            return localVarFp.putClusterConfig(clusterUuid, putClusterConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put the status of the cluster configuration. This endpoint will be used by the cluster to sync its remote configuration status.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PutClusterConfigStatusRequest} putClusterConfigStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putClusterConfigStatus(clusterUuid: string, putClusterConfigStatusRequest: PutClusterConfigStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putClusterConfigStatus(clusterUuid, putClusterConfigStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClusterConfigApi - object-oriented interface
 * @export
 * @class ClusterConfigApi
 * @extends {BaseAPI}
 */
export class ClusterConfigApi extends BaseAPI {
    /**
     * 
     * @summary Get the cluster configuration.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterConfigApi
     */
    public getClusterConfig(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClusterConfigApiFp(this.configuration).getClusterConfig(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the internal configuration of a cluster. This API is intended to be used by RunAI components on the cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterConfigApi
     */
    public getInternalClusterConfig(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClusterConfigApiFp(this.configuration).getInternalClusterConfig(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch the cluster configuration.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {PatchClusterConfigRequest} patchClusterConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterConfigApi
     */
    public patchClusterConfig(clusterUuid: string, patchClusterConfigRequest: PatchClusterConfigRequest, options?: AxiosRequestConfig) {
        return ClusterConfigApiFp(this.configuration).patchClusterConfig(clusterUuid, patchClusterConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put the cluster configuration.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {PutClusterConfigRequest} putClusterConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterConfigApi
     */
    public putClusterConfig(clusterUuid: string, putClusterConfigRequest: PutClusterConfigRequest, options?: AxiosRequestConfig) {
        return ClusterConfigApiFp(this.configuration).putClusterConfig(clusterUuid, putClusterConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put the status of the cluster configuration. This endpoint will be used by the cluster to sync its remote configuration status.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {PutClusterConfigStatusRequest} putClusterConfigStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterConfigApi
     */
    public putClusterConfigStatus(clusterUuid: string, putClusterConfigStatusRequest: PutClusterConfigStatusRequest, options?: AxiosRequestConfig) {
        return ClusterConfigApiFp(this.configuration).putClusterConfigStatus(clusterUuid, putClusterConfigStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClustersApi - axios parameter creator
 * @export
 */
export const ClustersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use to create a Kubernetes cluster.
         * @summary Create a cluster.
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster: async (clusterCreationRequest: ClusterCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterCreationRequest' is not null or undefined
            assertParamExists('createCluster', 'clusterCreationRequest', clusterCreationRequest)
            const localVarPath = `/api/v1/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete a cluster by Universally Unique Identifier (UUID).  Will return 202 for success if this api was called on a cluster that its version is >=2.20,  and force query param is false or not provided. Will return 204 for success if force query param is true, or if cluster is in a version < 2.20 
         * @summary Delete a cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [force] if true will force cluster instant deletion otherwise will start cluster graceful deletion process. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster: async (clusterUuid: string, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteCluster', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterAncestors: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterAncestors', 'clusterUuid', clusterUuid)
            const localVarPath = `/internal/clusters/{clusterUuid}/ancestors`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve cluster details by Universally Unique Identifier (UUID).
         * @summary Get cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterByUuid: async (clusterUuid: string, verbosity?: 'metadata' | 'full', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterByUuid', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to retrieve installation instruction for a cluster by Universally Unique Identifier (UUID).  Supports clusters version 2.15 or above. 
         * @summary Retrieve the installation instructions of a cluster by ID.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} version The cluster version to install
         * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterInstallInfoByUuid: async (clusterUuid: string, version: string, remoteClusterUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterInstallInfoByUuid', 'clusterUuid', clusterUuid)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getClusterInstallInfoByUuid', 'version', version)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/cluster-install-info`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (remoteClusterUrl !== undefined) {
                localVarQueryParameter['remoteClusterUrl'] = remoteClusterUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the metrics data for a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Get the cluster metrics data.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterMetrics: async (clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterMetrics', 'clusterUuid', clusterUuid)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getClusterMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getClusterMetrics', 'end', end)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getClusterMetrics', 'metricType', metricType)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the name of the cluster by id.
         * @summary Get the cluster name.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterName: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterName', 'clusterUuid', clusterUuid)
            const localVarPath = `/internal/clusters/{clusterUuid}/name`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the cluster versions
         * @summary Get cluster versions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterVersions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clusters/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of clusters with details.
         * @summary Get a list of clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {Array<string>} [tenantId] The tenant Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters: async (verbosity?: 'metadata' | 'full', tenantId?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }

            if (tenantId) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the details of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update a cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster: async (clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateCluster', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterUpdateRequest' is not null or undefined
            assertParamExists('updateCluster', 'clusterUpdateRequest', clusterUpdateRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the domain, version and status of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update cluster domain, version and status for a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterInfo: async (clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateClusterInfo', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterInfoSyncRequest' is not null or undefined
            assertParamExists('updateClusterInfo', 'clusterInfoSyncRequest', clusterInfoSyncRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/cluster-info`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterInfoSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the liveness probe status of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update liveness status for a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterLiveness: async (clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateClusterLiveness', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterLivenessSyncRequest' is not null or undefined
            assertParamExists('updateClusterLiveness', 'clusterLivenessSyncRequest', clusterLivenessSyncRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/liveness`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterLivenessSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClustersApiAxiosParamCreator(configuration)
    return {
        /**
         * Use to create a Kubernetes cluster.
         * @summary Create a cluster.
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCluster(clusterCreationRequest: ClusterCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayedCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCluster(clusterCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete a cluster by Universally Unique Identifier (UUID).  Will return 202 for success if this api was called on a cluster that its version is >=2.20,  and force query param is false or not provided. Will return 204 for success if force query param is true, or if cluster is in a version < 2.20 
         * @summary Delete a cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [force] if true will force cluster instant deletion otherwise will start cluster graceful deletion process. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCluster(clusterUuid: string, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCluster(clusterUuid, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterAncestors(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterAncestors(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve cluster details by Universally Unique Identifier (UUID).
         * @summary Get cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterByUuid(clusterUuid: string, verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayedCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterByUuid(clusterUuid, verbosity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to retrieve installation instruction for a cluster by Universally Unique Identifier (UUID).  Supports clusters version 2.15 or above. 
         * @summary Retrieve the installation instructions of a cluster by ID.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} version The cluster version to install
         * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterInstallInfoByUuid(clusterUuid: string, version: string, remoteClusterUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInstallationInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterInstallInfoByUuid(clusterUuid, version, remoteClusterUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the metrics data for a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Get the cluster metrics data.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterMetrics(clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterMetrics(clusterUuid, start, end, metricType, numberOfSamples, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the name of the cluster by id.
         * @summary Get the cluster name.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterName(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClusterName200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterName(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the cluster versions
         * @summary Get cluster versions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterVersions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterVersions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of clusters with details.
         * @summary Get a list of clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {Array<string>} [tenantId] The tenant Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusters(verbosity?: 'metadata' | 'full', tenantId?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisplayedCluster>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusters(verbosity, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the details of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update a cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCluster(clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCluster(clusterUuid, clusterUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the domain, version and status of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update cluster domain, version and status for a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterInfo(clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterInfo(clusterUuid, clusterInfoSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the liveness probe status of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update liveness status for a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterLiveness(clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterLiveness(clusterUuid, clusterLivenessSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClustersApiFp(configuration)
    return {
        /**
         * Use to create a Kubernetes cluster.
         * @summary Create a cluster.
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(clusterCreationRequest: ClusterCreationRequest, options?: any): AxiosPromise<DisplayedCluster> {
            return localVarFp.createCluster(clusterCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete a cluster by Universally Unique Identifier (UUID).  Will return 202 for success if this api was called on a cluster that its version is >=2.20,  and force query param is false or not provided. Will return 204 for success if force query param is true, or if cluster is in a version < 2.20 
         * @summary Delete a cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [force] if true will force cluster instant deletion otherwise will start cluster graceful deletion process. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(clusterUuid: string, force?: boolean, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteCluster(clusterUuid, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterAncestors(clusterUuid: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getClusterAncestors(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve cluster details by Universally Unique Identifier (UUID).
         * @summary Get cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterByUuid(clusterUuid: string, verbosity?: 'metadata' | 'full', options?: any): AxiosPromise<DisplayedCluster> {
            return localVarFp.getClusterByUuid(clusterUuid, verbosity, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to retrieve installation instruction for a cluster by Universally Unique Identifier (UUID).  Supports clusters version 2.15 or above. 
         * @summary Retrieve the installation instructions of a cluster by ID.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} version The cluster version to install
         * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterInstallInfoByUuid(clusterUuid: string, version: string, remoteClusterUrl?: string, options?: any): AxiosPromise<ClusterInstallationInfoResponse> {
            return localVarFp.getClusterInstallInfoByUuid(clusterUuid, version, remoteClusterUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the metrics data for a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Get the cluster metrics data.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterMetrics(clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getClusterMetrics(clusterUuid, start, end, metricType, numberOfSamples, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the name of the cluster by id.
         * @summary Get the cluster name.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterName(clusterUuid: string, options?: any): AxiosPromise<GetClusterName200Response> {
            return localVarFp.getClusterName(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the cluster versions
         * @summary Get cluster versions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterVersions(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getClusterVersions(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of clusters with details.
         * @summary Get a list of clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {Array<string>} [tenantId] The tenant Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters(verbosity?: 'metadata' | 'full', tenantId?: Array<string>, options?: any): AxiosPromise<Array<DisplayedCluster>> {
            return localVarFp.getClusters(verbosity, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the details of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update a cluster by id.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster(clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCluster(clusterUuid, clusterUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the domain, version and status of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update cluster domain, version and status for a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterInfo(clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterInfo(clusterUuid, clusterInfoSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the liveness probe status of a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update liveness status for a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterLiveness(clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterLiveness(clusterUuid, clusterLivenessSyncRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * Use to create a Kubernetes cluster.
     * @summary Create a cluster.
     * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public createCluster(clusterCreationRequest: ClusterCreationRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).createCluster(clusterCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete a cluster by Universally Unique Identifier (UUID).  Will return 202 for success if this api was called on a cluster that its version is >=2.20,  and force query param is false or not provided. Will return 204 for success if force query param is true, or if cluster is in a version < 2.20 
     * @summary Delete a cluster by id.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {boolean} [force] if true will force cluster instant deletion otherwise will start cluster graceful deletion process. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public deleteCluster(clusterUuid: string, force?: boolean, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).deleteCluster(clusterUuid, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cluster ancestors.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterAncestors(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterAncestors(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve cluster details by Universally Unique Identifier (UUID).
     * @summary Get cluster by id.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterByUuid(clusterUuid: string, verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterByUuid(clusterUuid, verbosity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to retrieve installation instruction for a cluster by Universally Unique Identifier (UUID).  Supports clusters version 2.15 or above. 
     * @summary Retrieve the installation instructions of a cluster by ID.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} version The cluster version to install
     * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterInstallInfoByUuid(clusterUuid: string, version: string, remoteClusterUrl?: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterInstallInfoByUuid(clusterUuid, version, remoteClusterUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the metrics data for a Kubernetes cluster by Universally Unique Identifier (UUID).
     * @summary Get the cluster metrics data.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {Array<MetricsType>} metricType specifies what data to request
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterMetrics(clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterMetrics(clusterUuid, start, end, metricType, numberOfSamples, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the name of the cluster by id.
     * @summary Get the cluster name.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterName(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterName(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the cluster versions
     * @summary Get cluster versions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterVersions(options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterVersions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of clusters with details.
     * @summary Get a list of clusters.
     * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
     * @param {Array<string>} [tenantId] The tenant Ids of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusters(verbosity?: 'metadata' | 'full', tenantId?: Array<string>, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusters(verbosity, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the details of a Kubernetes cluster by Universally Unique Identifier (UUID).
     * @summary Update a cluster by id.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateCluster(clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateCluster(clusterUuid, clusterUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the domain, version and status of a Kubernetes cluster by Universally Unique Identifier (UUID).
     * @summary Update cluster domain, version and status for a cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterInfo(clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterInfo(clusterUuid, clusterInfoSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the liveness probe status of a Kubernetes cluster by Universally Unique Identifier (UUID).
     * @summary Update liveness status for a cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterLiveness(clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterLiveness(clusterUuid, clusterLivenessSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ControlPlaneConfigApi - axios parameter creator
 * @export
 */
export const ControlPlaneConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the control plane configuration of a Cluster by Universally Unique Identifier (UUID).
         * @summary Retrieve the control plane configuration of a Cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterControlPlaneConfig: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterControlPlaneConfig', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/config/control-plane`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ControlPlaneConfigApi - functional programming interface
 * @export
 */
export const ControlPlaneConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ControlPlaneConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the control plane configuration of a Cluster by Universally Unique Identifier (UUID).
         * @summary Retrieve the control plane configuration of a Cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterControlPlaneConfig(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterControlPlaneConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterControlPlaneConfig(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ControlPlaneConfigApi - factory interface
 * @export
 */
export const ControlPlaneConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ControlPlaneConfigApiFp(configuration)
    return {
        /**
         * Retrieve the control plane configuration of a Cluster by Universally Unique Identifier (UUID).
         * @summary Retrieve the control plane configuration of a Cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterControlPlaneConfig(clusterUuid: string, options?: any): AxiosPromise<ClusterControlPlaneConfig> {
            return localVarFp.getClusterControlPlaneConfig(clusterUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ControlPlaneConfigApi - object-oriented interface
 * @export
 * @class ControlPlaneConfigApi
 * @extends {BaseAPI}
 */
export class ControlPlaneConfigApi extends BaseAPI {
    /**
     * Retrieve the control plane configuration of a Cluster by Universally Unique Identifier (UUID).
     * @summary Retrieve the control plane configuration of a Cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlPlaneConfigApi
     */
    public getClusterControlPlaneConfig(clusterUuid: string, options?: AxiosRequestConfig) {
        return ControlPlaneConfigApiFp(this.configuration).getClusterControlPlaneConfig(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstallationApi - axios parameter creator
 * @export
 */
export const InstallationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInstallationUrl: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('clusterInstallationUrl', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/installation-url`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapClusterUrl: async (clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('mapClusterUrl', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterUrlRequest' is not null or undefined
            assertParamExists('mapClusterUrl', 'clusterUrlRequest', clusterUrlRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/domain`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstallationApi - functional programming interface
 * @export
 */
export const InstallationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstallationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterInstallationUrl(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInstallationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterInstallationUrl(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapClusterUrl(clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapClusterUrl(clusterUuid, clusterUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstallationApi - factory interface
 * @export
 */
export const InstallationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstallationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInstallationUrl(clusterUuid: string, options?: any): AxiosPromise<ClusterInstallationResponse> {
            return localVarFp.clusterInstallationUrl(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapClusterUrl(clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: any): AxiosPromise<ClusterUrlResponse> {
            return localVarFp.mapClusterUrl(clusterUuid, clusterUrlRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstallationApi - object-oriented interface
 * @export
 * @class InstallationApi
 * @extends {BaseAPI}
 */
export class InstallationApi extends BaseAPI {
    /**
     * 
     * @summary Get cluster installation url
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public clusterInstallationUrl(clusterUuid: string, options?: AxiosRequestConfig) {
        return InstallationApiFp(this.configuration).clusterInstallationUrl(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Map target url to cluster url
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterUrlRequest} clusterUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public mapClusterUrl(clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: AxiosRequestConfig) {
        return InstallationApiFp(this.configuration).mapClusterUrl(clusterUuid, clusterUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List cluster histories.
         * @param {Array<string>} clusterId The clusters Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClustersHistories: async (clusterId: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getClustersHistories', 'clusterId', clusterId)
            const localVarPath = `/internal/cluster-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the cluster access control.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClusterAccessControl: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('setClusterAccessControl', 'clusterUuid', clusterUuid)
            const localVarPath = `/internal/clusters/{clusterUuid}/access-control`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List cluster histories.
         * @param {Array<string>} clusterId The clusters Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClustersHistories(clusterId: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClusterHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClustersHistories(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the cluster access control.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setClusterAccessControl(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setClusterAccessControl(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @summary List cluster histories.
         * @param {Array<string>} clusterId The clusters Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClustersHistories(clusterId: Array<string>, options?: any): AxiosPromise<Array<ClusterHistory>> {
            return localVarFp.getClustersHistories(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the cluster access control.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClusterAccessControl(clusterUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.setClusterAccessControl(clusterUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary List cluster histories.
     * @param {Array<string>} clusterId The clusters Ids of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getClustersHistories(clusterId: Array<string>, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getClustersHistories(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the cluster access control.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public setClusterAccessControl(clusterUuid: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).setClusterAccessControl(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodePoolsApi - axios parameter creator
 * @export
 */
export const NodePoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Notify cluster operation
         * @summary Cluster notification
         * @param {ClusterNotification} clusterNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterNotification: async (clusterNotification: ClusterNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterNotification' is not null or undefined
            assertParamExists('clusterNotification', 'clusterNotification', clusterNotification)
            const localVarPath = `/internal/node-pools/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count nodepools
         * @summary Count nodepools
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNodepools: async (filterBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/node-pools/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create nodepool
         * @summary Create nodepool
         * @param {NodepoolCreateFields} nodepoolCreateFields Nodepool to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNodepool: async (nodepoolCreateFields: NodepoolCreateFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodepoolCreateFields' is not null or undefined
            assertParamExists('createNodepool', 'nodepoolCreateFields', nodepoolCreateFields)
            const localVarPath = `/api/v1/node-pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodepoolCreateFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete nodepool
         * @summary Delete nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNodepool: async (nodepoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodepoolId' is not null or undefined
            assertParamExists('deleteNodepool', 'nodepoolId', nodepoolId)
            const localVarPath = `/api/v1/node-pools/{nodepoolId}`
                .replace(`{${"nodepoolId"}}`, encodeURIComponent(String(nodepoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get nodepool
         * @summary Get nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepool: async (nodepoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodepoolId' is not null or undefined
            assertParamExists('getNodepool', 'nodepoolId', nodepoolId)
            const localVarPath = `/api/v1/node-pools/{nodepoolId}`
                .replace(`{${"nodepoolId"}}`, encodeURIComponent(String(nodepoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the nodepool ancestors.
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepoolAncestors: async (nodepoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodepoolId' is not null or undefined
            assertParamExists('getNodepoolAncestors', 'nodepoolId', nodepoolId)
            const localVarPath = `/internal/node-pools/{nodepoolId}/ancestors`
                .replace(`{${"nodepoolId"}}`, encodeURIComponent(String(nodepoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the node pool metrics data by Universally Unique Identifier (UUID).
         * @summary Get the node pool metrics data. [Experimental]
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodepoolName The unique nodepool name.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepoolMetrics: async (clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodepoolName' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'nodepoolName', nodepoolName)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'end', end)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'metricType', metricType)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodepools/{nodepoolName}/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"nodepoolName"}}`, encodeURIComponent(String(nodepoolName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get nodepools
         * @summary Get nodepools
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {NodepoolSortFilterFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepools: async (filterBy?: Array<string>, sortBy?: NodepoolSortFilterFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/node-pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update only specific fields of a nodepool
         * @summary Patch nodepool fields
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {NodepoolUpdateFields} nodepoolUpdateFields Fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNodepool: async (nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodepoolId' is not null or undefined
            assertParamExists('patchNodepool', 'nodepoolId', nodepoolId)
            // verify required parameter 'nodepoolUpdateFields' is not null or undefined
            assertParamExists('patchNodepool', 'nodepoolUpdateFields', nodepoolUpdateFields)
            const localVarPath = `/api/v1/node-pools/{nodepoolId}`
                .replace(`{${"nodepoolId"}}`, encodeURIComponent(String(nodepoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodepoolUpdateFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync nodepools status
         * @summary Sync nodepools status
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {SyncNodepoolsStatusRequest} syncNodepoolsStatusRequest Nodepools sync data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodepoolsStatus: async (clusterUuid: string, syncNodepoolsStatusRequest: SyncNodepoolsStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncNodepoolsStatus', 'clusterUuid', clusterUuid)
            // verify required parameter 'syncNodepoolsStatusRequest' is not null or undefined
            assertParamExists('syncNodepoolsStatus', 'syncNodepoolsStatusRequest', syncNodepoolsStatusRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodepools/status`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncNodepoolsStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update nodepool
         * @summary Update nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {NodepoolUpdateFields} nodepoolUpdateFields Nodepool to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodepool: async (nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodepoolId' is not null or undefined
            assertParamExists('updateNodepool', 'nodepoolId', nodepoolId)
            // verify required parameter 'nodepoolUpdateFields' is not null or undefined
            assertParamExists('updateNodepool', 'nodepoolUpdateFields', nodepoolUpdateFields)
            const localVarPath = `/api/v1/node-pools/{nodepoolId}`
                .replace(`{${"nodepoolId"}}`, encodeURIComponent(String(nodepoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodepoolUpdateFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodePoolsApi - functional programming interface
 * @export
 */
export const NodePoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodePoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * Notify cluster operation
         * @summary Cluster notification
         * @param {ClusterNotification} clusterNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterNotification(clusterNotification: ClusterNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterNotification(clusterNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count nodepools
         * @summary Count nodepools
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countNodepools(filterBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountNodepools200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countNodepools(filterBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create nodepool
         * @summary Create nodepool
         * @param {NodepoolCreateFields} nodepoolCreateFields Nodepool to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNodepool(nodepoolCreateFields: NodepoolCreateFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodepool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNodepool(nodepoolCreateFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete nodepool
         * @summary Delete nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNodepool(nodepoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNodepool(nodepoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get nodepool
         * @summary Get nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodepool(nodepoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodepool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodepool(nodepoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the nodepool ancestors.
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodepoolAncestors(nodepoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodepoolAncestors(nodepoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the node pool metrics data by Universally Unique Identifier (UUID).
         * @summary Get the node pool metrics data. [Experimental]
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodepoolName The unique nodepool name.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodepoolMetrics(clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodepoolMetrics(clusterUuid, nodepoolName, start, end, metricType, numberOfSamples, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get nodepools
         * @summary Get nodepools
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {NodepoolSortFilterFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodepools(filterBy?: Array<string>, sortBy?: NodepoolSortFilterFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNodepools200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodepools(filterBy, sortBy, sortOrder, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update only specific fields of a nodepool
         * @summary Patch nodepool fields
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {NodepoolUpdateFields} nodepoolUpdateFields Fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNodepool(nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodepool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNodepool(nodepoolId, nodepoolUpdateFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sync nodepools status
         * @summary Sync nodepools status
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {SyncNodepoolsStatusRequest} syncNodepoolsStatusRequest Nodepools sync data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncNodepoolsStatus(clusterUuid: string, syncNodepoolsStatusRequest: SyncNodepoolsStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncNodepoolsStatus(clusterUuid, syncNodepoolsStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update nodepool
         * @summary Update nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {NodepoolUpdateFields} nodepoolUpdateFields Nodepool to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNodepool(nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodepool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNodepool(nodepoolId, nodepoolUpdateFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodePoolsApi - factory interface
 * @export
 */
export const NodePoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodePoolsApiFp(configuration)
    return {
        /**
         * Notify cluster operation
         * @summary Cluster notification
         * @param {ClusterNotification} clusterNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterNotification(clusterNotification: ClusterNotification, options?: any): AxiosPromise<void> {
            return localVarFp.clusterNotification(clusterNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * Count nodepools
         * @summary Count nodepools
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNodepools(filterBy?: Array<string>, options?: any): AxiosPromise<CountNodepools200Response> {
            return localVarFp.countNodepools(filterBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Create nodepool
         * @summary Create nodepool
         * @param {NodepoolCreateFields} nodepoolCreateFields Nodepool to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNodepool(nodepoolCreateFields: NodepoolCreateFields, options?: any): AxiosPromise<Nodepool> {
            return localVarFp.createNodepool(nodepoolCreateFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete nodepool
         * @summary Delete nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNodepool(nodepoolId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteNodepool(nodepoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get nodepool
         * @summary Get nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepool(nodepoolId: string, options?: any): AxiosPromise<Nodepool> {
            return localVarFp.getNodepool(nodepoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the nodepool ancestors.
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepoolAncestors(nodepoolId: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getNodepoolAncestors(nodepoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the node pool metrics data by Universally Unique Identifier (UUID).
         * @summary Get the node pool metrics data. [Experimental]
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodepoolName The unique nodepool name.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepoolMetrics(clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getNodepoolMetrics(clusterUuid, nodepoolName, start, end, metricType, numberOfSamples, options).then((request) => request(axios, basePath));
        },
        /**
         * Get nodepools
         * @summary Get nodepools
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {NodepoolSortFilterFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepools(filterBy?: Array<string>, sortBy?: NodepoolSortFilterFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options?: any): AxiosPromise<GetNodepools200Response> {
            return localVarFp.getNodepools(filterBy, sortBy, sortOrder, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update only specific fields of a nodepool
         * @summary Patch nodepool fields
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {NodepoolUpdateFields} nodepoolUpdateFields Fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNodepool(nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options?: any): AxiosPromise<Nodepool> {
            return localVarFp.patchNodepool(nodepoolId, nodepoolUpdateFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Sync nodepools status
         * @summary Sync nodepools status
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {SyncNodepoolsStatusRequest} syncNodepoolsStatusRequest Nodepools sync data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodepoolsStatus(clusterUuid: string, syncNodepoolsStatusRequest: SyncNodepoolsStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncNodepoolsStatus(clusterUuid, syncNodepoolsStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update nodepool
         * @summary Update nodepool
         * @param {string} nodepoolId The unique id identifying the Node Pool.
         * @param {NodepoolUpdateFields} nodepoolUpdateFields Nodepool to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodepool(nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options?: any): AxiosPromise<Nodepool> {
            return localVarFp.updateNodepool(nodepoolId, nodepoolUpdateFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodePoolsApi - object-oriented interface
 * @export
 * @class NodePoolsApi
 * @extends {BaseAPI}
 */
export class NodePoolsApi extends BaseAPI {
    /**
     * Notify cluster operation
     * @summary Cluster notification
     * @param {ClusterNotification} clusterNotification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public clusterNotification(clusterNotification: ClusterNotification, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).clusterNotification(clusterNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count nodepools
     * @summary Count nodepools
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public countNodepools(filterBy?: Array<string>, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).countNodepools(filterBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create nodepool
     * @summary Create nodepool
     * @param {NodepoolCreateFields} nodepoolCreateFields Nodepool to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public createNodepool(nodepoolCreateFields: NodepoolCreateFields, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).createNodepool(nodepoolCreateFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete nodepool
     * @summary Delete nodepool
     * @param {string} nodepoolId The unique id identifying the Node Pool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public deleteNodepool(nodepoolId: string, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).deleteNodepool(nodepoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get nodepool
     * @summary Get nodepool
     * @param {string} nodepoolId The unique id identifying the Node Pool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public getNodepool(nodepoolId: string, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).getNodepool(nodepoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the nodepool ancestors.
     * @param {string} nodepoolId The unique id identifying the Node Pool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public getNodepoolAncestors(nodepoolId: string, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).getNodepoolAncestors(nodepoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the node pool metrics data by Universally Unique Identifier (UUID).
     * @summary Get the node pool metrics data. [Experimental]
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodepoolName The unique nodepool name.
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {Array<MetricsType>} metricType specifies what data to request
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public getNodepoolMetrics(clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).getNodepoolMetrics(clusterUuid, nodepoolName, start, end, metricType, numberOfSamples, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get nodepools
     * @summary Get nodepools
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {NodepoolSortFilterFields} [sortBy] Sort results by a parameters.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public getNodepools(filterBy?: Array<string>, sortBy?: NodepoolSortFilterFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).getNodepools(filterBy, sortBy, sortOrder, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update only specific fields of a nodepool
     * @summary Patch nodepool fields
     * @param {string} nodepoolId The unique id identifying the Node Pool.
     * @param {NodepoolUpdateFields} nodepoolUpdateFields Fields to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public patchNodepool(nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).patchNodepool(nodepoolId, nodepoolUpdateFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync nodepools status
     * @summary Sync nodepools status
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {SyncNodepoolsStatusRequest} syncNodepoolsStatusRequest Nodepools sync data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public syncNodepoolsStatus(clusterUuid: string, syncNodepoolsStatusRequest: SyncNodepoolsStatusRequest, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).syncNodepoolsStatus(clusterUuid, syncNodepoolsStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update nodepool
     * @summary Update nodepool
     * @param {string} nodepoolId The unique id identifying the Node Pool.
     * @param {NodepoolUpdateFields} nodepoolUpdateFields Nodepool to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public updateNodepool(nodepoolId: string, nodepoolUpdateFields: NodepoolUpdateFields, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).updateNodepool(nodepoolId, nodepoolUpdateFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodesApi - axios parameter creator
 * @export
 */
export const NodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a node that doesn\'t exist in the Kubernetes cluster.
         * @summary Delete a node from a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNode: async (clusterUuid: string, nodeName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteNode', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('deleteNode', 'nodeName', nodeName)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes/{nodeName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"nodeName"}}`, encodeURIComponent(String(nodeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the node metrics data by Universally Unique Identifier (UUID).
         * @summary Get the node metrics data. [Experimental]
         * @param {string} nodeId The node UUID.
         * @param {Array<NodeMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeMetrics: async (nodeId: string, metricType: Array<NodeMetricType>, start: string, end: string, numberOfSamples?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getNodeMetrics', 'nodeId', nodeId)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getNodeMetrics', 'metricType', metricType)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getNodeMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getNodeMetrics', 'end', end)
            const localVarPath = `/api/v1/nodes/{nodeId}/metrics`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve node telemetry data for use in analysis applications.
         * @summary Get node telemetry data.
         * @param {NodeTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
         * @param {Array<string>} [tenantId] The tenant Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTelemetry: async (telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, tenantId?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryType' is not null or undefined
            assertParamExists('getNodeTelemetry', 'telemetryType', telemetryType)
            const localVarPath = `/api/v1/nodes/telemetry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = groupBy.join(COLLECTION_FORMATS.csv);
            }

            if (telemetryType !== undefined) {
                localVarQueryParameter['telemetryType'] = telemetryType;
            }

            if (tenantId) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of nodes from the Kubernetes cluster.
         * @summary Get a list of nodes.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes: async (clusterUuid: string, nodeName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getNodes', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to sync the nodes in the cluster
         * @summary Sync the nodes in the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodes: async (clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncNodes', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodesSyncRequest' is not null or undefined
            assertParamExists('syncNodes', 'nodesSyncRequest', nodesSyncRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes/sync`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodesSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the details of a node in a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update node details.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNode: async (clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('upsertNode', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('upsertNode', 'nodeName', nodeName)
            // verify required parameter 'nodeInfo' is not null or undefined
            assertParamExists('upsertNode', 'nodeInfo', nodeInfo)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes/{nodeName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"nodeName"}}`, encodeURIComponent(String(nodeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodesApi - functional programming interface
 * @export
 */
export const NodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodesApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a node that doesn\'t exist in the Kubernetes cluster.
         * @summary Delete a node from a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNode(clusterUuid: string, nodeName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNode(clusterUuid, nodeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the node metrics data by Universally Unique Identifier (UUID).
         * @summary Get the node metrics data. [Experimental]
         * @param {string} nodeId The node UUID.
         * @param {Array<NodeMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeMetrics(nodeId: string, metricType: Array<NodeMetricType>, start: string, end: string, numberOfSamples?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeMetrics(nodeId, metricType, start, end, numberOfSamples, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve node telemetry data for use in analysis applications.
         * @summary Get node telemetry data.
         * @param {NodeTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
         * @param {Array<string>} [tenantId] The tenant Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeTelemetry(telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, tenantId?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of nodes from the Kubernetes cluster.
         * @summary Get a list of nodes.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodes(clusterUuid: string, nodeName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodes(clusterUuid, nodeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to sync the nodes in the cluster
         * @summary Sync the nodes in the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncNodes(clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncNodes(clusterUuid, nodesSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the details of a node in a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update node details.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertNode(clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertNode(clusterUuid, nodeName, nodeInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodesApi - factory interface
 * @export
 */
export const NodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodesApiFp(configuration)
    return {
        /**
         * Delete a node that doesn\'t exist in the Kubernetes cluster.
         * @summary Delete a node from a cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNode(clusterUuid: string, nodeName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNode(clusterUuid, nodeName, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the node metrics data by Universally Unique Identifier (UUID).
         * @summary Get the node metrics data. [Experimental]
         * @param {string} nodeId The node UUID.
         * @param {Array<NodeMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeMetrics(nodeId: string, metricType: Array<NodeMetricType>, start: string, end: string, numberOfSamples?: number, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getNodeMetrics(nodeId, metricType, start, end, numberOfSamples, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve node telemetry data for use in analysis applications.
         * @summary Get node telemetry data.
         * @param {NodeTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
         * @param {Array<string>} [tenantId] The tenant Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTelemetry(telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, tenantId?: Array<string>, options?: any): AxiosPromise<TelemetryResponse> {
            return localVarFp.getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of nodes from the Kubernetes cluster.
         * @summary Get a list of nodes.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes(clusterUuid: string, nodeName?: string, options?: any): AxiosPromise<Nodes> {
            return localVarFp.getNodes(clusterUuid, nodeName, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to sync the nodes in the cluster
         * @summary Sync the nodes in the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodes(clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncNodes(clusterUuid, nodesSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the details of a node in a Kubernetes cluster by Universally Unique Identifier (UUID).
         * @summary Update node details.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNode(clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: any): AxiosPromise<void> {
            return localVarFp.upsertNode(clusterUuid, nodeName, nodeInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodesApi - object-oriented interface
 * @export
 * @class NodesApi
 * @extends {BaseAPI}
 */
export class NodesApi extends BaseAPI {
    /**
     * Delete a node that doesn\'t exist in the Kubernetes cluster.
     * @summary Delete a node from a cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeName The node name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public deleteNode(clusterUuid: string, nodeName: string, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).deleteNode(clusterUuid, nodeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the node metrics data by Universally Unique Identifier (UUID).
     * @summary Get the node metrics data. [Experimental]
     * @param {string} nodeId The node UUID.
     * @param {Array<NodeMetricType>} metricType Specify which data to request.
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public getNodeMetrics(nodeId: string, metricType: Array<NodeMetricType>, start: string, end: string, numberOfSamples?: number, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).getNodeMetrics(nodeId, metricType, start, end, numberOfSamples, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve node telemetry data for use in analysis applications.
     * @summary Get node telemetry data.
     * @param {NodeTelemetryType} telemetryType specifies what data to request
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodepoolName] Filter using the nodepool.
     * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
     * @param {Array<string>} [tenantId] The tenant Ids of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public getNodeTelemetry(telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, tenantId?: Array<string>, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of nodes from the Kubernetes cluster.
     * @summary Get a list of nodes.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodeName] The node name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public getNodes(clusterUuid: string, nodeName?: string, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).getNodes(clusterUuid, nodeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to sync the nodes in the cluster
     * @summary Sync the nodes in the cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public syncNodes(clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).syncNodes(clusterUuid, nodesSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the details of a node in a Kubernetes cluster by Universally Unique Identifier (UUID).
     * @summary Update node details.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeName The node name.
     * @param {NodeInfo} nodeInfo The node info in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public upsertNode(clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).upsertNode(clusterUuid, nodeName, nodeInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


