// Stores
import { defineStore } from "pinia";

// Models
import { type Permission, ResourceType, Action } from "@/swagger-models/authorization-client";
import { permissionService } from "@/services/authorization/permission.service/permission.service";

export const usePermissionStore = defineStore("Permission", {
  state: () => ({
    permissions: [] as Array<Permission>,
    resourceTypeMap: {} as Record<ResourceType, Permission>,
  }),
  getters: {
    permissionsList(): Array<Permission> {
      return this.permissions;
    },
  },
  actions: {
    async loadPermissions(forceLoad = false): Promise<void> {
      if (this.permissions.length && !forceLoad) return;
      const permissions: Permission[] = await permissionService.getPermissions();
      this.setPermissions(permissions);
    },
    setPermissions(permissions: Permission[]): void {
      this.permissions = permissions;
      this.resourceTypeMap = this.permissions.reduce((acc: Record<ResourceType, Permission>, permission: Permission) => {
        acc[permission.resourceType] = permission;
        return acc;
      }, {} as Record<ResourceType, Permission>);
    },

    getPermittedActions(resourceType: ResourceType): Action[] {
      return (
        this.permissions.find((permission: Permission) => {
          return permission.resourceType === resourceType;
        })?.actions || []
      );
    },

    isReadOnly(resourceType: ResourceType): boolean {
      const permittedActions = this.getPermittedActions(resourceType);
      return permittedActions.length === 1 && permittedActions[0] === Action.Read;
    },

    hasPermission(resourceType: ResourceType | ResourceType[], action: Action): boolean {
      if (Array.isArray(resourceType)) {
        return resourceType.some((res) => this.resourceTypeMap[res]?.actions.includes(action));
      }
      return this.resourceTypeMap[resourceType]?.actions.includes(action) || false;
    },
    hasReadPermission(resourceType: ResourceType | ResourceType[]): boolean {
      return this.hasPermission(resourceType, Action.Read);
    },
    isActionGrantedForEveryResource(resourceTypes: ResourceType[], action: Action): boolean {
      return resourceTypes.every((resourceType: ResourceType) =>
        this.resourceTypeMap[resourceType]?.actions.includes(action),
      );
    },
    hasSomePermittedActions(resourceType: ResourceType | ResourceType[], actions: Action[]): boolean {
      return actions.some((action: Action) =>
        Array.isArray(resourceType)
          ? resourceType.some((res) => this.resourceTypeMap[res]?.actions.includes(action))
          : this.resourceTypeMap[resourceType]?.actions.includes(action) || false,
      );
    },
    userHasNoPermissions(): boolean {
      return !this.permissions.length;
    },
    hasOverviewV2ModePermission(): boolean {
      return this.isActionGrantedForEveryResource(
        [ResourceType.Nodes, ResourceType.Cluster, ResourceType.Department],
        Action.Read,
      );
    },
  },
});
