import type { IWrapperBoxSettingMessages } from "@/models/setting.model";
import { certificateUtil } from "@/utils/certificate.util";
import { dateUtil, TimeUnit } from "@/utils/date.util";
import type { ISelectOption } from "@/models/global.model";

export const idpCommon = {
  getSettingsBoxMessages,
  getCertificateExpirationDate,
  iValidCertificateDate,
  getDefaultScope,
};

export const DEFAULT_METADATA_FILE_NAME = "metadata.xml";
export const DEFAULT_METADATA_FILE_TYPE = "application/xml";
export const OPENID_SCOPE = "openid";
export const DEFAULT_SCOPE_OPTIONS: ISelectOption = { label: OPENID_SCOPE, value: OPENID_SCOPE, disable: true };
export const SCOPE_REGEX = /^([a-zA-Z0-9-._:]+)(\s[a-zA-Z0-9-._:]+)*$/;
function getSettingsBoxMessages(idpName: string, isSSOUser: boolean): IWrapperBoxSettingMessages {
  return {
    headerTitle: `Identity provider - ${idpName}`,
    editToolTip: "Edit identity provider",
    removeToolTip: isSSOUser
      ? "To remove the identity provider, first sign in as a local user to avoid losing access"
      : "Remove identity provider",
    removeDisable: "To remove the identity provider, first sign in as a local user to avoid losing access",
    confirmRemoveTitle: "Remove identity provider?",
    confirmRemoveSubTitle: "Single Sign-On will be disabled and only local users will be able to sign in.",
  };
}

function getCertificateExpirationDate(certificate: string): Promise<Date | null> {
  return certificateUtil.getExpirationDate(certificate);
}

function iValidCertificateDate(expirationDate: Date): boolean {
  return dateUtil.differenceBy(TimeUnit.minute, expirationDate as Date, new Date()) > 0;
}

function getDefaultScope(scopeString: string | undefined, withOpenId: boolean): Array<string> {
  let scope = withOpenId ? [OPENID_SCOPE] : [];
  if (scopeString) {
    scope = scopeString.split(" ");
    if (withOpenId && !scope.includes(OPENID_SCOPE)) {
      scope.unshift(OPENID_SCOPE);
    }
  }
  return scope;
}
