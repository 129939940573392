<template>
  <section class="auto-scale-section">
    <section class="min-max-section">
      <div class="q-my-md">
        Set the minimum and maximum number of replicas for your inference
        <runai-tooltip :tooltip-text="minMaxTooltip" tooltip-position="right" width="450px" />
      </div>
      <div class="min-max-replicas q-my-lg">
        <div class="row">
          <policy-number-input
            label="Minimum"
            class="replicas-input"
            type="number"
            :model-value="autoScaleData.minReplicas"
            @update:model-value="updateMin"
            stack-label
            :min-value="minReplicasAllowed"
            :disable="disabled || isScaleDownToZeroSelected"
            custom-ref="minRepInput"
            aid="min-rep-input"
            :tooltip="minReplicasTooltip"
            :policy-rules="policyRules?.minReplicas"
          />

          <span class="col-1 row justify-center items-center q-pt-sm">
            <hr style="width: 10px; display: block" />
          </span>

          <policy-number-input
            class="replicas-input"
            type="number"
            label="Maximum"
            :model-value="autoScaleData.maxReplicas"
            @update:model-value="updateMax"
            stack-label
            :min-value="1"
            :disable="disabled"
            custom-ref="maxRepInput"
            :rules="[isMaxMorThanMin, isMaxMoreThanMaxReplicas]"
            aid="max-rep-input"
            :policy-rules="policyRules?.maxReplicas"
          />
        </div>
      </div>
    </section>

    <div class="dashed-separator" />

    <q-slide-transition>
      <section class="new-replica-condition-section" v-if="showReplicaCondition">
        <div class="q-my-md">Set the conditions for creating a new replica</div>
        <div class="row new-replica-condition-container">
          <runai-select
            class="col-6 threshold-metric-options"
            aid="threshold-metric-options"
            :model-value="autoScaleData.thresholdMetric || null"
            @update:model-value="onThresholdMetricChanged"
            :options="thresholdMetricOptions"
            :disable="disabled"
            label="Variable"
            emit-value
            :policy-rules="policyMetricsRules"
          />

          <q-select
            class="col-2"
            label="Operator"
            :model-value="1"
            option-value="id"
            option-label="name"
            map-options
            :options="[{ id: 1, name: '>' }]"
            no-error-icon
            :disable="true"
            hide-dropdown-icon
          />

          <policy-number-input
            class="col-2"
            type="number"
            label="Value"
            :model-value="autoScaleData.thresholdValue || 100"
            @update:model-value="onThresholdValueChanged"
            stack-label
            :min-value="1"
            :disable="disabled"
            custom-ref="thresholdValueInput"
            :rules="[isValueEmpty, isGreaterThanZero]"
            aid="threshold-value-input"
            :policy-rules="policyRules?.metricThreshold"
          />
        </div>
      </section>
    </q-slide-transition>

    <section class="scale-down-section" v-if="showScaleDownToZeroValues">
      <div class="q-mt-md">
        Set when the replicas should be automatically scaled down to zero
        <runai-tooltip :tooltip-text="scaleDownTooltip" tooltip-position="right" width="315px" />
      </div>
      <div class="row items-center gap-15 q-mb-lg">
        <runai-select
          aid="scale-down-select"
          class="scale-down-select col-6"
          :model-value="scaleDownSelected"
          @update:model-value="onScaleDownChanged"
          :options="scaleDownOptions"
          :disable="disabled"
          emit-value
        />

        <div v-if="isCustomScaleDownToZeroSelected" class="custom-auto-scale row flex-1 gap-15">
          <span class="custom-auto-scale-label">After</span>
          <policy-number-input
            class="custom-scale-to-zero-input col-4"
            type="number"
            :model-value="customScaleDownSelected"
            @update:model-value="updateCustomScaleToZero"
            stack-label
            :min-value="1"
            :max-value="60"
            custom-ref="customScaleToZeroInput"
            :rules="[isValidScaleToZero]"
            :policy-rules="policyRules?.scaleToZeroRetentionSeconds"
          />
          <span class="custom-auto-scale-label custom-auto-scale-label-minutes">minutes of inactivity</span>
        </div>
      </div>
      <runai-information-bar v-if="isScaleDownToZeroSelected" class="q-mt-md">
        When automatic scaling to zero is enabled, the minimum number of replicas is 0
      </runai-information-bar>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiInformationBar } from "@/components/common/runai-information-bar";
import { PolicyNumberInput } from "@/components/common/policy-number-input";
import { RunaiSelect } from "@/components/common/runai-select";
import { errorMessages } from "@/common/error-message.constant";
import { isMaxEqualOrHigherThenMin, isValueLowerOrEqualToMax } from "@/common/form.validators";

// utils
import { is } from "quasar";

// models
import {
  type IAutoScaleData,
  type IThresholdMetricOption,
  scaleDownOptions,
  thresholdMetricOptions,
  MIN_REPLICAS,
  MAX_REPLICAS_ALLOWED,
  DEFAULT_THRESHOLD_VALUE,
  NEVER_SCALE_DOWN_ID,
  CUSTOM_SCALE_DOWN_ID,
  MIN_REPLICAS_WHEN_SCALE_TO_ZERO_SELECTED,
  SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE,
  THRESHOLD_DEFAULT_METRIC,
} from "./auto-scale-section.models";
import {
  AutoScalingMetric,
  AutoScalingMetricOptionsOptionsInner,
  type AutoScalingRules,
} from "@/swagger-models/assets-service-client";
import type { ISelectOption } from "@/models/global.model";
import { IGenericSelectPolicyRules } from "@/models/policy.model";

function toMinutes(seconds: number): number {
  return seconds / 60;
}

function toSeconds(minutes: number): number {
  return minutes * 60;
}

export default defineComponent({
  components: {
    RunaiTooltip,
    RunaiInformationBar,
    PolicyNumberInput,
    RunaiSelect,
  },
  emits: ["on-auto-scale-changed"],
  props: {
    autoScaleData: {
      type: Object as PropType<IAutoScaleData>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    policyRules: {
      type: [Object, null] as PropType<AutoScalingRules | null>,
      required: false,
    },
  },
  data() {
    return {
      minMaxTooltip:
        "Set the minimum and maximum number of replicas to be scaled up and down. If there is a difference between the two, you'll need to set conditions for auto-scaling." as string,
      scaleDownTooltip:
        "Compute resources can be freed up when the model is inactive (i.e., there are no requests being sent)" as string,
      maxNumberInvalidErrorMessage: errorMessages.EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM,
      scaleDownOptions: scaleDownOptions,
      scaleDownSelected: null as ISelectOption | null,
      customScaleDownSelected: SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE as number,
      thresholdMetricOptions: thresholdMetricOptions.map((option: IThresholdMetricOption) => {
        return {
          label: option.name,
          value: option.id.toString(),
        };
      }) as Array<ISelectOption>,
      minReplicasLastValueTyped: 1 as number,
      minimumInput: null as HTMLElement | null,
      maximumInput: null as HTMLElement | null,
      customScaleToZeroInput: null as HTMLElement | null,
      thresholdValueInput: null as HTMLElement | null,
      customScaleToZeroMessageError: "" as string,
      minMinutesForScaleToZero: 1 as number,
      maxMinutesForScaleToZero: 60 as number,
    };
  },
  created() {
    this.customScaleToZeroMessageError = errorMessages.ENTER_A_NUMBER_FROM_MIN_TO_MAX.replace(
      "${1}",
      this.minMinutesForScaleToZero.toString(),
    ).replace("${2}", this.maxMinutesForScaleToZero.toString());

    this.scaleDownSelected =
      scaleDownOptions.find(
        (option: ISelectOption) => option.value === String(this.autoScaleData.scaleToZeroRetentionSeconds),
      ) || // check if the option is one of the predefined options
      (this.autoScaleData.scaleToZeroRetentionSeconds && this.autoScaleData.scaleToZeroRetentionSeconds > 0 // if not, check if the value is a custom value
        ? scaleDownOptions[scaleDownOptions.length - 1] // last option is the custom one
        : (scaleDownOptions[0] as ISelectOption)); // if not, default to the first option (never)

    this.customScaleDownSelected = this.autoScaleData.scaleToZeroRetentionSeconds
      ? toMinutes(this.autoScaleData.scaleToZeroRetentionSeconds)
      : SCALE_TO_ZERO_RETENTION_IN_SECONDS_DEFAULT_VALUE;
  },
  mounted() {
    // Quasar input is incrementing its value on scroll if the window reaches the bottom of the page, therefore we blur the element whenever the scroll event is fired in order to prevent that
    this.addScrollListeners();
  },
  computed: {
    showScaleDownToZeroValues(): boolean {
      return this.autoScaleData.thresholdMetric !== AutoScalingMetric.Latency;
    },
    isCustomScaleToZeroRetentionSecondsInvalid(): boolean {
      return !is.number(this.customScaleDownSelected) || this.customScaleDownSelected <= 0;
    },
    isCustomScaleDownToZeroSelected(): boolean {
      return !!this.scaleDownSelected && this.scaleDownSelected.value == CUSTOM_SCALE_DOWN_ID;
    },
    isScaleDownToZeroSelected(): boolean {
      return !!this.scaleDownSelected && this.scaleDownSelected.value !== NEVER_SCALE_DOWN_ID;
    },
    minReplicasAllowed(): number {
      return this.isScaleDownToZeroSelected ? 0 : 1;
    },
    showReplicaCondition(): boolean {
      if (this.autoScaleData.minReplicas == 0 && this.autoScaleData.maxReplicas == 1) {
        return false;
      }
      return this.autoScaleData.maxReplicas > this.autoScaleData.minReplicas;
    },
    customScaleDownSelectedAsSeconds(): number {
      return toSeconds(this.customScaleDownSelected);
    },
    minReplicasTooltip(): string {
      return this.isScaleDownToZeroSelected
        ? "The minimum number of replicas is 0. This can't be changed when automatic scaling to zero is enabled."
        : "";
    },
    policyMetricsRules(): IGenericSelectPolicyRules | undefined {
      if (!this.policyRules?.metric) return;

      return {
        ...this.policyRules.metric,
        options: this.policyRules.metric.options?.map((option: AutoScalingMetricOptionsOptionsInner) => {
          return {
            label: option.displayed || option.value,
            value: option.value,
          };
        }),
      };
    },
  },
  methods: {
    isMaxMorThanMin(): boolean | string {
      return (
        isMaxEqualOrHigherThenMin(this.autoScaleData.maxReplicas, this.autoScaleData.minReplicas) ||
        errorMessages.EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM
      );
    },
    isMaxMoreThanMaxReplicas(): boolean | string {
      return (
        isValueLowerOrEqualToMax(this.autoScaleData.maxReplicas, MAX_REPLICAS_ALLOWED) ||
        `${errorMessages.ENTER_A_NUMBER_EQUAL_TO_OR_LOWER_THAN} 10,000`
      );
    },
    isValidScaleToZero(val: string): boolean | string {
      return (
        (Number(val) >= this.minMinutesForScaleToZero && Number(val) <= this.maxMinutesForScaleToZero) ||
        this.customScaleToZeroMessageError
      );
    },
    updateMin(num: number | string | null): void {
      const minReplicas: number = num && Number(num) > 0 ? Number(num) : 1;
      let maxReplicas: number = this.autoScaleData.maxReplicas;

      let thresholdMetric = this.autoScaleData.thresholdMetric;
      let thresholdValue = this.autoScaleData.thresholdValue;
      if (minReplicas >= this.autoScaleData.maxReplicas) {
        thresholdMetric = undefined;
        thresholdValue = undefined;
        maxReplicas = minReplicas;
      } else if (thresholdMetric === undefined && thresholdValue === undefined) {
        thresholdMetric = THRESHOLD_DEFAULT_METRIC;
        thresholdValue = DEFAULT_THRESHOLD_VALUE;
      }

      this.updateModel({
        ...this.autoScaleData,
        minReplicas,
        maxReplicas,
        thresholdMetric,
        thresholdValue,
      });
    },

    updateCustomScaleToZero(minutes: number | string | null): void {
      this.customScaleDownSelected = Math.round(Number(minutes));
      this.updateModel({
        ...this.autoScaleData,
        scaleToZeroRetentionSeconds: minutes ? this.customScaleDownSelectedAsSeconds : undefined,
      });
    },
    updateMax(max: number | string | null): void {
      const maxReplicas: number = max ? Number(max) : 1;
      let thresholdMetric = this.autoScaleData.thresholdMetric;
      let thresholdValue = this.autoScaleData.thresholdValue;
      if (this.autoScaleData.minReplicas >= maxReplicas) {
        thresholdMetric = undefined;
        thresholdValue = undefined;
      } else if (thresholdMetric === undefined && thresholdValue === undefined) {
        thresholdMetric = THRESHOLD_DEFAULT_METRIC;
        thresholdValue = DEFAULT_THRESHOLD_VALUE;
      }

      this.updateModel({
        ...this.autoScaleData,
        maxReplicas,
        thresholdMetric,
        thresholdValue,
      });
    },
    onScaleDownChanged(selectedOption: string): void {
      const scaleDownOption: ISelectOption | undefined = scaleDownOptions.find(
        (option: ISelectOption) => option.value === selectedOption,
      );

      if (!scaleDownOption) return;

      this.scaleDownSelected = scaleDownOption;
      if (this.isScaleDownToZeroSelected) {
        if (this.autoScaleData.minReplicas > 0) {
          this.minReplicasLastValueTyped = this.autoScaleData.minReplicas;
        }

        this.updateModel({
          ...this.autoScaleData,
          minReplicas: MIN_REPLICAS_WHEN_SCALE_TO_ZERO_SELECTED,
          scaleToZeroRetentionSeconds: Number(this.scaleDownSelected.value),
        });
      } else {
        this.updateModel({
          ...this.autoScaleData,
          minReplicas: this.minReplicasLastValueTyped,
          scaleToZeroRetentionSeconds: undefined,
        });
      }
    },
    getScaleDownOptionById(id: string): ISelectOption | undefined {
      return this.scaleDownOptions.find((option: ISelectOption) => option.value === id);
    },
    onThresholdMetricChanged(selectedValue: string): void {
      const selectedThresholdMetric: IThresholdMetricOption | undefined = thresholdMetricOptions.find(
        (option) => option.id.toString() === selectedValue,
      );

      const autoScaleData: IAutoScaleData = {
        ...this.autoScaleData,
        thresholdMetric: selectedThresholdMetric?.id,
        thresholdValue: DEFAULT_THRESHOLD_VALUE,
      };
      if (selectedThresholdMetric?.id === AutoScalingMetric.Latency) {
        autoScaleData.scaleToZeroRetentionSeconds = undefined;
        autoScaleData.minReplicas = MIN_REPLICAS;
        const scaleDownSelected: ISelectOption | undefined = this.getScaleDownOptionById(NEVER_SCALE_DOWN_ID);
        if (scaleDownSelected) {
          this.scaleDownSelected = scaleDownSelected;
        }
      }
      this.updateModel(autoScaleData);
    },
    onThresholdValueChanged(value: number | string | null): void {
      this.updateModel({
        ...this.autoScaleData,
        thresholdValue: value ? Number(value) : undefined,
      });
    },
    updateModel(autoScaleData: IAutoScaleData): void {
      this.$emit("on-auto-scale-changed", autoScaleData);
    },
    isValueEmpty(val: string): boolean | string {
      return is.number(val) || errorMessages.ENTER_A_VALUE;
    },
    isGreaterThanZero(val: string): boolean | string {
      return (is.number(val) && Number(val) > 0) || errorMessages.VALUE_ABOVE_ZERO;
    },
    blurMinimumInput(): void {
      this.minimumInput?.blur();
    },
    blurMaximumInput(): void {
      this.maximumInput?.blur();
    },
    blurCustomScaleToZeroInput(): void {
      this.customScaleToZeroInput?.blur();
    },
    blurThresholdValueInputInput(): void {
      this.thresholdValueInput?.blur();
    },
    addScrollListeners(): void {
      this.minimumInput = this.$refs.minRepInput as HTMLElement | null;
      this.maximumInput = this.$refs.maxRepInput as HTMLElement | null;
      this.customScaleToZeroInput = this.$refs.customScaleToZeroInput as HTMLElement | null;
      window.addEventListener("scroll", this.blurMinimumInput);
      window.addEventListener("scroll", this.blurMaximumInput);
      window.addEventListener("scroll", this.blurCustomScaleToZeroInput);
    },
    removeScrollListeners(): void {
      window.removeEventListener("scroll", this.blurMinimumInput);
      window.removeEventListener("scroll", this.blurMaximumInput);
      window.removeEventListener("scroll", this.blurCustomScaleToZeroInput);

      if (this.thresholdValueInput) {
        window.removeEventListener("scroll", this.blurThresholdValueInputInput);
      }
    },
  },
  watch: {
    showReplicaCondition(isShow: boolean): void {
      if (isShow) {
        this.thresholdValueInput = this.$refs.thresholdValueInput as HTMLElement | null;
        window.addEventListener("scroll", this.blurThresholdValueInputInput);
      } else {
        window.removeEventListener("scroll", this.blurThresholdValueInputInput);
        this.thresholdValueInput = null;
      }
    },
  },
  beforeUnmount() {
    // Quasar input is incrementing its value on scroll if the window reaches the bottom of the page, therefore we blur the element whenever the scroll event is fired in order to prevent that
    this.removeScrollListeners();
  },
});
</script>
<style lang="scss" scoped>
.auto-scale-section {
  .replicas-input {
    width: 160px;
  }
  .scale-down-select {
    width: 215px;
  }

  .dashed-seperator {
    border: 1px dashed $black-12;
  }

  .custom-auto-scale {
    position: relative;

    .custom-auto-scale-label {
      position: absolute;
      left: 2px;
      top: -12px;
    }

    .custom-auto-scale-label-minutes {
      left: 250px;
    }

    .custom-scale-to-zero-input {
      position: absolute;
      top: -30px;
      left: 60px;
    }
  }
  .new-replica-condition-section {
    .new-replica-condition-container {
      gap: 45px;
      background-color: $body-background-color;
      padding: 15px;
    }
  }
}
</style>
