import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

import type { IJwtPayload, IRefreshTokenResponse } from "@/models/auth.model";

import { API } from "@/common/api.constant";
import jwt_decode from "jwt-decode";
import { identityManagerApi } from "@/services/infra/client-apis/identity-manager-api/identity-manager-api";

export const authService = {
  refreshToken,
  isJwtAboutToExpired,
  exchangeCode,
  changePassword,
  isValidSsoEmailMapperToken,
};

// api calls
async function refreshToken(refreshToken: string): Promise<IRefreshTokenResponse> {
  return await controlPlaneService.post(`${API.v1}/token`, {
    grantType: "refresh_token",
    refreshToken: refreshToken,
  });
}

function isJwtAboutToExpired(token: string): boolean {
  const tokenPayload: IJwtPayload = jwt_decode(token);
  if (tokenPayload.exp) {
    // convert current time in milliseconds to seconds since exp is NumericDate
    const currentTime = new Date().getTime() / 1000;
    // exp time is in NumericDate format, which is seconds since epoch
    const tokenExpirationSec = tokenPayload.exp - currentTime;
    return tokenExpirationSec < 80;
  }
  return false;
}

function exchangeCode(code: string, redirectUri: string): Promise<IRefreshTokenResponse> {
  return controlPlaneService.post(`${API.v1}/token`, { grantType: "exchange_token", code, redirectUri });
}

function changePassword(newPassword: string, currentPassword: string): Promise<unknown> {
  return identityManagerApi.usersApi.changeUserPassword({ newPassword, currentPassword });
}

function isValidSsoEmailMapperToken(accessToken: string): boolean {
  const tokenPayload: IJwtPayload = jwt_decode(accessToken);
  return "email" in tokenPayload;
}
