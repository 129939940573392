// @ts-nocheck

import { eventEmitter } from "@/services/infra/event-emitter.service/event-emitter.service";

const RUNAI_APP_NAME = "runai-apps";
const isDevelopment = import.meta.env.DEV;

let LOCAL_FRONTEND_V1_URL = window.location.origin.replace("9000", "9001");

type CallbackFunc = (data: any) => void;
class OldAppService {
  private _eventEmitter = eventEmitter;

  private baseUrl: string = isDevelopment ? LOCAL_FRONTEND_V1_URL : "/frontend-v1";
  private defaultOrigin: string = isDevelopment ? LOCAL_FRONTEND_V1_URL : document.location.origin;
  private iframeId = "runai-old-app";

  constructor() {
    const appListener = (event: any) => {
      const { data } = event;
      if (data.app !== RUNAI_APP_NAME) return;

      const { eventy, appData } = data;
      this._eventEmitter.emit(eventy, appData ? JSON.parse(appData) : appData, event);
    };

    window.addEventListener("message", appListener);
  }

  addListener(event: string, cb: CallbackFunc): string {
    return this._eventEmitter.addListener(event, cb);
  }

  removeListener(event: string, listenerId: string): void {
    this._eventEmitter.removeListener(event, listenerId);
  }

  send(event: string, data: any, origin?: string): void {
    const iframeWin: HTMLIFrameElement | null = document?.getElementById(this.iframeId) as HTMLIFrameElement;
    iframeWin?.contentWindow?.postMessage(
      { app: RUNAI_APP_NAME, appData: data ? JSON.stringify(data) : data, eventy: event },
      origin || this.defaultOrigin,
    );
  }

  async get(event: string, data?: any) {
    return new Promise((resolve, reject) => {
      const listenerId = this.addListener(event, (data: any) => {
        this.removeListener(event, listenerId);
        resolve(data);
      });
      this.send(event, data);
    });
  }

  get url() {
    return this.baseUrl;
  }
}

export default new OldAppService();
