<template>
  <runai-expansion-item
    class="notifications-section settings-container"
    label="Cluster authentication"
    subheader="Enable workload submission via the Run:ai CLI or Kubectl"
  >
    <section class="researcher-authentication">
      <div class="q-pb-md">
        Use the following to enable users' workload submissions via the Run:ai command-line interface or Kubectl
      </div>
      <div>
        <server-config v-if="serverConfigInfo" :server-config="serverConfigInfo" />
      </div>
      <div class="q-mt-lg">
        <client-config v-if="clientConfigInfo" :client-config="clientConfigInfo" />
      </div>
    </section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// Stores
import { useAuthStore } from "@/stores/auth.store";
// Models
import type { ITenantClientConfigInfo, ITenantServerConfigInfo } from "@/models/tenant.model";
// Components
import { ClientConfig } from "./client-config";
import { ServerConfig } from "./server-config";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";

export default defineComponent({
  name: "researcher-authentication",
  components: {
    RunaiExpansionItem,
    ClientConfig,
    ServerConfig,
  },
  data() {
    return {
      authStore: useAuthStore(),
      clientConfigInfo: null as ITenantClientConfigInfo | null,
      serverConfigInfo: null as ITenantServerConfigInfo | null,
      realm: "" as string,
      loading: false as boolean,
    };
  },
  async created(): Promise<void> {
    this.loading = true;
    try {
      const configInfo = await this.authStore.loadTenantConfigInfo();
      this.clientConfigInfo = configInfo.clientConfigInfo;
      this.serverConfigInfo = configInfo.serverConfigInfo;
      this.realm = configInfo.clientConfigInfo.realm;
    } catch (err: unknown) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  },
});
</script>
