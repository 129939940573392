import type { INode } from "@/models/node.model";
import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import { memoryFormat, numberFormat } from "@/utils/format.util";
import { nodeUtil } from "@/utils/node.util";
import { percentFormat, tableNumberFormat, tableNumberWithPercentFormat } from "@/utils/table-format.util";
import { CLUSTER_COLUMN_FILTER_NAME } from "@/models/filter.model";
import type { Node } from "@/swagger-models/cluster-service-client";

export enum ENodeColumnName {
  Node = "node",
  Status = "status",
  NodePool = "node-pool",
  GpuType = "gpu-type",
  Gpu = "gpu",
  FreeGpu = "free-gpu",
  GpuMemory = "gpu-memory",
  AllocatedGpu = "allocated-gpu",
  UsedGpuMemory = "used-gpu-memory",
  Utilization = "utilization",
  MemoryUtilization = "memoryUtilization",
  Cpu = "cpu",
  CpuMemory = "cpu-memory",
  AllocatedCpu = "allocated-cpu",
  AllocatedCpuMemory = "allocated-cpu-memory",
  UsedCpuMemory = "used-cpu-memory",
  CpuUtilization = "cpu-utilization",
  CpuMemoryUtilization = "cpu-memory-utilization",
  SwapCpuMemory = "swap-cpu-memory",
  GraphicsEngineActivity = "graphics-engine-activity",
  SmActivity = "sm-activity",
  SmOccupancy = "sm-occupancy",
  TensorActivity = "tensor-activity",
  Fp64EngineActivity = "fp64-engine-activity",
  Fp32EngineActivity = "fp32-engine-activity",
  Fp16EngineActivity = "fp16-engine-activity",
  MemoryBWUtilization = "memory-bw-utilization",
  NvLinkBandwidthRead = "nv-link-bandwidth-read",
  NvLinkBandwidthTransmit = "nv-link-bandwidth-transmit",
  PcieBandwidthRead = "pcie-bandwidth-read",
  PcieBandwidthTransmit = "pcie-bandwidth-transmit",
  Pods = "pods",
}

export const allNodeColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: ENodeColumnName.Node,
    label: "Node",
    field: "node",
    sortable: true,
    align: "left",
    display: true,
  },
  status: {
    name: ENodeColumnName.Status,
    label: "Status",
    field: (node) => node,
    sortable: true,
    align: "left",
    display: true,
    filterKey: "status", // this is a special case where the format returns an object and needs this logic for filtering
    format: (node: Node): IStatusColOptions => {
      return nodeUtil.getStatusColOptions(node);
    },
    exportFormat: (node: INode) => node?.nodeStatus || "NA",
    customCell: ECustomCell.STATUS_COL,
  },
  nodePool: {
    name: ENodeColumnName.NodePool,
    label: "Node pool",
    field: "nodePool",
    sortable: true,
    align: "left",
    display: true,
  },
  gpuType: {
    name: ENodeColumnName.GpuType,
    label: "GPU type",
    field: "gpuType",
    sortable: true,
    align: "left",
    display: true,
    format: (type: string) => type || "-",
  },
  gpu: {
    name: ENodeColumnName.Gpu,
    label: "GPU devices",
    field: (node: INode) => node,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "gpus-clicked" },
    format: (node: INode): string[] | string => {
      if (node.gpuDevices && node.gpuDevices?.length > 0) {
        return ["" + node.gpuDevices?.length];
      }
      return node.totalGpus && parseInt(node.totalGpus) > 0 ? node.totalGpus : [];
    },
    sort: (a: INode, b: INode) => {
      const aGpuCount = a.gpuDevices ? a.gpuDevices.length : parseInt(a.totalGpus || "0");
      const bGpuCount = b.gpuDevices ? b.gpuDevices.length : parseInt(b.totalGpus || "0");
      return aGpuCount - bGpuCount;
    },
  },
  freeGpu: {
    name: ENodeColumnName.FreeGpu,
    label: "Free GPU devices",
    field: (node: INode) => node.freeGpus,
    sortable: true,
    align: "left",
    display: false,
    format: (freeGpus: string | null) => (freeGpus != null ? freeGpus : "-"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  gpuMemory: {
    name: ENodeColumnName.GpuMemory,
    label: "GPU memory",
    field: "totalGpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  allocatedGpu: {
    name: ENodeColumnName.AllocatedGpu,
    label: "Allocated GPUs",
    field: "allocatedGpus",
    sortable: true,
    align: "left",
    display: true,
    format: (allocatedGpus: string) => (allocatedGpus ? numberFormat(allocatedGpus) : "-"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  usedGpuMemory: {
    name: ENodeColumnName.UsedGpuMemory,
    label: "Used GPU memory",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  utilization: {
    name: ENodeColumnName.Utilization,
    label: "GPU compute utilization",
    field: "utilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  memoryUtilization: {
    name: ENodeColumnName.MemoryUtilization,
    label: "GPU memory utilization",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalGpuMemory"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  cpu: {
    name: ENodeColumnName.Cpu,
    label: "CPU (Cores)",
    field: "totalCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  cpuMemory: {
    name: ENodeColumnName.CpuMemory,
    label: "CPU memory",
    field: "totalCpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  allocatedCpu: {
    name: ENodeColumnName.AllocatedCpu,
    label: "Allocated CPU (Cores)",
    field: "allocatedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  allocatedCpuMemory: {
    name: ENodeColumnName.AllocatedCpuMemory,
    label: "Allocated CPU memory",
    field: "allocatedMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  usedCpuMemory: {
    name: ENodeColumnName.UsedCpuMemory,
    label: "Used CPU memory",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  cpuUtilization: {
    name: ENodeColumnName.CpuUtilization,
    label: "CPU compute utilization",
    field: "usedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  cpuMemoryUtilization: {
    name: ENodeColumnName.CpuMemoryUtilization,
    label: "CPU memory utilization",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalCpuMemory"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  swapCpuMemory: {
    name: ENodeColumnName.SwapCpuMemory,
    label: "Used swap CPU memory",
    field: "swapCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberWithPercentFormat("totalCpuMemory", true),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  graphicsEngineActivity: {
    name: ENodeColumnName.GraphicsEngineActivity,
    label: "Graphics engine activity",
    field: "graphicsEngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  smActivity: {
    name: ENodeColumnName.SmActivity,
    label: "SM activity",
    field: "smActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  smOccupancy: {
    name: ENodeColumnName.SmOccupancy,
    label: "SM occupancy",
    field: "smOccupancy",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  tensorActivity: {
    name: ENodeColumnName.TensorActivity,
    label: "Tensor activity",
    field: "tensorActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  fp64EngineActivity: {
    name: ENodeColumnName.Fp64EngineActivity,
    label: "FP64 engine activity",
    field: "fp64EngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  fp32EngineActivity: {
    name: ENodeColumnName.Fp32EngineActivity,
    label: "FP32 engine activity",
    field: "fp32EngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  fp16EngineActivity: {
    name: ENodeColumnName.Fp16EngineActivity,
    label: "FP16 engine activity",
    field: "fp16EngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  memoryBWUtilization: {
    name: ENodeColumnName.MemoryBWUtilization,
    label: "Memory bandwidth utilization",
    field: "memoryBWUtilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  nvLinkBandwidthRead: {
    name: ENodeColumnName.NvLinkBandwidthRead,
    label: "NVLink received bandwidth",
    field: "nvLinkBandwidthRead",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  nvLinkBandwidthTransmit: {
    name: ENodeColumnName.NvLinkBandwidthTransmit,
    label: "NVLink transmitted bandwidth",
    field: "nvLinkBandwidthTransmit",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  pcieBandwidthRead: {
    name: ENodeColumnName.PcieBandwidthRead,
    label: "PCIe received bandwidth",
    field: "pcieBandwidthRead",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  pcieBandwidthTransmit: {
    name: ENodeColumnName.PcieBandwidthTransmit,
    label: "PCIe transmitted bandwidth",
    field: "pcieBandwidthTransmit",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
    sort: (a: string, b: string) => parseFloat(a) - parseFloat(b),
  },
  pods: {
    name: ENodeColumnName.Pods,
    label: "Pod(s)",
    field: () => "View",
    sortable: false,
    align: "left",
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "pods-clicked" },
    hideFilter: false,
    display: true,
    exportFormat: () => "-",
  },
  cluster: {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (row: INode): string => row.clusterId,
    sortable: false,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
  },
};

export const allNodesColumns = [
  { ...allNodeColumnsMap.name, mandatory: true },
  { ...allNodeColumnsMap.status },
  { ...allNodeColumnsMap.nodePool },
  { ...allNodeColumnsMap.gpuType },
  { ...allNodeColumnsMap.gpu },
  { ...allNodeColumnsMap.freeGpu },
  { ...allNodeColumnsMap.gpuMemory },
  { ...allNodeColumnsMap.allocatedGpu },
  { ...allNodeColumnsMap.usedGpuMemory },
  { ...allNodeColumnsMap.utilization },
  { ...allNodeColumnsMap.memoryUtilization },
  { ...allNodeColumnsMap.cpu },
  { ...allNodeColumnsMap.cpuMemory },
  { ...allNodeColumnsMap.allocatedCpu },
  { ...allNodeColumnsMap.allocatedCpuMemory },
  { ...allNodeColumnsMap.usedCpuMemory },
  { ...allNodeColumnsMap.cpuUtilization },
  { ...allNodeColumnsMap.cpuMemoryUtilization },
  { ...allNodeColumnsMap.swapCpuMemory },
  { ...allNodeColumnsMap.graphicsEngineActivity },
  { ...allNodeColumnsMap.smActivity },
  { ...allNodeColumnsMap.smOccupancy },
  { ...allNodeColumnsMap.tensorActivity },
  { ...allNodeColumnsMap.fp64EngineActivity },
  { ...allNodeColumnsMap.fp32EngineActivity },
  { ...allNodeColumnsMap.fp16EngineActivity },
  { ...allNodeColumnsMap.memoryBWUtilization },
  { ...allNodeColumnsMap.nvLinkBandwidthRead },
  { ...allNodeColumnsMap.nvLinkBandwidthTransmit },
  { ...allNodeColumnsMap.pcieBandwidthRead },
  { ...allNodeColumnsMap.pcieBandwidthTransmit },
  { ...allNodeColumnsMap.pods },
  { ...allNodeColumnsMap.cluster },
] as Array<ITableColumn>;

export const nodeAdvancedMetricsColumns = [
  { ...allNodeColumnsMap.graphicsEngineActivity },
  { ...allNodeColumnsMap.smActivity },
  { ...allNodeColumnsMap.smOccupancy },
  { ...allNodeColumnsMap.tensorActivity },
  { ...allNodeColumnsMap.fp64EngineActivity },
  { ...allNodeColumnsMap.fp32EngineActivity },
  { ...allNodeColumnsMap.fp16EngineActivity },
  { ...allNodeColumnsMap.memoryBWUtilization },
  { ...allNodeColumnsMap.nvLinkBandwidthRead },
  { ...allNodeColumnsMap.nvLinkBandwidthTransmit },
  { ...allNodeColumnsMap.pcieBandwidthRead },
  { ...allNodeColumnsMap.pcieBandwidthTransmit },
];

export const nodeDependentColumns = {
  advancedMetrics: new Set([...nodeAdvancedMetricsColumns.map((col: ITableColumn) => col.name as ENodeColumnName)]),
  nodePools: new Set([ENodeColumnName.NodePool]),
  pods: new Set([ENodeColumnName.Pods]),
};
