export enum WORKLOAD_TEMPLATE_ROUTE_NAMES {
  WORKLOAD_TEMPLATE_INDEX = "workload-template-index",
  WORKLOAD_TEMPLATE_NEW = "workload-template-new",
  WORKLOAD_TEMPLATE_NEW_APP = "workload-template-new-app",
  WORKLOAD_TEMPLATE_ASSETS_APP = "workload-template-assets-app",
  WORKLOAD_TEMPLATE_ENVIRONMENT_NEW = "workload-template-environment-new",
  WORKLOAD_TEMPLATE_COMPUTE_RESOURCE_NEW = "workload-template-compute-resource-new",
  WORKLOAD_TEMPLATE_NFS_NEW = "workload-template-nfs-new",
  WORKLOAD_TEMPLATE_HOST_PATH_NEW = "workload-template-host-path-new",
  WORKLOAD_TEMPLATE_PVC_NEW = "workload-template-pvc-new",
  WORKLOAD_TEMPLATE_S3_NEW = "workload-template-s3-new",
  WORKLOAD_TEMPLATE_GIT_NEW = "workload-template-git-new",
  WORKLOAD_TEMPLATE_CONFIG_MAP_NEW = "workload-template-config-map-new",
}
