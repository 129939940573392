<template>
  <q-td>
    <q-btn
      v-if="!isTextOnly"
      flat
      :ripple="false"
      color="primary"
      class="btn-link no-padding"
      :label="displayText"
      @click.stop="$emit('custom-cell-event', row)"
    />
    <span v-else>{{ displayText }}</span>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// models
import { ECustomCell, type ITableColumn } from "@/models/table.model";

// services
import { getFieldContent } from "@/utils/table-format.util";
import { getCustomCellConfig } from "@/utils/table-format.util/table-format.util";

export default defineComponent({
  emits: ["custom-cell-event"],
  props: {
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  computed: {
    displayText(): string {
      return getFieldContent(this.column, this.row);
    },
    isTextOnly(): boolean {
      return !!getCustomCellConfig(this.column, ECustomCell.LINK_COL)?.isTextOnly;
    },
  },
});
</script>
