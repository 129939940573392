import type { RequestHeaders } from "@/models/http-response.model";
import type { Dashboards } from "@/swagger-models/backend-client";
import { K8S_API } from "@/common/api.constant";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
export const grafanaService = {
  smgLogin,
  getDashboards,
  grafanaLogout,
};

async function smgLogin(headers: RequestHeaders): Promise<void> {
  return controlPlaneService.get(`grafana`, {}, { headers, useIdToken: true, responseType: "json" });
}

async function grafanaLogout(): Promise<void> {
  return controlPlaneService.get(`grafana/logout`, {}, { useIdToken: true, responseType: "json" });
}

async function getDashboards(clusterId?: string): Promise<Dashboards> {
  const query = clusterId ? `?clusterId=${clusterId}` : "";
  return controlPlaneService.get(`${K8S_API.v1}/dashboards${query}`, {}, { useIdToken: true, responseType: "json" });
}
