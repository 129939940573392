<template>
  <section class="runai-draggable-list-order">
    <section class="list-container">
      <ul class="list-numbers-col">
        <li class="title">Order of priority</li>
        <li class="item item-number" v-for="item in listOfNumbers" :key="item" :style="currentHeight">{{ item }}</li>
      </ul>

      <ul class="main-list-col" ref="main-list-col" :class="{ 'list-disabled': disabled }">
        <li class="title">
          <span>{{ mainListTitle }}</span>
          <runai-tooltip
            v-if="!disabled"
            icon="fa-solid fa-rotate-left"
            @click="onReset"
            dark-mode
            :tooltip-text="resetTooltipText"
          />
        </li>
        <vue-draggable-next class="dragArea list-group w-full" ghost-class="ghost" :list="list" @change="onChange">
          <transition-group type="transition" name="flip-list">
            <li class="item" v-for="item in list" :key="item[rowKey]">
              <q-icon
                v-if="!disabled"
                name="fas fa-grip-dots-vertical"
                class="draggable-icon"
                size="24px"
                style="color: rgba(0, 0, 0, 0.12)"
              ></q-icon>

              <div class="item-content">
                <slot name="list-item" v-bind="item"></slot>
              </div>
            </li>
          </transition-group>
        </vue-draggable-next>
      </ul>
    </section>
    <div class="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";

// cmps
import { VueDraggableNext } from "vue-draggable-next";
import { RunaiTooltip } from "@/components/common/runai-tooltip";

export default defineComponent({
  name: "runai-draggable-list-order",
  components: {
    VueDraggableNext,
    RunaiTooltip,
  },
  emits: ["change", "reset"],
  props: {
    list: {
      type: Array as PropType<Array<any>>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    rowKey: {
      type: String as PropType<string>,
      required: false,
      default: "id",
    },
    mainListTitle: {
      type: String as PropType<string>,
      required: false,
    },
    resetTooltipText: {
      type: String as PropType<string>,
      required: false,
      default: "Reset to default",
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  data() {
    return {
      defaultHeight: 50 as number,
      itemHeight: null as number | null,
    };
  },
  mounted() {
    const mainListCol = this.$refs["main-list-col"] as HTMLElement;
    this.itemHeight = mainListCol.querySelector(".item")?.clientHeight || null;
  },
  computed: {
    listOfNumbers(): Array<number> {
      return Array(this.list.length)
        .fill(0)
        .map((_, i) => i + 1);
    },
    currentHeight(): string {
      return `height: ${this.itemHeight ? this.itemHeight : this.defaultHeight}px`;
    },
  },
  methods: {
    onChange(): void {
      this.$emit("change", this.list);
    },
    onReset(): void {
      this.$emit("reset");
    },
  },
});
</script>

<style lang="scss">
.ghost {
  background: transparent !important;
  border: $black-54 1px dashed;

  > div,
  > svg {
    opacity: 0;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>

<style lang="scss" scoped>
.runai-draggable-list-order {
  $size: 50px;
  border: 1px solid $black-12;
  padding: 5px 15px 15px 15px;

  .list-container {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .main-list-col {
    flex: 1;

    .title {
      padding: 0 10px 0 54px;
      justify-content: space-between;
    }

    &.list-disabled {
      pointer-events: none;

      .title {
        padding-left: 20px;
      }
    }
  }

  .item {
    width: 100%;
    min-height: $size;
    background-color: $body-background-color;
    border-radius: 4px;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    &:hover {
      background-color: $grey-4;
    }
    &.item-number {
      width: $size;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .list-numbers-col {
    padding-right: 10px;
  }

  .title {
    height: 42px;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .item-content {
    flex: 1;
    padding-left: 10px;

    &:first-child {
      padding-left: 0px;
    }
  }
}
</style>
