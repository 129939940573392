<template>
  <runai-base-dialog :model-value="true" @close="onCancel" size="width-md" :no-padding="true">
    <template #header>Remove</template>

    <template #body>
      <div class="remove-cluster-body q-px-lg q-py-md column">
        <p class="remove-modal-msg q-mt-md">Remove: {{ clusterName }}?</p>
        <div class="main-content row no-wrap">
          <q-icon class="q-mr-lg q-mt-xs" color="red" size="20px" name="fa-solid fa-triangle-exclamation"></q-icon>
          <div class="remove-modal-info">
            <div class="q-mb-lg">
              <div>Once a cluster is removed, it cannot be restored.</div>
              <div>
                All the associated departments, projects, workloads, environments, data sources, compute resources,
                templates, credentials, event history, node pools and dashboards will be permanently deleted.
              </div>
            </div>
            <div class="q-mb-md">The Run:ai cluster installation will remain on the Kubernetes cluster.</div>
            <div class="q-mb-md italic">
              For more information, see the
              <a href="https://docs.run.ai/latest/admin/runai-setup/cluster-setup/cluster-delete/" target="_blank"
                >deleting a cluster Installation guide</a
              >
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer-left> </template>
    <template #footer>
      <q-btn label="Cancel" color="primary" flat @click="onCancel" aid="remove-modal-cancel-button" />
      <q-btn label="Remove" color="primary" :loading="deleting" @click="onRemove" aid="remove-modal-remove-button" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// Components
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

const enum Events {
  cancel = "cancel",
  remove = "remove",
}
export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: [Events.cancel, Events.remove],
  props: {
    clusterName: {
      type: String as PropType<string>,
      required: true,
    },
    deleting: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    onRemove(): void {
      this.$emit(Events.remove);
    },
    onCancel(): void {
      this.$emit(Events.cancel);
    },
  },
});
</script>

<style lang="scss" scoped>
.remove-cluster-body {
  a:hover {
    text-decoration: underline;
  }
  .remove-modal-msg {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
  }
  .remove-modal-info {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
