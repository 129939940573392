import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";
import { USER_ROUTES_NAMES } from "@/router/users.routes/users.routes.names";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";
import { useAppStore } from "@/stores/app.store";

export const usersRoutes: RunaiRouteRecord = {
  path: "/users",
  component: () => import("@/views/users/users-index.vue"),
  name: USER_ROUTES_NAMES.USER_INDEX,
  meta: {
    pageTitle: "Users",
    resourceType: ResourceType.Users,
    minPermittedActions: [Action.Read],
    requiresAuth: true,
    parentSubject: EMenuSubjectNames.ACCESS,
  },
  beforeEnter: (to, from, next) => {
    const appStore = useAppStore();
    if (appStore.isNewNavigationFeatureOn) {
      to.meta.fullWidth = false;
      to.meta.supportBack = false;
    } else {
      to.meta.fullWidth = true;
      to.meta.supportBack = true;
    }

    next();
  },
};
