import type { INodeType } from "@/models/node-type.model";
import { K8S_API } from "@/common/api.constant";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

const getEndPoint = (clusterUuid: string) => `${K8S_API.v1}/clusters/${clusterUuid}/nodetypes`;
export const nodeTypeService = {
  getNodeTypes,
  createNodeType,
};
async function getNodeTypes(clusterUuid: string): Promise<INodeType[]> {
  return controlPlaneService.get(getEndPoint(clusterUuid));
}

async function createNodeType(name: string, clusterUuid: string): Promise<INodeType> {
  return controlPlaneService.post(getEndPoint(clusterUuid), { name });
}
