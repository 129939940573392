import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { K8S_API } from "@/common/api.constant";

import { tenantManagerApi } from "@/services/infra/client-apis/tenants-manager-api/tenants-manager-api";
import { httpService } from "@/services/infra/https.service/http.service";

import type { GetTenantLogo200Response, Tenant } from "@/swagger-models/tenants-manager-client";

export const tenantService = {
  get,
  getTenantAuthProviderData,
  getTenantLogo,
  signEULA,
};

const ENDPOINT = `${K8S_API.v1}/tenant`;

async function get(): Promise<Tenant[]> {
  try {
    const response = await tenantManagerApi.tenantApi.getTenants();
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getTenantLogo(): Promise<GetTenantLogo200Response> {
  try {
    const response = await tenantManagerApi.logoApi.getTenantLogo();
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getTenantAuthProviderData(tenantName: string): Promise<object> {
  return controlPlaneService.get(`${ENDPOINT}/${tenantName}/auth`);
}

async function signEULA(email: string): Promise<void> {
  try {
    const response = await tenantManagerApi.tenantApi.postTenantEula({
      email: email,
    });
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
