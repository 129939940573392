import { type IConfig, EEnvTypes } from "@/models/config.model";
import { identityManagerApi } from "@/services/infra/client-apis/identity-manager-api/identity-manager-api";

import type { AuthConfiguration } from "@/swagger-models/identity-manager-client";

export const configService = {
  get,
  getSassEnvList,
  getCustomerEnvList,
  isStagingOrTestOrLocalEnv,
  isLocalEnv,
  isTestEnv,
  isStagingEnv,
};

async function get(hostname: string): Promise<IConfig> {
  const env = _getEnvByHostname(hostname);
  const response = await identityManagerApi.configApi.getTenantConfiguration();
  const config: AuthConfiguration = response.data;
  config.authHost = config.authHost + "/auth/realms/" + config.tenantName;
  return { ...config, env };
}

function getSassEnvList(): Array<string> {
  return [
    EEnvTypes.CustomerCloud,
    EEnvTypes.Cloud,
    EEnvTypes.CustomerStaging,
    EEnvTypes.Staging,
    EEnvTypes.CustomerTest,
    EEnvTypes.Test,
  ];
}

function getCustomerEnvList(): Array<string> {
  return [EEnvTypes.CustomerCloud, EEnvTypes.CustomerStaging, EEnvTypes.CustomerTest];
}

function _getEnvByHostname(hostname: string = window.location.hostname): string {
  if (hostname.startsWith("localhost")) {
    return EEnvTypes.Local;
  }
  if (!hostname.endsWith(".run.ai")) {
    return EEnvTypes.OnPremise;
  }
  if (hostname == "test.run.ai") {
    return EEnvTypes.Test;
  }
  if (hostname.endsWith(".test.run.ai")) {
    return EEnvTypes.CustomerTest;
  }
  if (hostname.endsWith(".staging.run.ai")) {
    return EEnvTypes.CustomerStaging;
  }
  if (hostname == "staging.run.ai") {
    return EEnvTypes.Staging;
  }
  if (hostname == "app.run.ai") {
    return EEnvTypes.Cloud;
  }
  if (hostname.endsWith(".run.ai")) {
    return EEnvTypes.CustomerCloud;
  }
  return EEnvTypes.Unknown;
}

function isStagingEnv(): boolean {
  return _getEnvByHostname() === EEnvTypes.Staging || _getEnvByHostname() === EEnvTypes.CustomerStaging;
}

function isTestEnv(): boolean {
  return _getEnvByHostname() === EEnvTypes.Test || _getEnvByHostname() === EEnvTypes.CustomerTest;
}

function isLocalEnv(): boolean {
  return _getEnvByHostname() === EEnvTypes.Local;
}

function isStagingOrTestOrLocalEnv(): boolean {
  return isStagingEnv() || isTestEnv() || isLocalEnv();
}
