import {
  Configuration,
  PVCsApi,
  type ConfigurationParameters,
  SecretsApi,
  ConfigMapsApi,
  StorageClassesApi,
} from "@/swagger-models/k8s-objects-tracker-client";

class K8sObjectTrackerApi {
  configuration: Configuration;

  pvcApi: PVCsApi;
  secretsApi: SecretsApi;
  configMapsApi: ConfigMapsApi;
  storageClassesApi: StorageClassesApi;
  constructor() {
    this.configuration = new Configuration();

    this.pvcApi = new PVCsApi(this.configuration, this.baseUrl);
    this.secretsApi = new SecretsApi(this.configuration, this.baseUrl);
    this.storageClassesApi = new StorageClassesApi(this.configuration, this.baseUrl);
    this.configMapsApi = new ConfigMapsApi(this.configuration, this.baseUrl);
  }
  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const k8sObjectTrackerApi = new K8sObjectTrackerApi();
