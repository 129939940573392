<template>
  <runai-expansion-item
    :label="label"
    :default-opened="false"
    :hide-expend-icon="false"
    :disable-opening="true"
    :section-invalid="false"
    :aid="aid"
  >
    <template #subheader>
      <div class="row items-center justify-between q-pr-sm">
        <span>{{ summary }}</span>
        <q-btn icon="fa-solid fa-pen" flat round size="sm" @click="onBack" aid="back-btn">
          <q-tooltip anchor="center right" self="center left">{{ backTooltip }}</q-tooltip>
        </q-btn>
      </div>
    </template>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";

export default defineComponent({
  components: {
    RunaiExpansionItem,
  },
  emits: ["on-back"],
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    summary: {
      type: String as PropType<string>,
      required: true,
    },
    backTooltip: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    aid(): string {
      return `title-${this.label.replace(" ", "-").toLowerCase()}-and-back-section`;
    },
  },
  methods: {
    onBack(): void {
      this.$emit("on-back");
    },
  },
});
</script>

<style scoped lang="scss"></style>
