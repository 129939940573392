<template>
  <div class="email-notifications">
    <div class="row items-center q-mb-md">
      <span class="q-mr-md title">Email notification</span>
    </div>
    <div class="row items-center q-mb-md">
      <span class="q-mr-md">Set the email server through which the email notifications will be sent</span>
      <runai-tooltip
        aid="tool-tip-email-notification"
        tooltip-text="To allow users to receive email notifications and set their own preferences via the user settings, first configure the details of the email server through which the emails will be sent"
        width="400px"
        tooltip-position="right"
      />
    </div>
    <setting-readonly-input>
      <template v-slot:default="{ readonly }">
        <q-btn
          aid="add-email-botifications-btn"
          label="+ email server"
          flat
          color="primary"
          v-if="showAddButton"
          @click="edit"
          :disable="readonly"
        />
      </template>
    </setting-readonly-input>
    <setting-editable-field
      v-if="!editMode && hasSmtpServer"
      delete-tool-tip="Remove email server"
      edit-tool-tip="Edit email server"
      label="Email server"
      delete-sub-title="Email notifications will be disabled."
      @edit="edit"
      @delete="deleteSmtpServer"
    />
    <setting-wrapper-box
      v-if="editMode"
      primary-button-label="save"
      :secondary-button-label="isReadOnly ? 'Close' : 'Cancel'"
      @primary-button-clicked="saveSmtpServer"
      @secondary-button-clicked="cancel"
      has-footer
      :validation-message="validationMessage"
      :show-primary="!isReadOnly"
    >
      <email-server-form :email-server-settings="emailServerSettings" @update="updateSettings" ref="emailServerForm" />
      <div class="q-mt-lg">
        <verify-button
          :verify-status="verifyStatus"
          @verify="verifyEmailServer"
          :validation-message="emailServerValidation"
        />
      </div>
    </setting-wrapper-box>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Models
import { EmailErrorMessages, type ISettingEmailServer } from "@/models/setting.model";
import { ERunaiVerifyButtonStatus } from "@/components/common/runai-verify-button";

// Components
import { RunaiTooltip } from "@/components/common/runai-tooltip";
// Stores
import { useSettingStore } from "@/stores/setting.store";
// Utils
import SettingWrapperBox from "@/components/settings/setting-wrapper-box/setting-wrapper-box.vue";
import SettingEditableField from "@/components/settings/setting-editable-field/setting-editable-field.vue";
import { DEFAULT_EMPTY_EMAIL_SETTINGS } from "@/models/setting.model";

import { VerifyButton } from "@/components/settings/sections/notifications/email-notifications/verify-button";
import { EmailServerForm } from "./email-server-form";
import { notificationService } from "@/services/control-plane/notifications.service/notification.service";
import { settingsUtil } from "@/utils/settings.util";
import { alertUtil } from "@/utils/alert.util";
import { isEqual } from "@/utils/common.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { ResourceType } from "@/swagger-models/authorization-client";
import SettingReadonlyInput from "@/components/settings/setting-readonly-input/setting-readonly-input.vue";

export default defineComponent({
  name: "email-notifications",
  components: {
    SettingReadonlyInput,
    EmailServerForm,
    VerifyButton,
    SettingEditableField,
    SettingWrapperBox,
    RunaiTooltip,
  },
  emits: ["updated", "deleted"],
  props: {
    setting: {
      type: Object as PropType<ISettingEmailServer | undefined>,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      enableDismiss: false as boolean,
      editMode: false as boolean,
      emailServerSettings: {} as ISettingEmailServer,
      verifyStatus: ERunaiVerifyButtonStatus.None as ERunaiVerifyButtonStatus,
    };
  },
  created() {
    if (this.setting) {
      this.emailServerSettings = { ...this.setting };
    } else {
      this.emailServerSettings = DEFAULT_EMPTY_EMAIL_SETTINGS;
    }
  },
  computed: {
    hasSmtpServer(): boolean {
      return this.setting != undefined && this.setting.smtpHost != "";
    },
    showAddButton(): boolean {
      return !this.hasSmtpServer && !this.editMode;
    },
    hasChanges(): boolean {
      return this.setting != undefined && JSON.stringify(this.setting) !== JSON.stringify(this.emailServerSettings);
    },
    validationMessage(): string {
      return this.hasChanges ? "Unsaved changes" : "";
    },
    emailServerValidation(): string {
      switch (this.verifyStatus) {
        case ERunaiVerifyButtonStatus.None:
          return "To continue, complete the verification by clicking above";
        case ERunaiVerifyButtonStatus.Verified:
          return "Verification successful";
        case ERunaiVerifyButtonStatus.Failed:
          return "Verification failed. Check server details.";
        default:
          return "";
      }
    },
    isReadOnly(): boolean {
      return usePermissionStore().isReadOnly(ResourceType.Settings);
    },
  },
  methods: {
    async cancel() {
      this.emailServerSettings = { ...this.setting } as ISettingEmailServer;
      this.editMode = false;
    },
    async edit() {
      this.editMode = true;
    },
    async deleteSmtpServer(): Promise<void> {
      try {
        await notificationService.deleteSmtpConfig();
        this.emailServerSettings = { ...DEFAULT_EMPTY_EMAIL_SETTINGS };
        this.editMode = false;
        this.verifyStatus = ERunaiVerifyButtonStatus.None;
        this.$emit("deleted");
      } catch {
        this.$q.notify(alertUtil.getError(EmailErrorMessages.DELETE_SERVER_ERROR));
      }
    },
    async saveSmtpServer(): Promise<void> {
      if (await this.validateFields()) {
        try {
          await notificationService.updateSmtpConfig(settingsUtil.emailSettingsToSmtpConfig(this.emailServerSettings));
          this.editMode = false;
          this.$emit("updated", this.emailServerSettings);
        } catch (error) {
          this.$q.notify(alertUtil.getError(EmailErrorMessages.EMAIL_SAVED_ERROR));
        }
        this.editMode = false;
      }
    },
    async validateFields(): Promise<boolean> {
      const isEmailServerFormValid = await this.validateMandatoryFields();
      return isEmailServerFormValid && this.verifyStatus === ERunaiVerifyButtonStatus.Verified;
    },
    async validateMandatoryFields(): Promise<boolean> {
      return await (this.$refs.emailServerForm as typeof EmailServerForm).validate();
    },
    updateSettings(settings: ISettingEmailServer): void {
      this.emailServerSettings = { ...settings };
      if (!isEqual(this.emailServerSettings, this.setting)) {
        this.verifyStatus = ERunaiVerifyButtonStatus.None;
      }
    },
    async verifyEmailServer(): Promise<void> {
      const isMandatoryFieldsFilled = await this.validateMandatoryFields();
      if (!isMandatoryFieldsFilled) {
        this.verifyStatus = ERunaiVerifyButtonStatus.None;
        return;
      }
      this.verifyStatus = ERunaiVerifyButtonStatus.Verifying;
      try {
        const verificationObject = await notificationService.verifySmtpConfig(
          settingsUtil.emailSettingsToSmtpConfig(this.emailServerSettings),
        );
        if (verificationObject.errorCode || verificationObject.errorMessage) {
          this.verifyStatus = ERunaiVerifyButtonStatus.Failed;
        } else {
          this.verifyStatus = ERunaiVerifyButtonStatus.Verified;
        }
      } catch (error) {
        this.verifyStatus = ERunaiVerifyButtonStatus.Failed;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.email-notifications {
  .title {
    font-size: 16px;
  }
}
</style>
