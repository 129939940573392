<template>
  <runai-base-dialog :model-value="isModalOpen" @close="$emit('close')" size="width-md" no-padding>
    <template #header>Delete</template>

    <template #body>
      <q-card>
        <q-card-section class="text-wrapper">
          <div class="q-px-lg">
            <span class="text-subtitle1">Permanently delete project: {{ projectName }}?</span>
            <p class="text-body2 q-py-xs">
              Once a project is deleted, it cannot be restored <br />
              The associated namespace <span class="text-weight-bold">{{ namespace }}</span> and workloads in the cluster
              will remain. <br />
              To delete the namespace run the following command as the kube admin in the cluster:
            </p>
            <div class="row">
              <div class="col-7">
                <runai-copyable-code-block :code="displayedNamespaceText" />
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>

    <template #footer>
      <q-btn label="Cancel" color="primary" flat @click="$emit('close')" aid="delete-modal-cancel-button" />
      <q-btn
        :loading="loading"
        :disable="loading"
        label="Delete"
        color="primary"
        @click="$emit('delete')"
        aid="delete-modal-delete-button"
      />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { copyToClipboard } from "quasar";
import { alertUtil } from "@/utils/alert.util";
import { RunaiCopyableCodeBlock } from "@/components/common/runai-copyable-code-block/";

export default defineComponent({
  components: { RunaiCopyableCodeBlock, RunaiBaseDialog },
  emits: ["close", "delete"],
  props: {
    projectName: {
      type: String as PropType<string>,
      required: true,
    },
    namespace: {
      type: [String, null] as PropType<string | null>,
      required: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return { isModalOpen: true as boolean };
  },
  computed: {
    displayedNamespaceText() {
      const namespaceName = this.namespace || `runai-${this.projectName}`;
      return `kubectl delete ns ${namespaceName}`;
    },
  },
  methods: {
    async copyText(text: string): Promise<void> {
      await copyToClipboard(text);
      this.$q.notify(alertUtil.getSuccess("Copied to clipboard"));
    },
  },
});
</script>
<style lang="scss">
.text-wrapper {
  padding: 10px 2px 1px;
}
</style>
