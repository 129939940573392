<template>
  <figure class="runai-resources-chart">
    <div ref="container"></div>
  </figure>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import Highcharts, { type Options } from "highcharts";

import { chartUtil } from "@/utils/chart.util";

export default defineComponent({
  props: {
    overQuotaValue: {
      type: Number as PropType<number>,
      required: true,
    },
    quotaValue: {
      type: Number as PropType<number>,
      required: true,
    },
    allocatedValue: {
      type: Number as PropType<number>,
      required: true,
    },
    showToolTip: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      options: chartUtil.getResourcesOptions(this.overQuotaValue, this.quotaValue, this.allocatedValue) as Options,
      chart: {} as Highcharts.Chart,
    };
  },
  mounted() {
    this.chart = Highcharts.chart(this.$refs.container as HTMLElement, this.options);
  },
  watch: {
    showToolTip: function (newVal: boolean) {
      this.chart.tooltip.options.hideDelay = newVal ? 1000000 : 0;
      newVal ? this?.chart?.series[0]?.data[0]?.onMouseOver() : this.chart?.tooltip?.hide(0);
    },
  },
});
</script>

<style lang="scss">
.runai-resources-chart,
.highcharts-data-table table {
  height: 105px;
}
</style>
