// Stores
import { defineStore } from "pinia";

// Models
import type {
  WorkloadCreationRequest,
  Inference,
  InferenceCreationRequestV2,
  InferenceV2,
  PolicyInfo,
  WorkspaceSpecificRunParams,
} from "@/swagger-models/assets-service-client";
import type { IUIInferenceCreation } from "@/models/workload.model";
import type { ILoadedItemizeDefaults } from "@/models/policy.model";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { deepCopy } from "@/utils/common.util";

// services
import { inferenceService } from "@/services/control-plane/inference.service/inference.service";
import { useClusterStore } from "./cluster.store";
import { unleashService } from "@/services/infra/unleash.service/unleash.service";

// constants
import {
  MIN_CLUSTER_VERSION_FOR_SERVING_ENDPOINT_ACCESS,
  MIN_WORKLOAD_CREATION_V2_VERSION,
} from "@/common/version.constant";
import { useSettingStore } from "./setting.store";

export const useInferenceStore = defineStore("Inference", {
  state: () => ({
    inference: workloadUtil.getEmptyUIInferenceCreation() as IUIInferenceCreation,
    loadedInferenceModelWithDefaultsAndPolicy: null as InferenceV2 | null,
    selectedInference: null as Inference | null,
    originRouteName: "" as string,
  }),
  getters: {
    inferenceName(): string {
      return this.inference.name || "";
    },
    loadedPolicyInfo(): PolicyInfo | null | undefined {
      return this.loadedInferenceModelWithDefaultsAndPolicy?.policyInfo || null;
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedItemizeDefaults(): ILoadedItemizeDefaults | null {
      const itemizeDefaults = {
        annotations: {
          instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.annotations || null,
        },
        labels: { instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.labels || null },
        environmentVariables: {
          instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.environmentVariables || null,
        },
        tolerations: {
          instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.tolerations || null,
          attributes:
            this.loadedInferenceModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.specificRunParams
              ?.tolerations || null,
        },
      };
      return deepCopy(itemizeDefaults);
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedSpecificRunParamsWithDefaults(): WorkspaceSpecificRunParams | null {
      return deepCopy(this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams || null);
    },
    supportingServingPortAccess:
      () =>
      (clusterId: string): boolean => {
        const clusterStore = useClusterStore();
        return (
          clusterStore.isClusterVersionSufficient(clusterId, MIN_CLUSTER_VERSION_FOR_SERVING_ENDPOINT_ACCESS) &&
          unleashService.showServingEndpointAccessInference()
        );
      },
  },
  actions: {
    async createInference(inference: IUIInferenceCreation): Promise<Inference | InferenceV2> {
      const clusterStore = useClusterStore();
      const settingStore = useSettingStore();
      const isWorkloadCreationFromControlPlan =
        clusterStore.isClusterVersionSufficient(inference.clusterId, MIN_WORKLOAD_CREATION_V2_VERSION) &&
        settingStore.isWorkloadSubmissionV2Enabled;

      let workloadCreated: Inference | InferenceV2;
      if (isWorkloadCreationFromControlPlan) {
        const workloadCreationRequest: InferenceCreationRequestV2 = workloadUtil.getInferenceCreationRequestV2(
          inference,
          this.supportingServingPortAccess(inference.clusterId),
        );
        workloadCreated = await inferenceService.createInferenceV2(workloadCreationRequest);
      } else {
        const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getInferenceWorkloadCreationRequest(
          inference,
          this.supportingServingPortAccess(inference.clusterId),
        );

        workloadCreated = await inferenceService.create(workloadCreationRequest);
      }

      return workloadCreated;
    },
    setInference(inference: IUIInferenceCreation): void {
      this.inference = inference;
    },
    resetCreateInferenceData(): void {
      this.inference = workloadUtil.getEmptyUIInferenceCreation();
      this.loadedInferenceModelWithDefaultsAndPolicy = null;
    },
    setOriginRoutePage(name: string): void {
      this.originRouteName = name;
    },
    setLoadedInferenceModelWithDefaults(inference: InferenceV2): void {
      this.loadedInferenceModelWithDefaultsAndPolicy = inference;
    },
  },
});
