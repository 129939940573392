import type { IFilterBy } from "@/models/filter.model";
import { ECustomCell, type ITableColumn } from "@/models/table.model";
import type { Role } from "@/swagger-models/authorization-client";

import { dateUtil } from "@/utils/date.util";

export const allRoleColumnsMap: Record<string, ITableColumn> = {
  role: {
    name: "role",
    label: "Role",
    field: (row: Role) => row.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "role-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: Role) => row.createdBy,
    sortable: true,
    align: "left",
  },
  creationTime: {
    name: "creationTime",
    label: "Creation time",
    field: (row: Role) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allRoleColumns: Array<ITableColumn> = [
  allRoleColumnsMap.role,
  allRoleColumnsMap.createdBy,
  allRoleColumnsMap.creationTime,
];

export const roleIndexColumns: Array<ITableColumn> = [
  { ...allRoleColumnsMap.role, display: true, mandatory: true },
  { ...allRoleColumnsMap.createdBy, display: true },
  { ...allRoleColumnsMap.creationTime, display: false },
];

export const defaultRolesFilterBy: IFilterBy = {
  sortBy: "role",
  descending: false,
  page: 1,
  rowsPerPage: 20,
  columnFilters: [],
  searchTerm: "",
  displayedColumns: [allRoleColumnsMap.role.name, allRoleColumnsMap.createdBy.name],
};
