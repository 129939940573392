import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import { getEnvType, EnvType, prodDomains, stagingDomains, testDomains } from "@/utils/infra.util/infra.util";

export { createLogger };
interface ISentryProps {
  dsn: string;
  tracePropagationTargets: RegExp[];
}

const productionProps: ISentryProps = {
  dsn: "https://63cdaa2932412d2663ff68f63a09f65f@o4507424624148480.ingest.us.sentry.io/4507425212727296",
  tracePropagationTargets: [prodDomains],
};

const stagingProps: ISentryProps = {
  dsn: "https://5e51d2ef367f9980ee19eebd62d3e15d@o4507424624148480.ingest.us.sentry.io/4507463351009280",
  tracePropagationTargets: [stagingDomains],
};

const testProps: ISentryProps = {
  dsn: "https://58f37e526de36476eac82dda64e341b1@o4507424624148480.ingest.us.sentry.io/4507531786911744",
  tracePropagationTargets: [testDomains],
};

function createLogger(app: App): void {
  let envProps: ISentryProps | null = null;
  const envType = getEnvType(window.location.hostname);
  // test/staging should be before the prod check because it's a subset of the prod regex
  if (envType === EnvType.TEST) {
    envProps = testProps;
  } else if (envType === EnvType.STAGING) {
    envProps = stagingProps;
  } else if (envType === EnvType.PROD) {
    envProps = productionProps;
  }

  if (!envProps) return;

  initSentry(app, envProps);
}

function initSentry(app: App, props: ISentryProps): void {
  Sentry.init({
    app,
    dsn: props.dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // we want to trace only the API calls: /api | /v1/k8s | /researcher/api | _version
    tracePropagationTargets: [/^\/(api|v1\/k8s|researcher\/api|_version)?/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
