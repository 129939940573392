<template>
  <q-td class="license-status-col row justify-between items-center">
    <span :class="statusClass">{{ status }}</span>
    <q-btn v-if="showRenewBtn" size="sm" color="grey-4" text-color="black-70" label="renew license">
      <q-menu max-width="376px" class="q-py-sm q-px-md" :offset="[0, 10]">
        <p>
          We recommend using valid licensed software.<br />
          To renew this license, contact
          <span class="link" @click="emailSupport">support@run.ai</span>.<br />If you've already renewed this license,
          <span class="link" @click="$emit('custom-cell-event', column.customCellEvent)" v-close-popup>upload it</span>
          now.
        </p>
        <div class="row justify-end">
          <q-btn flat color="primary" label="got it" v-close-popup />
        </div>
      </q-menu>
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import type { ILicense } from "@/models/license.model";
import type { ITableColumn } from "@/models/table.model";
import { getFieldContent } from "@/utils/table-format.util";
import { emailUtil } from "@/utils/email.util";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  emits: ["custom-cell-event"],
  props: {
    row: {
      type: Object as PropType<ILicense>,
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  computed: {
    status(): string {
      return getFieldContent(this.column, this.row);
    },
    statusClass(): string {
      switch (this.status) {
        case "Expired":
          return "expired";
        case "Active":
          return "active";
        default:
          return "expiring-soon";
      }
    },
    showRenewBtn(): boolean {
      return this.status !== "Active";
    },
  },
  methods: {
    emailSupport(): void {
      const a = document.createElement("a");
      a.href = emailUtil.renewLicenseTemplate();
      a.target = "_blank";
      a.click();
    },
  },
});
</script>
<style lang="scss" scoped>
.expired {
  color: $negative;
}
.active {
  color: $success;
}
.expiring-soon {
  color: $warning;
}
span.link {
  color: $primary;
  cursor: pointer;
}
</style>
