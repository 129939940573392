<template>
  <q-card flat class="model-spec-card-skeleton">
    <q-skeleton height="150px" square />

    <q-card-section>
      <q-skeleton type="text" class="text-subtitle1" />
      <q-skeleton type="text" width="50%" class="text-subtitle1" />
      <q-skeleton type="text" class="text-caption" />
    </q-card-section>
  </q-card>
</template>

<style lang="scss" scoped>
.model-spec-card-skeleton {
  width: 300px;
  border-radius: 0;
  height: 330px;
}
</style>
