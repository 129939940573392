import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import type { ICustomHeaderRadioButtonsConfig } from "@/models/table.model";

export interface IEmptyProjectModelConfig {
  nodePoolsNamesAndIds: INodePoolsNameAndId[];
  clusterId: string;
  isCpuEnabled: boolean;
  parentId?: string;
}

export interface IEmptyDepartmentModelConfig {
  nodePoolsNamesAndIds: INodePoolsNameAndId[];
  clusterId: string;
  isCpuEnabled: boolean;
}
/** Over time data **/
export enum EOrgUnitOverTimeValue {
  TwentyFourHours = "24h",
  SevenDays = "7d",
  ThirtyDays = "30d",
}

export const ORG_UNIT_OVERTIME_CUSTOM_HEADER_CONFIG = {
  label: "Show the last",
  options: [
    { label: "24 hours", value: EOrgUnitOverTimeValue.TwentyFourHours, shortLabel: "24h" },
    { label: "7 days", value: EOrgUnitOverTimeValue.SevenDays, shortLabel: "7d" },
    { label: "30 days", value: EOrgUnitOverTimeValue.ThirtyDays, shortLabel: "30d" },
  ],
  iconName: "fa-regular fa-calendar-clock q-mr-sm text-black-30",
  chipPrefix: "Last",
} as Partial<ICustomHeaderRadioButtonsConfig>;

export enum EOrgUnitOverTimeColumnName {
  AverageGpuUtilization = "averageGpuUtilization",
  AverageGpuAllocation = "averageGpuAllocation",
}
