export const stringUtil = {
  parse,
  toString,
  spaceToDash,
  toLowerCase,
  generateTempPassword,
  getStringAfterFirstDot,
  formatCamelCaseToWords,
  parseCamelCaseAndCapitalize,
  stringToBase64,
  camelCaseToHyphenated,
  slug,
  toBoolean,
  replaceNewLineWithBreaks,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toString(data: any) {
  if (!data) return "";
  return JSON.stringify(data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parse(data: any) {
  if (!data) return data;
  return JSON.parse(data);
}

export function spaceToDash(word: string): string {
  return word.replace(/[ ]/g, "-");
}

export function toLowerCase(word: string): string {
  return word.toLowerCase();
}

export function generateTempPassword(): string {
  return Math.random().toString(36).slice(-8).concat("Ii2!");
}

export function capitalizeString(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function shallowObjectToQueryString(obj: Record<string, string>): string {
  return "?" + new URLSearchParams(obj).toString();
}

export function getStringAfterFirstDot(input: string): string {
  const parts = input.split(".");
  return parts.length > 1 ? parts.slice(1).join(".") : input;
}

export function formatCamelCaseToWords(input: string): string {
  return input
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .trim();
}

export function parseCamelCaseAndCapitalize(input: string): string {
  return capitalizeString(input.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2"));
}

export function getEntityWithArticle(entity: string): string {
  const vowels = ["a", "e", "i", "o", "u"];
  const article = vowels.includes(entity.toLocaleLowerCase()[0]) ? "an" : "a";

  return `${article} ${entity}`;
}

export function replaceAll(source = "", match = "", replace = ""): string {
  return source.split(match).join(replace);
}

export function stringToBase64(input: string): string {
  return btoa(input);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isString(input: any): boolean {
  return input instanceof String || typeof input === "string";
}

/**
 * Converts a camelCase string to a hyphenated string.
 *
 * @param {string} str - The camelCase string to be converted.
 * @returns {string} The hyphenated version of the input string.
 *
 * @example
 * // returns "hello-world"
 * camelCaseToHyphenated("helloWorld");
 */
function camelCaseToHyphenated(str: string): string {
  return str.replace(/[A-Z]/g, function (match, offset) {
    return offset > 0 ? "-" + match.toLowerCase() : match.toLowerCase();
  });
}

/**
 * Converts a string to a slug.
 *
 * This function takes a string as input, converts all the characters in the string to lowercase, trims any leading or trailing spaces, and replaces all spaces in the string with the specified separator (default is a hyphen).
 *
 * @param {string} text - The string to be converted to a slug.
 * @param {string} [separator="-"] - The separator to replace spaces with. Default is "-".
 * @returns {string} The slug version of the input string.
 *
 * @example
 * // returns "hello-world"
 * slug("Hello World");
 */
function slug(text: string, separator = "-") {
  return text.toLowerCase().trim().replace(/ /g, separator);
}

export function toBoolean(value: string | boolean | undefined): boolean {
  return value === "true" || value === true;
}

/**
 * Replaces all newline characters in a string with HTML line break tags.
 *
 * This function takes a string as input and replaces every occurrence of the newline character (`\n`)
 * with the HTML line break tag (`<br>`). This is useful for rendering multi-line text in HTML contexts
 * where newline characters need to be converted to line breaks.
 *
 * @param {string} text - The input string containing newline characters.
 * @returns {string} The modified string with newline characters replaced by `<br>` tags.
 *
 * @example
 * // returns "Hello<br>World"
 * replaceNewLineWithBreaks("Hello\nWorld");
 */
function replaceNewLineWithBreaks(text: string): string {
  return text.replace(/\n/g, "<br>");
}
