<template>
  <section class="summary-wrapper text-body2">
    <div v-if="isScopePage" class="row">
      <div class="col-5">
        <div class="text-item">
          <span class="text-capitalize">{{ accessRule.subjectType }} :</span> {{ accessRule.subjectId }}
        </div>
      </div>
      <div class="col-5 text-item">Role: {{ accessRule.roleName }}</div>
    </div>
    <div v-else class="row">
      <div class="col-4 text-item">Role: {{ accessRule.roleName }}</div>
      <div class="col-3 text-item">Scope: {{ scope }}</div>
      <div v-if="accessRule.isInherited" class="col-4">
        <q-badge outline align="middle" class="text-item inherited-group">
          Inherited from group {{ accessRule.subjectId }}
        </q-badge>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { IAccessRuleItem } from "@/models/access-rule.model";
import type { EAccessRuleModalPage } from "@/models/access-rule.model";
import { accessRuleUtil } from "@/utils/rbac.util/access-rule.util";

export default defineComponent({
  name: "access-rule-summary",
  props: {
    accessRule: {
      type: Object as PropType<IAccessRuleItem>,
      required: true,
    },
    page: {
      type: String as PropType<EAccessRuleModalPage>,
      required: true,
    },
  },
  computed: {
    isScopePage(): boolean {
      return accessRuleUtil.isScopePage(this.page);
    },
    scope(): string {
      return `${this.accessRule.scopeType} ${this.accessRule.scopeName ? "-" : ""} ${this.accessRule.scopeName}`;
    },
  },
});
</script>

<style scoped lang="scss">
.summary-wrapper {
  .text-item {
    text-overflow: ellipsis;
    margin-top: 5px;
  }
  .inherited-group {
    font-style: italic;
    color: $black-54;
  }
  .q-badge--outline {
    border: 1px solid $black-12;
  }
}
</style>
