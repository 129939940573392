export interface IUnleashConfig {
  url: string;
  clientKey: string;
  refreshInterval: number;
  appName: string;
  environment: string;
  project: string;
}

export const unleashTestConfig: IUnleashConfig = {
  url: "https://us.app.unleash-hosted.com/ushh0022/api/frontend",
  clientKey: "runai:development.374f998d94b6ccc45d8c9f3bb55b68039302118e6086698ab9e024f4",
  refreshInterval: 15,
  appName: "ui-saas-test",
  environment: "development",
  project: "runai",
};

export const unleashStagingConfig: IUnleashConfig = {
  url: "https://us.app.unleash-hosted.com/ushh0022/api/frontend",
  clientKey: "runai:development.36c29cb3a86cac4ca761e2475402235d403d0a4a25982647ea9d3284",
  refreshInterval: 15,
  appName: "ui-saas-staging",
  environment: "development",
  project: "runai",
};

export const unleashProdConfig: IUnleashConfig = {
  url: "https://us.app.unleash-hosted.com/ushh0022/api/frontend",
  clientKey: "runai:production.485b1c32554f72fafbb1d84f1b6117799a544e81f8c668cd17dc9909",
  refreshInterval: 15,
  appName: "ui-saas-prod",
  environment: "production",
  project: "runai",
};
