// any change here require change in IframePage component in the old app
export const POST_MESSAGE_EVENTS = {
  ACCESS_TOKEN: "access-token",
  REFRESH_TOKEN: "refresh-token",
  IFRAME_LOADED: "iframe-loaded",
  MODAL_MODE: "modal-mode",
  SIDENAV_STATE: "sidenav-state",
  NAVIGATE_TO: "navigate-to",
  UPDATE_LOCATION: "update-location",
  UPDATE_EXPRIED_TIMEOUT: "update-expried-timeout",
  DO_LOGOUT: "do-logout",
  CURRENT_CLUSTER_CHANGED: "current-cluster-changed",
};

export const NAVIGATION_EVENTS = {
  LEAVE_PAGE_REQUEST: "leave-page-request",
  LEAVE_PAGE_STATUS: "leave-page-status",
};
