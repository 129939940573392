import { API } from "@/common/api.constant";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { Trial } from "@/swagger-models/trial-service-client";

export const trialService = {
  verifyTenant,
  getTrialById,
};

const ENDPOINT = `${API.v1}/trials`;

async function verifyTenant(trialId: string, validation: string, password: string): Promise<void> {
  controlPlaneService.post(
    `${ENDPOINT}/${trialId}/verify`,
    { password },
    {
      headers: {
        validation: validation,
        "content-type": "application/json",
      },
      responseType: "json",
    },
  );
}

async function getTrialById(trialId: string, validation: string): Promise<Trial> {
  return controlPlaneService.get(
    `${ENDPOINT}/${trialId}`,
    {},
    {
      headers: {
        validation: validation,
        "content-type": "application/json",
      },
      responseType: "json",
    },
  );
}
