<template>
  <q-card flat square class="wrapper" :class="{ 'q-mx-md': !noGutters }">
    <template v-if="title">
      <div class="row items-center q-pa-md">
        <runai-svg-icon class="q-mr-sm" v-if="iconName" :name="iconName" size="22"></runai-svg-icon>
        <div class="q-pt-xs italic text-black-54">{{ title }}</div>
      </div>
      <q-separator />
    </template>

    <div class="row items-center">
      <div class="col-11">
        <q-card-section> <slot /> </q-card-section>
      </div>
      <div class="col-1">
        <runai-tooltip
          v-if="resetTooltipText"
          aid="reset-button"
          icon="fa-solid fa-rotate-left"
          @click="$emit('reset')"
          dark-mode
          :tooltip-text="resetTooltipText"
          color="black-54"
        />
        <q-btn
          v-else-if="!hideCloseButton"
          @click="$emit('close')"
          size="12px"
          round
          flat
          aid="close-button"
          icon="fa-regular fa-xmark"
        ></q-btn>
      </div>
    </div>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiTooltip } from "@/components/common/runai-tooltip";

export default defineComponent({
  components: { RunaiTooltip, RunaiSvgIcon },
  emits: ["close", "reset"],
  props: {
    hideCloseButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noGutters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      required: false,
    },
    iconName: {
      type: String as PropType<string>,
      required: false,
    },
    resetTooltipText: {
      type: String as PropType<string>,
      required: false,
    },
  },
});
</script>
<style scoped lang="scss">
.wrapper {
  background-color: $body-background-color;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
