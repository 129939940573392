<template>
  <q-td class="name-col">
    <div class="col-container row items-center">
      <span class="icon-container q-mr-sm" v-if="icon">
        <runai-svg-icon :key="icon" :name="icon" size="34"></runai-svg-icon>
      </span>
      {{ name }}
    </div>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// models
import type { ITableColumn } from "@/models/table.model";

// cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

// services
import { getFieldContent } from "@/utils/table-format.util";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  props: {
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    name(): string {
      return getFieldContent(this.column, this.row);
    },
  },
});
</script>

<style lang="scss" scoped>
.name-col {
  .col-container {
    flex-wrap: nowrap;
  }

  .icon-container {
    width: 34px;
  }
}
</style>
