import { Configuration, type ConfigurationParameters } from "@/swagger-models/workloads-service-client";
import { OrgUnitApi, DepartmentsApi, ProjectsApi } from "@/swagger-models/org-unit-service-client";

class OrgUnitServiceApi {
  configuration: Configuration;

  orgUnitApi: OrgUnitApi;
  projectsApi: ProjectsApi;
  departmentsApi: DepartmentsApi;

  constructor() {
    this.configuration = new Configuration();

    this.orgUnitApi = new OrgUnitApi(this.configuration, this.baseUrl);
    this.projectsApi = new ProjectsApi(this.configuration, this.baseUrl);
    this.departmentsApi = new DepartmentsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const orgUnitServiceApi = new OrgUnitServiceApi();
