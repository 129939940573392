import { Configuration, type ConfigurationParameters, MetricsApi } from "@/swagger-models/metrics-service-client";

class MetricsServiceApi {
  configuration: Configuration;

  metricsApi: MetricsApi;

  constructor() {
    this.configuration = new Configuration();

    this.metricsApi = new MetricsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const metricsServiceApi = new MetricsServiceApi();
