<template>
  <q-td>
    <span :class="cmpClass">{{ status }}</span>
  </q-td>
</template>

<script lang="ts">
import type { IApplication } from "@/models/applications.model";
import type { ITableColumn } from "@/models/table.model";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<IApplication>,
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  computed: {
    status(): string {
      return this.row.enabled ? "Active" : "Revoked";
    },
    cmpClass(): string {
      return this.status === "Active" ? "active-clr" : "";
    },
  },
});
</script>
