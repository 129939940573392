<template>
  <q-td :class="getStatusClass" style="min-width: 150px">
    {{ statusDisplayLabel }}
    <q-spinner-hourglass class="on-left" v-if="isStatusPending" />
    <q-tooltip v-if="getStatusMessage" anchor="center right">{{ getStatusMessage }}</q-tooltip>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps

// models
import {
  type INodePool,
  NodePoolStatusMap,
  NodePoolStatusMessageMap,
  NodePoolStatusMessagePrefix,
} from "@/models/node-pool.model";

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<INodePool>,
      required: true,
    },
  },
  computed: {
    status(): string {
      return this.row.status;
    },
    getStatusClass(): string {
      return this.status?.toLowerCase() + "-color";
    },
    statusDisplayLabel(): string {
      return NodePoolStatusMap.get(this.status)?.displayLabel || this.status;
    },
    isStatusPending(): boolean {
      return NodePoolStatusMap.get(this.status)?.isPending || false;
    },
    getStatusMessage() {
      let message = this.row.statusMessage;
      let newMessage;
      if (message) {
        newMessage = NodePoolStatusMessageMap.get(message)?.newMessage;
        if (!newMessage) {
          NodePoolStatusMessagePrefix.forEach((statusMessagePrefix) => {
            if (message.startsWith(statusMessagePrefix.originalMessage)) {
              newMessage = message.replace(statusMessagePrefix.originalMessage, statusMessagePrefix.newMessage);
            }
          });
        }
      }
      return newMessage;
    },
  },
});
</script>
<style lang="scss" scoped>
.ready-color {
  color: $success;
}
.unschedulable-color {
  color: $negative;
}
</style>
