/* tslint:disable */
/* eslint-disable */
/**
 * Email Service
 * Email Service API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface ContactSupportRequest
 */
export interface ContactSupportRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactSupportRequest
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface EmailAttachment
 */
export interface EmailAttachment {
    /**
     * 
     * @type {string}
     * @memberof EmailAttachment
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof EmailAttachment
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof EmailAttachment
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface ExportEmailRequest
 */
export interface ExportEmailRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportEmailRequest
     */
    'to': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportEmailRequest
     */
    'cc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportEmailRequest
     */
    'bcc'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExportEmailRequest
     */
    'fromDisplayName'?: string;
    /**
     * 
     * @type {Template}
     * @memberof ExportEmailRequest
     */
    'template': Template;
    /**
     * 
     * @type {Array<EmailAttachment>}
     * @memberof ExportEmailRequest
     */
    'attachments'?: Array<EmailAttachment>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof Template
     */
    'data'?: object | null;
}

/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Export email. 
         * @summary export email
         * @param {ExportEmailRequest} [exportEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmail: async (exportEmailRequest?: ExportEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/export/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * Export email. 
         * @summary export email
         * @param {ExportEmailRequest} [exportEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEmail(exportEmailRequest?: ExportEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEmail(exportEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * Export email. 
         * @summary export email
         * @param {ExportEmailRequest} [exportEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmail(exportEmailRequest?: ExportEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.exportEmail(exportEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * Export email. 
     * @summary export email
     * @param {ExportEmailRequest} [exportEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public exportEmail(exportEmailRequest?: ExportEmailRequest, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).exportEmail(exportEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Contact Run:ai support. 
         * @summary contact Run:ai support
         * @param {ContactSupportRequest} [contactSupportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupport: async (contactSupportRequest?: ContactSupportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/support/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactSupportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportApiAxiosParamCreator(configuration)
    return {
        /**
         * Contact Run:ai support. 
         * @summary contact Run:ai support
         * @param {ContactSupportRequest} [contactSupportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactSupport(contactSupportRequest?: ContactSupportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactSupport(contactSupportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportApiFp(configuration)
    return {
        /**
         * Contact Run:ai support. 
         * @summary contact Run:ai support
         * @param {ContactSupportRequest} [contactSupportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupport(contactSupportRequest?: ContactSupportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.contactSupport(contactSupportRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI {
    /**
     * Contact Run:ai support. 
     * @summary contact Run:ai support
     * @param {ContactSupportRequest} [contactSupportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApi
     */
    public contactSupport(contactSupportRequest?: ContactSupportRequest, options?: AxiosRequestConfig) {
        return SupportApiFp(this.configuration).contactSupport(contactSupportRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


