<template>
  <div class="idp-setup-fields col-12">
    <div>
      <div>Add your identity provider metadata</div>
      <div class="row col-12">
        <div class="row col-12">
          <setting-readonly-input>
            <template v-slot:default="{ readonly }">
              <q-input
                class="col-12 q-mb-lg"
                :model-value="oidcSettingsFields.discoverDocumentUrl"
                placeholder="Enter URL"
                debounce="300"
                :rules="[isValidUrl]"
                no-error-icon
                @update:model-value="updateDiscoveryUrl"
                stack-label
                label="Discovery URL"
                ref="oidcUrlInput"
                autofocus
                :hint="urlHint"
                :disable="readonly"
              />
            </template>
          </setting-readonly-input>
        </div>
        <div class="row col-12">
          <setting-readonly-input>
            <template v-slot:default="{ readonly }">
              <q-input
                class="col-5"
                :model-value="oidcSettingsFields.clientId"
                debounce="300"
                :rules="[isNotEmptyClientID]"
                no-error-icon
                @update:model-value="updateClientId"
                stack-label
                label="Client ID"
                ref="oidcClientIdInput"
                :disable="readonly"
              />
            </template>
          </setting-readonly-input>
          <div class="col-1" />
          <setting-readonly-input>
            <template v-slot:default="{ readonly }">
              <q-input
                class="col-6 items-end"
                :model-value="oidcSettingsFields.clientSecret"
                debounce="300"
                :rules="[isNotEmptySecret]"
                no-error-icon
                @update:model-value="updateClientSecret"
                stack-label
                label="Client secret"
                ref="oidcClientSecretInput"
                :disable="readonly"
              />
            </template>
          </setting-readonly-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { isNotEmpty, isValidUrl } from "@/common/form.validators";
import type { QInput } from "quasar";
import type { OidcCreationData } from "@/swagger-models/identity-manager-client";
import { urlHintMessage } from "@/models/setting.model";
import SettingReadonlyInput from "@/components/settings/setting-readonly-input/setting-readonly-input.vue";

export default defineComponent({
  name: "oidc-fields",
  components: { SettingReadonlyInput },
  emits: ["updated"],
  props: {
    oidcSettings: {
      type: Object as PropType<OidcCreationData>,
    },
    isEditMode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      oidcSettingsFields: {} as OidcCreationData,
    };
  },
  created() {
    if (this.oidcSettings) {
      this.oidcSettingsFields = {
        discoverDocumentUrl: this.oidcSettings.discoverDocumentUrl,
        clientId: this.oidcSettings.clientId,
        clientSecret: this.oidcSettings.clientSecret,
      };
    }
  },
  computed: {
    urlHint(): string {
      return this.isEditMode ? urlHintMessage : "";
    },
  },
  methods: {
    async validate(): Promise<boolean> {
      return (
        (await (this.$refs.oidcUrlInput as QInput).validate()) &&
        (await (this.$refs.oidcClientIdInput as QInput).validate()) &&
        (await (this.$refs.oidcClientSecretInput as QInput).validate())
      );
    },
    isNotEmptyClientID(value: string): boolean | string {
      return isNotEmpty(value) || "Enter ID";
    },
    isNotEmptySecret(value: string): boolean | string {
      return isNotEmpty(value) || "Enter a secret";
    },
    isValidUrl(url: string): boolean | string {
      return (this.isEditMode && url === "") || isValidUrl(url) || "Enter a valid URL";
    },
    updateClientId(clientId: string): void {
      this.oidcSettingsFields.clientId = clientId;
      this.onUpdated();
    },
    updateClientSecret(clientSecret: string): void {
      this.oidcSettingsFields.clientSecret = clientSecret;
      this.onUpdated();
    },
    updateDiscoveryUrl(discoveryUrl: string): void {
      this.oidcSettingsFields.discoverDocumentUrl = discoveryUrl;
      this.onUpdated();
    },
    onUpdated(): void {
      this.$emit("updated", this.oidcSettingsFields, this.isIdpFieldsValid());
    },
    isIdpFieldsValid(): boolean {
      const discoverDocumentUrl: string = this.oidcSettingsFields.discoverDocumentUrl;
      return (
        (this.isEditMode && !discoverDocumentUrl) ||
        (isValidUrl(discoverDocumentUrl) &&
          isNotEmpty(this.oidcSettingsFields.clientId) &&
          isNotEmpty(this.oidcSettingsFields.clientSecret))
      );
    },
  },
});
</script>
