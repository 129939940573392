import {
  Configuration,
  TenantsApi,
  TenantApi,
  LogoApi,
  type ConfigurationParameters,
} from "@/swagger-models/tenants-manager-client";

class TenantManagerApi {
  configuration: Configuration;
  tenantsApi: TenantsApi;
  tenantApi: TenantApi;
  logoApi: LogoApi;

  constructor() {
    this.configuration = new Configuration();
    this.tenantsApi = new TenantsApi(this.configuration, this.baseUrl);
    this.tenantApi = new TenantApi(this.configuration, this.baseUrl);
    this.logoApi = new LogoApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const tenantManagerApi = new TenantManagerApi();
