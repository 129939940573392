import type { IAuthProviderData, ITenantClientConfigInfo, ITenantServerConfigInfo } from "@/models/tenant.model";

export const tenantUtil = {
  getClientConfigInfo,
  getServerConfigInfo,
};

const DEFAULT_SERVER_CLIENT_ID = "runai";

function getClientConfigInfo(authData: IAuthProviderData, authRealm: string, isSso: boolean): ITenantClientConfigInfo {
  return isSso
    ? {
        airgapped: true,
        authFlow: "remote-browser",
        realm: authRealm,
        clientId: "runai-cli",
        idpIssuerUrl: authData.issuerUrl,
        redirectUri: `${window.location.origin}/oauth-code`,
      }
    : {
        airgapped: true,
        authFlow: "cli",
        realm: authRealm,
        clientId: "runai-cli",
        idpIssuerUrl: authData.issuerUrl,
        redirectUri: undefined,
      };
}

function getServerConfigInfo(authData: IAuthProviderData, isSso: boolean): ITenantServerConfigInfo {
  return isSso
    ? {
        clientId: DEFAULT_SERVER_CLIENT_ID,
        issuerUrl: authData.issuerUrl,
        groupsClaim: "groups",
      }
    : {
        clientId: DEFAULT_SERVER_CLIENT_ID,
        issuerUrl: authData.issuerUrl,
        groupsClaim: undefined,
      };
}
