<template>
  <div class="column-graph-frame">
    <div class="col-12">
      <div class="row q-pt-lg">
        <div class="col-3 text-weight-bold q-pl-lg label">
          <span>{{ title }}</span>
        </div>
        <div class="col-9 q-pr-md q-pb-md">
          <runai-datepicker-with-predefined
            v-if="withDatePicker"
            @changed="updateDateRange"
            :range-of-dates="rangeOfDatesCallback"
            info-bar-message="Select a period of up to 30 days within the last 90 days"
            :predefined-options="predefinedOptions"
            :default-option-index="5"
          />
        </div>
      </div>
      <div class="row">
        <highcharts :options="chartOptions" :key="columnSeries" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { Options } from "highcharts";
import { Chart } from "highcharts-vue";

//service
import type { IColumnChartSeries } from "@/models/chart.model";
import { dashboardService } from "@/services/control-plane/dashboard/dashboard.service";
import { RunaiDatepickerWithPredefined } from "@/components/common/runai-datepicker-with-predefined";
import { dateUtil, TimeUnit } from "@/utils/date.util";
import type { PredefinedOption } from "@/models/date.model";

//utils
import { extendedHoursPredefinedOptions } from "@/utils/date.util";

const DEFAULT_OFFSET_WIDTH = 1200;
export default defineComponent({
  components: {
    RunaiDatepickerWithPredefined,
    Highcharts: Chart,
  },
  emits: ["date-range-changed"],
  props: {
    categories: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    yAxisTitle: {
      type: String as PropType<string>,
      required: false,
    },
    splineSeries: {
      type: Object as PropType<IColumnChartSeries>,
      required: false,
    },
    columnSeries: {
      type: Object as PropType<IColumnChartSeries>,
      required: false,
    },
    withDatePicker: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data() {
    return {
      chartOptions: {} as Options | null,
      predefinedOptions: extendedHoursPredefinedOptions as Array<PredefinedOption>,
    };
  },
  created() {
    this.setChartOptions();
    window.addEventListener("resize", this.updateChartWidth);
  },
  mounted() {
    setTimeout(() => {
      // wait for offset width to be available it is not available on immediately after created
      this.updateChartWidth();
    }, 1500);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateChartWidth);
  },
  methods: {
    updateDateRange(dateRange: Array<Date>): void {
      this.$emit("date-range-changed", { startDate: dateRange[0], endDate: dateRange[1] });
    },
    rangeOfDatesCallback(date: string): boolean {
      const todayDate: number = new Date().getTime();
      const dateToCheck: number = Date.parse(date);
      const back90Days: Date = dateUtil.adjustDateBy(TimeUnit.day, todayDate, -90);
      return dateToCheck >= back90Days.getTime() && dateToCheck <= todayDate;
    },
    setChartOptions(): void {
      this.chartOptions = dashboardService.getColumnAndLineChartOption(
        "",
        this.categories,
        this.yAxisTitle as string,
        this.columnSeries as IColumnChartSeries,
        this.splineSeries as IColumnChartSeries,
      ) as Options;
    },
    updateChartWidth(): void {
      const frame: HTMLElement = document.querySelector(".column-graph-frame") as HTMLElement;
      if (frame) {
        const frameWidth = frame?.offsetWidth || DEFAULT_OFFSET_WIDTH;
        if (this.chartOptions?.chart) {
          this.chartOptions.chart.width = frameWidth;
        }
      }
    },
  },
  watch: {
    columnSeries: {
      handler(newOptions, oldOptions) {
        if (newOptions === oldOptions || oldOptions === null) return;
        this.setChartOptions();
        this.updateChartWidth();
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.label {
  color: $black-default-highcharts;
}
.column-graph-frame {
  background: $white;
  box-shadow: 0 1px 4px $black-35;
  border-radius: 3px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
