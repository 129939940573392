import {
  Configuration,
  type ConfigurationParameters,
  ClustersApi,
  InstallationApi,
  NodesApi,
  NodePoolsApi,
} from "@/swagger-models/cluster-service-client";

class ClusterServiceApi {
  configuration: Configuration;

  clustersApi: ClustersApi;
  installationApi: InstallationApi;
  nodesApi: NodesApi;
  nodePoolsApi: NodePoolsApi;

  constructor() {
    this.configuration = new Configuration();

    this.clustersApi = new ClustersApi(this.configuration, this.baseUrl);
    this.installationApi = new InstallationApi(this.configuration, this.baseUrl);
    this.nodesApi = new NodesApi(this.configuration, this.baseUrl);
    this.nodePoolsApi = new NodePoolsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const clusterServiceApi = new ClusterServiceApi();
