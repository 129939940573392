<template>
  <section class="runai-list-modal-search row">
    <q-input
      class="col-12"
      aid="runai-list-modal-search"
      :model-value="modelValue"
      @update:model-value="$emit('update:model-value', $event)"
      :placeholder="placeholder"
      :input-style="{ paddingInline: '16px' }"
      clearable
      clear-icon="fa-regular fa-xmark"
      @clear="clearSearch"
    >
      <template v-slot:append>
        <q-icon name="fa-regular fa-magnifying-glass" size="xs" class="q-mr-md" />
      </template>
    </q-input>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: [String, undefined] as PropType<string | undefined>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
  },
  methods: {
    clearSearch(): void {
      this.$emit("update:model-value", "");
    },
  },
});
</script>
