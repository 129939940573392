import type {
  MetricsResponse,
  MeasurementResponse,
  MeasurementResponseValuesInner,
} from "@/swagger-models/cluster-service-client";
import type { TMeasurementsTimeAndValue } from "@/models/chart.model";

import { dateUtil } from "@/utils/date.util";

export const metricUtil = {
  mapMeasurementsToMap,
  getMeasurementTimestamp,
  getMeasurementValue,
  insertConstantValueIntoTimeframe,
};

function mapMeasurementsToMap(metricsResponse: MetricsResponse): Record<string, Array<MeasurementResponseValuesInner>> {
  const measurementMap: Record<string, Array<MeasurementResponseValuesInner>> = {};
  metricsResponse.measurements.reduce(
    (measurementMap: Record<string, Array<MeasurementResponseValuesInner>>, measurement: MeasurementResponse) => {
      measurementMap[measurement.type] = measurement.values || [];
      return measurementMap;
    },
    measurementMap,
  );

  return measurementMap;
}

function getMeasurementValue(measurements: Array<MeasurementResponseValuesInner>): Array<number> {
  return measurements.map((measurement: MeasurementResponseValuesInner) => Number(measurement.value));
}

function getMeasurementTimestamp(measurements: Array<MeasurementResponseValuesInner>): Array<string> {
  return measurements.map((measurement: MeasurementResponseValuesInner) =>
    measurement.timestamp ? dateUtil.dateFormat(new Date(measurement.timestamp), "dd/MM HH:mm") : "",
  );
}

function insertConstantValueIntoTimeframe(
  constantValue: number,
  timeframes: Array<number>,
  startTime?: number,
): Array<TMeasurementsTimeAndValue> {
  const startTimestamp = startTime || 0;

  return timeframes.map((timeframe: number) => {
    const value = timeframe >= startTimestamp ? constantValue : null;
    return [timeframe, value];
  });
}
