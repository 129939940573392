export const clickOutside = {
  mounted(el: HTMLElement, { value }: { value: () => void }) {
    el.clickOutside = ({ clientX, clientY }: { clientX: number; clientY: number }) => {
      const { left, top, width, height } = el.getBoundingClientRect();
      if (!(clientX > left && clientX < left + width && clientY > top && clientY < top + height)) {
        value();
      }
    };
    document.addEventListener("click", el.clickOutside);
  },
  unmounted(el: HTMLElement) {
    document.removeEventListener("click", el.clickOutside);
  },
};
