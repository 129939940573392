<template>
  <runai-expansion-item
    class="general-section"
    label="General"
    :subheader="summary"
    aid="general-section"
    :section-invalid="sectionInvalid"
  >
    <section class="row no-wrap justify-between q-mb-md" v-if="showOverQuotaSection">
      <runai-toggle
        aid="allow-over-quota-input"
        :model-value="generalModel.allowOverQuota || false"
        :label="`Allow the workload to exceed the project's quota`"
        left-label
        @update:model-value="allowOverQuotaChanged"
        :disable="sectionDisabled"
      />
      <!--is this relevant?: no policy for this property yet -->
      <q-banner class="bg-orange-1 q-ml-sm col-5">
        <div class="row no-wrap items-center">
          <q-icon name="fa-regular fa-triangle-exclamation" size="sm" color="warning" />
          <div class="q-ml-md text-caption">Note: Workloads running over quota may stop at any time</div>
        </div>
      </q-banner>
    </section>

    <runs-and-parallelism-section
      v-if="showRunsAndParallelism"
      class="q-mb-lg"
      :model-value="{ completions: generalModel.completions, parallelism: generalModel.parallelism }"
      @update:model-value="updateRunsAndParallelism"
      :disabled="sectionDisabled"
      :policy-rules="runsAndParallelismRules"
    />

    <backoff-limit-section
      class="q-mb-lg"
      v-if="showBackoffLimit"
      :model-value="generalModel.backoffLimit"
      @update:model-value="updateBackoffLimit"
      :disabled="sectionDisabled"
      :policy-rules="policyRules?.rules?.specificRunParams?.backoffLimit"
    />

    <auto-delete-time-section
      :model-value="generalModel.autoDeletionTimeAfterCompletionSeconds"
      @update:model-value="autoDeletionTimeoutChanged"
      :disable="sectionDisabled"
      :policy-rules="policyRules?.rules?.specificRunParams?.autoDeletionTimeAfterCompletionSeconds"
    />

    <section class="annotations-section">
      <runai-itemized-list
        :items-list="annotationsList"
        title="Set annotation(s)"
        title-tooltip="Kubernetes annotations are key-value pairs attached to the workload. They are used for storing additional descriptive metadata to enable documentation, monitoring and automation."
        add-item-btn="annotation"
        @changed="onAnnotationUpdated"
        :disable="sectionDisabled"
        :policy-defaults="itemizeDefaults?.annotations"
        :policy-rules="policyRules?.rules?.specificRunParams?.annotations"
      />
    </section>
    <section class="labels-section">
      <runai-itemized-list
        :items-list="labelsList"
        title="Set label(s)"
        title-tooltip="Kubernetes labels are key-value pairs attached to the workload. They are used for categorizing to enable querying."
        add-item-btn="label"
        @changed="onLabelUpdated"
        :disable="sectionDisabled"
        :policy-defaults="itemizeDefaults?.labels"
        :policy-rules="policyRules?.rules?.specificRunParams?.labels"
      />
    </section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiItemizedList } from "@/components/common";
import { AutoDeleteTimeSection } from "./auto-delete-time-section";
import { BackoffLimitSection } from "./backoff-limit-section";
import { RunaiToggle } from "@/components/common";

// Models
import type { IUIGeneralSectionModel } from "./general-section.model";
import { EWorkloadFormType } from "@/models/workload.model";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { Annotation, Label, PolicyInfo } from "@/swagger-models/assets-service-client";
import type { ILoadedItemizeDefaults } from "@/models/policy.model";
import { RunsAndParallelismSection } from "./runs-and-parallelism-section";
import {
  ICompletionsAndParallelism,
  IRunsAndParallelismRules,
} from "./runs-and-parallelism-section/runs-and-parallelism-section.vue";

export default defineComponent({
  name: "general-section",
  components: {
    RunaiExpansionItem,
    RunaiItemizedList,
    AutoDeleteTimeSection,
    BackoffLimitSection,
    RunaiToggle,
    RunsAndParallelismSection,
  },
  emits: ["general-model-changed"],
  props: {
    workloadFormType: {
      type: String as PropType<EWorkloadFormType>,
      required: true,
    },
    generalModel: {
      type: Object as PropType<IUIGeneralSectionModel>,
      required: true,
    },
    sectionDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    showBackoffLimit: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    showRunsAndParallelism: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    policyRules: {
      type: [Object, null] as PropType<PolicyInfo | null>,
      required: false,
    },
    itemizeDefaults: {
      type: [Object, null] as PropType<ILoadedItemizeDefaults | null>,
      required: false,
    },
  },
  computed: {
    summary(): string {
      if (this.showOverQuotaSection) {
        const prefix: string = this.generalModel.allowOverQuota ? "Allowed" : "Not allowed";
        return prefix + " to exceed the project's quota";
      } else if (this.showBackoffLimit) {
        return `Backoff limit (${this.generalModel.backoffLimit || 0}) attempts`;
      }
      return "";
    },
    showOverQuotaSection(): boolean {
      return this.workloadFormType === EWorkloadFormType.Workspace;
    },
    annotationsList(): Array<IItemizedListItem> {
      return this.generalModel.annotations || [];
    },
    labelsList(): Array<IItemizedListItem> {
      return this.generalModel.labels || [];
    },
    invalidAnnotations(): boolean {
      return !!this.generalModel.annotations?.length && this.generalModel.annotations.some((a) => !a.name);
    },
    invalidLabels(): boolean {
      return !!this.generalModel.labels?.length && this.generalModel.labels.some((l) => !l.name);
    },
    sectionInvalid(): boolean {
      return (
        !this.generalModel.backoffLimit ||
        (!this.generalModel.autoDeletionTimeAfterCompletionSeconds &&
          typeof this.generalModel.autoDeletionTimeAfterCompletionSeconds !== "number") ||
        this.invalidAnnotations ||
        this.invalidLabels
      );
    },
    runsAndParallelismRules(): IRunsAndParallelismRules {
      return {
        parallelism: this.policyRules?.rules?.specificRunParams?.parallelism || null,
        completions: this.policyRules?.rules?.specificRunParams?.completions || null,
      };
    },
  },
  methods: {
    allowOverQuotaChanged(allowOverQuota: boolean): void {
      this.$emit("general-model-changed", { ...this.generalModel, allowOverQuota });
    },
    onAnnotationUpdated(annotations: Array<Annotation>) {
      this.$emit("general-model-changed", { ...this.generalModel, annotations });
    },
    onLabelUpdated(labels: Array<Label>) {
      this.$emit("general-model-changed", { ...this.generalModel, labels });
    },
    autoDeletionTimeoutChanged(autoDeletionTimeAfterCompletionSeconds: number | null): void {
      this.$emit("general-model-changed", { ...this.generalModel, autoDeletionTimeAfterCompletionSeconds });
    },
    updateBackoffLimit(backoffLimit: number | null): void {
      this.$emit("general-model-changed", { ...this.generalModel, backoffLimit });
    },
    updateRunsAndParallelism(runsAndParallelism: ICompletionsAndParallelism): void {
      this.$emit("general-model-changed", {
        ...this.generalModel,
        completions: runsAndParallelism.completions,
        parallelism: runsAndParallelism.parallelism,
      });
    },
  },
});
</script>
