export const SECOND_IN_MS = 1000;
export const PROMETHEUS_STEP_FACTOR = 400;
export const DAY_IN_HOURS = 24;
export const MIN_PROMETHEUS_STEP = 5;
export const PERCENTAGE_FACTOR = 100;

export type PrometheusMetricResponse = Array<Array<number>[]>;
export type TMetricsResponseMap = Record<string, PrometheusMetricResponse>;
export interface IPrometheusResponse {
  key: string;
  data: Array<IPrometheusMetric>;
}

export interface IPrometheusMetric {
  metric: Record<string, string>;
  value: Array<string>;
  values?: Array<Array<number>>;
}

export interface AreaChartDataDataItem {
  name: string;
  data: Array<number>[];
}
export interface IPrometheusNodePoolMetrics {
  promQueries: Record<string, string>;
}
