import {
  Configuration,
  ValidatedTrialsApi,
  type ConfigurationParameters,
  TrialsApi,
} from "@/swagger-models/trial-service-client";

class TrailServiceApi {
  configuration: Configuration;

  trialsApi: TrialsApi;
  validatedTrialsApi: ValidatedTrialsApi;

  constructor() {
    this.configuration = new Configuration();

    this.trialsApi = new TrialsApi(this.configuration, this.baseUrl);
    this.validatedTrialsApi = new ValidatedTrialsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const trailServiceApi = new TrailServiceApi();
