<template>
  <q-btn
    class="runai-action-button q-mr-lg q-pt-sm"
    size="sm"
    stack
    flat
    :disable="disable"
    :icon="icon"
    :label="label"
    @click="$emit('click')"
    :aid="aid"
  >
    <slot></slot>
  </q-btn>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

const btnActionMap: TBtnActionMap = {
  delete: {
    icon: "fa-solid fa-trash-can",
    label: "delete",
  },
  connect: {
    icon: "fa-solid fa-arrow-up-right-from-square",
    label: "connect",
  },
  stop: {
    icon: "fa-solid fa-stop",
    label: "stop",
  },
  activate: {
    icon: "fa-solid fa-play",
    label: "activate",
  },
  run: {
    icon: "fa-solid fa-play",
    label: "run",
  },
  edit: {
    icon: "fa-solid fa-pen",
    label: "edit",
  },
  review: {
    icon: "fa-solid fa-file",
    label: "review",
  },
  copy: {
    icon: "fa-solid fa-copy",
    label: "copy & edit",
  },
  duplicate: {
    icon: "fa-solid fa-copy",
    label: "make a copy",
  },
  showDetails: {
    icon: "fa-solid fa-memo-circle-info",
    label: "show details",
  },
  hideDetails: {
    icon: "fa-solid fa-memo-circle-info",
    label: "hide details",
  },
  rename: {
    icon: "fa-solid fa-pen",
    label: "rename",
  },
  assign: {
    icon: "fa-regular fa-chess",
    label: "access rules",
  },
  reset: {
    icon: "fa-solid fa-lock-hashtag",
    label: "reset password",
  },
  install: {
    icon: "fa-solid fa-download",
    label: "get installation instructions",
  },
  regenerate: {
    icon: "fa-solid fa-lock-hashtag",
    label: "regenerate key",
  },
  remove: {
    icon: "fa-solid fa-xmark",
    label: "remove",
  },
  viewPolicy: {
    icon: "fa-solid fa-clipboard-list-check",
    label: "view policy",
  },
};
// Types
type TAction =
  | "delete"
  | "connect"
  | "stop"
  | "activate"
  | "run"
  | "edit"
  | "copy"
  | "duplicate"
  | "showDetails"
  | "hideDetails"
  | "review"
  | "rename"
  | "assign"
  | "reset"
  | "install"
  | "regenerate"
  | "remove"
  | "viewPolicy";

type TBtnAction = {
  icon: string;
  label: string;
};

type TBtnActionMap = Record<TAction, TBtnAction>;

export default defineComponent({
  emits: ["click"],
  props: {
    btnAction: {
      type: String as PropType<TAction>,
      required: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    aid: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    label() {
      return btnActionMap[this.btnAction].label;
    },
    icon() {
      return btnActionMap[this.btnAction].icon;
    },
  },
});
</script>
