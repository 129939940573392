<template>
  <runai-form-wrapper :form-state="formData" ref="elProjectEditCreateForm">
    <runai-expansion-wrapper>
      <project-scope-section
        @update-scope="updateProjectScope"
        :cluster-id="formData.clusterId"
        :department-id="formData.parentId"
      />
      <template v-if="formData.clusterId">
        <project-name-section
          v-model:project-name="formData.name"
          v-model:description="formData.description"
          :namespace="namespace"
          @update:namespace="formData.requestedNamespace = $event"
          :cluster-id="formData.clusterId"
          @is-section-invalid="invalidSection.projectName = !$event"
        />
        <quota-management-section
          v-if="formData.parentId"
          v-model:resources="formData.resources"
          v-model:node-pools-priorities="formData.defaultNodePools"
          :loading="loading"
          :entity="EQuotaEntity.project"
          :department-id="formData.parentId"
          :cluster-id="formData.clusterId"
          :quota-statuses="quotaStatuses"
        />
        <scheduling-rules-section
          :cluster-id="formData.clusterId"
          :entity="EQuotaEntity.project"
          :parent-scheduling-rules="parentSchedulingRules"
          :loading="loading"
          v-model:scheduling-rules="formData.schedulingRules"
          v-model:node-types="formData.nodeTypes"
          @is-section-invalid="invalidSection.schedulingRules = !$event"
        />
      </template>
      <project-form-footer-section
        :loading="isProjectSubmitting"
        :is-form-valid="isFormValid"
        @on-cancel="$emit('on-cancel')"
        @on-save="save"
      />
    </runai-expansion-wrapper>
  </runai-form-wrapper>
</template>

<script lang="ts">
import { computed, type PropType } from "vue";
import { defineComponent } from "vue";
// cmps
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { SchedulingRulesSection } from "@/components/project/project-edit-form/scheduling-rules-section/";
import { ProjectNameSection } from "@/components/project/project-edit-form/project-name-section";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { ProjectFormFooterSection } from "@/components/project/project-edit-form/project-form-footer-section";
// services
import { deepCopy } from "@/utils/common.util";
// stores
import { useSettingStore } from "@/stores/setting.store";
// models
import { EQuotaEntity } from "@/models/resource.model";
import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import ProjectScopeSection from "@/components/project/project-edit-form/project-scope-section/project-scope-section.vue";
import {
  type AggregatedResources,
  type Project,
  type ProjectCreationRequest,
  type QuotaStatusNodePool,
  type Resources,
  type SchedulingRules,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";
import type { Department } from "@/swagger-models/org-unit-service-client";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { unleashService } from "@/services/infra/unleash.service/unleash.service";

interface ISectionValidation {
  departmentSelect: boolean;
  projectName: boolean;
  schedulingRules: boolean;
}
export default defineComponent({
  components: {
    ProjectScopeSection,
    SchedulingRulesSection,
    ProjectFormFooterSection,
    ProjectNameSection,
    QuotaManagementSection,
    RunaiFormWrapper,
    RunaiExpansionWrapper,
  },
  emits: ["on-save", "on-cancel", "load-default-department", "load-department"],
  props: {
    project: {
      type: Object as PropType<Project | ProjectCreationRequest>,
      required: true,
    },
    isProjectSubmitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    department: {
      type: [Object, null] as PropType<Department | null>,
      required: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  provide() {
    return {
      projectsAggregatedResources: computed<AggregatedResources[]>(
        () => this.department?.projectsAggregatedResources || [],
      ),
      departmentResources: computed<Resources[]>(() => this.department?.resources || []),
    };
  },
  data() {
    return {
      formData: deepCopy(this.project) as Project | ProjectCreationRequest,
      parentSchedulingRules: null as SchedulingRules | null,
      settingStore: useSettingStore(),
      isFormValid: true as boolean,
      invalidSection: {
        departmentSelect: false as boolean,
        projectName: false as boolean,
        schedulingRules: false as boolean,
      } as ISectionValidation,
    };
  },
  created() {
    if (!this.isDepartmentEnabled) {
      this.invalidSection.departmentSelect = true;
    }
    if (this.showOrgUnitParity) {
      this.initSchedulingRules();
    }
    this.initDefaultNodePools();
  },
  computed: {
    namespace(): string {
      if (this.formData.requestedNamespace) {
        return this.formData.requestedNamespace;
      } else {
        return (this.formData as Project).status?.namespace || "";
      }
    },
    quotaStatuses(): Array<QuotaStatusNodePool> {
      return (this.project as Project)?.status?.nodePoolQuotaStatuses || [];
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
    showOrgUnitParity(): boolean {
      return unleashService.showOrgUnitParity();
    },
  },
  methods: {
    updateProjectScope({ clusterId, departmentId }: { clusterId: string; departmentId: string | null }): void {
      this.formData.clusterId = clusterId;
      this.updateDepartmentId(departmentId, clusterId);
    },
    updateDepartmentId(departmentId: string | null, clusterId: string): void {
      if (departmentId !== null) {
        this.loadDepartment(departmentId);
      } else {
        this.loadDefaultDepartment(clusterId);
      }
    },
    loadDefaultDepartment(clusterId: string): void {
      this.$emit("load-default-department", clusterId);
    },
    loadDepartment(departmentId: string): void {
      this.$emit("load-department", departmentId);
    },
    updateProjectNodePools(): void {
      if (this.department) {
        this.formData.resources = orgUnitUtil.getEmptyResourcesModel(
          this.getNodePoolsNamesAndIds(this.department.resources),
          this.isCpuEnabled,
        );
      }
    },
    initSchedulingRules(): void {
      const parentRules = this.department?.schedulingRules || {};
      this.formData.schedulingRules = orgUnitUtil.initSchedulingRules(this.project.schedulingRules || {}, parentRules);
      this.parentSchedulingRules = parentRules;
    },
    initDefaultNodePools(): void {
      if (this.department) {
        const defaultNodePools = this.department?.defaultNodePools || [];
        if (this.isNewProject || !this.formData?.defaultNodePools?.length) {
          this.formData.defaultNodePools = [...defaultNodePools];
        }
      }
    },
    getNodePoolsNamesAndIds(resources: Resources[]): INodePoolsNameAndId[] {
      return resources.map((resources) => {
        return {
          id: orgUnitUtil.getNodePoolIdByResource(resources),
          name: orgUnitUtil.getNodePoolNameByResource(resources),
        };
      });
    },
    async validate(): Promise<boolean> {
      return await (this.$refs.elProjectEditCreateForm as HTMLFormElement).validate();
    },
    async save(): Promise<void> {
      this.isFormValid = await this.validate();
      if (this.isFormValid) {
        this.$emit("on-save", this.formData);
      }
    },
  },
  watch: {
    formData: {
      handler() {
        this.isFormValid = true;
      },
      deep: true,
    },
    department: {
      handler(department: Department) {
        this.formData.parentId = department.id;

        this.updateProjectNodePools();
        this.initDefaultNodePools();
        if (this.showOrgUnitParity) {
          this.initSchedulingRules();
        }
      },
    },
  },
});
</script>
