/* tslint:disable */
/* eslint-disable */
/**
 * Tenant Management
 * An API for managing tenants objects and their associated resources.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface DeleteTenantById200Response
 */
export interface DeleteTenantById200Response {
    /**
     * 
     * @type {string}
     * @memberof DeleteTenantById200Response
     */
    'uid': string;
}
/**
 * 
 * @export
 * @interface GetLicenseQuery200ResponseInner
 */
export interface GetLicenseQuery200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetLicenseQuery200ResponseInner
     */
    'feature'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseQuery200ResponseInner
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetLicenseQuery200ResponseInner
     */
    'valid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseQuery200ResponseInner
     */
    'expiry'?: string;
}
/**
 * 
 * @export
 * @interface GetTenantLogo200Response
 */
export interface GetTenantLogo200Response {
    /**
     * 
     * @type {string}
     * @memberof GetTenantLogo200Response
     */
    'logo': string;
}
/**
 * 
 * @export
 * @interface MetricStore
 */
export interface MetricStore {
    /**
     * 
     * @type {MetricStoreEndpoint}
     * @memberof MetricStore
     */
    'read': MetricStoreEndpoint;
    /**
     * 
     * @type {MetricStoreEndpoint}
     * @memberof MetricStore
     */
    'write': MetricStoreEndpoint;
}
/**
 * 
 * @export
 * @interface MetricStoreEndpoint
 */
export interface MetricStoreEndpoint {
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpoint
     */
    'url': string;
    /**
     * 
     * @type {MetricStoreEndpointAuth}
     * @memberof MetricStoreEndpoint
     */
    'auth'?: MetricStoreEndpointAuth | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MetricStoreEndpoint
     */
    'headers'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface MetricStoreEndpointAuth
 */
export interface MetricStoreEndpointAuth {
    /**
     * 
     * @type {MetricStoreEndpointAuthBasic}
     * @memberof MetricStoreEndpointAuth
     */
    'basic'?: MetricStoreEndpointAuthBasic | null;
}
/**
 * 
 * @export
 * @interface MetricStoreEndpointAuthBasic
 */
export interface MetricStoreEndpointAuthBasic {
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpointAuthBasic
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricStoreEndpointAuthBasic
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface MetricStorePatchRequest
 */
export interface MetricStorePatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MetricStorePatchRequest
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface PostTenantEulaRequest
 */
export interface PostTenantEulaRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTenantEulaRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Roles = {
    SystemAdministrator: 'System administrator',
    CloudOperator: 'Cloud operator'
} as const;

export type Roles = typeof Roles[keyof typeof Roles];


/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof Tenant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'mimirId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'displayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    'smg': boolean;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'status': TenantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'contractType': TenantContractTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'eula'?: string;
}

export const TenantStatusEnum = {
    Creating: 'Creating',
    Failed: 'Failed',
    Updating: 'Updating',
    Deleted: 'Deleted',
    Deleting: 'Deleting',
    Ready: 'Ready'
} as const;

export type TenantStatusEnum = typeof TenantStatusEnum[keyof typeof TenantStatusEnum];
export const TenantContractTypeEnum = {
    Normal: 'normal',
    Trial: 'trial'
} as const;

export type TenantContractTypeEnum = typeof TenantContractTypeEnum[keyof typeof TenantContractTypeEnum];

/**
 * 
 * @export
 * @interface TenantCreationRequest
 */
export interface TenantCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'contractType'?: TenantCreationRequestContractTypeEnum;
    /**
     * 
     * @type {Roles}
     * @memberof TenantCreationRequest
     */
    'role'?: Roles | null;
    /**
     * 
     * @type {TenantCreationRequestMetricStore}
     * @memberof TenantCreationRequest
     */
    'metricStore'?: TenantCreationRequestMetricStore | null;
}

export const TenantCreationRequestContractTypeEnum = {
    Normal: 'normal',
    Trial: 'trial',
    Empty: ''
} as const;

export type TenantCreationRequestContractTypeEnum = typeof TenantCreationRequestContractTypeEnum[keyof typeof TenantCreationRequestContractTypeEnum];

/**
 * 
 * @export
 * @interface TenantCreationRequestMetricStore
 */
export interface TenantCreationRequestMetricStore {
    /**
     * 
     * @type {MetricStoreEndpoint}
     * @memberof TenantCreationRequestMetricStore
     */
    'read': MetricStoreEndpoint;
    /**
     * 
     * @type {MetricStoreEndpoint}
     * @memberof TenantCreationRequestMetricStore
     */
    'write': MetricStoreEndpoint;
}
/**
 * 
 * @export
 * @interface TenantCreationResponse
 */
export interface TenantCreationResponse {
    /**
     * 
     * @type {Tenant}
     * @memberof TenantCreationResponse
     */
    'tenant': Tenant;
    /**
     * additional data for tenant creation
     * @type {object}
     * @memberof TenantCreationResponse
     */
    'additionalData'?: object | null;
}
/**
 * 
 * @export
 * @interface UploadLicense200Response
 */
export interface UploadLicense200Response {
    /**
     * 
     * @type {string}
     * @memberof UploadLicense200Response
     */
    'tenant-name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadLicense200Response
     */
    'tenant-id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadLicense200Response
     */
    'expiry-date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadLicense200Response
     */
    'issue-date'?: string;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * health check
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * health check
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * health check
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * health check
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create or refresh image pull secret for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrRefreshImagePullSecret: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createOrRefreshImagePullSecret', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/imagepullsecret`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary revoke image pull secret for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagePullSecret: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteImagePullSecret', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/imagepullsecret`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant domain
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantDomain: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantDomain', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/domain`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant image pull secret
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantImagePullSecret: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantImagePullSecret', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/imagepullsecret`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantMetricStore: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantMetricStore', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/metric-store`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenants list internal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGetTenantsInternal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/management/api/v1/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create metrics data source for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileMetricsDataSource: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('reconcileMetricsDataSource', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/metrics-data-source`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to activate a tenant\'s metric store
         * @summary Update tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {MetricStorePatchRequest} metricStorePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMetricStoreActivation: async (tenantId: number, metricStorePatchRequest: MetricStorePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('setMetricStoreActivation', 'tenantId', tenantId)
            // verify required parameter 'metricStorePatchRequest' is not null or undefined
            assertParamExists('setMetricStoreActivation', 'metricStorePatchRequest', metricStorePatchRequest)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/metric-store`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricStorePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {MetricStore} metricStore 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantMetricStore: async (tenantId: number, metricStore: MetricStore, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateTenantMetricStore', 'tenantId', tenantId)
            // verify required parameter 'metricStore' is not null or undefined
            assertParamExists('updateTenantMetricStore', 'metricStore', metricStore)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/metric-store`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricStore, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create or refresh image pull secret for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrRefreshImagePullSecret(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrRefreshImagePullSecret(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary revoke image pull secret for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImagePullSecret(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImagePullSecret(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant domain
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantDomain(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantDomain(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant image pull secret
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantImagePullSecret(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantImagePullSecret(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantMetricStore(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantMetricStore(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenants list internal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async managementGetTenantsInternal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tenant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.managementGetTenantsInternal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create metrics data source for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reconcileMetricsDataSource(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reconcileMetricsDataSource(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to activate a tenant\'s metric store
         * @summary Update tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {MetricStorePatchRequest} metricStorePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMetricStoreActivation(tenantId: number, metricStorePatchRequest: MetricStorePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMetricStoreActivation(tenantId, metricStorePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {MetricStore} metricStore 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenantMetricStore(tenantId: number, metricStore: MetricStore, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenantMetricStore(tenantId, metricStore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Create or refresh image pull secret for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrRefreshImagePullSecret(tenantId: number, options?: any): AxiosPromise<void> {
            return localVarFp.createOrRefreshImagePullSecret(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary revoke image pull secret for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImagePullSecret(tenantId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImagePullSecret(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant domain
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantDomain(tenantId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getTenantDomain(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant image pull secret
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantImagePullSecret(tenantId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getTenantImagePullSecret(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantMetricStore(tenantId: number, options?: any): AxiosPromise<MetricStore> {
            return localVarFp.getTenantMetricStore(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenants list internal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managementGetTenantsInternal(options?: any): AxiosPromise<Array<Tenant>> {
            return localVarFp.managementGetTenantsInternal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create metrics data source for tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileMetricsDataSource(tenantId: number, options?: any): AxiosPromise<void> {
            return localVarFp.reconcileMetricsDataSource(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to activate a tenant\'s metric store
         * @summary Update tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {MetricStorePatchRequest} metricStorePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMetricStoreActivation(tenantId: number, metricStorePatchRequest: MetricStorePatchRequest, options?: any): AxiosPromise<MetricStore> {
            return localVarFp.setMetricStoreActivation(tenantId, metricStorePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update tenant Metric Store
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {MetricStore} metricStore 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenantMetricStore(tenantId: number, metricStore: MetricStore, options?: any): AxiosPromise<MetricStore> {
            return localVarFp.updateTenantMetricStore(tenantId, metricStore, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary Create or refresh image pull secret for tenant
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public createOrRefreshImagePullSecret(tenantId: number, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).createOrRefreshImagePullSecret(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary revoke image pull secret for tenant
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteImagePullSecret(tenantId: number, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deleteImagePullSecret(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant domain
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getTenantDomain(tenantId: number, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getTenantDomain(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant image pull secret
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getTenantImagePullSecret(tenantId: number, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getTenantImagePullSecret(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant Metric Store
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getTenantMetricStore(tenantId: number, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getTenantMetricStore(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenants list internal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public managementGetTenantsInternal(options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).managementGetTenantsInternal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create metrics data source for tenant
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public reconcileMetricsDataSource(tenantId: number, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).reconcileMetricsDataSource(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to activate a tenant\'s metric store
     * @summary Update tenant Metric Store
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {MetricStorePatchRequest} metricStorePatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public setMetricStoreActivation(tenantId: number, metricStorePatchRequest: MetricStorePatchRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).setMetricStoreActivation(tenantId, metricStorePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update tenant Metric Store
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {MetricStore} metricStore 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateTenantMetricStore(tenantId: number, metricStore: MetricStore, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateTenantMetricStore(tenantId, metricStore, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogoApi - axios parameter creator
 * @export
 */
export const LogoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the base64 logo file.
         * @summary Get tenant logo. (base64)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to upload a base64 logo file. Max size 128kb.
         * @summary Upload logo for tenant. (base64)
         * @param {GetTenantLogo200Response} [getTenantLogo200Response] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTenantLogo: async (getTenantLogo200Response?: GetTenantLogo200Response, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTenantLogo200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogoApi - functional programming interface
 * @export
 */
export const LogoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogoApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the base64 logo file.
         * @summary Get tenant logo. (base64)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantLogo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to upload a base64 logo file. Max size 128kb.
         * @summary Upload logo for tenant. (base64)
         * @param {GetTenantLogo200Response} [getTenantLogo200Response] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTenantLogo(getTenantLogo200Response?: GetTenantLogo200Response, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTenantLogo(getTenantLogo200Response, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogoApi - factory interface
 * @export
 */
export const LogoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogoApiFp(configuration)
    return {
        /**
         * Retrieve the base64 logo file.
         * @summary Get tenant logo. (base64)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantLogo(options?: any): AxiosPromise<GetTenantLogo200Response> {
            return localVarFp.getTenantLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * Use to upload a base64 logo file. Max size 128kb.
         * @summary Upload logo for tenant. (base64)
         * @param {GetTenantLogo200Response} [getTenantLogo200Response] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTenantLogo(getTenantLogo200Response?: GetTenantLogo200Response, options?: any): AxiosPromise<void> {
            return localVarFp.uploadTenantLogo(getTenantLogo200Response, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogoApi - object-oriented interface
 * @export
 * @class LogoApi
 * @extends {BaseAPI}
 */
export class LogoApi extends BaseAPI {
    /**
     * Retrieve the base64 logo file.
     * @summary Get tenant logo. (base64)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogoApi
     */
    public getTenantLogo(options?: AxiosRequestConfig) {
        return LogoApiFp(this.configuration).getTenantLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to upload a base64 logo file. Max size 128kb.
     * @summary Upload logo for tenant. (base64)
     * @param {GetTenantLogo200Response} [getTenantLogo200Response] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogoApi
     */
    public uploadTenantLogo(getTenantLogo200Response?: GetTenantLogo200Response, options?: AxiosRequestConfig) {
        return LogoApiFp(this.configuration).uploadTenantLogo(getTenantLogo200Response, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create tenant
         * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (tenantCreationRequest: TenantCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCreationRequest' is not null or undefined
            assertParamExists('createTenant', 'tenantCreationRequest', tenantCreationRequest)
            const localVarPath = `/api/v1/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary soft/hard delete tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantById: async (tenantId: number, isHardDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteTenantById', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isHardDelete !== undefined) {
                localVarQueryParameter['isHardDelete'] = isHardDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantById: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantById', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of tenants and their details.
         * @summary Get a list of tenants.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post tenant eula
         * @param {PostTenantEulaRequest} postTenantEulaRequest The user email that signing the eula.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTenantEula: async (postTenantEulaRequest: PostTenantEulaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTenantEulaRequest' is not null or undefined
            assertParamExists('postTenantEula', 'postTenantEulaRequest', postTenantEulaRequest)
            const localVarPath = `/api/v1/sign-eula`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTenantEulaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create tenant
         * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(tenantCreationRequest: TenantCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(tenantCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary soft/hard delete tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenantById(tenantId: number, isHardDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteTenantById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenantById(tenantId, isHardDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantById(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantById(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of tenants and their details.
         * @summary Get a list of tenants.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tenant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post tenant eula
         * @param {PostTenantEulaRequest} postTenantEulaRequest The user email that signing the eula.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTenantEula(postTenantEulaRequest: PostTenantEulaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTenantEula(postTenantEulaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @summary Create tenant
         * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(tenantCreationRequest: TenantCreationRequest, options?: any): AxiosPromise<TenantCreationResponse> {
            return localVarFp.createTenant(tenantCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary soft/hard delete tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantById(tenantId: number, isHardDelete?: boolean, options?: any): AxiosPromise<DeleteTenantById200Response> {
            return localVarFp.deleteTenantById(tenantId, isHardDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantById(tenantId: number, options?: any): AxiosPromise<Tenant> {
            return localVarFp.getTenantById(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of tenants and their details.
         * @summary Get a list of tenants.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants(options?: any): AxiosPromise<Array<Tenant>> {
            return localVarFp.getTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post tenant eula
         * @param {PostTenantEulaRequest} postTenantEulaRequest The user email that signing the eula.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTenantEula(postTenantEulaRequest: PostTenantEulaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postTenantEula(postTenantEulaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @summary Create tenant
     * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(tenantCreationRequest: TenantCreationRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createTenant(tenantCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary soft/hard delete tenant
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenantById(tenantId: number, isHardDelete?: boolean, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).deleteTenantById(tenantId, isHardDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantById(tenantId: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantById(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of tenants and their details.
     * @summary Get a list of tenants.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenants(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post tenant eula
     * @param {PostTenantEulaRequest} postTenantEulaRequest The user email that signing the eula.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public postTenantEula(postTenantEulaRequest: PostTenantEulaRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).postTenantEula(postTenantEulaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create tenant
         * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantInternal: async (tenantCreationRequest: TenantCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantCreationRequest' is not null or undefined
            assertParamExists('createTenantInternal', 'tenantCreationRequest', tenantCreationRequest)
            const localVarPath = `/internal/api/v1/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary soft/hard delete tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantByIdInternal: async (tenantId: number, isHardDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteTenantByIdInternal', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isHardDelete !== undefined) {
                localVarQueryParameter['isHardDelete'] = isHardDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get license query api
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {'gpu'} [feature] 
         * @param {string} [expiredBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseQuery: async (tenantId: number, feature?: 'gpu', expiredBefore?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getLicenseQuery', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/license/query`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (feature !== undefined) {
                localVarQueryParameter['feature'] = feature;
            }

            if (expiredBefore !== undefined) {
                localVarQueryParameter['expired-before'] = (expiredBefore as any instanceof Date) ?
                    (expiredBefore as any).toISOString().substr(0,10) :
                    expiredBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant
         * @param {string} tenantName The name of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (tenantName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantName' is not null or undefined
            assertParamExists('getTenant', 'tenantName', tenantName)
            const localVarPath = `/internal/api/v1/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant by id
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantByIdInternal: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantByIdInternal', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant license
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantLicense: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantLicense', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/license`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload license
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLicense: async (tenantId: number, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('uploadLicense', 'tenantId', tenantId)
            const localVarPath = `/internal/api/v1/tenants/{tenantId}/license`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create tenant
         * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenantInternal(tenantCreationRequest: TenantCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenantInternal(tenantCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary soft/hard delete tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenantByIdInternal(tenantId: number, isHardDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteTenantById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenantByIdInternal(tenantId, isHardDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get license query api
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {'gpu'} [feature] 
         * @param {string} [expiredBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseQuery(tenantId: number, feature?: 'gpu', expiredBefore?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLicenseQuery200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseQuery(tenantId, feature, expiredBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant
         * @param {string} tenantName The name of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(tenantName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenant(tenantName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant by id
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantByIdInternal(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantByIdInternal(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant license
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantLicense(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantLicense(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload license
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLicense(tenantId: number, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLicense200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLicense(tenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create tenant
         * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantInternal(tenantCreationRequest: TenantCreationRequest, options?: any): AxiosPromise<TenantCreationResponse> {
            return localVarFp.createTenantInternal(tenantCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary soft/hard delete tenant
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenantByIdInternal(tenantId: number, isHardDelete?: boolean, options?: any): AxiosPromise<DeleteTenantById200Response> {
            return localVarFp.deleteTenantByIdInternal(tenantId, isHardDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get license query api
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {'gpu'} [feature] 
         * @param {string} [expiredBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseQuery(tenantId: number, feature?: 'gpu', expiredBefore?: string, options?: any): AxiosPromise<Array<GetLicenseQuery200ResponseInner>> {
            return localVarFp.getLicenseQuery(tenantId, feature, expiredBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant
         * @param {string} tenantName The name of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(tenantName: string, options?: any): AxiosPromise<Tenant> {
            return localVarFp.getTenant(tenantName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant by id
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantByIdInternal(tenantId: number, options?: any): AxiosPromise<Tenant> {
            return localVarFp.getTenantByIdInternal(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant license
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantLicense(tenantId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getTenantLicense(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload license
         * @param {number} tenantId The id of the tenant to retrieve
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLicense(tenantId: number, file?: any, options?: any): AxiosPromise<UploadLicense200Response> {
            return localVarFp.uploadLicense(tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @summary Create tenant
     * @param {TenantCreationRequest} tenantCreationRequest The tenant to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public createTenantInternal(tenantCreationRequest: TenantCreationRequest, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).createTenantInternal(tenantCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary soft/hard delete tenant
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public deleteTenantByIdInternal(tenantId: number, isHardDelete?: boolean, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).deleteTenantByIdInternal(tenantId, isHardDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get license query api
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {'gpu'} [feature] 
     * @param {string} [expiredBefore] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public getLicenseQuery(tenantId: number, feature?: 'gpu', expiredBefore?: string, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).getLicenseQuery(tenantId, feature, expiredBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant
     * @param {string} tenantName The name of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public getTenant(tenantName: string, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).getTenant(tenantName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant by id
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public getTenantByIdInternal(tenantId: number, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).getTenantByIdInternal(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant license
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public getTenantLicense(tenantId: number, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).getTenantLicense(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload license
     * @param {number} tenantId The id of the tenant to retrieve
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public uploadLicense(tenantId: number, file?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).uploadLicense(tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


