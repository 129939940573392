import type { Connection, Environment } from "@/swagger-models/workloads-service-client";
import {
  type HistoryRecord,
  HistoryRecordType,
  Phase,
  Source,
  type Workload,
  WorkloadSortFilterFields,
} from "@/swagger-models/workloads-service-client";
import {
  type Annotation,
  type AssetIdAndKind,
  type AssetsIds,
  type AssetsRef,
  type DatasourceRef,
  type DisplayedJob,
  type DistributedParams,
  type EnvironmentVariable,
  type Inference,
  type InferenceV2,
  type InferenceCreationRequestV2,
  InternalConnectionType,
  type Label,
  type SpecificRunConnectionInfo,
  type SpecificRunParams,
  type ToolType,
  type TrainingCreationRequestV2,
  type TrainingV2,
  type WorkloadCreationRequest,
  type WorkloadMeta,
  type WorkloadSupportedTypes,
  type WorkspaceCreationRequestV2,
  type WorkspaceV2,
  type ModelInference,
  type ModelInferenceV2,
  type ModelInferenceCreationRequest,
  type ModelInferenceCreationRequestV2,
  type DistributedData,
  type AssetsRefOptional,
  type SpecificRunServingPortAccessServingPortAccess,
  SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum,
  type InferenceSpecificRunParams,
} from "@/swagger-models/assets-service-client";
import {
  EWorkloadAction,
  EWorkloadEntity,
  EWorkloadName,
  type IUIDistributed,
  type IUIDistributedMaster,
  type IUIInferenceCreation,
  type IUIWorkloadAssets,
  type IUIWorkloadCreation,
  type IUIWorkloadSpecificEnv,
  type IWorkloadMeta,
  WorkloadPhaseMap,
  type WorkloadServicePod,
  EWorkloadType,
  type IUIWorkloadModelCreation,
  type IWorkloadSupportedRoutes,
  type IToolItem,
} from "@/models/workload.model";
import { EInferenceType, type IInferenceList } from "@/models/inference.model";
import type { IUIVolume } from "@/models/data-source.model";
import { EHuggingFaceEnvVariableNames, LLM_IMAGE_FOR_HUGGING_FACE } from "@/models/inference.model";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import { deepCopy, fallbackDefaultIfNullOrUndefined, omit, pick } from "@/utils/common.util";
import { tableUtil } from "@/utils/table.util";
import { ETableFilters, type IStatusColOptions } from "@/models/table.model";
import { allWorkloadsIconsMap } from "@/common/icons.constant";
import type { IWorkspaceList } from "@/models/workspace.model";
import { UI_WORKSPACE_STATUS } from "@/common/status.constant";
import { STORAGE_KEYS } from "@/models/storage.model";
import { filterService } from "@/services/filter.service/filter.service";
import type { IAdvancedFilterModel, IEnumBasedFilterModel } from "@/models/filter.model";
import { EColumnFilterType, EFilterOperator } from "@/models/filter.model";
import type { ITrainingList } from "@/models/training.model";

import type { IWorkloadSpecificRunParams, SpecificRunParamsKeys } from "./workload.util.models";
import { storageUtil } from "@/utils/storage.util";
import { ENVIRONMENT_ROUTE_NAMES } from "@/router/environment.routes/environment.routes.names";
import { COMPUTE_RESOURCE_ROUTE_NAMES } from "@/router/compute-resource.routes/compute-resource.routes.names";
import { DATA_SOURCE_CREATION_ROUTE_MAP } from "@/router/data-source.routes/data-source.routes.names";
import {
  type IConnectionAccess,
  EAccessOptions,
} from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";

export interface IUIDistributedUISetup {
  distributedData: DistributedData | null;
  enableEditingMaster?: boolean;
  noMaster?: boolean;
}

export const workloadUtil = {
  getEmptyUIWorkloadCreation,
  getUIWorkloadSpecificEnv,
  getUIWorkloadAssets,
  getWorkloadAssetsFromUIWorkloadAssets,
  getWorkloadSpecificEnvFromUIWorkloadSpecificEnv,
  getWorkloadCreationRequest,
  getWorkspaceCreationRequestV2,
  getTrainingCreationRequestV2,
  getInferenceCreationRequestV2,
  convertWorkloadToWorkloadUI,
  getDisabledToolTipText,
  getConnectivityMessage,
  getMissingDomainMessage,
  getWorkloadStatusColOptions,
  getPodStatusColOptions,
  isWorkloadSourceControlPlane,
  isPhaseUpdateRecordType,
  getWorkloadIcon,
  convertConnectionsToToolItems,
  getWorkspaceTrainingStatusColOptions,
  getRunningVsRequestedDisplayedValue,
  convertWorkloadTypesToString,
  isWorkloadSourceCli,
  getWorkloadConnectionsForGrid,
  applyUserCreatedWorkloadsFilter,
  getEmptyUIInferenceCreation,
  convertEnvVarToEnvVarUI,
  convertToWorkload,
  getWorkloadListModalStatusColOptions,
  getModelInferenceCreationRequest,
  getModelInferenceCreationRequestV2,
  adjustWorkloadPhaseFilter,
  isRunaiWorkload,
  getSupportedRoutes,
  convertInferenceToWorkloadUI,
  getInferenceWorkloadCreationRequest,
  getInferenceRequestV2SpecificRunParams,
  _processCommonSpecificEnv,
  _getAuthorizationType,
  _servingPortAccess,
  _getServingPortAccessOption,
  _servingPortInternalToolInfo,
  _getWorkloadUI,
};

function convertWorkloadTypesToString(workloadTypes: WorkloadSupportedTypes): string {
  // It's important to keep the distributed first in this array,
  // when distributed is supported it is the only type that can be supported
  const workloadsList: Array<string> = [
    EWorkloadName.Distributed,
    EWorkloadName.Workspace,
    EWorkloadName.Training,
    EWorkloadName.Inference,
  ];

  const supportedTypes = [];
  if (workloadTypes[EWorkloadName.Distributed]) {
    supportedTypes.push(EWorkloadName.Training); // Distributed is an architecture type of training
  } else {
    workloadsList.forEach((key: string) => {
      if (workloadTypes[key as keyof WorkloadSupportedTypes]) {
        supportedTypes.push(key);
      }
    });
  }

  const selectedString = supportedTypes.join(", ");
  return selectedString.charAt(0).toUpperCase() + selectedString.slice(1);
}

function getEmptyUIWorkloadCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIWorkloadCreation {
  return {
    name: "",
    projectId: -1,
    namespace: "",
    clusterId: "",
    assets: {
      environment: "",
      compute: "",
    },
    specificEnv: {
      ...specificEnv,
      backoffLimit: 6,
    },
  };
}

function getEmptyUIInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const MIN_REPLICAS_DEFAULT_VALUE = 1;
  const MAX_REPLICAS_DEFAULT_VALUE = 1;

  const uiWorkload: IUIWorkloadCreation = getEmptyUIWorkloadCreation(specificEnv);
  return {
    ...uiWorkload,
    specificEnv: {
      autoScaleData: {
        minReplicas: MIN_REPLICAS_DEFAULT_VALUE,
        maxReplicas: MAX_REPLICAS_DEFAULT_VALUE,
        thresholdMetric: undefined,
        thresholdValue: undefined,
        scaleToZeroRetentionSeconds: undefined,
      },
      servingPortAccess: {
        authorizedUsers: null,
        authorizedGroups: null,
        accessOption: EAccessOptions.PUBLIC,
      },
      ...uiWorkload.specificEnv,
    },
    inferenceType: EInferenceType.AssetsBased,
  };
}

function getUIWorkloadAssets(assets: AssetsIds | undefined, uiVolumes?: Array<IUIVolume>): IUIWorkloadAssets {
  return {
    environment: assets?.environment || null,
    compute: assets?.compute || null,
    datasources: assets?.datasources,
    uiVolumes,
  };
}

function getUIWorkloadAssetsRef(
  assets: AssetsRef | AssetsRefOptional | undefined | null,
  uiVolumes?: Array<IUIVolume>,
): IUIWorkloadAssets {
  return {
    environment: assets?.environment?.id || null,
    compute: assets?.compute?.id || null,
    datasources: assets?.datasources?.map((dataSource: DatasourceRef) => {
      const dsAsset: AssetIdAndKind = { id: dataSource.id, kind: dataSource.kind };
      if (dataSource.overrides) dsAsset.overrides = dataSource.overrides;
      return dsAsset;
    }),
    uiVolumes,
  };
}

function getUIWorkloadSpecificEnv(specificEnv: SpecificRunParams | undefined = {}): IUIWorkloadSpecificEnv {
  const workloadSpecificEnv: IUIWorkloadSpecificEnv = {
    args: specificEnv.args,
    command: specificEnv.command,
    runAsUid: specificEnv.runAsUid || null,
    runAsGid: specificEnv.runAsGid || null,
    supplementalGroups: specificEnv.supplementalGroups,
    nodeType: specificEnv.nodeType || null,
    allowOverQuota: specificEnv.allowOverQuota || null,
    nodePools: specificEnv.nodePools ? specificEnv.nodePools : null,
    autoDeletionTimeAfterCompletionSeconds: fallbackDefaultIfNullOrUndefined(
      specificEnv.autoDeletionTimeAfterCompletionSeconds,
      null,
    ),
    connections: specificEnv.connections,
    environmentVariables: specificEnv.environmentVariables || undefined,
    annotations: specificEnv.annotations?.map((ann: Annotation) => {
      const itemized: IItemizedListItem = {
        ...ann,
        name: ann.name || "",
        value: ann.value || "",
        exclude: ann.exclude || undefined,
      };

      return itemized;
    }),
    labels: specificEnv.labels?.map((lbl: Label) => {
      const itemized: IItemizedListItem = {
        ...lbl,
        name: lbl.name || "",
        value: lbl.value || "",
        exclude: lbl.exclude || undefined,
      };

      return itemized;
    }),
    backoffLimit: specificEnv.backoffLimit || 6,
    tolerations: specificEnv.tolerations,
    podAffinity: specificEnv.podAffinity,
    parallelism: specificEnv.parallelism,
    completions: specificEnv.completions,
  };

  return workloadSpecificEnv;
}

function _servingPortInternalToolInfo(
  servingPortAccess: SpecificRunServingPortAccessServingPortAccess | undefined | null,
): IConnectionAccess {
  return {
    accessOption: _getServingPortAccessOption(servingPortAccess),
    authorizedGroups: servingPortAccess?.authorizedGroups,
    authorizedUsers: servingPortAccess?.authorizedUsers,
  };
}

function _getServingPortAccessOption(
  servingPortAccess: SpecificRunServingPortAccessServingPortAccess | undefined | null,
): EAccessOptions {
  let authorizationType: EAccessOptions = EAccessOptions.PUBLIC;
  if (!servingPortAccess) return authorizationType;
  switch (servingPortAccess.authorizationType) {
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public:
      authorizationType = EAccessOptions.PUBLIC;
      break;
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthenticatedUsers:
      authorizationType = EAccessOptions.EVERYONE;
      break;
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthorizedUsersOrGroups:
      if (servingPortAccess.authorizedGroups) {
        authorizationType = EAccessOptions.GROUPS;
      } else {
        authorizationType = EAccessOptions.SPECIFIC_USERS;
      }
  }

  return authorizationType;
}

function convertEnvVarToEnvVarUI(environmentVariables: Array<EnvironmentVariable>): Array<IItemizedListItem> {
  return environmentVariables.map((env: EnvironmentVariable) => {
    return {
      ...env,
      name: env.name || "",
      value: env.value || "",
      exclude: env.exclude || undefined,
    };
  });
}

function getWorkloadAssetsFromUIWorkloadAssets(assets: IUIWorkloadAssets, workloadVolumes?: Array<string>): AssetsIds {
  if (!assets.environment) throw new Error("assets.environment is required");

  const retAssets: AssetsIds = {
    environment: assets.environment,
    compute: assets.compute,
  };

  if (assets.datasources) {
    retAssets.datasources = assets.datasources;
  }

  if (workloadVolumes) {
    retAssets.workloadVolumes = workloadVolumes;
  }

  return retAssets;
}

function getWorkloadSpecificEnvFromUIWorkloadSpecificEnv(specificEnv: IUIWorkloadSpecificEnv): SpecificRunParams {
  const retSpecificEnv = _processCommonSpecificEnv(specificEnv, ["autoScaleData", "servingPortAccess"]);
  return retSpecificEnv;
}

function getWorkspaceCreationRequestV2(
  workspace: IUIWorkloadCreation,
  workloadVolumes?: Array<string>,
): WorkspaceCreationRequestV2 {
  if (!workspace.assets.environment) throw "Environment details are missing";
  const workloadCreationRequest: WorkspaceCreationRequestV2 = {
    meta: {
      name: workspace.name,
      projectId: workspace.projectId?.toString(),
      clusterId: workspace.clusterId,
    },
    assets: getWorkloadAssetsFromUIWorkloadAssets(workspace.assets, workloadVolumes),
    specificRunParams: getWorkloadV2RequestSpecificRunParams(workspace.specificEnv),
  };
  return workloadCreationRequest;
}

function getTrainingCreationRequestV2(
  training: IUIWorkloadCreation,
  workloadVolumes?: Array<string>,
  masterWorkloadVolumes?: Array<string>,
): TrainingCreationRequestV2 {
  if (!training.assets.environment) throw "Environment details are missing";
  const workloadCreationRequest: TrainingCreationRequestV2 = {
    meta: {
      name: training.name,
      projectId: training.projectId?.toString(),
      clusterId: training.clusterId,
    },
    assets: getWorkloadAssetsFromUIWorkloadAssets(training.assets, workloadVolumes),
    specificRunParams: getWorkloadV2RequestSpecificRunParams(training.specificEnv),
  };

  if (training.distributed) {
    workloadCreationRequest.distributed = _getDistributedObjectForTrainingV2Request(
      training.distributed,
      masterWorkloadVolumes,
      !training.enableEditingMaster,
    );
  }

  return workloadCreationRequest;
}

function getInferenceCreationRequestV2(
  inference: IUIWorkloadCreation,
  supportingServingPortAccess = false,
): InferenceCreationRequestV2 {
  if (!inference.assets.environment) throw "Environment details are missing";
  const workloadCreationRequest: InferenceCreationRequestV2 = {
    meta: {
      name: inference.name,
      projectId: inference.projectId.toString(),
      clusterId: inference.clusterId,
    },
    assets: getWorkloadAssetsFromUIWorkloadAssets(inference.assets),
    specificRunParams: getInferenceRequestV2SpecificRunParams(inference.specificEnv, supportingServingPortAccess),
  };
  return workloadCreationRequest;
}

function getModelInferenceCreationRequest(
  modelSpecCreate: IUIWorkloadModelCreation,
  supportingServingPortAccess = false,
): ModelInferenceCreationRequest {
  if (!modelSpecCreate.assets.compute) throw "Compute resource is missing";

  return {
    name: modelSpecCreate.name,
    clusterId: modelSpecCreate.clusterId,
    projectId: modelSpecCreate.projectId,
    namespace: modelSpecCreate.namespace,
    modelId: modelSpecCreate.modelId,
    assets: {
      compute: modelSpecCreate.assets.compute,
    },
    specificEnv: getInferenceRequestV2SpecificRunParams(modelSpecCreate.specificEnv, supportingServingPortAccess),
  };
}

function getModelInferenceCreationRequestV2(
  modelSpecCreate: IUIWorkloadModelCreation,
  supportingServingPortAccess = false,
): ModelInferenceCreationRequestV2 {
  if (!modelSpecCreate.assets.compute) throw "Compute resource is missing";

  return {
    meta: {
      name: modelSpecCreate.name,
      clusterId: modelSpecCreate.clusterId,
      projectId: String(modelSpecCreate.projectId),
    },
    assets: {
      model: modelSpecCreate.modelId,
      compute: modelSpecCreate.assets.compute || "",
    },
    specificRunParams: getInferenceRequestV2SpecificRunParams(modelSpecCreate.specificEnv, supportingServingPortAccess),
  };
}

function _processCommonSpecificEnv(
  specificEnv: IUIWorkloadSpecificEnv,
  omitFields: SpecificRunParamsKeys,
): SpecificRunParams {
  const retSpecificEnv: SpecificRunParams = {
    ...omit(specificEnv, [...omitFields, "environmentVariables", "annotations", "labels"]),
  };

  retSpecificEnv.args ||= null;
  retSpecificEnv.command ||= null;
  retSpecificEnv.runAsUid = retSpecificEnv.runAsUid ? +retSpecificEnv.runAsUid : undefined;
  retSpecificEnv.runAsGid = retSpecificEnv.runAsGid ? +retSpecificEnv.runAsGid : undefined;
  retSpecificEnv.supplementalGroups = Array.isArray(retSpecificEnv.supplementalGroups)
    ? retSpecificEnv.supplementalGroups.join(",")
    : retSpecificEnv.supplementalGroups;

  if (specificEnv.environmentVariables) {
    retSpecificEnv.environmentVariables = specificEnv.environmentVariables;
  }

  if (specificEnv.annotations) {
    retSpecificEnv.annotations = specificEnv.annotations.map((env: IItemizedListItem) =>
      pick(env, "name", "value", "exclude"),
    );
  }

  if (specificEnv.labels) {
    retSpecificEnv.labels = specificEnv.labels.map((env: IItemizedListItem) => pick(env, "name", "value", "exclude"));
  }

  return retSpecificEnv;
}

function getWorkloadV2RequestSpecificRunParams(specificEnv: IUIWorkloadSpecificEnv): IWorkloadSpecificRunParams {
  const retSpecificEnv = _processCommonSpecificEnv(specificEnv, ["autoScaleData"]);

  return retSpecificEnv;
}

function getInferenceRequestV2SpecificRunParams(
  specificEnv: IUIWorkloadSpecificEnv,
  supportingServingPortAccess = false,
): InferenceSpecificRunParams {
  const retSpecificEnv: InferenceSpecificRunParams = _processCommonSpecificEnv(specificEnv, [
    "autoScaleData",
    "allowOverQuota",
    "backoffLimit",
    "servingPortAccess",
  ]);

  if (specificEnv.autoScaleData) {
    retSpecificEnv.autoScaling = specificEnv.autoScaleData;
  }

  if (supportingServingPortAccess) {
    retSpecificEnv.servingPortAccess = _servingPortAccess(specificEnv.servingPortAccess);
  }

  return retSpecificEnv;
}

function _servingPortAccess(
  servingPortAccess: IConnectionAccess | null | undefined,
): SpecificRunServingPortAccessServingPortAccess {
  return {
    authorizationType: _getAuthorizationType(servingPortAccess),
    authorizedGroups: servingPortAccess?.authorizedGroups,
    authorizedUsers: servingPortAccess?.authorizedUsers,
  };
}

function _getAuthorizationType(
  servingPortAccess: IConnectionAccess | null | undefined,
): SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum {
  let authorizationType: SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum =
    SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public;
  if (!servingPortAccess?.accessOption) return authorizationType;

  switch (servingPortAccess.accessOption) {
    case EAccessOptions.PUBLIC:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public;
      break;
    case EAccessOptions.EVERYONE:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthenticatedUsers;
      break;
    case EAccessOptions.GROUPS:
    case EAccessOptions.SPECIFIC_USERS:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthorizedUsersOrGroups;
  }

  return authorizationType;
}

function _getDistributedObjectForTrainingV2Request(
  distInfo: IUIDistributed,
  masterWorkloadVolumes?: Array<string>,
  sameAsWorker?: boolean,
): DistributedParams {
  if (distInfo.master && !distInfo.noMaster && !distInfo.master.assets.environment)
    throw "Environment details are missing";
  const distParams: DistributedParams = {
    numWorkers: distInfo.numWorkers || 0,
    framework: distInfo.distFramework,
    master: null,
    // if there is no master (only workers) -> do not send master at all
  };

  if (distInfo.master && !distInfo.noMaster) {
    if (sameAsWorker) {
      // if master and worker are equal we only send "sameAsWorker: true"
      distParams.master = { sameAsWorker: true };
      return distParams;
    }
    // master details should only be sent if it is different than the worker (i.e sameAsWorker is falsey)
    distParams.master = {
      assets: getWorkloadAssetsFromUIWorkloadAssets(distInfo.master.assets, masterWorkloadVolumes),
      specificRunParams: getWorkloadV2RequestSpecificRunParams(distInfo.master.specificEnv),
    };
  }

  return distParams;
}

function getInferenceWorkloadCreationRequest(
  workload: IUIInferenceCreation,
  supportingServingPortAccess = false,
): WorkloadCreationRequest {
  const workloadCreationRequest: WorkloadCreationRequest = getWorkloadCreationRequest(workload);

  workloadCreationRequest.specificEnv = {
    ...workloadCreationRequest.specificEnv,
    autoScaling: workload.specificEnv.autoScaleData,
  };

  if (supportingServingPortAccess && workload.specificEnv.servingPortAccess) {
    workloadCreationRequest.specificEnv.servingPortAccess = _servingPortAccess(workload.specificEnv.servingPortAccess);
  }

  // Inference does not support backoffLimit
  delete workloadCreationRequest.specificEnv.backoffLimit;

  return workloadCreationRequest;
}

function getWorkloadCreationRequest(
  workload: IUIWorkloadCreation,
  workloadVolumes?: Array<string>,
  masterWorkloadVolumes?: Array<string>,
): WorkloadCreationRequest {
  const workloadCreationRequest: WorkloadCreationRequest = {
    name: workload.name,
    namespace: workload.namespace,
    clusterId: workload.clusterId,
    projectId: workload.projectId,
    assets: getWorkloadAssetsFromUIWorkloadAssets(workload.assets, workloadVolumes),
    specificEnv: getWorkloadSpecificEnvFromUIWorkloadSpecificEnv(workload.specificEnv),
  };

  if (workload.distributed) {
    workloadCreationRequest.distributed = {
      ...workload.distributed,
      master: null,
    };

    if (!workload.distributed.noMaster) {
      workloadCreationRequest.distributed.master = {
        specificEnv: getWorkloadSpecificEnvFromUIWorkloadSpecificEnv(workload.distributed.master?.specificEnv || {}),
      };

      if (workload.distributed.master?.assets && workloadCreationRequest.distributed.master) {
        workloadCreationRequest.distributed.master.assets = getWorkloadAssetsFromUIWorkloadAssets(
          workload.distributed.master.assets,
          masterWorkloadVolumes,
        );
      }
    }
  }

  return workloadCreationRequest;
}

export type TWorkloadsToConvert = WorkspaceV2 & TrainingV2 & InferenceV2;

function convertWorkloadToWorkloadUI(workload: TWorkloadsToConvert, uiVolumes?: Array<IUIVolume>): IUIWorkloadCreation {
  const workloadMeta: IWorkloadMeta = {
    name: workload.meta.name,
    projectId: +workload.meta.projectId,
    namespace: "",
    clusterId: workload.meta.clusterId,
  };

  const dataSources: AssetIdAndKind[] =
    workload.assets.datasources?.map((ds: DatasourceRef): AssetIdAndKind => {
      const dsAsset: AssetIdAndKind = {
        id: ds.id,
        kind: ds.kind,
      };
      if (ds.overrides) dsAsset.overrides = ds.overrides;
      return dsAsset;
    }) || [];

  const assets = {
    environment: workload.assets.environment.id,
    compute: workload.assets.compute?.id || null,
    datasources: dataSources,
  };

  // For copied workload we need to reset the connection
  const specificEnv: SpecificRunParams = workload.specificRunParams ? deepCopy(workload.specificRunParams) : {};
  if (specificEnv.connections) {
    specificEnv.connections = _emptyConnectionsCustomInfo(specificEnv.connections);
  }

  let distributedSetup: IUIDistributedUISetup | undefined;

  if (workload.distributed) {
    distributedSetup = { distributedData: workload.distributed };
    if (workload.distributed.master && Object.keys(workload.distributed.master).length) {
      if (
        workload.distributed.master.sameAsWorker ||
        !Object.keys(workload.distributed.master.specificRunParams || {}).length
      ) {
        distributedSetup.enableEditingMaster = false;
      } else {
        // if we have data which is specific for master we make enableEditingMaster true by default
        distributedSetup.enableEditingMaster = true;
      }
    } else {
      distributedSetup.noMaster = true;
    }
  }

  return _getWorkloadUI(workloadMeta, assets, specificEnv, uiVolumes, distributedSetup);
}

function convertInferenceToWorkloadUI(
  workload: TWorkloadsToConvert,
  inferenceType: EInferenceType,
  supportingServingPortAccess = false,
): IUIInferenceCreation {
  const baseWorkload: IUIWorkloadCreation = convertWorkloadToWorkloadUI(workload);
  const inferenceUI: IUIInferenceCreation = {
    ...baseWorkload,
    inferenceType,
  };

  const autoScaling = workload.specificRunParams?.autoScaling;
  inferenceUI.specificEnv.autoScaleData = {
    minReplicas: fallbackDefaultIfNullOrUndefined(autoScaling?.minReplicas, 1),
    maxReplicas: fallbackDefaultIfNullOrUndefined(autoScaling?.maxReplicas, 1),
    thresholdMetric: autoScaling?.thresholdMetric || undefined,
    thresholdValue: autoScaling?.thresholdValue || undefined,
    scaleToZeroRetentionSeconds: autoScaling?.scaleToZeroRetentionSeconds || undefined,
  };

  if (supportingServingPortAccess) {
    inferenceUI.specificEnv.servingPortAccess = _servingPortInternalToolInfo(
      workload.specificRunParams?.servingPortAccess,
    );
  }

  // delete inferenceUI.specificEnv.allowOverQuota;
  return {
    ...inferenceUI,
    inferenceType: inferenceType || EInferenceType.AssetsBased,
  };
}

function _getWorkloadUI(
  meta: IWorkloadMeta,
  assets: AssetsIds,
  specificEnv: SpecificRunParams = {},
  uiVolumes?: Array<IUIVolume>,
  distributedSetup?: IUIDistributedUISetup,
): IUIWorkloadCreation {
  const workloadUI: IUIWorkloadCreation = {
    name: meta.name,
    namespace: meta.namespace,
    clusterId: meta.clusterId,
    projectId: meta.projectId,
    assets: getUIWorkloadAssets(assets, uiVolumes),
    specificEnv: getUIWorkloadSpecificEnv(specificEnv),
  };

  if (distributedSetup?.distributedData) {
    let master: IUIDistributedMaster | null;
    if (!distributedSetup.distributedData.master || distributedSetup.noMaster) {
      master = null;
    } else {
      master = {
        assets: getUIWorkloadAssetsRef(distributedSetup.distributedData.master?.assets),
        specificEnv: getUIWorkloadSpecificEnv(distributedSetup.distributedData.master?.specificRunParams || {}),
      };
    }

    workloadUI.distributed = {
      numWorkers: distributedSetup.distributedData.numWorkers,
      distFramework: distributedSetup.distributedData.framework,
      master,
      noMaster: distributedSetup.noMaster,
    };

    workloadUI.enableEditingMaster = distributedSetup.enableEditingMaster;
  }

  return workloadUI;
}

function _emptyConnectionsCustomInfo(connections: Array<SpecificRunConnectionInfo>): Array<SpecificRunConnectionInfo> {
  return connections.map((connection: SpecificRunConnectionInfo) => {
    if (connection.nodePort) {
      return {
        ...connection,
        nodePort: null,
      };
    } else if (connection.externalUrl) {
      return {
        ...connection,
        externalUrl: null,
      };
    }
    return connection;
  });
}

function getDisabledToolTipText(action: EWorkloadAction, entity: EWorkloadEntity): string {
  switch (action) {
    case EWorkloadAction.activate:
      return `The selected ${entity} is already active or in the process of being activated`;
    case EWorkloadAction.run:
      return `The selected ${entity} is already running or being prepared to run`;
    case EWorkloadAction.stop:
      return `The selected ${entity} is already stopped or in the process of being stopped`;
    case EWorkloadAction.connect:
      return `To connect, the selected ${entity} must first be running`;
    default:
      return "Action for the selected workload is not authorized";
  }
}

function getConnectivityMessage(errorCode?: number): string {
  const unauthorizedProblem = "The API server is not configured correctly. Contact your administrator"; // when error code is 401
  const connectivityProblem = (errorCode?: number): string =>
    errorCode
      ? `There are issues with your connection to the cluster. Make sure you're using your organization's VPN, or contact your administrator (error code: ${errorCode})` // when error code is 403, 404 or 50X
      : "There are issues with your connection to the cluster. Contact your administrator.";
  switch (errorCode) {
    case 401:
      return unauthorizedProblem;
    case -1:
      return connectivityProblem();
    default:
      return connectivityProblem(errorCode);
  }
}

function getMissingDomainMessage(): string {
  return "The cluster domain is not defined. Contact your administrator.";
}

function getWorkspaceTrainingStatusColOptions(job?: DisplayedJob, tooltipText = ""): IStatusColOptions {
  const statusOptions = UI_WORKSPACE_STATUS[job?.status || ""];
  if (job?.msSinceLastStatusUpdate) {
    statusOptions.statusUpdatedTimeInMs = Date.now() - job?.msSinceLastStatusUpdate;
  }
  return tableUtil.getStatusColOptions(statusOptions, tooltipText);
}

//new workloads table
function getWorkloadStatusColOptions(workload: Workload, phaseMessage?: string): IStatusColOptions {
  const statusOptions: IStatusColOptions = { ...WorkloadPhaseMap[workload.phase] };
  statusOptions.statusUpdatedTimeInMs = new Date(workload.phaseUpdatedAt).getTime();
  return tableUtil.getStatusColOptions(statusOptions, phaseMessage);
}
function getWorkloadListModalStatusColOptions(phase: Phase): IStatusColOptions {
  if (!WorkloadPhaseMap[phase]) {
    return tableUtil.getStatusColOptions({ status: phase, displayAnimation: false });
  }
  const statusOptions: IStatusColOptions = { ...WorkloadPhaseMap[phase] };
  return tableUtil.getStatusColOptions(statusOptions);
}

function getPodStatusColOptions(pod: WorkloadServicePod): IStatusColOptions {
  const statusOptions: IStatusColOptions = tableUtil.getStatusColOptions(WorkloadPhaseMap[pod.k8sPhase as Phase]);
  statusOptions.statusUpdatedTimeInMs = new Date(pod.k8sPhaseUpdatedAt).getTime();
  if (statusOptions.status === "-" && pod.k8sPhase) {
    statusOptions.status = pod.k8sPhase;
  }
  return statusOptions;
}

function isWorkloadSourceControlPlane(workload: Workload): boolean {
  return workload.source === Source.ControlPlane;
}
function isWorkloadSourceCli(workload: Workload): boolean {
  return workload.source === Source.Cli;
}
function isRunaiWorkload(workload: Workload): boolean {
  return isWorkloadSourceCli(workload) || isWorkloadSourceControlPlane(workload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isWorkload(workload: any): workload is Workload {
  return workload.name !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _isWorkspaceList(workload: any): workload is IWorkspaceList {
  return !!workload.job;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _isTrainingList(workload: any): workload is ITrainingList {
  return !!workload.job;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _isWorkspaceV2(workload: any): workload is WorkspaceV2 {
  return workload.meta.workloadId !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _isTrainingV2(workload: any): workload is TrainingV2 {
  return workload.meta.workloadId !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _isInferenceV2(workload: any): workload is InferenceV2 | ModelInferenceV2 {
  return workload.meta.workloadId !== undefined;
}

function isPhaseUpdateRecordType(historyRecord: HistoryRecord): boolean {
  return historyRecord.meta.type === HistoryRecordType.PhaseUpdate;
}

function convertToWorkload(
  workload:
    | Workload
    | IWorkspaceList
    | ITrainingList
    | WorkspaceV2
    | TrainingV2
    | IInferenceList
    | Inference
    | InferenceV2
    | ModelInference
    | ModelInferenceV2,
  workloadType?: EWorkloadType,
): Workload {
  if (isWorkload(workload)) {
    return workload;
  } else if (workloadType === EWorkloadType.Inference) {
    return _isInferenceV2(workload)
      ? _convertWorkloadMetaToWorkload(workload.meta)
      : _convertInferenceV1WorkloadToWorkload(workload as Inference | ModelInference);
  } else if (_isWorkspaceV2(workload) || _isTrainingV2(workload) || _isInferenceV2(workload)) {
    return _convertWorkloadMetaToWorkload(workload.meta);
  } else if (_isTrainingList(workload) || _isWorkspaceList(workload)) {
    return _convertDisplayedJobToWorkload(workload.job as DisplayedJob);
  } else {
    throw new Error(`Failed to convert workload to Workload ${workload}`);
  }
}

function _convertInferenceV1WorkloadToWorkload(workload: Inference | ModelInference): Workload {
  return {
    source: Source.ControlPlane,
    conditions: [{ status: "", type: "", lastTransitionTime: null }],
    priorityClassName: "",
    type: String(EWorkloadType.Inference),
    name: workload.meta.name,
    id: workload.meta.id,
    clusterId: workload.meta.clusterId,
    projectName: "",
    projectId: String(workload.meta.projectId),
    departmentName: "",
    departmentId: "",
    namespace: "",
    createdAt: "",
    phase: Phase.Creating,
    k8sPhase: "",
    tenantId: 0,
    runningPods: 0,
    phaseUpdatedAt: "",
    k8sPhaseUpdatedAt: "",
    updatedAt: "",
    deletedAt: null,
    submittedBy: workload.meta.createdBy,
  };
}

function _convertDisplayedJobToWorkload(displayedJob: DisplayedJob): Workload {
  return {
    source: Source.ControlPlane,
    conditions: [{ status: displayedJob.status || "", type: "", lastTransitionTime: null }],
    priorityClassName: "",
    type: displayedJob.jobType || "",
    name: displayedJob.jobName || "",
    //@ts-ignore - api is inconsistent
    id: displayedJob.workloadId || "",
    clusterId: displayedJob.clusterId || "",
    projectName: displayedJob.project || "",
    projectId: "",
    namespace: "",
    createdAt: displayedJob.creationTime || "",
    phase: Phase.Creating,
    k8sPhase: "",
    tenantId: 0,
    runningPods: 0,
    departmentId: "",
    departmentName: "",
    phaseUpdatedAt: "",
    k8sPhaseUpdatedAt: "",
    updatedAt: "",
    deletedAt: null,
    submittedBy: displayedJob.user,
  };
}

function _convertWorkloadMetaToWorkload(workloadMeta: WorkloadMeta): Workload {
  return {
    phase: Phase.Creating,
    id: workloadMeta.workloadId,
    name: workloadMeta.name,
    clusterId: workloadMeta.clusterId,
    createdAt: workloadMeta.createdAt,
    departmentId: workloadMeta.departmentId || "",
    projectId: workloadMeta.projectId,
    submittedBy: workloadMeta.createdBy,
    conditions: [],
    deletedAt: null,
    departmentName: "",
    k8sPhase: "",
    k8sPhaseUpdatedAt: "",
    namespace: "",
    phaseUpdatedAt: "",
    priorityClassName: "",
    projectName: "",
    runningPods: 0,
    source: Source.ControlPlane,
    tenantId: 0,
    type: "",
    updatedAt: "",
  };
}

function _getDistributedFrameworkIcon(workload: Workload): undefined | string {
  if (!workload.distributedFramework) return undefined;
  return allWorkloadsIconsMap[workload.distributedFramework]
    ? allWorkloadsIconsMap[workload.distributedFramework]
    : undefined;
}

function _getToolTypeIcon(workload: Workload): undefined | string {
  const toolType = workload.environments?.[0]?.connections?.[0]?.toolType;
  if (!toolType) return undefined;
  return allWorkloadsIconsMap[toolType];
}

function _getDefaultIcon(workload: Workload): string {
  return allWorkloadsIconsMap[workload.type] || allWorkloadsIconsMap.Unknown;
}

function _getHuggingFaceInferenceIcon(workload: Workload): string | undefined {
  if (workload.type !== EWorkloadType.Inference) return undefined;
  const isRunaiModelExist: string | undefined =
    workload.environmentVariables && workload.environmentVariables[EHuggingFaceEnvVariableNames.RUNAI_MODEL];
  const isRunaiModelNameExist: string | undefined =
    workload.environmentVariables && workload.environmentVariables[EHuggingFaceEnvVariableNames.RUNAI_MODEL_NAME];
  if (
    workload.images &&
    workload.images[0]?.includes(LLM_IMAGE_FOR_HUGGING_FACE) &&
    isRunaiModelExist &&
    !isRunaiModelNameExist
  ) {
    return allWorkloadsIconsMap["HuggingFace"];
  } else {
    return undefined;
  }
}

function getWorkloadIcon(workload: Workload): string {
  const distributedIcon = _getDistributedFrameworkIcon(workload);
  if (distributedIcon) return distributedIcon;

  const huggingFaceIcon = _getHuggingFaceInferenceIcon(workload);
  if (huggingFaceIcon) return huggingFaceIcon;

  const toolTypeIcon = _getToolTypeIcon(workload);
  return toolTypeIcon ? toolTypeIcon : _getDefaultIcon(workload);
}

function convertConnectionsToToolItems(connections: Connection[]): IToolItem[] {
  return connections.map((connection: Connection) => ({
    toolType: connection.toolType as ToolType,
    toolName: connection.name,
    url: connection.url || "",
    authorizedUsers: connection.authorizedUsers || undefined,
  }));
}

function getRunningVsRequestedDisplayedValue(workload: Workload): string {
  if (workload.requestedPods?.number) {
    return `${workload.runningPods}/${workload.requestedPods.number}`;
  } else if (workload.requestedPods?.min !== undefined && workload.requestedPods.max) {
    return `${workload.runningPods}/${workload.requestedPods.min}-${workload.requestedPods.max}`;
  } else if (workload.requestedPods?.parallelism) {
    return `${workload.runningPods}/${workload.requestedPods.parallelism}`;
  } else if (workload.requestedPods?.completions && !workload.requestedPods?.parallelism) {
    return `${workload.runningPods}/1`;
  }
  return "-";
}

/**
 *
 * @param workload - current workload
 * @param connections current connections in the workload
 * @param clusterDomain For inference workloads we set the connections URL to the external URL. This sets the same URL for all the connections. This need to be changed in the future.
 * @returns Connection[] - updated connections
 */
function getWorkloadConnectionsForInference(
  workload: Workload,
  connections: Connection[],
  clusterDomain?: string,
): Connection[] {
  let currConnections = [...connections];

  if (workload.urls && workload.urls.length > connections.length) {
    const connectionMap = new Set(connections.map((c: Connection) => c.url));
    workload.urls?.forEach((url: string) => {
      if (connectionMap.has(url)) return;
      currConnections.push({
        url: url,
        name: "Serving Endpoint",
        toolType: "serving-endpoint",
        connectionType: InternalConnectionType.ServingPort,
      });
    });
  }

  if (clusterDomain) {
    currConnections = connections.map((c: Connection) => {
      if (c.connectionType !== InternalConnectionType.ExternalUrl) return c;
      const workloadName = workload.name;
      const projectName = workload.projectName;
      c.url = `${clusterDomain}/${projectName}/${workloadName}`;
      return c;
    });
  }

  return currConnections;
}
/**
 *
 * @param workload
 * @param clusterDomain For inference workloads we set the connections URL to the external URL. This sets the same URL for all the connections. This need to be changed in the future.
 * @returns Connection[]
 */
function getWorkloadConnectionsForGrid(workload: Workload, clusterDomain?: string): Connection[] {
  let connections: Connection[];
  if (workload.environments?.length) {
    connections = workload?.environments
      .reduce((acc: Connection[], env: Environment) => {
        acc = [...acc, ...(env.connections || [])];
        return acc;
      }, [])
      .filter((c) => {
        // Old clusters(older than 2.17) doesn't report the url for the serving port connection
        // In this case we want to filter it from the connections and add it later hard coded.
        if (c.connectionType === InternalConnectionType.ServingPort) {
          return !!c.url;
        } else {
          return true;
        }
      });
  } else {
    connections = workload.externalConnections || [];
  }

  return workload.type === EWorkloadType.Inference
    ? getWorkloadConnectionsForInference(workload, connections, clusterDomain)
    : connections;
}

/**
 * Applies a filter to the workloads based on the user who created them.
 * This function is used to filter the workloads displayed to the user,
 * showing only those that were created by the currently logged-in user.
 *
 * @param {string} userName - The name of the user for whom to filter the workloads.
 */
function applyUserCreatedWorkloadsFilter(shouldApplyFilter: boolean, userName: string): void {
  const isFirstLogin = storageUtil.get(STORAGE_KEYS.IS_FIRST_LOGIN);

  if (isFirstLogin) {
    const filterBy = filterService.loadFilters(window.location, ETableFilters.WORKLOAD, {});
    if (shouldApplyFilter) {
      const submittedByFilter = {
        term: userName,
        value: EFilterOperator.Equals,
        label: "Created by",
        name: WorkloadSortFilterFields.SubmittedBy,
        type: EColumnFilterType.FreeText,
      };
      filterService.setColumnAdvancedFilter(filterBy, submittedByFilter, ETableFilters.WORKLOAD);
    } else {
      filterBy.advancedFilters = [];
    }
    filterService.saveFilters(ETableFilters.WORKLOAD, filterBy);
  }
  storageUtil.save(STORAGE_KEYS.IS_FIRST_LOGIN, false);
}

/*
 * Adjust the workload phase filter to include all the relevant hidden phases,
 * for e.g. when Running is selected, also include Resuming and Updating
 * always include Terminating
 */
function adjustWorkloadPhaseFilter(filters: IAdvancedFilterModel[]): IAdvancedFilterModel[] {
  const updatedFilters: IAdvancedFilterModel[] = deepCopy(filters);
  const phaseFilter = updatedFilters.find((f) => f.name === WorkloadSortFilterFields.Phase) as IEnumBasedFilterModel;

  if (!phaseFilter) return updatedFilters;

  const phaseMapping: Record<string, string[]> = {
    [Phase.Running]: [Phase.Resuming, Phase.Updating],
    [Phase.Stopped]: [Phase.Stopping],
  };

  for (const phase in phaseMapping) {
    if (phaseFilter.selectedValues.includes(phase as keyof typeof phaseMapping)) {
      phaseFilter.selectedValues.push(...phaseMapping[phase as keyof typeof phaseMapping]);
    } else {
      phaseFilter.selectedValues = phaseFilter.selectedValues.filter(
        (v) => !phaseMapping[phase as keyof typeof phaseMapping].includes(v),
      );
    }
  }

  if (!phaseFilter.selectedValues.includes(Phase.Terminating)) {
    phaseFilter.selectedValues.push(Phase.Terminating);
  }

  return updatedFilters;
}

function getSupportedRoutes(fromPage: string, leavePage: string): IWorkloadSupportedRoutes | undefined {
  return {
    environment: {
      name: ENVIRONMENT_ROUTE_NAMES.ENVIRONMENT_NEW,
      query: { fromPage },
    },
    compute: {
      name: COMPUTE_RESOURCE_ROUTE_NAMES.COMPUTE_RESOURCE_NEW,
      query: { fromPage },
    },
    datasource: {
      pages: DATA_SOURCE_CREATION_ROUTE_MAP,
    },
    onLeave: {
      name: leavePage,
    },
  };
}
