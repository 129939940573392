<template>
  <runai-expansion-item class="settings-container" label="Analytics" :subheader="sectionSubHeader">
    <div class="row items-center q-mb-md" v-if="analyticsSectionSettings.cpu">
      <toggle-setting :setting="analyticsSectionSettings.cpu" />
    </div>
    <template v-if="analyticsSectionSettings.consumption">
      <q-separator class="q-mb-md hide-if-last-child" />
      <div>
        <dashborad-resource-cost :consumption-setting="analyticsSectionSettings.consumption" />
      </div>
    </template>
    <template v-if="analyticsSectionSettings.advancedGpuMetrics">
      <q-separator class="q-mb-md hide-if-last-child" />
      <div class="row items-center q-mb-md">
        <toggle-setting :setting="analyticsSectionSettings.advancedGpuMetrics" />
      </div>
    </template>
    <q-separator class="q-mb-md" />
    <div class="row items-center q-mb-md" v-if="analyticsSectionSettings.optimizationEnabled">
      <toggle-setting
        :setting="analyticsSectionSettings.optimizationEnabled"
        :warning-message="warningMessage"
        emit-only
        @update-setting="enablePerformance"
      />
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { useSettingStore } from "@/stores/setting.store";
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";
import DashboradResourceCost from "@/components/settings/sections/analytics/dashboard-resources-cost/dashboard-resource-cost.vue";
import { type IAnalyticsSectionSettings, SettingKeys, settingUpdateSuccessMessage } from "@/models/setting.model";
import { alertUtil } from "@/utils/alert.util";
import { settingsUtil } from "@/utils/settings.util";

export default defineComponent({
  name: "analytics-section",
  components: {
    DashboradResourceCost,
    ToggleSetting,
    RunaiExpansionItem,
  },
  props: {
    analyticsSectionSettings: {
      type: Object as PropType<IAnalyticsSectionSettings>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      showPerformanceModal: false as boolean,
    };
  },
  computed: {
    isPerformanceEnabled(): boolean {
      const optimizationEnabled = this.analyticsSectionSettings?.optimizationEnabled?.value;
      return optimizationEnabled != undefined && Boolean(optimizationEnabled);
    },
    warningMessage(): string {
      return !this.isPerformanceEnabled
        ? "Once activated, dashboard data collected before cluster version 2.13 will no longer be visible"
        : "";
    },
    sectionSubHeader(): string {
      return settingsUtil.getToggleSectionCollapsedTitle(this.analyticsSectionSettings);
    },
  },
  methods: {
    async enablePerformance(): Promise<void> {
      try {
        await this.settingStore.updateSetting(
          SettingKeys.DashboardOptimization as SettingKeys,
          !this.analyticsSectionSettings?.optimizationEnabled?.value,
        );
        this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
      } catch (err) {
        this.$q.notify(settingsUtil.getSettingErrorMessage());
      }
    },
  },
});
</script>
