import { ResourceType, Action } from "@/swagger-models/authorization-client";

import { WORKLOAD_TEMPLATE_ROUTE_NAMES } from "./workload-template.routes.names";

import WorkloadTemplateIndex from "@/views/workload-template/workload-template-index.vue";
import WorkloadTemplateNewApp from "@/views/workload-template/workload-template-new-app.vue";
import WorkloadTemplateNew from "@/views/workload-template/workload-template-new.vue";
import WorkloadTemplateAssetsApp from "@/views/workload-template/workload-template-assets-app.vue";
import type { RunaiRouteRecord } from "vue-router";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";

export const workloadTemplateRoutes: RunaiRouteRecord = {
  path: "/workload-templates",
  // route level code-splitting
  // this generates a separate chunk (workload-template.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/workload-template/workload-template-app.vue"),
  children: [
    {
      path: "",
      name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_INDEX,
      component: WorkloadTemplateIndex,
      meta: {
        pageTitle: "Templates",
        resourceType: ResourceType.Templates,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
    },
    {
      path: "new",
      name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW_APP,
      component: WorkloadTemplateNewApp,
      children: [
        {
          path: "",
          name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
          component: WorkloadTemplateNew,
          meta: {
            pageTitle: "New template",
            fullWidth: true,
            requestToLeave: true,
            backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_INDEX,
            resourceType: ResourceType.Templates,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
            parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
          },
        },
        {
          path: "assets",
          name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_ASSETS_APP,
          component: WorkloadTemplateAssetsApp,
        },
      ],
    },
  ],
};
