export const prodDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)?run\.ai$/;
export const stagingDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)?staging\.run\.ai$/;
export const testDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)?test\.run\.ai$/;

export enum EnvType {
  TEST,
  STAGING,
  PROD,
  DEV,
}

export function getEnvType(hostName: string): EnvType {
  const isProd = prodDomains.test(hostName);
  const isStaging = stagingDomains.test(hostName);
  const isTest = testDomains.test(hostName);

  if (isTest) {
    return EnvType.TEST;
  } else if (isStaging) {
    return EnvType.STAGING;
  } else if (isProd) {
    return EnvType.PROD;
  }
  return EnvType.DEV;
}
