import type { ESortOrder } from "@/models/filter.model";

export enum EEventStatus {
  Succeeded = "Succeeded",
  Failed = "Failed",
}
export interface IAuditEventQueryParams {
  start?: string;
  end?: string;
  filterBy?: Array<string>;
  sortBy?: string;
  sortOrder?: ESortOrder;
  offset?: number;
  numberOfSamples?: number;
}
