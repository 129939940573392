<template>
  <section class="not-permitted row justify-center items-center" aid="no-eula">
    <div class="column">
      <span class="title">To start using Run:ai, your</span>
      <span class="title">administrator needs to sign the end-</span>
      <span class="title">user license agreement (EULA) first</span>
    </div>
    <runai-svg-icon name="403" size="500px" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
});
</script>

<style scoped lang="scss">
.not-permitted {
  .title {
    font-size: 40px;
    color: $black-70;
  }

  .subtitle {
    font-size: 18px;
    color: $black-70;
  }

  .link {
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
